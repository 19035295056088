/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react"
import { FileResponse, FileState, UploadFileResposne } from "./model/file.model";
import { BEARER_STRING, DEFINE_COMMON_GET_FILE, DEFINE_COMMON_MODIFY_FILE, DEFINE_COMMON_UPLOAD_FILE, MBAAS_ACCESS_TOKEN } from './const';
import { fn_Debug, fn_Decrypt, fn_Loginout } from "./module";
import axios, { Axios, AxiosRequestConfig } from "axios";
import { define } from "./define/define";
import { useLayoutHook } from "../components/common/Layout.hook";
import { BasicAlertModel } from "../components/alert/BasicAlert.model";
import { AlertContext, LoadingContext } from "./context";
import { ApiResponse } from "./model/response";
import { useNavigate } from "react-router-dom";
import { ChatGPTRequestModel, ChatGPTResponseModel } from "./model/chatGPT.model";

export const usePopupHook = (initialValue: boolean) => {
    const [isOpen, setIsOpen] = useState(initialValue);

    const onClick = () => {
        setIsOpen(!isOpen);
    }

    return {isOpen, onClick};
}

export const useAlertHook = (initialValue: boolean) => {
    const [isOpen, setIsOpen] = useState(initialValue);

    const onClick = () => {
        setIsOpen(!isOpen);
    }

    return {isOpen, onClick};
}

export const useInterval = (callback: () => void, interval: number) => {
    const savedCallback = useRef<(() => void) | null>(null);
    const intervalTimer = useRef<NodeJS.Timer>();

    savedCallback.current = callback;
    function tick() {
        if (savedCallback.current) {
            savedCallback.current();
        }
    }
    
    const run = () => {
        let timer = setInterval(tick, interval);
        intervalTimer.current = timer;
    }

    const stop = () => {
        clearInterval(intervalTimer.current);
    }
    
    return {
        run,
        stop,
    }
};

export const useFileHook = (fileResponse?: FileResponse[]) => {
    const [fileState, setFileState] = useState<FileState>({
        fileResponse: fileResponse,
        deleteIdxes: [],
    });
    const [alertProps, setAlertProps] = useState<BasicAlertModel>({
        alertText: "",
        open: false,
        serverity: "success",
    });
    const layoutHook = useLayoutHook();
    const axiosHook = useAxios();

    useEffect(() => {
        fn_Debug("FILE_STATE");
        fn_Debug(fileState);
    }, [fileState])
    
    const uploadFile = async(e:any) => {
        const uploadList = fileState.uploadFiles ? [...fileState.uploadFiles!, ...e.target.files] : [...e.target.files];
        setFileState({
            ...fileState,
            uploadFiles: uploadList
        })

        if(!uploadList) return "FAIL";
        fn_Debug("CALL_UPLOAD_FILE");
        const data = new FormData();
        uploadList?.forEach(file => {
            data.append("file", file);
        })
        const response = await axiosHook.uploadFileData<UploadFileResposne>(DEFINE_COMMON_UPLOAD_FILE, data);
        fn_Debug("UPLOAD_FILE_RESPONSE");
        fn_Debug(response);

        if(response.fleGrpId) {
            setAlertProps({
                alertText: "파일이 첨부되었습니다.",
                open: true,
                serverity: "success",
            })
        } else {
            setAlertProps({
                alertText: "파일 첨부 과정중 에러가 발생했습니다.",
                open: true,
                serverity: "error",
            })
        }

        return response.fleGrpId;
    }

    const getFileByGrpId = async (fileId: string) => {
        return axios.get<FileResponse[]>(
            define[DEFINE_COMMON_GET_FILE].url,
            {params: {fleGrpId: fileId}},
        ).then((res) => {
            fn_Debug("COMMON_GET_FILE_RESPONSE");
            fn_Debug(res.data)
            return res.data;
        })
    }

    const modifyFile = async(e: any) => {
        const uploadList = fileState.uploadFiles ? [...fileState.uploadFiles!, ...e.target.files] : [...e.target.files];
        setFileState({
            ...fileState,
            uploadFiles: uploadList
        })

        const data = new FormData();

        fileState.deleteIdxes.forEach(idx => {
            data.append("fleIdxs", String(idx));
        });

        // fileState.uploadFiles?.forEach(file => {
        //     data.append("file", file);
        // });

        uploadList?.forEach(file => {
            data.append("file", file);
        });

        if(fileState.deleteIdxes.length === 0 && uploadList?.length === 0) return "FAIL";

        data.append("fleUpdId", layoutHook.statusValue.memberData?.memberEmail!);
        data.append("fleGrpId", fileState.fleGrpId!);
        fn_Debug("CALL_MODIFY_FILE");
        const response = await axiosHook.uploadFileData<UploadFileResposne>(DEFINE_COMMON_MODIFY_FILE, data);
        
        setFileState({
            ...fileState,
            // fileResponse: response.resultList,
            uploadFiles: [],
        })
        

        if(response.fleGrpId) {
            setAlertProps({
                alertText: "파일이 첨부되었습니다.",
                open: true,
                serverity: "success",
            })

            const result = await getFileByGrpId(fileState.fleGrpId!);
            fn_Debug("RESPONSE_AFTER_MODIFY_FILE");
            fn_Debug(result)

            if(result)
                setFileState({
                    ...fileState,
                    fileResponse: result,
                    uploadFiles: [],
                })
        } else {
            setAlertProps({
                alertText: "파일 첨부 과정중 에러가 발생했습니다.",
                open: true,
                serverity: "error",
            })
        }
    }

    const modifySingleFile = async(e: any) => {
        const uploadList = fileState.uploadFiles ? [...fileState.uploadFiles!, ...e.target.files] : [...e.target.files];
        setFileState({
            ...fileState,
            uploadFiles: uploadList
        })

        if(!uploadList) return "FAIL";
        fn_Debug("CALL_UPLOAD_FILE");
        const data = new FormData();
        uploadList?.forEach(file => {
            data.append("file", file);
        })
        const response = await axiosHook.uploadFileData<UploadFileResposne>(DEFINE_COMMON_UPLOAD_FILE, data);
        fn_Debug("UPLOAD_FILE_RESPONSE");
        fn_Debug(response);

        if(response.fleGrpId) {
            setAlertProps({
                alertText: "파일이 첨부되었습니다.",
                open: true,
                serverity: "success",
            })
        } else {
            setAlertProps({
                alertText: "파일 첨부 과정중 에러가 발생했습니다.",
                open: true,
                serverity: "error",
            })
        }

        return response.fleGrpId;
    }

    const addUploadFile = (e: any) => {
        const uploadList = fileState.uploadFiles ? [...fileState.uploadFiles!, ...e.target.files] : [...e.target.files];
        setFileState({
            ...fileState,
            uploadFiles: uploadList
        })
    }

    const deleteUploadFile = (lastModified: number) => {
        let uploadFilesCopy = fileState.uploadFiles;
        uploadFilesCopy = uploadFilesCopy?.filter(f => {
            return !(f.lastModified === lastModified);
        })
        setFileState({
            ...fileState,
            uploadFiles: uploadFilesCopy,
        })
    }

    const deleteFileResponse = async(e:any, deleteIdx: number, afterPost: () => void) => {
        let fileResponseCopy = fileState.fileResponse;
        fileResponseCopy = fileResponseCopy?.filter(f => {
            return !(f.fleIdx === deleteIdx);
        })

        let deleteIdxesCopy = fileState.deleteIdxes ?? [];
        deleteIdxesCopy.push(deleteIdx);

        setFileState({
            ...fileState,
            fileResponse: fileResponseCopy,
            deleteIdxes: deleteIdxesCopy,
        })

        const data = new FormData();

        fileState.deleteIdxes.forEach(idx => {
            data.append("fleIdxs", String(idx));
        });

        data.append("fleUpdId", layoutHook.statusValue.memberData?.memberEmail!);
        data.append("fleGrpId", fileState.fleGrpId!);
        fn_Debug("CALL_MODIFY_FILE");
        await axiosHook.uploadFileData<UploadFileResposne>(DEFINE_COMMON_MODIFY_FILE, data);
        
        setFileState({
            ...fileState,
            deleteIdxes: [],
        })
        
        setAlertProps({
            alertText: "파일이 삭제되었습니다.",
            open: true,
            serverity: "warning",
        })
        
        const result = await getFileByGrpId(fileState.fleGrpId!);
        fn_Debug("RESPONSE_AFTER_DELETE_FILE")
        fn_Debug(result)

        if(result.length === 0) {
            afterPost();
            setFileState({
                ...fileState,
                fileResponse: result,
                fleGrpId: "", 
                deleteIdxes: [],
            })
        } else {
            setFileState({
                ...fileState,
                fileResponse: result,
                deleteIdxes: [],
            })
        }
        
    }

    return {
        fileState,
        uploadFile,
        getFileByGrpId,
        modifyFile,
        addUploadFile,
        deleteFileResponse,
        deleteUploadFile,
        setFileState,
        alertProps,
        setAlertProps,
        modifySingleFile,
    }
}

export const useAxios = () => {
    const alertContext = useContext(AlertContext);
    const loadingContext = useContext(LoadingContext);
    const navigator = useNavigate();

    const getData = async <T>(defineName: string, config?: AxiosRequestConfig, isPathvar:boolean = false, pathvars?: string[]): Promise<ApiResponse<T>> => {
        const client: Axios = define[defineName].getClient();
        let newUrl = define[defineName].url;
        if(sessionStorage.getItem(MBAAS_ACCESS_TOKEN)) {
            config = {
                ...config,
                headers: {
                    ...config?.headers,
                    Authorization: `${BEARER_STRING}${fn_Decrypt(sessionStorage.getItem(MBAAS_ACCESS_TOKEN)!)}`,
                }
            }
        }
        
        if(isPathvar) {
            for(let num in pathvars) {
                newUrl += `/${pathvars[Number(num)]}`
            }
        }
    
        try {
            loadingContext.startLoading();
            const response = await client.get<ApiResponse<T>>(newUrl, config);
            setTimeout(() => {loadingContext.endLoading();}, 500);
            return response.data;
        } catch (error:any) {
            setTimeout(() => {loadingContext.endLoading();}, 500);
            return error.response.data;
        }
    };
    
    const postData = async <T>(defineName: string, data?: any, config?: AxiosRequestConfig): Promise<ApiResponse<T>> => {
        const client: Axios = define[defineName].getClient();
        if(sessionStorage.getItem(MBAAS_ACCESS_TOKEN)) {
            config = {
                ...config,
                headers: {
                    ...config?.headers,
                    Authorization: `${BEARER_STRING}${fn_Decrypt(sessionStorage.getItem(MBAAS_ACCESS_TOKEN)!)}`,
                }
            }
        }
        
        try {
            loadingContext.startLoading();
            const response = await client.post<ApiResponse<T>>(define[defineName].url, data, config);
            setTimeout(() => {loadingContext.endLoading();}, 500);
            return response.data;
        } catch (error:any) {
            setTimeout(() => {loadingContext.endLoading();}, 500);
            return error.response.data;
        }
    };
    
    const uploadFileData = async <T>(defineName: string, data?: any, config?: AxiosRequestConfig): Promise<T> => {
        const client: Axios = define[defineName].getClient();
        if(sessionStorage.getItem(MBAAS_ACCESS_TOKEN)) {
            config = {
                ...config,
                headers: {
                    ...config?.headers,
                    Authorization: `${BEARER_STRING}${fn_Decrypt(sessionStorage.getItem(MBAAS_ACCESS_TOKEN)!)}`,
                }
            }
        }
        
        try {
            loadingContext.startLoading();
            const response = await client.post<T>(define[defineName].url, data, config);
            setTimeout(() => {loadingContext.endLoading();}, 500);
            return response.data;
        } catch (error:any) {
            setTimeout(() => {loadingContext.endLoading();}, 500);
            return error.response.data;
        }
    };
      
    const putData = async <T>(defineName: string, data?: any, config?: AxiosRequestConfig): Promise<ApiResponse<T>> => {
        const client: Axios = define[defineName].getClient();
        if(sessionStorage.getItem(MBAAS_ACCESS_TOKEN)) {
            config = {
                ...config,
                headers: {
                    ...config?.headers,
                    Authorization: `${BEARER_STRING}${fn_Decrypt(sessionStorage.getItem(MBAAS_ACCESS_TOKEN)!)}`,
                }
            }
        }
    
        try {
            loadingContext.startLoading();
            const response = await client.put<ApiResponse<T>>(define[defineName].url, data, config);
            setTimeout(() => {loadingContext.endLoading();}, 500);
            return response.data;
        } catch (error:any) {
            setTimeout(() => {loadingContext.endLoading();}, 500);
            return error.response.data;
        }
    };    
      
    const deleteData = async <T>(defineName: string, config?: AxiosRequestConfig, isPathvar:boolean = false, pathvars?: string[]): Promise<ApiResponse<T>> => {
        const client: Axios = define[defineName].getClient();
        let newUrl = define[defineName].url;
        if(sessionStorage.getItem(MBAAS_ACCESS_TOKEN)) {
            config = {
                ...config,
                headers: {
                    ...config?.headers,
                    Authorization: `${BEARER_STRING}${fn_Decrypt(sessionStorage.getItem(MBAAS_ACCESS_TOKEN)!)}`,
                }
            }
        }
        
        if(isPathvar) {
            for(let num in pathvars) {
                newUrl += `/${pathvars[Number(num)]}`
            }
        }
    
        try {
            loadingContext.startLoading();
            const response = await client.delete<ApiResponse<T>>(newUrl, config);
            setTimeout(() => {loadingContext.endLoading();}, 500);
            return response.data;
        } catch (error:any) {
            return error.response.data;
        }
    };

    return {
        getData,
        postData,
        uploadFileData,
        putData,
        deleteData,
    }
}

export const useChatGPT = () => {
    const loadingContext = useContext(LoadingContext);
    
    const sendContent = async(content: string):Promise<ChatGPTResponseModel> => {
        loadingContext.startCodeLoading();
        content = `아무런 말도 하지말고 그냥 코드만줘. 

        ${content}
        
        라는 문구에서 원하는 기능들을 포함한 앱을 만들어줘. 
        
        1. 화면이동등 기본적인 기능은 다 포함시켜줘. 
        2.절대 코드이외에 다른말은 하지마. 
        3.화면 내부는 그냥 텍스트만 적지말고 적재적소에 필요한 ui를 삽입해줘. 
        4.메인페이지는 특히 신경써줘. 
        5.다른 많은 쇼핑몰앱들 처럼 상품진열 등 여러 ui로 채워줘.
        6.절대 기능을 간소화하거나 페이지를 빼먹지마. 
        7.다시한번 강조할게. 페이지가 보여졌을때 "it is a cart menu" 등 이런식으로만 보여주지마 절대 이 항목은 무조건 지켜져야해. 
        8.적절하게 이미지 아이콘 버튼등 다양한 ui를 활용해서 간단하게라도 포괄적으로 많이 쓰이는 ui를 구성해줘.
        9. 모든 페이지에서 body: Center(child: Text('Past Orders')), 이런식으로 처리하지마.
        10.글자는 한글로해줘.
        11.Drawer등 메뉴가 있으면 전부 연결된 페이지를 만들어줘
        
        위 8가지 항목은 무조건 반드시 절대 지켜줘.
        `;
        const requestModel:ChatGPTRequestModel = {
            model: "gpt-4-1106-preview",
            messages: [
                {
                    role: "user",
                    content: content,
                }
            ]
        };

        const client = axios.create({
            baseURL: `https://api.openai.com`,
        });
        const config:AxiosRequestConfig = {
            headers: {
                Authorization: `${BEARER_STRING} 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`,
            },
        }
    
        const response = await client.post<ChatGPTResponseModel>("/v1/chat/completions", requestModel, config);
        loadingContext.endCodeLoading();
    
        return response.data;
    }

    return {
        sendContent,
    }
}