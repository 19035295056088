import React from 'react'
import { useNavigate } from 'react-router-dom'

const HelpApList = () => {
  const navigator = useNavigate();

  return (
    <>
      <h2>앱 리스트</h2>
      <div className="flex flexE"><button className="on ">앱 생성</button></div>
      <div className="flex spaceB alignC" style={{marginTop: "20px"}}>
        <div className="search">
          <select>
            <option value="all">전체</option>
            <option value="name" selected>앱이름 명</option>
          </select>
          <input type="text" placeholder="검색어를 입력해주세요."/>
          <button><img src={require("../../../../../img/icon_search_white.png")} alt="검색" /></button>
        </div>
        <div className="filter">
          <select>
            <option value="all">전체</option>
            <option value="use" selected>사용</option>
            <option value="notUse">미사용</option>
          </select>
        </div>
      </div>

      <div className="boxContents">
        <table className="table table--block">
          <thead>
            <tr>
              <th className="tableShort">No</th>
              <th className="tableLong">앱이름</th>
              <th>앱유형</th>
              <th>생성일</th>
              <th>기획서</th>
              <th>상태</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td data-name="No.">3</td>
              <td data-name="앱이름">엠바스</td>
              <td data-name="앱유형">테이크아웃</td>
              <td data-name="생성일">YYYY-MM-DD</td>
              <td data-name="기획서"><button>기획서 바로가기</button></td>
              <td data-name="상태"><span className="use">사용</span></td>
            </tr>
            <tr>
              <td data-name="No.">2</td>
              <td data-name="앱이름">엠바스</td>
              <td data-name="앱유형">원데이클래스</td>
              <td data-name="생성일">YYYY-MM-DD</td>
              <td data-name="기획서"><button>기획서 바로가기</button></td>
              <td data-name="상태"><span className="use">사용</span></td>
            </tr>
            <tr>
              <td data-name="No.">1</td>
              <td data-name="앱이름">엠바스</td>
              <td data-name="앱유형">협회</td>
              <td data-name="생성일">YYYY-MM-DD</td>
              <td data-name="기획서"><button>기획서 바로가기</button></td>
              <td data-name="상태"><span className="use">사용</span></td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default HelpApList