import React from 'react'
import { useNavigate } from 'react-router-dom'
import { CONSULT_LIST_PATH, CONSULT_READ_PATH, CONSULT_UPDATE_PATH } from '../../../../common/path';

const ConsultUpdate = () => {
  const navigator = useNavigate();

  return (
    <>
        <h2>요청상담 관리 상세</h2>

        <div className="boxContents">
            <table className="tableCol">
            <tr>
                <th>접수 채널</th>
                <td>value</td>
            </tr>
            <tr>
                <th>상담분류</th>
                <td>value</td>
            </tr>
            <tr>
                <th>아이디</th>
                <td>value</td>
            </tr>
            <tr>
                <th>연락처</th>
                <td>value</td>
            </tr>
            <tr>
                <th>이메일</th>
                <td>value</td>
            </tr>
            <tr>
                <th>상담 내용</th>
                <td>value</td>
            </tr>
            <tr>
                <th>답변 내용</th>
                <td>
                <div>
                    <textarea></textarea>
                </div>
                </td>
            </tr>
            <tr>
                <th>상태</th>
                <td>
                <select>
                    <option>value</option>
                    <option>미답변</option>
                    <option>답변완료</option>
                    <option>치리완료</option>
                </select>
                </td>
            </tr>
            <tr>
                <th>접수 일시</th>
                <td>value</td>
            </tr>
            <tr>
                <th>답변 일시(답변자)</th>
                <td>value (value)</td>
            </tr>
            <tr>
                <th>완료 일시(완료처리자)</th>
                <td>value (value)</td>
            </tr>
            </table>
            <div className="btnBoxB">
            <button className="off" onClick={() => {navigator(CONSULT_LIST_PATH)}}>목록</button>
            <button className="on" onClick={() => {navigator(CONSULT_READ_PATH)}}>수정</button>
            </div>
        </div>
    </>
  )
}

export default ConsultUpdate