import React from 'react'
import { useApMemReadHook } from './ApMemRead.hook'
import { useNavigate } from 'react-router-dom';

const ApMemRead = () => {
  const navigator = useNavigate();
  const apMemReadHook = useApMemReadHook();

  return (
    <>
        <h2>앱회원 <span>{apMemReadHook.statusValue.apMemResponse?.appMemberName}</span> 님의 상세 정보</h2>

        <div className="boxContentsBg">
            <div className="boxContents">
                <div className="flex">
                    <div>
                        <div className="detailTop flex">
                            <div className="contentTit">이름</div>
                            <div className="contentCon">{apMemReadHook.statusValue.apMemResponse?.appMemberName}</div>
                        </div>
                        <div className="detailTop flex">
                            <div className="contentTit">아이디</div>
                            <div className="contentCon">{apMemReadHook.statusValue.apMemResponse?.appMemberEmail}</div>
                        </div>
                        {/* <div className="detailTop flex">
                            <div className="contentTit">회원유형</div>
                            <div className="contentCon">{apMemReadHook.statusValue.apMemResponse?.businessType}</div>
                        </div> */}
                        <div className="detailTop flex">
                            <div className="contentTit">상태</div>
                            <div className="contentCon">{apMemReadHook.statusValue.apMemResponse?.appMemberStatus}</div>
                        </div>
                        <div className="detailTop flex">
                            <div className="contentTit">가입일</div>
                            <div className="contentCon">{apMemReadHook.statusValue.apMemResponse?.createAt}</div>
                        </div>
                    </div>
                </div>
                <div className="flex">
                    {/* <select id="staus">
                        <option value="all">전체</option>
                        <option value="act" selected>활동</option>
                        <option value="sleep">휴면</option>
                        <option value="wthdr">탈퇴</option>
                        <option value="stop">사용중지</option>
                        <option value="fire">강퇴</option>
                    </select> */}
                    <div className="selectOption">
                        <ul>
                            <li className="sleep">휴면일시 : 2023-07-15</li>
                            <li className="wthdr"><input type="date" date-placeholder="기간(시작)" required/> ~ <input type="date" date-placeholder="기간(종료)" required/></li>
                            <li className="stop"><input type="date" date-placeholder="기간(시작)" required/> ~ <input type="date" date-placeholder="기간(종료)" required/></li>
                            <li className="fire">강퇴일시 : 2023-07-15</li>
                        </ul>
                    </div>
                </div>
                <div className="btnBox">
                    <button className="off" onClick={() => {navigator(-1)}}>닫기</button>
                    {/* <button className="on">수정</button> */}
                </div>
            </div>
        </div>  
    </>
  )
}

export default ApMemRead