import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import "../../css/common.css";
import "../../css/font.css";
import "../../css/media.css";
import { ADD_FUNC_LIST_PATH, APHELP_READ_PATH, APP_MEMBER_PATH, COM_BOARD_PATH, COM_COMPANY_PATH, COM_MEMBER_PATH, COM_MYPAGE_PATH, COM_TEMPLATE_PATH, CONSULT_LIST_PATH, ITEM_LIST_PATH, MYPAGE_READ_PATH, M_BOARD_LIST_PATH, M_MEMBER_PATH, RESERVE_LIST_PATH, ROOT_PATH, SETTING_SYSTEM_PATH, SIM_APP_LIST_PATH, TEM_LIST_PATH, USERASK_LIST_PATH } from '../../common/path';
import { useDropDownHook } from '../../ui/ui.hooks';
import { fn_Loginout } from '../../common/module';
import { useLayoutHook } from './Layout.hook';
import DefaultPopup from '../alert/DefaultPopup';
import BasicPopup from '../popup/BasicPopup';
import { AlertProvider, LoadingProvider } from '../../common/context';
import { ROLE_ADMIN, ROLE_USER } from '../../common/const';
import { QRCodeCanvas } from 'qrcode.react';
import Tracker from './Tracker';

const Layout:React.FC = () => {
  const location = useLocation();
  const navigator = useNavigate();

  const dropDownHook = useDropDownHook(false);

  const layoutHook = useLayoutHook();

  return (
    <div className="wrap">
    <Tracker/>
    <header className="header flex alignC flexE">
        <div className="util flex alignC">
            <div><button className='qrBtn'><a href='https://file.aiapp.link/v1/download2/2491'>매뉴얼 다운로드</a></button></div>
            {
                layoutHook.statusValue.memberData?.role === ROLE_USER ? <div onClick={layoutHook.onClickQrBtn}><button className='qrBtn'>앱 다운로드 QR코드 보기</button></div> : ""
            }
            {/* <div className="search"><img src={require('../../img/icon_search.png')} alt="검색아이콘"/></div> */}
            <div className="logout" onClick={() => {fn_Loginout(navigator)}}><img src={require('../../img/icon_logout.png')} alt="로그아웃"/></div>
            <div className="profile flex alignC"><img src={require('../../img/icon_profile.png')} alt="프로필이미지"/><span>{layoutHook.statusValue.memberData?.memberName}</span>님</div>
        </div>
    </header>

    {/* qr팝업 */}
    {
        layoutHook.openQr ?
        <div className='popWrap'>
            <div className='popUp'>
            <button className="closeBtn" onClick={layoutHook.onClickQrBtn}><img src={require('../../img/icon_close.png')} alt="close"></img></button>
                <div className="QrWrap">
                    <QRCodeCanvas value={layoutHook.appDownUrl}/>
                    <p>apk 파일로 안드로이드 폰(AOS)에서 테스트 가능합니다.</p>
                </div>
            </div>
        </div> : ""
    }

    <div className="left_sub_menu">
        <div className="sub_menu">
            <h1><img src={require('../../img/Ai.app_logoW.png')} alt="엠바스 로고"/></h1>
            <ul className="big_menu">
                <Link to={ROOT_PATH}><li className={location.pathname === ROOT_PATH ? "on" : ""}>대시보드</li></Link>
            </ul>
            <ul className="big_menu">
                <Link to={layoutHook.statusValue.memberData?.role === ROLE_ADMIN ? M_MEMBER_PATH : APP_MEMBER_PATH}><li className={location.pathname.includes(COM_MEMBER_PATH) ? "on" : ""}>회원관리</li></Link>
            </ul>
            <ul className="big_menu">
                <li className={location.pathname.includes(COM_BOARD_PATH) ? "on" : ""} onClick={() => {navigator(M_BOARD_LIST_PATH)}}>공지사항</li>
            </ul>
            <ul className="big_menu">
                <li className={location.pathname.includes(USERASK_LIST_PATH) ? "on" : ""} onClick={() => {navigator(USERASK_LIST_PATH)}}>1:1문의</li>
            </ul>
            <ul className="big_menu">
                <li onClick={dropDownHook.onClick} className={location.pathname.includes(COM_TEMPLATE_PATH) ? "on" : ""}>템플릿</li>
                <ul className={dropDownHook.isOpen ? "small_menu on" : "small_menu"}>
                    {/* {
                        layoutHook.statusValue.memberData?.role === ROLE_ADMIN ? <li onClick={() => {navigator(APP_USE_LIST_PATH)}} className={location.pathname.includes(APP_USE_LIST_PATH) ? "on" : ""}>앱용도 관리</li> : ""
                    } */}
                    {
                        layoutHook.statusValue.memberData?.role === ROLE_ADMIN ? <li onClick={() => {navigator(SIM_APP_LIST_PATH)}} className={location.pathname.includes(SIM_APP_LIST_PATH) ? "on" : ""}>유사앱 관리</li> : ""
                    }
                    {
                        layoutHook.statusValue.memberData?.role === ROLE_ADMIN ? <li onClick={() => {navigator(TEM_LIST_PATH)}} className={location.pathname.includes(TEM_LIST_PATH) ? "on" : ""}>템플릿 관리</li> : ""
                    }
                    {
                        layoutHook.statusValue.memberData?.role === ROLE_ADMIN ? <li onClick={() => {navigator(ADD_FUNC_LIST_PATH)}} className={location.pathname.includes(ADD_FUNC_LIST_PATH) ? "on" : ""}>추가기능 관리</li> : ""
                    }
                    {
                        layoutHook.statusValue.memberData?.role === ROLE_USER ? <li onClick={() => {navigator(APHELP_READ_PATH)}} className={location.pathname.includes(APHELP_READ_PATH) ? "on" : ""}>앱 관리</li> : ""
                    }
                    {
                        // layoutHook.statusValue.memberData?.role === ROLE_ADMIN ? <li onClick={() => {navigator(ADV_LIST_PATH)}} className={location.pathname.includes(ADV_LIST_PATH) ? "on" : ""}>광고 관리</li> : ""
                    }
                    {/* <li onClick={() => {navigator(CONSULT_LIST_PATH)}}>상담 관리</li> */}
                    {
                        layoutHook.statusValue.memberData?.role === ROLE_USER ? <li onClick={() => {navigator(ITEM_LIST_PATH)}} className={location.pathname.includes(ITEM_LIST_PATH) ? "on" : ""}>아이템 관리</li> : ""
                    }
                    {
                        layoutHook.statusValue.memberData?.role === ROLE_USER ? <li onClick={() => {navigator(RESERVE_LIST_PATH)}} className={location.pathname.includes(RESERVE_LIST_PATH) ? "on" : ""}>예약 관리</li> : ""
                    }
                    {
                        // layoutHook.statusValue.memberData?.role === ROLE_ADMIN ? <li onClick={() => {navigator(SETTING_SYSTEM_PATH)}} className={location.pathname.includes(RESERVE_LIST_PATH) ? "on" : ""}>시스템설정</li> : ""
                    }
                </ul> 
            </ul>
            <ul className="big_menu">
                <li className={location.pathname.includes(COM_MYPAGE_PATH) ? "on" : ""} onClick={() => {navigator(MYPAGE_READ_PATH)}}>
                    마이페이지
                </li>
            </ul>
            {
                layoutHook.statusValue.memberData?.role === ROLE_USER ? 
                    <ul className="big_menu">
                        <li className={location.pathname.includes(COM_COMPANY_PATH) ? "on" : ""} onClick={() => {navigator(COM_COMPANY_PATH)}}>
                            회사 정보 관리
                        </li>
                    </ul> : ""
            }
        </div> 
    </div>
    <div className="contents">
        <LoadingProvider>
            <AlertProvider>
                <Outlet/>
            </AlertProvider>
        </LoadingProvider>
    </div>
    {
        layoutHook.statusValue.isError ?
            <DefaultPopup zIndex={100}>
                <BasicPopup message={layoutHook.statusValue.errorMessage} onClick={layoutHook.statusValue.wrongEnter}/>
            </DefaultPopup> : ""
    }
    </div>
  )
}

export default Layout