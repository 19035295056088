import React from 'react'
import { useCompInfoReadHook } from './CompInfoRead.hook'
import DefaultPopup from '../../../alert/DefaultPopup';
import BasicConfirmPopup from '../../../popup/BasicConfirmPopup';
import parse from 'html-react-parser';
import FileUpload from '../../../common/FileUpload';
import Editor from '../../../common/Editor';
import MovePopup from '../../../alert/MovePopup';
import AddressPopup from '../../../popup/AddressPopup';
import { Address } from 'react-daum-postcode';

interface Props {
    companyCode: string,
    getData: () => void,
}

const CompInfoRead:React.FC<Props> = (props: Props) => {
  const compInfoReadHook = useCompInfoReadHook(props.companyCode, props.getData);

  return (
    <>
        {
            compInfoReadHook.statusValue.loading ? "loading" :
            <>
                <table className="tableCol" ref={compInfoReadHook.ref}>
                    <tr>
                        <th>회사명</th>
                        <td>
                            {
                                !compInfoReadHook.statusValue.isUpdate ? 
                                    compInfoReadHook.statusValue.compInfoReadResponse!.name :
                                    <input 
                                        type="text" 
                                        value={compInfoReadHook.statusValue.compInfoUpdateRequest!.name} 
                                        name='name'
                                        onChange={compInfoReadHook.onChange}/>
                            }
                        </td>
                    </tr>
                    <tr>
                        <th>대표자명</th>
                        <td>
                            {
                                !compInfoReadHook.statusValue.isUpdate ?
                                    compInfoReadHook.statusValue.compInfoReadResponse!.owner :
                                    <input 
                                        type="text" 
                                        value={compInfoReadHook.statusValue.compInfoUpdateRequest!.owner} 
                                        name='owner'
                                        onChange={compInfoReadHook.onChange}/>
                            }
                        </td>
                    </tr>
                    <tr>
                        <th>대표 이메일</th>
                        <td>
                            {
                                !compInfoReadHook.statusValue.isUpdate ?
                                    compInfoReadHook.statusValue.compInfoReadResponse!.email :
                                    <input type="email" 
                                        value={compInfoReadHook.statusValue.compInfoUpdateRequest!.email} 
                                        name='email'
                                        onChange={compInfoReadHook.onChange}/>
                            }
                        </td>
                    </tr>
                    <tr>
                        <th>사업자 등록 번호</th>
                        <td>
                            {
                                !compInfoReadHook.statusValue.isUpdate ?
                                    compInfoReadHook.statusValue.compInfoReadResponse!.businessNumber :
                                    <input 
                                        type="text" 
                                        value={compInfoReadHook.statusValue.compInfoUpdateRequest!.businessNumber} 
                                        name='businessNumber'
                                        onChange={compInfoReadHook.onChange}/>
                            }
                        </td>
                    </tr>
                    <tr>
                        <th>통신판매번호</th>
                        <td>
                            {
                                !compInfoReadHook.statusValue.isUpdate ?
                                    compInfoReadHook.statusValue.compInfoReadResponse!.mailOrder :
                                    <input 
                                        type="text" 
                                        value={compInfoReadHook.statusValue.compInfoUpdateRequest!.mailOrder} 
                                        name='mailOrder'
                                        onChange={compInfoReadHook.onChange}/>
                            }
                        </td>
                    </tr>
                    <tr>
                        <th>회사 주소</th>
                        <td>
                            {
                                !compInfoReadHook.statusValue.isUpdate ?
                                    compInfoReadHook.statusValue.compInfoReadResponse!.address?.fullLocation :
                                    <>
                                        <input type="text" className="short gap"
                                        value={compInfoReadHook.statusValue.compInfoUpdateRequest?.address?.postNum}
                                        readOnly 
                                        disabled/>
                
                                        &nbsp;&nbsp;&nbsp;
                                        <button onClick={compInfoReadHook.changeAddressPopup}>우편번호 찾기</button>
                                        <input type="text" className="gap"
                                            readOnly
                                            disabled
                                            value={compInfoReadHook.statusValue.compInfoUpdateRequest?.address?.fullLocation}/>
                                        <input 
                                            type="text" 
                                            value={compInfoReadHook.statusValue.compInfoUpdateRequest?.address?.detail} 
                                            onChange={compInfoReadHook.onChangeAddressDetail}/>
                                    </>
                            }
                        </td>
                    </tr>
                    <tr>
                        <th>회사 대표번호</th>
                        <td>
                            {
                                !compInfoReadHook.statusValue.isUpdate ?
                                    compInfoReadHook.statusValue.compInfoReadResponse!.phone :
                                    <>
                                        <input 
                                            type="text" 
                                            name='phone' 
                                            value={compInfoReadHook.statusValue.compInfoUpdateRequest?.phone}
                                            onChange={compInfoReadHook.onChange}/> (-없이 숫자만 입력해주세요.)
                                    </>
                            }
                        </td>
                    </tr>
                    <tr>
                        <th>회사 로고</th>
                        <td className='companyLogo'>
                            {
                                !compInfoReadHook.statusValue.isUpdate ?
                                    compInfoReadHook.statusValue.compInfoReadResponse?.fileInfoList.map(file => (
                                        <img src={file.fileUrl} alt="회사 로고"/>
                                    )) :
                                    <FileUpload
                                        fileResponse={compInfoReadHook.statusValue.compInfoFileResponse}
                                        fleGrpId={compInfoReadHook.statusValue.compInfoReadResponse?.fileId}
                                        afterPost={compInfoReadHook.onChangeFileId}
                                        isSingle={true}/>
                            }
                        </td>
                    </tr>
                    <tr>
                        <th>회사 소개</th>
                        <td>
                            {
                                !compInfoReadHook.statusValue.isUpdate ?
                                    parse(compInfoReadHook.statusValue.compInfoReadResponse!.introduction ?? "") :
                                    <Editor 
                                        initialData={compInfoReadHook.statusValue.compInfoUpdateRequest?.introduction}
                                        onChange={compInfoReadHook.onChangeIntroduction}/>
                            }
                        </td>
                    </tr>
                    <tr>
                        <th>회사 연혁</th>
                        <td>
                            {
                                !compInfoReadHook.statusValue.isUpdate ?
                                    parse(compInfoReadHook.statusValue.compInfoReadResponse!.history ?? "") :
                                    <Editor initialData={compInfoReadHook.statusValue.compInfoUpdateRequest?.history}
                                        onChange={compInfoReadHook.onChangeHistory}/>
                            }
                        </td>
                    </tr>
                    <tr>
                        <th>오시는길<br/>(위치 안내)</th>
                        <td>
                            {
                                !compInfoReadHook.statusValue.isUpdate ?
                                    parse(compInfoReadHook.statusValue.compInfoReadResponse!.address?.directions ?? "") :
                                    <Editor initialData={compInfoReadHook.statusValue.compInfoUpdateRequest?.address?.directions}
                                        onChange={compInfoReadHook.onChangeDirections}/>
                            }
                        </td>
                    </tr>
                </table>
                <div className="btnBoxE">
                    <div>
                        {
                            !compInfoReadHook.statusValue.isUpdate ?
                                <button className="on" onClick={compInfoReadHook.changeUpdate}>수정</button> :
                                <button className="on" onClick={compInfoReadHook.updateCompany}>수정</button>
                        }
                    </div>
                    <div>
                        {
                            !compInfoReadHook.statusValue.isUpdate ?
                                <button className="off" onClick={compInfoReadHook.changeDeletePopup}>삭제</button> :
                                <button className="off" onClick={compInfoReadHook.changeUpdate}>취소</button>
                        }
                    </div>
                </div>
            </>
        }
        {
            compInfoReadHook.deletePopup ? 
            <DefaultPopup zIndex={1000}>
                <BasicConfirmPopup
                    message={"등록된 회사를 삭제하시겠습니까?"}
                    onCancle={compInfoReadHook.changeDeletePopup}
                    onConfirm={compInfoReadHook.deleteCompanyInfo}
                />
            </DefaultPopup> : ""
        }
         {
            compInfoReadHook.statusValue.isAddressPopupOpen ?
                <MovePopup
                    onClickBackground={compInfoReadHook.changeAddressPopup}
                >
                    <AddressPopup
                        onConfirm={(address: Address) => {
                            compInfoReadHook.comfirmAddressPopup(address);
                        }}
                        onCancle={compInfoReadHook.changeAddressPopup}
                    />
                </MovePopup> : ""
        }
    </>
  )
}

export default CompInfoRead