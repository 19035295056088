import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useBoardUpdateHook } from './BoardUpdate.hook';
import Editor from '../../../../common/Editor';
import FileUpload from '../../../../common/FileUpload';
import { MAX_TITLE_LENGTH } from '../../../../../common/config';

const BoardUpdate = () => {
  const navigator = useNavigate();
  const boardUpdateHook = useBoardUpdateHook();

  return (
    <>
      <h2>공지사항 수정(최대 100글자까지 작성가능합니다.)</h2>

      <div className="boxContents">
        <table className="tableCol">
          <tr>
            <th>제목*</th>
            <td>
              <label>
                <input type="text" name='noticeTitle' onChange={boardUpdateHook.onChange} value={boardUpdateHook.statusValue.boardUpdateRequest.noticeTitle} placeholder="제목을 입력해주세요." max={MAX_TITLE_LENGTH}/>
              </label>
            </td>
          </tr>
          {/* <tr>
            <th>앱선택*</th>
            <td>
              <select>
                <option value="">앱선택</option>
                <option value="">앱이름1</option>
                <option value="">앱이름2</option>
                <option value="">앱이름3</option>
              </select>
            </td>
          </tr> */}
          <tr>
            <th>등록 게시일*</th>
            <td>
              <input name='noticeStartDate' value={boardUpdateHook.statusValue.boardUpdateRequest.noticeStartDate} onChange={boardUpdateHook.onChange} type="date"/>&nbsp;&nbsp;<span className="warningRow">!등록개시일을 입력해주세요.</span>
            </td>
          </tr>
          {/* <tr>
            <th>등록일</th>
            <td>
              <input type="date"/>
            </td>
          </tr>
          <tr>
            <th>작성자</th>
            <td>
              <label>
                <input type="text"/>
              </label>
            </td>
          </tr> */}
          <tr>
            <th>내용*</th>
            <td>
              <div>
                {
                  boardUpdateHook.getOriginalContents() ?
                    <Editor 
                      onChange={boardUpdateHook.onChangeContents}
                      initialData={boardUpdateHook.statusValue.boardUpdateRequest.noticeContent}
                    /> : ""
                }
              </div>
            </td>
          </tr>
          <tr>
            <th>첨부파일</th>
            <td> 
                <FileUpload
                  fileResponse={boardUpdateHook.statusValue.fileResponse}
                  fleGrpId={boardUpdateHook.statusValue.boardUpdateRequest.fileId}
                  afterPost={boardUpdateHook.updateNoticeFileId}
                />
            </td>
          </tr>
        </table>
        <div className="btnBox">
          <button className="off" onClick={() => {navigator(-1)}}>취소</button>
          <button className="on" onClick={boardUpdateHook.updateNotice}>수정</button>
        </div>
      </div>
    </>
  )
}

export default BoardUpdate