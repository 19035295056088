import React from 'react'
import { ADV_LIST_PATH, ADV_UPDATE_PATH } from '../../../../../common/path'
import { useNavigate } from 'react-router-dom'

const AdvRead = () => {
  const navigator = useNavigate();
  
  return (
    <>
        <h2>광고 관리 상세</h2>

        <div className="boxContents">
        <table className="tableCol">
            <tr>
            <th>이름</th>
            <td>앱 하단 01</td>
            </tr>
            <tr>
            <th>설명</th>
            <td>value</td>
            </tr>
            <tr>
            <th>예제 이미지<br/>(가로 300px * 세로 50px)</th>
            <td>
                <div className="advImg advImgS">
                <img src={require("../../../../../img/Adv01.png")} alt="광고1"/>
                </div>
            </td>
            </tr>
            <tr>
            <th>AI 생성 표준 프롬프트<br/><button>복사</button></th>
            <td>
                <div>
                <textarea></textarea>
                </div>
            </td>
            </tr>
            <tr>
            <th>노출 영역</th>
            <td>value</td>
            </tr>
            <tr>
            <th>노출 좌표</th>
            <td>value&nbsp;&nbsp;<span>예&#41; 가로 000px * 세로 000px</span></td>
            </tr>
            <tr>
            <th>노출 형태</th>
            <td>value</td>
            </tr>
            <tr>
            <th>노출 기간</th>
            <td>value&nbsp;&nbsp;<span>예&#41; 2023-10-01 ~ 2023-12-31</span></td>
            </tr>
            <tr>
            <th>표준 일 광고비</th>
            <td>value value</td>
            </tr>
            <tr>
            <th>사용 여부</th>
            <td>value</td>
            </tr>
            <tr>
            <th>사용앱 수</th>
            <td>value</td>
            </tr>
        </table>
        <div className="btnBoxB">
            <button className="off" onClick={() => {navigator(ADV_LIST_PATH)}}>목록</button>
            <button className="on" onClick={() => {navigator(ADV_UPDATE_PATH)}}>수정</button>
        </div>
        </div>
    </>
  )
}

export default AdvRead