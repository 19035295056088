import { Alert, Box, Snackbar } from '@mui/material'
import React from 'react'

export type ServerityType = 'success' | 'error' | 'warning' | 'info';

export interface Props {
    open: boolean,
    setOpen: (value: boolean) => void,
    alertText: string,
    serverity: ServerityType,
}

const BasicAlert:React.FC<Props> = (props: Props) => {
  // return (
  //   <Collapse in={props.open}>
  //       <Alert severity={props.serverity} onClose={() => {props.setOpen(false);}}>{props.alertText}</Alert>
  //   </Collapse>
  // )
  return (
    <Box sx={{ width: 500 }}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={props.open}
        key={"bottomcenter"}
        autoHideDuration={3000}
        onClose={() => {props.setOpen(false)}}
      >
        <Alert severity={props.serverity} onClose={() => {props.setOpen(false)}}>{props.alertText}</Alert>
      </Snackbar>
    </Box>
  );
}

export default BasicAlert