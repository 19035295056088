import React from 'react'
import { useBridgeHook } from './Bridge.hook'

const Bridge = () => {
  const bridgeHook = useBridgeHook();
  return (
    <div></div>
  )
}

export default Bridge