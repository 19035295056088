import React from 'react'
import { useNavigate } from 'react-router-dom'
import { TEM_CREATE_PATH, TEM_READ_PATH } from '../../../../../common/path';
import { useTemListHook } from './TemList.hook';
import NoActionAlert from '../../../../alert/NoActionAlert';
import Paging from '../../../../common/Paging';

const TemList = () => {
  const navigator = useNavigate();
  const temListHook = useTemListHook();

  return (
    <>
        {
        temListHook.statusValue.isImgPop ?
            <div className='previewWrap'>
                <div className='previewPop'>
                    <div className='closeBtnWrap'>
                        <button className='closeBtn'>
                            <img src={require("../../../../../img/icon_close.png")} onClick={temListHook.closePreviewPopup}/>
                        </button>
                    </div>
                    <div className='preview'>
                        {
                            temListHook.statusValue.previewImgList.map(url => (
                                <img src={url} alt={url}/>
                            ))
                        }
                    </div>
                </div>
            </div> : ""
        }

        <h2>템플릿 관리</h2>
        <div className="flex flexE" onClick={() => {navigator(TEM_CREATE_PATH)}} style={{marginBottom:"10px"}}><button className="on">템플릿 등록</button></div>
        <div className="flex spaceB alignC" style={{marginTop: "20px"}}>
            <div className="search">
                <select>
                    <option value="all">전체</option>
                    <option value="name" selected>이름</option>
                    <option value="explanation">설명</option>
                </select>
                <input type="text" placeholder="검색어를 입력해주세요."/>
                <button><img src={require("../../../../../img/icon_search_white.png")} alt="검색" /></button>
            </div>
            <div className="filter">
                <select>
                    <option value="all">전체</option>
                    <option value="use" selected>사용</option>
                    <option value="notUse">미사용</option>
                </select>
                <select>
                    <option value="all" selected>전체</option>
                    <option value="default">기본 추천</option>
                    <option value="random">랜덤 추천</option>
                </select>
            </div>
        </div>

        {
            temListHook.statusValue.temListResponse?.totalCount ?
            <div className="boxContents">
                <table className="table table--block">
                    <thead>
                        <tr>
                            <th className="tableShort">No</th>
                            <th className="tableLong">이름</th>
                            <th>앱용도</th>
                            <th>추천 유형</th>
                            <th>이미지</th>
                            <th>생성일</th>
                            <th>상태</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            temListHook.statusValue.temListResponse?.templateList ||
                            temListHook.statusValue.temListResponse?.templateList.length !== 0 ? 
                                temListHook.statusValue.temListResponse?.templateList.map((tem, idx) => (
                                    <tr key={tem.code} onClick={() => {navigator(TEM_READ_PATH, {state: {templateCode: tem.code}})}}>
                                        <td data-name="No.">{idx}</td>
                                        <td data-name="이름">{tem.name}</td>
                                        <td data-name="앱용도">
                                            {
                                                temListHook.convertAddFuncAppCateName(tem.categoryList)
                                            }
                                        </td>
                                        <td data-name="추천 유형">{temListHook.getRecommandName(tem.randomType)}</td>
                                        <td data-name="이미지"><button onClick={(e) => {temListHook.openPreviewPopup(e, tem.fileUrl)}}>미리보기</button></td>
                                        <td data-name="생성일">{tem.createAt}</td>
                                        <td data-name="상태"><span className={tem.status === "Y" ? "use" : "notuse"}>{tem.status === "Y" ? "사용중" : "미사용"}</span></td>
                                    </tr>
                                )) : ""
                        }
                    </tbody>
                    </table>
                    <div className="page">
                        <Paging
                            itemOffset={temListHook.statusValue.pagination.itemOffset}
                            setItemOffset={temListHook.setItemOffset}
                            itemsLength={temListHook.statusValue.temListResponse?.totalCount ?? 0}
                        />
                    </div>
                </div> : <NoActionAlert alertText='등록된 템플릿이 없습니다.' open={true} serverity='warning'/>
        }
    </>
  )
}

export default TemList