import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ADD_FUNC_LIST_PATH, ADD_FUNC_UPDATE_PATH } from '../../../../../common/path';
import { useAddFuncReadHook } from './AddFuncRead.hook';
import parse from 'html-react-parser';
import Slider from 'react-slick';

const AddFuncRead = () => {
  const navigator = useNavigate();
  const addFuncReadHook = useAddFuncReadHook();

  return (
    <>
        <h2>추가기능 관리 상세</h2>
          
        <div className="boxContents">
            <table className="tableCol">
                <tr>
                <th>앱용도</th>
                <td>{addFuncReadHook.convertAppUseName()}</td>
                </tr>
                <tr>
                <th>이름</th>
                <td>{addFuncReadHook.statusValue.addFuncReadResponse?.name}</td>
                </tr>
                <tr>
                <th>설명</th>
                <td>{parse(addFuncReadHook.statusValue.addFuncReadResponse?.content ?? "")}</td>
                </tr>
                <tr>
                <th>이미지<br/>(가로 270px * 세로130px)</th>
                <td className="temImgWrap">
                    {
                        addFuncReadHook.statusValue.addFuncReadResponse?.fileUrl ? 
                        <ul className="addFuncImg">
                            <Slider>
                                {
                                    addFuncReadHook.statusValue.addFuncReadResponse?.fileUrl.map(url => (
                                        <li><img src={url} alt="유사앱이미지1"/></li>
                                    ))
                                }
                            </Slider>
                        </ul> : ""
                    }
                </td>
                </tr>
                <tr>
                <th>AI 생성 표준 프롬프트<br/><button>복사</button></th>
                <td>{parse(addFuncReadHook.statusValue.addFuncReadResponse?.prompt ?? "")}</td>
                </tr>
                <tr>
                <th>AI 생성 표준 소스<br/><button>복사</button></th>
                <td>{parse(addFuncReadHook.statusValue.addFuncReadResponse?.ai ?? "")}</td>
                </tr>
                <tr>
                <th>사용 여부</th>
                <td>{addFuncReadHook.statusValue.addFuncReadResponse?.status === "Y" ? "사용중" : "미사용"}</td>
                </tr>
                <tr>
                <th>사용앱 수</th>
                <td>value</td>
                </tr>
            </table>
            <div className="btnBoxB">
                <button className="off" onClick={() => {navigator(ADD_FUNC_LIST_PATH)}}>목록</button>
                <button className="on" onClick={() => {navigator(ADD_FUNC_UPDATE_PATH, {state: {addFunc: addFuncReadHook.statusValue.addFuncReadResponse}})}}>수정</button>
            </div>
        </div>
    </>
  )
}

export default AddFuncRead