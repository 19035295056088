import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ADV_LIST_PATH } from '../../../../../common/path';

const AdvUpdate = () => {
  const navigator = useNavigate();

  return (
    <>
        <h2>광고 관리 생성</h2>

        <div className="boxContents">
            <table className="tableCol">
            <tr>
                <th>이름</th>
                <td><input type="text" placeholder="이름을 입력하세요."/></td>
            </tr>
            <tr>
                <th>설명</th>
                <td>
                <div>
                    <textarea></textarea>
                </div>
                </td>
            </tr>
            <tr>
                <th>예제 이미지<br/>(가로 300px * 세로 50px)</th>
                <td>
                <button>미리 보기</button>
                <input type="file" id="imgFile"/>
                </td>
            </tr>
            <tr>
                <th>AI 생성 표준 프롬프트<br/><button>복사</button></th>
                <td>
                <div>
                    <textarea></textarea>
                </div>
                </td>
            </tr>
            <tr>
                <th>노출 영역</th>
                <td>
                <select>
                    <option>포탈 메인</option>
                    <option>포탈 서브</option>
                    <option>앱 메인</option>
                    <option>앱 서브</option>
                </select>
                </td>
            </tr>
            <tr>
                <th>노출 좌표</th>
                <td>
                <select>
                    <option>이미지 배너</option>
                    <option>레이어 팝업</option>
                </select>
                </td>
            </tr>
            <tr>
                <th>노출 좌표</th>
                <td>
                <input type="text" placeholder="시작 가로 좌표 픽셀"/> * <input type="text" placeholder="시작 세로 좌표 픽셀"/>
                </td>
            </tr>
            <tr>
                <th>노출 기간</th>
                <td><input type="date"/> ~ <input type="date"/>&nbsp;&nbsp;<input type="checkbox" name="always"/> 상시</td>
            </tr>
            <tr>
                <th>표준 일 광고비</th>
                <td><input type="text"/>&nbsp;&nbsp;
                <select>
                    <option>KRW</option>
                    <option>USD</option>
                    <option>JPY</option>
                    <option>EUR</option>
                    <option>CNY</option>
                </select>
                </td>
            </tr>
            <tr>
                <th>사용 여부</th>
                <td>
                <label>
                    <input type="radio" name="usa"/> 사용
                </label>
                <label>
                    <input type="radio" name="usa"/> 미사용
                </label>
                </td>
            </tr>
            </table>
            <div className="btnBoxB">
            <button className="off" onClick={() => {navigator(ADV_LIST_PATH)}}>목록</button>
            <div>
                <button className="off" onClick={() => {navigator(-1)}}>취소</button>
                <button className="on" onClick={() => {navigator(ADV_LIST_PATH)}}>저장</button>
            </div>
            </div>
      </div>
    </>
  )
}

export default AdvUpdate