export const MBAAS: string = "mbaas";
export const APP: string = "app";
export const MEMBER: string = "member";
export const ADMIN: string = "admin";
export const UPDATE: string = "update";
export const CREATE: string = "create";
export const LIST: string = "list";

export const DASHBOARD: string = "dashboard";

export const MBAAS_ACCESS_TOKEN = "mbaas_access_token";
export const MBAAS_SAVED_ID = "mbaas_save_id";
export const MBAAS_APP_CODE = "mbaas_app_code";

export const RESPONSE_RESULT_FAIL = "FAIL";
export const RESPONSE_RESULT_SUCCESS = "SUCCESS";

export const EMAIL_REGEX = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;    
export const PASSWORD_REGEX = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/
export const PHONE_REGEX = /^(01[016789]{1})[0-9]{3,4}[0-9]{4}$/;

export const IMG_ACCEPT = ".png, .jpg, .jpeg";
export const EXCEL_ACCEPT = ".xlsx, .xls"

export const ROLE_ADMIN = "ROLE_ADMIN";
export const ROLE_USER = "ROLE_USER";

export const BEARER_STRING = "Bearer ";

export const DEFINE_LOGIN = "LOGIN";
export const DEFINE_JOIN = "JOIN";
export const DEFINE_SEND_AUTH_CODE_EMAIL = "SEND_AUTH_CODE_EMAIL";
export const DEFINE_CHECK_AUTH_CODE = "CHECK_AUTH_CODE";
export const DEFINE_UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const DEFINE_UPDATE_MYPAGE_PASSWORD = "UPDATE_MY_PASSWORD";

export const DEFINE_GET_MY_INFO = "GET_MY_INFO";
export const DEFINE_GET_M_MEM_LIST = "GET_M_MEM_LIST";
export const DEFINE_GET_M_MEM_READ = "GET_M_MEM_READ";

export const DEFINE_GET_AP_MEM_LIST = "GET_AP_MEM_LIST";
export const DEFINE_GET_AP_MEM_READ = "GET_AP_MEM_READ";

export const DEFINE_UPDATE_ACTIVE = "GET_UPDATE_ACTIVE";
export const DEFINE_UPDATE_BAN = "GET_UPDATE_BAN";
export const DEFINE_UPDATE_KICK = "GET_UPDATE_KICK";

export const DEFINE_GET_M_ADMIN_LIST = "GET_M_ADMIN_LIST";

export const DEFINE_GET_NOTICE_LIST = "GET_NOTICE_LIST";
export const DEFINE_GET_NOTICE_READ = "GET_NOTICE_READ";
export const DEFINE_UPDATE_NOTICE = "UPDATE_NOTICE";
export const DEFINE_CREATE_NOTICE = "CREATE_NOTICE";
export const DEFINE_DELETE_NOTICE = "DELETE_NOTICE";
export const DEFINE_UPDATE_FILEID_NOTICE = "UPDATE_FILEID_NOTICE";

export const DEFINE_GET_APP_NOTICE_LIST = "GET_APP_NOTICE_LIST";
export const DEFINE_GET_APP_NOTICE_READ = "GET_APP_NOTICE_READ";
export const DEFINE_UPDATE_APP_NOTICE = "UPDATE_APP_NOTICE";
export const DEFINE_CREATE_APP_NOTICE = "CREATE_APP_NOTICE";
export const DEFINE_DELETE_APP_NOTICE = "DELETE_APP_NOTICE";
export const DEFINE_UPDATE_APP_FILEID_NOTICE = "UPDATE_APP_FILEID_NOTICE";


export const DEFINE_USERASK_LIST = "GET_USERASK_LIST";
export const DEFINE_USERASK_READ = "GET_USERASK_READ";

export const DEFINE_USERASK_APP_LIST = "GET_USERASK_APP_LIST";
export const DEFINE_USERASK_APP_READ = "GET_USERASK_APP_READ";

export const DEFINE_USERASK_CREATE_ANSWER = "CREATE_USERASK_ANSWER";
export const DEFINE_USERASK_DELETE_ANSWER = "DELETE_USERASK_ANSWER";
export const DEFINE_USERASK_UPDATE_ANSWER = "UPDATE_USERASK_ANSWER";

export const DEFINE_USERASK_APP_CREATE_ANSWER = "CREATE_USERASK_APP_ANSWER";
export const DEFINE_USERASK_APP_DELETE_ANSWER = "DELETE_USERASK_APP_ANSWER";
export const DEFINE_USERASK_APP_UPDATE_ANSWER = "UPDATE_USERASK_APP_ANSWER";

export const DEFINE_COMPANY_LIST = "GET_COMPANY_LIST";
export const DEFINE_COMPANY_READ = "READ_COMPANY_LIST";
export const DEFINE_COMPANY_CREATE = "CREATE_COMPANY";
export const DEFINE_COMPANY_DELETE = "DELETE_COMPANY";
export const DEFINE_COMPANY_UPDATE = "UPDATE_COMPANY";
export const DEFINE_COMPANY_CREATE_AI_LOGO = "CREATE_COMPANY_AI_LOGO";

export const DEFINE_COMMON_UPLOAD_FILE = "COMMON_UPLOAD_FILE";
export const DEFINE_COMMON_GET_FILE = "COMMON_GET_FILE";
export const DEFINE_COMMON_MODIFY_FILE = "COMMON_MODIFY_FILE";

export const DEFINE_TEMCATEGORY_LIST = "GET_TEMCATEGORY_LIST";

export const DEFINE_TEMPLATE_CREATE = "CREATE_TEMPLATE";
export const DEFINE_TEMPLATE_LIST = "LIST_TEMPLATE";
export const DEFINE_TEMPLATE_READ = "READ_TEMPLATE";
export const DEFINE_TEMPLATE_UPDATE = "UPDATE_TEMPLATE";

export const DEFINE_ADDFUNC_LIST = "GET_ADDFUNC_LIST";
export const DEFINE_ADDFUNC_READ = "GET_ADDFUNC_READ";
export const DEFINE_ADDFUNC_CREATE = "CREATE_ADDFUNC_LIST";
export const DEFINE_ADDFUNC_UPDATE = "CREATE_ADDFUNC_UPDATE";

export const DEFINE_SIMAPP_LIST = "GET_SIMAPP_LIST";
export const DEFINE_SIMAPP_LIST_TAG = "GET_SIMAPP_LIST_TAG";
export const DEFINE_SIMAPP_READ = "GET_SIMAPP_READ";
export const DEFINE_SIMAPP_CREATE = "CREATE_SIMAPP";
export const DEFINE_SIMAPP_UPDATE = "UPDATE_SIMAPP";

export const DEFINE_MYPAGE_UPDATE = "UPDATE_MYPAGE";

export const DEFINE_APHELP_READ = "APHELP_READ";
export const DEFINE_APHELP_UPDATE = "APHELP_UPDATE";

export const DEFINE_ITEM_LIST = "GET_ITEM_LIST";
export const DEFINE_ITEM_CREATE = "GET_ITEM_CREATE";
export const DEFINE_ITEM_READ = "GET_ITEM_READ";
export const DEFINE_ITEM_UPDATE = "GET_ITEM_UPDATE";
export const DEFINE_ITEM_DELETE = "GET_ITEM_DELETE";

export const DEFINE_RESERVE_LIST ="GET_RESERVE_LIST"
export const DEFINE_RESERVE_CREATE ="GET_RESERVE_CREATE"
export const DEFINE_RESERVE_READ ="GET_RESERVE_READ"
export const DEFINE_RESERVE_UPDATE ="GET_RESERVE_UPDATE"
export const DEFINE_RESERVE_DELETE ="GET_RESERVE_DELETE"

// 템플릿 유형 조회
export const DEFINE_ALL_TEMPLATE_LIST = "GET_ALL_TEMPLATE_LIST";
export const DEFINE_ALL_CAPABILITY_LIST = "GET_ALL_CAPABILITY_LIST";
