import React from 'react'
import { useNavigate } from 'react-router-dom'
import { APP_USE_UPDATE_PATH } from '../../../../../common/path';

const AppUseRead = () => {
  const navigator = useNavigate();

  return (
    <>
        <h2>앱용도 관리 상세</h2>
          
          <div className="boxContents">
              <table className="tableCol">
                  <tr>
                    <th>이름</th>
                    <td>value</td>
                  </tr>
                  <tr>
                    <th>설명</th>
                    <td>value</td>
                  </tr>
                  <tr>
                    <th>사용앱 수</th>
                    <td>value</td>
                  </tr>
                  <tr>
                    <th>사용 여부</th>
                    <td>value</td>
                  </tr>
              </table>
              <div className="btnBoxB">
                <button className="off" onClick={() => {navigator(-1)}}>목록</button>
                <button className="on" onClick={() => {navigator(APP_USE_UPDATE_PATH)}}>수정</button>
              </div>
          </div>
    </>
  )
}

export default AppUseRead