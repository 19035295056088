import React, { useState } from 'react'
import { useCompInfoCreateHook } from './CompInfoCreate.hook'
import Editor from '../../../common/Editor';
import MovePopup from '../../../alert/MovePopup';
import AddressPopup from '../../../popup/AddressPopup';
import { Address } from 'react-daum-postcode';
import FileUpload from '../../../common/FileUpload';
import ImageAi from '../../../common/imageAi'


interface Props {
    selectedIdx: number,
    getData: () => void,
}

const CompInfoCreate:React.FC<Props> = (props:Props) => {
  const compInfoCreateHook = useCompInfoCreateHook(props.selectedIdx + 1, props.getData);
  const [visible, setVisible] = useState(false);

  return (
    <>
        <table className="tableCol">
            <tr>
                <th>회사명</th>
                <td>
                <input type="text" name='name' onChange={compInfoCreateHook.onChange}/>
                </td>
            </tr>
            <tr>
                <th>대표자명</th>
                <td>
                <input type="text" name='owner' onChange={compInfoCreateHook.onChange}/>
                </td>
            </tr>
            <tr>
                <th>대표 이메일</th>
                <td>
                <input type="email" name='email' onChange={compInfoCreateHook.onChange}/>
                </td>
            </tr>
            <tr>
                <th>사업자 등록 번호</th>
                <td>
                <input type="text" name='businessNumber' onChange={compInfoCreateHook.onChange}/>
                </td>
            </tr>
            <tr>
                <th>통신판매번호</th>
                <td>
                <input type="text" name='mailOrder' onChange={compInfoCreateHook.onChange}/>
                </td>
            </tr>
            <tr>
                <th>회사 주소</th>
                <td>
                    <input type="text" className="short gap" 
                        defaultValue={compInfoCreateHook.statusValue.compInfoCreateRequest.address?.postNum} 
                        readOnly 
                        disabled/>

                            &nbsp;&nbsp;&nbsp;
                            <button onClick={compInfoCreateHook.changeAddressPopup}>우편번호 찾기</button>
                <input type="text" className="gap"
                    defaultValue={compInfoCreateHook.statusValue.compInfoCreateRequest.address?.fullLocation}
                    readOnly
                    disabled/>
                <input type="text" onChange={compInfoCreateHook.onChangeAddressDetail}/>
                </td>
            </tr>
            <tr>
                <th>회사 대표번호</th>
                <td>
                <input type="text" name='phone' onChange={compInfoCreateHook.onChange}/> (-없이 숫자만 입력해주세요.)
                </td>
            </tr>
            <tr className='companylogo'>
                <th>회사 로고</th>
                {/* <td>
                    <FileUpload
                        afterPost={compInfoCreateHook.onChangeFileId}
                        isSingle={true}
                    /> */}
                {/* </td> */}
                <td className='flex' style={{justifyContent:"flex-start"}}>
                {
                    !visible ?
                        <FileUpload
                            fleGrpId={compInfoCreateHook.statusValue.compInfoCreateRequest.fileId}
                            afterPost={compInfoCreateHook.onChangeFileId}
                            isSingle={true}
                            required={true}
                        /> : ""
                }
                <button className='imageAiBtn' onClick={() => {setVisible(!visible);}} >{visible ? "생성중" : "이미지 ai로 자동생성(최대 3회)" }</button>
                {visible 
                && <ImageAi 
                    createAi={compInfoCreateHook.createAiLogo}
                    onChangePrompt={compInfoCreateHook.setPrompt}
                    aiImageList={compInfoCreateHook.aiImageList}
                    onClickSelect={(fileId: string) => {
                        compInfoCreateHook.onChangeFileId(fileId);
                        setVisible(false);
                    }}
                    selectImg={compInfoCreateHook.statusValue.compInfoCreateRequest.fileId ?? ""}/>
                    }
                </td>
            </tr>
            <tr>
                <th>회사 소개</th>
                <td>
                <div>
                    <Editor onChange={compInfoCreateHook.onChangeIntroduction}/>
                </div>
                </td>
            </tr>
            <tr>
                <th>회사 연혁</th>
                <td>
                <div>
                    <Editor onChange={compInfoCreateHook.onChangeHistory}/>
                </div>
                </td>
            </tr>
            <tr>
                <th>오시는길<br/>(위치 안내)</th>
                <td>
                <div>
                    <Editor onChange={compInfoCreateHook.onChangeDirections}/>
                </div>
                </td>
            </tr>
        </table>
        <div className="btnBoxE">
            <div>
                <button className="on" onClick={compInfoCreateHook.createCompany}>등록</button>
            </div>
        </div>

        {
            compInfoCreateHook.statusValue.isAddressPopupOpen ?
                <MovePopup
                    onClickBackground={compInfoCreateHook.changeAddressPopup}
                >
                    <AddressPopup
                        onConfirm={(address: Address) => {
                            compInfoCreateHook.comfirmAddressPopup(address);
                        }}
                        onCancle={compInfoCreateHook.changeAddressPopup}
                    />
                </MovePopup> : ""
        }
    </>
  )
}

export default CompInfoCreate