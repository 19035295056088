import React from 'react'

const UserAskUpdate = () => {
  return (
    <>
        <h2>1:1 문의 상세</h2>
        
        <div className="boxContents">
            <table className="tableCol">
            <tr>
                <th>제목</th>
                <td>[공지] 엠바스샵 이용안내</td>
                <th>답변상태</th>
                <td><span className="use">답변</span></td>
            </tr>
            <tr>
                <th>문의자</th>
                <td colSpan={3}>user123</td>
            </tr>
            <tr>
                <th>문의일</th>
                <td colSpan={3}>2023-07-20</td>
            </tr>
            <tr>
                <th>내용</th>
                <td colSpan={3}>
                <div>
                    <textarea></textarea>
                </div>
                </td>
            </tr>
            <tr>
                <th>첨부파일</th>
                <td colSpan={3}><input type="file"/></td>
            </tr>
            </table>
        </div>

        <h2 style={{marginTop: "60px"}}>1:1 문의 답변수정</h2>
        <div className="boxContents" style={{marginTop: "20px"}}>
            <table className="tableCol">
            <tr>
                <th>제목</th>
                <td><input type="text" placeholder="답변 제목을 입력해주세요"/></td>
            </tr>
            <tr>
                <th>답변자</th>
                <td colSpan={3}>엠바스 관리자</td>
            </tr>
            <tr>
                <th>답변일</th>
                <td colSpan={3}>2023-07-28</td>
            </tr>
            <tr>
                <th>내용</th>
                <td colSpan={3}>
                <div>
                    <textarea></textarea>
                </div>
                </td>
            </tr>
            <tr>
                <th>첨부파일</th>
                <td colSpan={3}><input type="file"/></td>
            </tr>
            </table>
            <div className="btnBox">
            <button className="off">취소</button>
            <button className="on">수정하기</button>
            </div>
        </div>
    </>
  )
}

export default UserAskUpdate