import { useContext, useState } from "react"
import { ItemCreateState } from "./ItemCreate.model"
import { DEFINE_ITEM_CREATE, MBAAS_APP_CODE, RESPONSE_RESULT_SUCCESS } from "../../../../../common/const";
import { useAxios } from "../../../../../common/hooks";
import { AlertContext } from "../../../../../common/context";
import { useNavigate } from "react-router-dom";

export const useItemCreateHook = () => {
    const axios = useAxios();
    const alertContext = useContext(AlertContext);
    const navigator = useNavigate();
    const [statusValue, setStatusValue] = useState<ItemCreateState>({
        itemCreateRequest: {
            appCode: sessionStorage.getItem(MBAAS_APP_CODE) ?? "",
            content : "",
            fileId: "",
            name: "",
            price: 0,
        }
    });

    const onChange = (e: any) => {
        setStatusValue({
            ...statusValue,
            itemCreateRequest: {
                ...statusValue.itemCreateRequest,
                [e.target.name] : e.target.value,
            }
        })
    }

    const onChangeContent = (data: string) => {
        setStatusValue({
            ...statusValue,
            itemCreateRequest: {
                ...statusValue.itemCreateRequest,
                content: data,
            }
        })
    }

    const onChangeFileId = (fileId: string) => {
        setStatusValue({
            ...statusValue,
            itemCreateRequest: {
                ...statusValue.itemCreateRequest,
                fileId: fileId,
            }
        })
    }

    const validCreateItem = ():boolean => {
        if(!statusValue.itemCreateRequest.name) {
            alertContext.openAlert("아이템 이름을 입력해주세요.", "warning");
            return false;
        }
        if(!statusValue.itemCreateRequest.price) {
            alertContext.openAlert("아이템 가격을 입력해주세요.", "warning");
            return false;
        }
        if(!statusValue.itemCreateRequest.content) {
            alertContext.openAlert("아이템 설명을 입력해주세요.", "warning");
            return false;
        }
        if(!statusValue.itemCreateRequest.fileId) {
            alertContext.openAlert("아이템 사진을 등록해주세요.", "warning");
            return false;
        }
        
        return true;
    }

    const createItem = async() => {
        if(!validCreateItem()) return;

        const response = await axios.postData(DEFINE_ITEM_CREATE, statusValue.itemCreateRequest);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            alertContext.openAlert("아이템이 등록되었습니다.", "success");
            navigator(-1);
        } else {
            alertContext.openAlert(response.message, "error");
        }
    }

    return {
        statusValue,
        onChange,
        onChangeContent,
        onChangeFileId,
        createItem,
    }
}