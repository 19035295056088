import React from 'react'
import { useAppInfoHook } from './AppInfo.hook'
import HTMLReactParser from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import { PLANNING_APPINFO_UPDATE_PATH } from '../../../../../common/path';

const AppInfo = () => {
  const appInfoHook = useAppInfoHook();
  const navigator = useNavigate();

  return (
    
    <div className="inputWrap">
      <div className="updateBtnWrap">
        <button onClick={() => {navigator(PLANNING_APPINFO_UPDATE_PATH)}} className='updateBtn'>수정하기</button>
      </div>
    <ul>
      <li>
        <h4>앱 이름*</h4>
        <input type="text" value={appInfoHook.statusValue.helpApReadResponse?.appName} disabled/>
      </li>
      <li>
        <h4>앱 유형*</h4>
        <input type="text" placeholder="앱 유형을 입력해 주세요." value={appInfoHook.statusValue.helpApReadResponse?.appCategory} disabled/>
        {/* <select name="" id="">
          <option selected value="">- - 선택 - -</option>  
          <option value="">테이크아웃 앱</option>
          <option value="">협회 앱</option>
          <option value="">사진공유 앱</option>
          <option value="">원데이클래스 앱</option>
        </select> */}
      </li>
      <li className="appIconWrap">
        <h4>앱 아이콘</h4>
        <div className='appIcon'>
          <img src={appInfoHook.helpApReadHook.appIconImg} alt='appIcon'/>
        </div>
      </li>
      {/* <li> */}
        {/* <h4>회사명</h4> */}
        {/* <input type="text" placeholder="앱 이름을 입력해 주세요." value={appInfoHook.helpApReadHook.statusValue.helpApReadResponse?.companyName} disabled/> */}
        {/* <select name="" id="">
          <option selected value="">- - 선택 - -</option>  
          <option value="">엠바스 1</option>
          <option value="">엠바스 2</option>
          <option value="">엠바스 3</option>
        </select> */}
      {/* </li> */}
      <li>
        <h4>템플릿*</h4>
        <form action="">
          <input type="text" value={appInfoHook.statusValue.helpApReadResponse?.templateName} disabled/>
        </form>
        <div className="myImgWrap">
          <img src={appInfoHook.statusValue.helpApReadResponse?.templateFileUrl[0]} alt="" />
          {
            // appInfoHook.statusValue.helpApReadResponse ?
            // appInfoHook.statusValue.helpApReadResponse?.templateFileUrl!.map(url => (
            //   <img src={url} alt={url}/>
            // )) : ""
          }
        </div>
      </li>
      <li>
        <h4>추가기능</h4>
        <table className="addFuctWrap">
          {
            appInfoHook.capaDetailList.map(detail => (
              <>
                <thead>
                  <th>{detail.name}</th>
                  <th>
                    사용중
                  </th>
                </thead>
                <tbody>
                  <td>
                    <img src={detail.fileUrl[0]} alt="소셜 로그인 이미지"/>
                  </td>
                  <td>
                    {HTMLReactParser(detail.content)}
                  </td>
                </tbody>
              </>
            ))
          }
          {/* <thead>
            <th>길찾기</th>
            <th>
              <select name="" id="">
                <option value="">사용</option>
                <option value="">미사용</option>
              </select>
            </th>
          </thead>
          <tbody>
            <td>
              <img src={require("../../../../img/addFuct3.png")} alt="길찾기 이미지"/>
            </td>
            <td>
              <p>지도 API를 제공하여 사용자에게 위치 정보를 전달하여 쉽게 접근할 수 있도록 도움을 줄 수 있습니다.</p>
            </td>
          </tbody> */}
        </table>
      </li>
      {/* <li>
        <h4>추가가능한 추가기능</h4>
        <table className='addFuctWrap addFuctWrap1'>
          <tr>
            <th>SNSKakao</th>
            <td>카카오 계정만 있다면 쉽고 간편하게 회원가입, 로그인하실 수 있습니다.</td>
          </tr>
          <tr>
            <th>SNSNaver</th>
            <td>네이버 계정만 있다면 쉽고 간편하게 회원가입, 로그인하실 수 있습니다.</td>
          </tr>
        </table>
      </li> */}
      <li>
        <h4>앱의 기능이나 템플릿과 관련하여 원하는 내용을 자유롭게 적어주세요.</h4>
        <textarea defaultValue={appInfoHook.statusValue.helpApReadResponse?.appAsk ?? ""} readOnly></textarea>
      </li>
    </ul>
  </div>

  )
}

export default AppInfo