import { useContext, useState } from "react"
import { ReserveCreateState } from "./ReserveCreate.model";
import { useAxios } from "../../../../../common/hooks";
import { AlertContext } from "../../../../../common/context";
import { DEFINE_RESERVE_CREATE, MBAAS_APP_CODE, RESPONSE_RESULT_SUCCESS } from "../../../../../common/const";
import { fn_Debug } from "../../../../../common/module";
import { useNavigate } from "react-router-dom";
import { OnedayClass } from "../Reserve.model";

export const useReserveCreateHook = () => {
    const axios = useAxios();
    const alertContext = useContext(AlertContext);
    const navigator = useNavigate();

    const [statusValue, setStatusValue] = useState<ReserveCreateState>({
        reserveCreateRequest: {
            appCode: sessionStorage.getItem(MBAAS_APP_CODE) ?? "",
            content: "",
            end: "",
            fileId: "",
            maxStudent: 0,
            name: "",
            oneDayClassList: [],
            price: 0,
            start: "",
            tutor: ""
        }
    });

    const [onedayClassObj, setOnedayClassObj] = useState<OnedayClass>({
        classCode: "",
        key: "",
        classEnd: "",
        classDate: "",
        classStart: "",
        // maxStudent: 0,
    });

    const onChange = (e:any) => {
        setStatusValue({
            ...statusValue,
            reserveCreateRequest: {
                ...statusValue.reserveCreateRequest,
                [e.target.name]: e.target.value
            }
        })
    }

    const onChangeFileId = (fileId: string) => {
        setStatusValue({
            ...statusValue,
            reserveCreateRequest: {
                ...statusValue.reserveCreateRequest,
                fileId: fileId,
            }
        })
    }

    const onChangeOnedayClass = (e: any) => {
        setOnedayClassObj({
            ...onedayClassObj,
            [e.target.name]: e.target.value,
        })
    }

    const validAddClass = (): boolean => {
        if(!onedayClassObj.classDate) {
            alertContext.openAlert("강의 일자를 입력해주세요.", "warning");
            return false;
        }
        if(!onedayClassObj.classStart) {
            alertContext.openAlert("강의 시작시간을 입력해주세요.", "warning");
            return false;
        }
        if(!onedayClassObj.classEnd) {
            alertContext.openAlert("강의 종료시간을 입력해주세요.", "warning");
            return false;
        }
        if(onedayClassObj.classStart >= onedayClassObj.classEnd) {
            alertContext.openAlert("강의 시간을 확인해주세요.", "warning");
            return false;
        }
        
        for(let i = 0; i < statusValue.reserveCreateRequest.oneDayClassList.length; i++) {
            if(statusValue.reserveCreateRequest.oneDayClassList[i].key 
                === onedayClassObj.classDate + onedayClassObj.classStart + onedayClassObj.classEnd) {
                    alertContext.openAlert("동일한 강의가 등록되어있습니다.", "warning");
                    return false;
            }
        }

        return true;
    }

    const addClass = () => {
        if(!validAddClass()) return;

        setStatusValue({
            ...statusValue,
            reserveCreateRequest: {
                ...statusValue.reserveCreateRequest,
                oneDayClassList: [
                    ...statusValue.reserveCreateRequest.oneDayClassList,
                    {
                        ...onedayClassObj,
                        key: onedayClassObj.classDate
                            + onedayClassObj.classStart
                            + onedayClassObj.classEnd,
                    },
                ]
            }
        })
    }

    const removeClass = (key: string) => {
        setStatusValue({
            ...statusValue,
            reserveCreateRequest: {
                ...statusValue.reserveCreateRequest,
                oneDayClassList: statusValue.reserveCreateRequest.oneDayClassList.filter(cls => cls.key !== key)
            }
        })
    }

    const onChangeContent = (data: string) => {
        setStatusValue({
            ...statusValue,
            reserveCreateRequest: {
                ...statusValue.reserveCreateRequest,
                content: data,
            }
        })
    }

    const validCreateReserve = ():boolean => {
        if(!statusValue.reserveCreateRequest.name) {
            alertContext.openAlert("강의 이름을 입력해주세요.", "warning");
            return false;
        }
        if(!statusValue.reserveCreateRequest.price) {
            alertContext.openAlert("강의 가격을 입력해주세요.", "warning");
            return false;
        }
        if(!statusValue.reserveCreateRequest.tutor) {
            alertContext.openAlert("강사 이름을 입력해주세요.", "warning");
            return false;
        }
        if(!statusValue.reserveCreateRequest.maxStudent) {
            alertContext.openAlert("강의 최대 수강생을 입력해주세요.", "warning");
            return false;
        }
        if(!statusValue.reserveCreateRequest.start || !statusValue.reserveCreateRequest.end) {
            alertContext.openAlert("강의 기간을 입력해주세요.", "warning");
            return false;
        }
        if(!statusValue.reserveCreateRequest.fileId) {
            alertContext.openAlert("강의 사진을 입력해주세요.", "warning");
            return false;
        }
        if(statusValue.reserveCreateRequest.oneDayClassList.length === 0) {
            alertContext.openAlert("+버튼을 눌러 강의 시간을 추가해주세요.", "warning");
            return false;
        }
        if(!statusValue.reserveCreateRequest.content) {
            alertContext.openAlert("강의 설명을 입력해주세요.", "warning");
            return false;
        }
        if(!statusValue.reserveCreateRequest.name) {
            alertContext.openAlert("강의 이미지를 입력해주세요.", "warning");
            return false;
        }
        for(let i = 0; i < statusValue.reserveCreateRequest.oneDayClassList.length; i++) {
            if(
                statusValue.reserveCreateRequest.oneDayClassList[i].classDate < statusValue.reserveCreateRequest.start
                    || statusValue.reserveCreateRequest.oneDayClassList[i].classDate > statusValue.reserveCreateRequest.end
            ) {
                alertContext.openAlert("강의 기간이 올바르지 않습니다", "warning");
                return false;
            }
        }

        return true;
    }

    const createReserve = async() => {
        if(!validCreateReserve()) return ;
        const response = await axios.postData(DEFINE_RESERVE_CREATE, statusValue.reserveCreateRequest);

        fn_Debug("CREATE_RESERVE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            alertContext.openAlert("강의가 등록되었습니다.", "success");
            navigator(-1);
        }
    }

    return {
        statusValue,
        onChange,
        onChangeFileId,
        onChangeOnedayClass,
        addClass,
        removeClass,
        onChangeContent,
        createReserve
    }
}