import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useMyPageUpdateHook } from './MyPageUpdateHook';
import { ROLE_ADMIN } from '../../../../common/const';

const MyPageUpdate = () => {
  const navigator = useNavigate();
  const myPageUpdateHook = useMyPageUpdateHook();

  return (
    <>
        {
            myPageUpdateHook.statusValue.isUpdateOpen ? 
            <div className='pwUpdateWrap'>
                <div className='pwUpdatePop'>
                    <h2>비밀번호 재설정</h2>
                    <div>
                        <input type="text" name='memberPassword' onChange={myPageUpdateHook.onChangeUpdatePw} placeholder='새 비밀번호 입력'/>
                        <span>영문+숫자+특수문자 8자 이상(특수문자 : !@#$%^&*)</span>
                    </div>
                    <input type="text" name='reMemberPassword' onChange={myPageUpdateHook.onChangeUpdatePw} placeholder='새 비밀번호 재입력'/>
                    <span className='error'>비밀번호가 일치하지않습니다.</span>
                    <div className='btnWrap'>
                        <button className='off' onClick={myPageUpdateHook.closeUpdatePwPopup}>취소</button>
                        <button onClick={myPageUpdateHook.updatePw}>비밀번호 변경하기</button>
                    </div>
                </div>
            </div> : ""
        }

        <h2>마이페이지</h2>
        <div className="boxContents">
            <table className="tableCol">
            <tr>
                <th>이름</th>
                <td>{myPageUpdateHook.statusValue.myPageUpdateRequest.memberName}</td>
            </tr>
            <tr>
                <th>아이디</th>
                <td>{myPageUpdateHook.statusValue.myPageUpdateRequest.memberEmail}</td>
            </tr>
            <tr>
                <th>비밀번호</th>
                <td>********** &nbsp;&nbsp;&nbsp;&nbsp;<button onClick={myPageUpdateHook.openUpdatePwPopup}>비밀번호 재설정</button></td>
            </tr>
            {/* <tr>
                <th>부서</th>
                <td>
                <select>
                    <option>CEO</option>
                    <option>기획팀</option>
                    <option>개발팀</option>
                    <option>디자인팀</option>
                    <option>마케팅팀</option>
                    <option>연구팀</option>
                </select>
                </td>
            </tr> */}
            <tr>
                <th>연락처</th>
                <td><input name='memberPhone' onChange={myPageUpdateHook.onChange} type="text" value={myPageUpdateHook.statusValue.myPageUpdateRequest.memberPhone}/></td>
            </tr>
            <tr>
                <th>가입일</th>
                <td>{myPageUpdateHook.statusValue.myPageUpdateRequest.createAt}</td>
            </tr>
            <tr>
                <th>권한</th>
                <td>{myPageUpdateHook.statusValue.myPageUpdateRequest.role === ROLE_ADMIN ? "관리자" : "유저"}</td>
            </tr>
            </table>
            <div className="btnBox">
            <button className="on" onClick={myPageUpdateHook.updateMyPage}>수정완료</button>
            </div>
        </div>
    </>
  )
}

export default MyPageUpdate