import React from 'react'

interface Props {
    display?: boolean
}

const CodeLoading:React.FC<Props> = ({display = false,}) => {
  return (
    <div className='popWrap' style={{display: display ? "flex" : "none"}}>
        <div className="codeLoadingWrap">
            <div className="codelodingPop">
            <div className="stick"></div>
            <div className="stick"></div>
            <div className="stick"></div>
            <div className="stick"></div>
            <div className="stick"></div>
            <div className="stick"></div>
            
            <h1>코드 생성중...</h1>
            
            </div>
        </div>
    </div>
  )
}

export default CodeLoading