import { useEffect, useState } from "react"
import { AddFuncListResponse, AddFuncListState } from "./AddFuncList.model"
import { ITEMS_PER_PAGE } from "../../../../../common/config";
import { useAxios } from "../../../../../common/hooks";
import { DEFINE_ADDFUNC_LIST, RESPONSE_RESULT_SUCCESS } from "../../../../../common/const";
import { fn_Debug } from "../../../../../common/module";
import { AppUseCate } from "../../tem/list/TemList.model";

export const useAddFuncListHook = () => {
    const axios = useAxios();
    const [statusValue, setStatusValue] = useState<AddFuncListState>({
        addFuncListRequest: {
            pageable: {
                page: 0,
                size: ITEMS_PER_PAGE,
            },
        },
        pagination: {
            itemOffset: 0,
        },
        isImgPop: false,
        previewImgList: [],
    });

    useEffect(() => {
        getAddFuncList();
    }, [])

    const getAddFuncList = async() => {
        const response = await axios.getData<AddFuncListResponse>(DEFINE_ADDFUNC_LIST, {
            params: statusValue.addFuncListRequest.pageable
        });

        fn_Debug("GET_ADDFUNCLIST_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setStatusValue({
                ...statusValue,
                addFuncListResponse: response.data
            })
        }
    }

    const convertAddFuncAppCateName = (capa: AppUseCate[]) => {
        const arr:string[] = [];

        for(let i in capa) {
            arr.push(capa[i].name);
        }

        return arr.join(",");
    }

    const setItemOffset = (newOffset: number) => {
        setStatusValue({
            ...statusValue,
            addFuncListRequest: {
                ...statusValue.addFuncListRequest,
                pageable: {
                    ...statusValue.addFuncListRequest.pageable,
                    page: newOffset / ITEMS_PER_PAGE,
                    size: ITEMS_PER_PAGE,
                }
            },
            pagination: {
                itemOffset: newOffset
            }
        })
    }

    const openPreviewPopup = (e:any, url: string[]) => {
        e.stopPropagation();
        setStatusValue({
            ...statusValue,
            isImgPop: true,
            previewImgList: url,
        })
    }

    const closePreviewPopup = () => {
        setStatusValue({
            ...statusValue,
            isImgPop: false,
        })
    }

    return {
        statusValue,
        setItemOffset,
        convertAddFuncAppCateName,
        openPreviewPopup,
        closePreviewPopup
    }
}