import { useEffect, useState } from "react"
import { HelpApReadResponse, HelpApReadState } from "./HelpApRead.model";
import { DEFINE_APHELP_READ, MBAAS_APP_CODE, RESPONSE_RESULT_SUCCESS } from "../../../../../common/const";
import { useAxios, useFileHook } from "../../../../../common/hooks";
import { fn_Debug } from "../../../../../common/module";
import { DEFAULT_APP_ICON, FILE_HOST } from "../../../../../common/config";

export const useHelpApReadHook = () => {
    const axios = useAxios();
    const fileHook = useFileHook();
    const [statusValue, setStatusValue] = useState<HelpApReadState>({
        helpApreadRequest: {
            appCode: sessionStorage.getItem(MBAAS_APP_CODE) ?? "",
        },
        isPreviewPopup: false,
    });
    const [appIconImg, setAppIconImg] = useState<string>(DEFAULT_APP_ICON);

    useEffect(() => {
        getHelpApRead();
    }, [])

    const getHelpApRead = async() => {
        const response = await axios.getData<HelpApReadResponse>(DEFINE_APHELP_READ, {}, true, [statusValue.helpApreadRequest.appCode]);
        fn_Debug("HELP_AP_READ_RESPONSE");
        fn_Debug(response);
        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setStatusValue({
                ...statusValue,
                helpApReadResponse: response.data
            })
            getAppIcon(response.data.appIcon);
        }
    }

    const onCheckSelectedCapa = (code: string): string => {
        const appList = statusValue.helpApReadResponse?.appCapabilityList ?? [];
        let cn = "";
        
        for(let i = 0; i < appList?.length; i++) {
            if(code === appList[i].capabilityCode) {
                cn = "on";
                break;
            }
        }

        return cn;
    }

    const getAppIcon = async(fileId: string) => {
        if(!fileId) return;
        const response = await fileHook.getFileByGrpId(fileId);
        if(response.length !== 0) {
            setAppIconImg(`${FILE_HOST}${response[0].fleUrl}`);
        }
        
    }

    const onChangePopup = () => {
        setStatusValue({ 
            ...statusValue,
            isPreviewPopup: !statusValue.isPreviewPopup
        })
    }

    return {
        statusValue,
        onCheckSelectedCapa,
        appIconImg,
        onChangePopup
    }
}