// export const gatewayUrl:string = "http://211.62.98.66";
// export const gatewayPort:string = ":20011";

//export const gatewayUrl:any = process.env.REACT_APP_GATE_WAY_URL;
export const gatewayUrl:any = "https://aiapp.link";
// export const gatewayUrl:string = "https://devapi.aiapp.link";
export const gatewayPort:string = "";

export let LOG_LEVEL = process.env.REACT_APP_LOG_LEVEL;
// export let LOG_LEVEL = "debug";

export const EMAIL_EXPIRE_TIME = 180;
export const SEND_EMAIL_COOKIE_MAXAGE = 60 * 3;

export const ITEMS_PER_PAGE = 10;

export const SECRET_KEY = "mbaas_secret_key_1458748HDFJJEHFALLDFi34354_243994HDOEKHIDFJEILLDF";

export const EDITOR_FILE_UPLOAD_URL = `${process.env.REACT_APP_FILE_HOST_URL}/v1/ckUpload/image`;
export const FILE_HOST = process.env.REACT_APP_FILE_HOST_URL;

export const DEFAULT_APP_ICON = `${process.env.REACT_APP_FILE_HOST_URL}/file/20231004/ff8d1e43-a89a-4f80-89bd-353f4e0afebf`;

export const MAX_TITLE_LENGTH = 10;
