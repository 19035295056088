/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import Tab from '../../../../ui/Tab'
import { useCompInfoListHook } from '../list/CompInfoList.hook'

const Company = () => {
    const compInfoListHook = useCompInfoListHook();

    return (
        <>
        <h2>회사정보 관리 상세</h2>
            {
                compInfoListHook.statusValue.loading ? "" : 
                <div className="boxContents">
                    <ul className="tapMenu">
                        {compInfoListHook.renderTabHeader()}
                    </ul>
                    <Tab selectedIndex={compInfoListHook.statusValue.selectedIndex}>
                        {compInfoListHook.renderTabBody()}
                    </Tab>
                </div>
            }
        </>
    )
}

export default Company