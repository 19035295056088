/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation } from "react-router"
import { BoardReadResponse, BoardReadState } from "./BoardRead.model";
import { DEFINE_DELETE_APP_NOTICE, DEFINE_DELETE_NOTICE, DEFINE_GET_APP_NOTICE_READ, DEFINE_GET_NOTICE_READ, MBAAS_APP_CODE, RESPONSE_RESULT_SUCCESS } from "../../../../../common/const";
import { fn_Debug } from "../../../../../common/module";
import { useNavigate } from "react-router-dom";
import { M_BOARD_LIST_PATH } from "../../../../../common/path";
import { useAxios, useFileHook } from "../../../../../common/hooks";

export const useBoardReadHook = () => {
    const navigator = useNavigate();
    const location = useLocation();
    const fileHook = useFileHook();
    const axios = useAxios();

    const [statusValue, setStatusValue] = useState<BoardReadState>({
        boardReadRequest: {
            noticeCode: location.state["noticeCode"]
        },
        updateAlertParam: {
            open: location.state["updateAlert"] ?? false,
            alertText: "공지사항이 수정되었습니다.",
            serverity: location.state["flag"],
        }
    });

    const [deletePopup, setDeletePopup] = useState<boolean>(false);

    useEffect(() => {
        getBoardRead();
    }, [statusValue.boardReadRequest.noticeCode])
    
    const getBoardRead = async () => {
        const response = await axios.getData<BoardReadResponse>(
            sessionStorage.getItem(MBAAS_APP_CODE) ? DEFINE_GET_APP_NOTICE_READ : DEFINE_GET_NOTICE_READ
            , {}
            , true
            , sessionStorage.getItem(MBAAS_APP_CODE) ? [sessionStorage.getItem(MBAAS_APP_CODE)!, statusValue.boardReadRequest.noticeCode] : [statusValue.boardReadRequest.noticeCode]);
        fn_Debug("GET_NOTICE_READ_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setStatusValue({
                ...statusValue,
                boardReadResponse: response.data,
            })

            if(response.data.fileId) {
                const fileres = await fileHook.getFileByGrpId(response.data.fileId);
                setStatusValue({
                    ...statusValue,
                    boardReadResponse: {
                        ...response.data,
                        files: fileres,
                    },
                })
            }
        }
    }

    const onClickPreviewBoard = (noticeCode?: string) => {
        setStatusValue({
            ...statusValue,
            boardReadRequest: {
                ...statusValue,
                noticeCode: noticeCode ?? "",
            }
        })
    }

    const changeUpdateAlert = () => {
        setStatusValue({
            ...statusValue,
            updateAlertParam: {
                ...statusValue.updateAlertParam,
                open: !statusValue.updateAlertParam.open,
            }
        })
    }

    const deleteNotice = async() => {
        const response = await axios.deleteData(
            sessionStorage.getItem(MBAAS_APP_CODE) ? DEFINE_DELETE_APP_NOTICE : DEFINE_DELETE_NOTICE
            ,{data: {noticeCodeList: [statusValue.boardReadRequest.noticeCode], appCode: sessionStorage.getItem(MBAAS_APP_CODE) ?? undefined}}
        );

        fn_Debug("DELETE_NOTICE_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setDeletePopup(false);
            navigator(M_BOARD_LIST_PATH, {replace: true, state: {deleteAlert: true}})
        }
    }

    return {
        statusValue,
        onClickPreviewBoard,
        changeUpdateAlert,
        deleteNotice,
        deletePopup,
        setDeletePopup,
    }
}