import { useContext, useState } from "react"
import { BoardCreateResponse, BoardCreateState } from "./BoardCreate.model"
import { BEARER_STRING, DEFINE_CREATE_APP_NOTICE, DEFINE_CREATE_NOTICE, MBAAS_ACCESS_TOKEN, MBAAS_APP_CODE, RESPONSE_RESULT_FAIL, RESPONSE_RESULT_SUCCESS } from "../../../../../common/const"
import { fn_Debug, fn_Decrypt } from "../../../../../common/module"
import { useNavigate } from "react-router-dom"
import { M_BOARD_LIST_PATH } from "../../../../../common/path"
import { useAxios } from "../../../../../common/hooks"
import { AlertContext } from "../../../../../common/context"

export const useBoardCreateHook = () => {
    const alertContext = useContext(AlertContext);
    const navigator = useNavigate();
    const axios = useAxios();

    const [statusValue, setStatusValue] = useState<BoardCreateState>({
        boardCreateRequest: {
            appCode: sessionStorage.getItem(MBAAS_APP_CODE) ?? undefined,
            noticeContent: "",
            noticeStartDate: "",
            noticeTitle: "",
            fileId: "",
        },
        uploadFile: {},
    })

    const onChange = (e: any) => {
        setStatusValue({
            ...statusValue,
            boardCreateRequest: {
                ...statusValue.boardCreateRequest,
                [e.target.name] : e.target.value
            }
        });
    }

    const onChangeFile = (e: any) => {
        setStatusValue({
            ...statusValue,
            uploadFile : e.target.files[0],
        });
    }

    const validCreateBoard = ():boolean => {
        if(!statusValue.boardCreateRequest.noticeTitle) {
            alertContext.openAlert("제목은 필수 값입니다.", "warning");
            return false;
        }
        if(!statusValue.boardCreateRequest.noticeContent) {
            alertContext.openAlert("내용은 필수 값입니다.", "warning");
            return false;
        }
        if(!statusValue.boardCreateRequest.noticeStartDate) {
            alertContext.openAlert("게시일은 필수 값입니다.", "warning");
            return false;
        }
        
        return true;
    }

    const createBoard = async() => {
        if(!validCreateBoard()) return;

        const response = await axios.postData<BoardCreateResponse>(
            sessionStorage.getItem(MBAAS_APP_CODE) ? DEFINE_CREATE_APP_NOTICE : DEFINE_CREATE_NOTICE
            , statusValue.boardCreateRequest
            );

        fn_Debug("CREATE_BOARD_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            navigator(M_BOARD_LIST_PATH, {
                replace: true,
            });
            alertContext.openAlert("공지사항이 등록되었습니다.", "success");
        }

        if(response.result === RESPONSE_RESULT_FAIL) {
            alertContext.openAlert(response.message, "error");
        } 
    }

    const onChangeContent = (value: string) => {
        setStatusValue({
            ...statusValue,
            boardCreateRequest: {
                ...statusValue.boardCreateRequest,
                noticeContent: value
            }
        });
    }

    const setFileId = (fileId: string) => {
        setStatusValue({
            ...statusValue,
            boardCreateRequest: {
                ...statusValue.boardCreateRequest,
                fileId: fileId,
            }
        })
    }

    return {
        statusValue,
        onChange,
        onChangeFile,
        createBoard,
        onChangeContent,
        setFileId
    }
}