import React from 'react'

export interface LoginErrorProps {
  errorMessage: string
  onClick: () => void,
}

const LoginErrorPopup:React.FC<LoginErrorProps> = (props: LoginErrorProps) => {
  return (
    <>
      <div className="popBg">
          <p className="center bold">{props.errorMessage}</p>
          <div className="btnBox">
              <button className="on" onClick={props.onClick}>확인</button>
          </div>
      </div>
    </>
  )
}

export default LoginErrorPopup