/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { AlertContext } from "../../../../../common/context";
import { useAxios } from "../../../../../common/hooks";
import { useHelpApReadHook } from "../../../../bo/template/helpAp/read/HelpApRead.hook";
import { CompInfoReadResponse } from "../../../../bo/company/read/CompInfoRead.model";
import { DEFINE_COMPANY_READ, DEFINE_COMPANY_UPDATE, PHONE_REGEX, RESPONSE_RESULT_SUCCESS } from "../../../../../common/const";
import { fn_Debug } from "../../../../../common/module";
import { Address } from "react-daum-postcode";
import { useNavigate } from "react-router-dom";

export const useCompanyInfoUpdateHook = () => {
    const axios = useAxios();
    const helpApReadHook = useHelpApReadHook();
    const alertContext = useContext(AlertContext);
    const [compInfo, setCompInfo] = useState<CompInfoReadResponse>();
    const [isAddressPopupOpen, setIsAddressPopupOpen] = useState<boolean>(false);
    const navigator = useNavigate();

    useEffect(() => {
        if(helpApReadHook.statusValue.helpApReadResponse) {
            getCompInfo();
        }
    }, [helpApReadHook.statusValue.helpApReadResponse])

    useEffect(() => {
        fn_Debug(compInfo);
    }, [compInfo])

    const getCompInfo = async() => {
        const response = await axios.getData<CompInfoReadResponse>(DEFINE_COMPANY_READ, {}, true, [helpApReadHook.statusValue.helpApReadResponse?.companyCode ?? ""])

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setCompInfo(response.data);
        } else {
            alertContext.openAlert(response.message, "error");
        }
    }

    const onChange = (e: any) => {
        setCompInfo({
            ...compInfo!,
            [e.target.name]: e.target.value,
        });
    }
    
    const onChangeIntroduction = (data: string) => {
        setCompInfo({
            ...compInfo!,
            introduction: data
        })
    }

    const onChangeHistory = (data: string) => {
        setCompInfo({
            ...compInfo!,
            history: data
        })
    }

    const onChangeDirections = (data: string) => {
        setCompInfo({
            ...compInfo!,
            address: {
                ...compInfo?.address!,
                directions: data
            }
        })
    }

    const changeAddressPopup = () => {
        setIsAddressPopupOpen(!isAddressPopupOpen);
    }

    const comfirmAddressPopup = (address: Address) => {
        fn_Debug("ADDRESS");
        fn_Debug(address);
        setCompInfo({
            ...compInfo!,
            address: {
                ...compInfo!.address!,
                depthOne: address.sido,
                depthTwo: address.sigungu,
                depthThree: address.bname,
                fullLocation: address.address,
                postNum: address.zonecode,
                street: address.roadAddress,
                detail: "",
            }
        });
        setIsAddressPopupOpen(!isAddressPopupOpen);
    }

    const onChangeAddressDetail = (e: any) => {
        setCompInfo({
            ...compInfo!,
            address:{
                ...compInfo?.address!,
                detail: e.target.value
            }
        })
    }

    const validCreateCompany =  ():boolean => {
        if(!compInfo!.name) {
            alertContext.openAlert("회사이름은 필수 입니다.", "warning");
            return false;
        }
        if(!compInfo!.owner) {
            alertContext.openAlert("대표자명은 필수 입니다.", "warning");
            return false;
        }
        if(!compInfo!.email) {
            alertContext.openAlert("회사이메일은 필수 입니다.", "warning");
            return false;
        }
        if(!compInfo!.phone) {
            alertContext.openAlert("회사대표번호는 필수 입니다.", "warning");
            return false;
        }
        if(!PHONE_REGEX.test(compInfo!.phone)) {
            alertContext.openAlert("회사대표번호는 숫자만 입력가능합니다.", "warning");
            return false;
        }
        if(!compInfo!.introduction) {
            alertContext.openAlert("회사소개는 필수 입니다.", "warning");
            return false;
        }
        if(!compInfo!.history) {
            alertContext.openAlert("연혁은 필수 입니다.", "warning");
            return false;
        }

        return true;
    }

    const updateCompany = async() => {
        if(!validCreateCompany()) return;

        const response = await axios.putData(
            DEFINE_COMPANY_UPDATE, compInfo
        )

        fn_Debug("UPDATE_COMPANY_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            navigator(-1);
            alertContext.openAlert("회사정보가 수정되었습니다.", "success");
        }
    }

    return {
        compInfo,
        onChange,
        isAddressPopupOpen,
        changeAddressPopup,
        comfirmAddressPopup,
        onChangeAddressDetail,
        onChangeIntroduction,
        onChangeHistory,
        onChangeDirections,
        updateCompany
    }
}