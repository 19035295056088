import React from 'react'
import { useCompanyInfoUpdateHook } from './CompanyInfoUpdate.hook';
import MovePopup from '../../../../alert/MovePopup';
import AddressPopup from '../../../../popup/AddressPopup';
import { Address } from 'react-daum-postcode';
import Editor from '../../../../common/Editor';

const CompanyInfoUpdate = () => {
  const companyInfoUpdateHook = useCompanyInfoUpdateHook();

  return (
    <>
      <div className="inputWrap">
        <div className="updateBtnWrap">
          <button onClick={companyInfoUpdateHook.updateCompany} className="updateBtn">수정완료</button>
        </div>
          <ul>
            <li>
              <h4>회사명</h4>
              <input 
                type="text" placeholder="회사명을 입력해주세요." 
                name='name'
                value={companyInfoUpdateHook.compInfo?.name}
                onChange={companyInfoUpdateHook.onChange}/>
            </li>
            <li>
              <h4>대표자명</h4>
              <input 
                type="text" placeholder="대표자 이름을 입력해주세요." 
                name='owner'
                value={companyInfoUpdateHook.compInfo?.owner}
                onChange={companyInfoUpdateHook.onChange}/>
            </li>
            <li>
              <h4>대표 이메일</h4>
              <input 
                type="text" placeholder="대표 이메일을 입력해주세요." 
                name='email'
                value={companyInfoUpdateHook.compInfo?.email}
                onChange={companyInfoUpdateHook.onChange}/>
            </li>
            <li>
              <h4>사업자등록번호</h4>
              <input 
                type="text" placeholder="사업자등록번호를 입력해주세요." 
                name='businessNumber'
                value={companyInfoUpdateHook.compInfo?.businessNumber}
                onChange={companyInfoUpdateHook.onChange}/>
            </li>
            <li>
              <h4>통신판매업번호</h4>
              <input 
                type="text" placeholder="통신판매업번호를 입력해주세요." 
                name='mailOrder'
                value={companyInfoUpdateHook.compInfo?.mailOrder}
                onChange={companyInfoUpdateHook.onChange}/>
            </li>
            <li>
              <h4>회사주소</h4>
              <div className="smallInput">
                <input type="text" className="short gap"
                  value={companyInfoUpdateHook.compInfo?.address?.postNum}
                  readOnly 
                  placeholder="우편 번호"
                  disabled/>
                  &nbsp;&nbsp;&nbsp;
                <button onClick={companyInfoUpdateHook.changeAddressPopup}  className='findBtn'>우편번호 찾기</button><br/>
              </div>
              <input type="text" className="gap"
                  readOnly
                  disabled
                  placeholder="회사 주소"
                  value={companyInfoUpdateHook.compInfo?.address?.fullLocation}/><br/>
                <input 
                  type="text" 
                  placeholder="회사 상세 주소"
                  value={companyInfoUpdateHook.compInfo?.address?.detail} 
                  onChange={companyInfoUpdateHook.onChangeAddressDetail}/>
            </li>
            <li>
              <h4>회사 소개</h4>
              <Editor
                key={companyInfoUpdateHook.compInfo?.businessNumber}
                initialData={companyInfoUpdateHook.compInfo?.introduction}
                onChange={companyInfoUpdateHook.onChangeIntroduction}/>
            </li>
            <li>
              <h4>회사 연혁</h4>
              <Editor
                key={companyInfoUpdateHook.compInfo?.businessNumber}
                initialData={companyInfoUpdateHook.compInfo?.history}
                onChange={companyInfoUpdateHook.onChangeHistory}/>
            </li>
            <li>
              <h4>오시는길</h4>
              <Editor
                key={companyInfoUpdateHook.compInfo?.businessNumber}
                initialData={companyInfoUpdateHook.compInfo?.address!.directions}
                onChange={companyInfoUpdateHook.onChangeDirections}/>
            </li>
            {/* <li>
              <h4>회사정보와 관련하여 원하는 내용들을 자유롭게 적어주세요.</h4>
              <textarea placeholder="ex. 회사 연혁 부분은 빼고 관련 뉴스 정보를 올릴 게시판이 필요합니다.  "></textarea>
              
            </li> */}
          </ul>
        </div>
        {
          companyInfoUpdateHook.isAddressPopupOpen ?
          <MovePopup
          onClickBackground={companyInfoUpdateHook.changeAddressPopup}
          >
            <AddressPopup
                onConfirm={(address: Address) => {
                  companyInfoUpdateHook.comfirmAddressPopup(address);
                }}
                onCancle={companyInfoUpdateHook.changeAddressPopup}
            />
          </MovePopup> : ""
        }
    </>
  )
}

export default CompanyInfoUpdate