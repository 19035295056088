import React from 'react'
import { PopupProps } from '../../common/model/popup.model'

const WarnDeleteAppPopup = ({onClickConfirm, onClickCancle}: PopupProps) => {
  return (
      <div className="popBg">
        <p className="center bold red" style={{fontSize: "24px"}}>경고</p>
        <br/>
        <p className="center bold red">“이름”의 “버전(앱코드)”를 삭제하시면 이 버전의 앱을 사용하실 수 없습니다.<br/>
            그래도 정말 삭제하시겠습니까?</p>
        <div className="btnBox">
            <button className="off" onClick={() => {onClickCancle()}}>취소</button>
            <button className="on" onClick={() => {onClickConfirm()}}>삭제</button>
        </div>
      </div>
    
  )
}

export default WarnDeleteAppPopup