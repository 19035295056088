/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react"
import { BoardListResponse, BoardListState } from "./BoardList.model";
import { ITEMS_PER_PAGE } from "../../../../../common/config";
import { BEARER_STRING, DEFINE_DELETE_NOTICE, DEFINE_GET_APP_NOTICE_LIST, DEFINE_GET_NOTICE_LIST, MBAAS_ACCESS_TOKEN, MBAAS_APP_CODE, RESPONSE_RESULT_SUCCESS } from "../../../../../common/const";
import { fn_Debug, fn_Decrypt } from "../../../../../common/module";
import { useAxios } from "../../../../../common/hooks";
import { AlertContext } from "../../../../../common/context";

export const useBoardListHook = () => {
    const alertContext = useContext(AlertContext);
    const [statusValue, setStatusValue] = useState<BoardListState>({
        boardListRequest: {
            pageable: {
                page: 0,
                size: ITEMS_PER_PAGE
            }
        },
        pagination: {
            itemOffset: 0,
        },
        boardListResponse: {
            noticeList: [],
        },
        totalCount: 0,
    });

    const [deleteRequest, setDeleteRequest] = useState<string[]>([]);
    const [deletePopup, setDeletePopup] = useState<boolean>(false);
    const axios = useAxios();

    useEffect(() => {
        getNoticeList();
    }, [statusValue.boardListRequest.pageable.page])

    const getNoticeList = async() => {
        const response = await axios.getData<BoardListResponse>(
            sessionStorage.getItem(MBAAS_APP_CODE) ? DEFINE_GET_APP_NOTICE_LIST : DEFINE_GET_NOTICE_LIST
            , {
                params: statusValue.boardListRequest.pageable
                , headers: {
                    Authorization: `${BEARER_STRING}${fn_Decrypt(sessionStorage.getItem(MBAAS_ACCESS_TOKEN) ?? "")}`
                }
            }, !(!sessionStorage.getItem(MBAAS_APP_CODE)), [sessionStorage.getItem(MBAAS_APP_CODE) ?? ""]
        );
        fn_Debug("NOTICE_LIST_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setStatusValue({
                ...statusValue,
                boardListResponse: response.data,
                totalCount: response.data.totalCount ?? 0
            })
        } else {
            alertContext.openAlert(response.message, "error");
        }
    }

    const onCheck = (e: any) => {
        e.stopPropagation();
        if(e.target.checked) {
            setDeleteRequest([...deleteRequest, e.target.name]);
        }
        else {
            setDeleteRequest(deleteRequest.filter(dr => dr !== e.target.name));
        }
    }

    const onCheckAll = (e:any) => {
        let codeList = [];
        const noticeList = statusValue.boardListResponse.noticeList;

        if(e.target.checked) {
            setDeleteRequest([]);
            for(let idx in noticeList) {
                codeList.push(noticeList[idx].noticeCode);
            }
            setDeleteRequest([...codeList]);
        } else {
            setDeleteRequest([]);
        }
        
    }

    const setItemOffset = (newOffset: number) => {
        setStatusValue({
            ...statusValue,
            boardListRequest: {
                ...statusValue.boardListRequest,
                pageable: {
                    ...statusValue.boardListRequest.pageable,
                    page: newOffset / 10,
                    size: 10,
                }
            },
            pagination: {
                itemOffset: newOffset
            }
        })
    }

    const deleteNotice = async() => {
        const response = await axios.deleteData(DEFINE_DELETE_NOTICE,{data: {noticeCodeList: deleteRequest}});

        fn_Debug("DELETE_NOTICE_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            getNoticeList();
            setDeletePopup(false);
            alertContext.openAlert("공지사항이 삭제되었습니다.", "success");
            setDeleteRequest([]);
        }
    }

    return {
        statusValue,
        setItemOffset,
        onCheck,
        deleteRequest,
        onCheckAll,
        deleteNotice,
        deletePopup,
        setDeletePopup,
    }
}