/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { DEFINE_ALL_CAPABILITY_LIST, DEFINE_ALL_TEMPLATE_LIST, DEFINE_APHELP_READ, DEFINE_APHELP_UPDATE, DEFINE_COMPANY_LIST, MBAAS_APP_CODE, RESPONSE_RESULT_FAIL, RESPONSE_RESULT_SUCCESS } from "../../../../../common/const";
import { useAxios } from "../../../../../common/hooks";
import { HelpApReadResponse } from "../read/HelpApRead.model";
import { CapabilityResponse, HelpApUpdateState, TemplateListResponse } from "./HelpApUpdate.model";
import { fn_Debug } from "../../../../../common/module";
import { CompInfoListResponse } from "../../../company/list/CompInfoList.model";
import { AlertContext } from "../../../../../common/context";
import { useNavigate } from "react-router-dom";

export const useHelpApHook = () => {
    const axios = useAxios();
    const alertContext = useContext(AlertContext);
    const navigator = useNavigate();
    const [statusValue, setStatusValue] = useState<HelpApUpdateState>({
        helpApreadRequest: {
            iconFile: "",
            appCode : sessionStorage.getItem(MBAAS_APP_CODE) ?? "",
            templateCode: "",
            appName: "",
            companyCode: "",
            appCapabilityList: [],
        }
    });
    const [temResponse, setTemResponse] = useState<TemplateListResponse>();
    const [capaResponse, setCapaResponse] = useState<CapabilityResponse[]>();
    const [companyResponse, setCompanyResponse] = useState<CompInfoListResponse[]>();

    useEffect(() => {
        getHelpApRead();
        getCompanyList();
    }, [])

    // useEffect(() => {
    //     setStatusValue({
    //         ...statusValue,
    //         helpApreadRequest: {
    //             ...statusValue.helpApreadRequest,
    //             companyCode: companyResponse[0].companyCode ?? ""
    //         }
    //     })
    // }, [companyResponse])

    // useEffect(() => {
    //     console.log(statusValue);
    // }, [statusValue])

    const getHelpApRead = async() => {
        const response = await axios.getData<HelpApReadResponse>(DEFINE_APHELP_READ, {}, true, [statusValue.helpApreadRequest.appCode]);
        fn_Debug("HELP_AP_READ_RESPONSE");
        fn_Debug(response);
        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setStatusValue({
                ...statusValue,
                helpApreadRequest: {
                    ...statusValue.helpApreadRequest,
                    templateCode : response.data.templateCode,
                    appName: response.data.appName,
                    iconFile: response.data.appIcon ?? "",
                    companyCode: response.data.companyCode,
                    appCapabilityList: response.data.appCapabilityList,
                    appAsk: response.data.appAsk,
                },
                helpApReadResponse: response.data
            });
            getTemplateList(response.data.appCategory);
            getCapabilityList(response.data.appCategory);
        }
    }

    const getTemplateList = async(appCategory: string) => {
        const response = await axios.getData<TemplateListResponse>(
            DEFINE_ALL_TEMPLATE_LIST
            , {params: {category: appCategory}}
        );

        fn_Debug("GET_TEMPLATE_LIST_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setTemResponse(response.data);
        }
    }

    const getCapabilityList = async(appCategory: string) => {
        const response = await axios.getData<CapabilityResponse[]>(
            DEFINE_ALL_CAPABILITY_LIST
            , {params: {category: appCategory}}
        );

        fn_Debug("GET_CAPA_LIST_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setCapaResponse(response.data);
        }
        
    }

    const getCompanyList = async() => {
        const response = await axios.getData<CompInfoListResponse[]>(DEFINE_COMPANY_LIST);

        fn_Debug("GET_COMPANY_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setCompanyResponse(response.data);
        }
    }

    const checkSelectedCapa = (code: string):boolean => {
        const appList = statusValue.helpApReadResponse?.appCapabilityList ?? [];
        let flag = false;

        for(let i = 0; i < appList.length; i++) {
            if(appList[i].capabilityCode === code) {
                flag = true;
                break;
            }
        }

        return flag;
    }

    const onChangeFileId = (fileId: string) => {
        setStatusValue({
            ...statusValue,
            helpApreadRequest: {
                ...statusValue.helpApreadRequest,
                iconFile: fileId
            }
        })
    }

    const onChange = (e:any) => {
        setStatusValue({
            ...statusValue,
            helpApreadRequest: {
                ...statusValue.helpApreadRequest,
                [e.target.name]: e.target.value,
            }
        })
    }

    const onChangeCapabilities = (e: any) => {
        let val:string = e.target.value;
        const valArr = val.split("^");
        const obj = {
            capabilityCode: valArr[1],
            capabilityName: valArr[0],
        }

        if(e.target.checked) {
            setStatusValue({
                ...statusValue,
                helpApreadRequest: {
                    ...statusValue.helpApreadRequest,
                    appCapabilityList: [...statusValue.helpApreadRequest.appCapabilityList, obj]
                }
            })
        } else {
            setStatusValue({
                ...statusValue,
                helpApreadRequest: {
                    ...statusValue.helpApreadRequest,
                    appCapabilityList: 
                        statusValue.helpApreadRequest.appCapabilityList.filter(c => c.capabilityCode !== obj.capabilityCode),
                }
            })
        }
        
    }

    const onChangeSelectCapabilities = (e: any) => {
        let val:string = e.target.value;
        const valArr = val.split("^");
        const obj = {
            capabilityCode: valArr[1],
            capabilityName: valArr[0],
        }

        if(e.target.value) {
            setStatusValue({
                ...statusValue,
                helpApreadRequest: {
                    ...statusValue.helpApreadRequest,
                    appCapabilityList: [...statusValue.helpApreadRequest.appCapabilityList, obj]
                }
            })
        } else {
            setStatusValue({
                ...statusValue,
                helpApreadRequest: {
                    ...statusValue.helpApreadRequest,
                    appCapabilityList: 
                        statusValue.helpApreadRequest.appCapabilityList.filter(c => c.capabilityCode !== obj.capabilityCode),
                }
            })
        }
        
    }

    const validUpdateApp = ():boolean => {
        if(!statusValue.helpApreadRequest.iconFile) {
            alertContext.openAlert("앱 아이콘을 등록해주세요.", "warning");
            return false;
        }

        return true;
    }

    const updateApp = async() => {
        if(!validUpdateApp()) return;

        const response = await axios.putData(DEFINE_APHELP_UPDATE, statusValue.helpApreadRequest);
        
        if(response.result === RESPONSE_RESULT_SUCCESS) {
            alertContext.openAlert("앱 정보가 수정되었습니다.", "success");
            navigator(-1);
        } else {
            alertContext.openAlert(response.message, "error");
        }
    }

    return {
        statusValue,
        temResponse,
        capaResponse,
        checkSelectedCapa,
        companyResponse,
        onChangeFileId,
        onChange,
        updateApp,
        onChangeCapabilities,
        onChangeSelectCapabilities
    }
}