import QueryString from "qs";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MBAAS_ACCESS_TOKEN, MBAAS_APP_CODE } from "../../../common/const";
import { fn_Encrypt } from "../../../common/module";
import { PLANNING_COMPANYINFO_PATH } from "../../../common/path";

export const usePlanningBridgeHook = () => {
    const location = useLocation();
    const data = QueryString.parse(location.search,{ignoreQueryPrefix: true});
    const navigator = useNavigate();

    useEffect(() => {
        const accessToken: string = data.token as string;
        const encryptAccessToken = fn_Encrypt(accessToken);
        sessionStorage.setItem(MBAAS_ACCESS_TOKEN, encryptAccessToken);
        sessionStorage.setItem(MBAAS_APP_CODE, data.appCode as string);
        navigator(PLANNING_COMPANYINFO_PATH);
    }, [])
}