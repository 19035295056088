import { useContext, useEffect, useState } from "react"
import { CompInfoCreateResponse, CompInfoCreateState } from "./CompInfoCreate.model"
import { fn_Debug, fn_Decrypt } from "../../../../common/module";
import { Address } from "react-daum-postcode";
import { BEARER_STRING, DEFINE_COMPANY_CREATE, DEFINE_COMPANY_CREATE_AI_LOGO, MBAAS_ACCESS_TOKEN, PHONE_REGEX, RESPONSE_RESULT_SUCCESS } from "../../../../common/const";
import { useAxios } from "../../../../common/hooks";
import { AlertContext } from "../../../../common/context";
import { FileResponseV2 } from "../../../../common/model/file.model";

export const useCompInfoCreateHook = (initialSlot: number, getData: () => void) => {
    const alertContext = useContext(AlertContext);
    const [statusValue, setStatusValue] = useState<CompInfoCreateState>({
        compInfoCreateRequest: {
            name: "",
            phone: "",
            email: "",
            introduction: "",
            history: "",
            mailOrder: "",
            slot: initialSlot,
        },
        isAddressPopupOpen: false,
    });
    const [prompt, setPrompt] = useState<string>("");
    const [aiImageList, setAiImageList] = useState<FileResponseV2[]>([]);
    const axios = useAxios();

    useEffect(() => {
        fn_Debug("COMP_INFO_STATE");
        fn_Debug(statusValue);
    }, [statusValue])

    const onChange = (e:any) => {
        setStatusValue({
            ...statusValue,
            compInfoCreateRequest: {
                ...statusValue.compInfoCreateRequest,
                [e.target.name] : e.target.value,
            }
        })
    }

    const onChangeIntroduction = (data: string) => {
        setStatusValue({
            ...statusValue,
            compInfoCreateRequest: {
                ...statusValue.compInfoCreateRequest,
                introduction: data,
            }
        })
    }

    const onChangeHistory = (data: string) => {
        setStatusValue({
            ...statusValue,
            compInfoCreateRequest: {
                ...statusValue.compInfoCreateRequest,
                history: data,
            }
        })
    }

    const onChangeDirections = (data: string) => {
        setStatusValue({
            ...statusValue,
            compInfoCreateRequest: {
                ...statusValue.compInfoCreateRequest,
                address: {
                    ...statusValue.compInfoCreateRequest.address!,
                    directions: data,
                }
            }
        })
    }

    const changeAddressPopup = () => {
        setStatusValue({
            ...statusValue,
            isAddressPopupOpen: !statusValue.isAddressPopupOpen,
        })
    }

    const comfirmAddressPopup = (address: Address) => {
        fn_Debug("ADDRESS");
        fn_Debug(address);
        setStatusValue({
            ...statusValue,
            compInfoCreateRequest: {
                ...statusValue.compInfoCreateRequest,
                address: {
                    ...statusValue.compInfoCreateRequest.address,
                    depthOne: address.sido,
                    depthTwo: address.sigungu,
                    depthThree: address.bname,
                    fullLocation: address.address,
                    postNum: address.zonecode,
                    street: address.roadAddress,
                    detail: "",
                },
            },
            isAddressPopupOpen: false,
        })
    }

    const onChangeAddressDetail = (e: any) => {
        setStatusValue({
            ...statusValue,
            compInfoCreateRequest: {
                ...statusValue.compInfoCreateRequest,
                address: {
                    ...statusValue.compInfoCreateRequest.address!,
                    detail: e.target.value,
                },
            },
            isAddressPopupOpen: false,
        })
    }

    const onChangeFileId = (fileId: string) => {
        setStatusValue({
            ...statusValue,
            compInfoCreateRequest: {
                ...statusValue.compInfoCreateRequest,
                fileId: fileId,
            }
        })
    }

    const validCreateCompany =  ():boolean => {
        if(!statusValue.compInfoCreateRequest.name) {
            alertContext.openAlert("회사이름은 필수 입니다.", "warning");
            return false;
        }
        if(!statusValue.compInfoCreateRequest.owner) {
            alertContext.openAlert("대표자명은 필수 입니다.", "warning");
            return false;
        }
        if(!statusValue.compInfoCreateRequest.email) {
            alertContext.openAlert("회사이메일은 필수 입니다.", "warning");
            return false;
        }
        if(!statusValue.compInfoCreateRequest.phone) {
            alertContext.openAlert("회사대표번호는 필수 입니다.", "warning");
            return false;
        }
        if(!PHONE_REGEX.test(statusValue.compInfoCreateRequest.phone)) {
            alertContext.openAlert("회사대표번호는 숫자만 입력가능합니다.", "warning");
            return false;
        }
        if(!statusValue.compInfoCreateRequest.introduction) {
            alertContext.openAlert("회사소개는 필수 입니다.", "warning");
            return false;
        }
        if(!statusValue.compInfoCreateRequest.history) {
            alertContext.openAlert("연혁은 필수 입니다.", "warning");
            return false;
        }

        return true;
    }

    const createCompany = async() => {
        if(!validCreateCompany()) return;
        const response = await axios.postData<CompInfoCreateResponse>(
            DEFINE_COMPANY_CREATE
            , statusValue.compInfoCreateRequest
            ,{
                headers: {
                    Authorization: `${BEARER_STRING}${fn_Decrypt(sessionStorage.getItem(MBAAS_ACCESS_TOKEN)!)}`
                }
            });

        fn_Debug("CREATE_COMPANY_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            getData();
        } else {
            // name, owner, phone, email, introduction, history
            // name, address
            alertContext.openAlert(response.message, "warning");
        }
    }

    const validCreateAiLogo = () => {
        if(!prompt) {
            alertContext.openAlert("생성하고싶은 이미지에 대한 설명 문구를 넣어주세요.", "warning");
            return false;
        }
        return true;
    }

    const createAiLogo = async() => {
        if(!validCreateAiLogo()) {
            return;
        }
        const response = await axios.postData<FileResponseV2>(DEFINE_COMPANY_CREATE_AI_LOGO, {
            "prompt" : prompt,
        });
        fn_Debug("CREATE_AI_LOGO");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setAiImageList([...aiImageList, response.data]);
        } else {
            alertContext.openAlert(response.message, "warning");
        }
    }

    return {
        statusValue,
        onChange,
        onChangeIntroduction,
        onChangeHistory,
        onChangeDirections,
        changeAddressPopup,
        comfirmAddressPopup,
        onChangeAddressDetail,
        onChangeFileId,
        createCompany,
        createAiLogo,
        setPrompt,
        aiImageList,
    }
}