import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { RESERVE_READ_PATH, RESERVE_CREATE_PATH } from '../../../../../common/path';
import Paging from '../../../../common/Paging';
import NoActionAlert from '../../../../alert/NoActionAlert';
import { useReserveListHook } from './ReserveList.hook';

const ReserveList = () => {
  const navigator = useNavigate();
  const reserveListHook = useReserveListHook();

  return (
    <>
        <h2>클래스(강좌) 관리</h2>
        <Link to={RESERVE_CREATE_PATH}><div className="flex flexE"><button className="on">클래스 등록</button></div></Link>
        {
            reserveListHook.statusValue.reserveListResponse &&
            reserveListHook.statusValue.reserveListResponse.mainList.length !== 0 ?
            <>
                <div className="flex spaceB alignC" style={{marginTop: "20px"}}>
                    <div className="search">
                    <select>
                        <option value="all">전체</option>
                        <option value="name" selected>이름</option>
                        <option value="explanation">설명</option>

                    </select>
                    <input type="text" placeholder="검색어를 입력해주세요."/>
                    <button><img src={require("../../../../../img/icon_search_white.png")} alt="검색" /></button>
                    </div>
                    <div className="filter">
                    <select>
                        <option value="all" selected>전체</option>
                        <option value="use">사용</option>
                        <option value="notuse">미사용</option>
                    </select>
                    </div>
                </div>

                <div className="boxContents">
                    <button onClick={reserveListHook.onClickDelete}>삭제</button>
                    <table className="table table--block">
                    <thead>
                        <tr>
                        <th><input type='checkbox' onClick={reserveListHook.onCheckAll}/></th>
                        {/* <th className="tableShort">No</th> */}
                        <th>이름</th>
                        <th>가격</th>
                        {/* <th>총 예약자</th>
                        <th>예약 대기자</th> */}
                        {/* <th>조회수</th> */}
                        <th>상태</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            reserveListHook.statusValue.reserveListResponse.mainList.map(rsv => (
                                <tr onClick={() => {navigator(RESERVE_READ_PATH, {state:{data: rsv}})}} key={rsv.code}>
                                    <td onClick={(e) => {e.stopPropagation();}}>
                                        <input type='checkbox' 
                                            value={rsv.code}
                                            onClick={reserveListHook.onCheck}
                                            checked={reserveListHook.deleteIdxes.includes(rsv.code)}
                                            />
                                    </td>
                                    {/* <td data-name="No.">{idx}</td> */}
                                    <td data-name="이름">{rsv.name}</td>
                                    <td data-name="가격">{rsv.price}</td>
                                    {/* <td data-name="총 에약자"><span>10</span>명</td>
                                    <td data-name="예약 대기자"><span>10</span>명</td> */}
                                    <td data-name="상태">
                                        <span className={reserveListHook.getStatusClassName(rsv.status)}>{reserveListHook.getStatus(rsv.status)}</span>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                    </table>
                    <div className="page">
                        <Paging
                            itemOffset={reserveListHook.statusValue.pagination.itemOffset}
                            setItemOffset={reserveListHook.setItemOffset}
                            itemsLength={reserveListHook.statusValue.reserveListResponse?.totalCount ?? 0}/>
                    </div>
                </div>
            </>:
            <NoActionAlert alertText='등록된 강의가 없습니다.' open={true} serverity='warning'/> 
        }
         
        {/* <WaitingListPopup/> */}
    </>
  )
}

export default ReserveList