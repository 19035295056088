import DefaultPopup from '../../alert/DefaultPopup';
import ChangePwPopup from '../../popup/ChangePwPopup';
import { useFindPwHook } from './FindPw.hook';
import BasicPopup from '../../popup/BasicPopup';

const FindPw = () => {
  const findPwHook = useFindPwHook();
  
  return (
    <>
        <div className="wrap flex flexC" style={{height:"100vh", background:"#fff"}}>
            <div className="findPwWrap" style={{marginTop:"150px"}}>
                <h1 className="center"><img src={require("../../../img/mbaas_shop_bi.png")} alt="엠바스 로고"/></h1>
                <h2 className="center">비밀번호 재설정</h2>
                <p>이메일을 입력해 주세요.</p>
                <div className="flexX" style={{gap:"5px"}}>
                    <input type="text" name="memberEmail" onChange={findPwHook.onChangeFindPwRequest} placeholder="이메일 입력"/>
                    {
                      findPwHook.statusValue.isSend ? "" : <button className="on" onClick={findPwHook.sendAuthCode}>인증메일 발송</button>
                    }
                </div>
                <div className=" flex alignC codeInput">
                    <input type="number" name="code" onChange={findPwHook.onChangeCheckAuthRequest} placeholder="인증번호 입력"/>
                    <span>{findPwHook.statusValue.authTimeFormat}</span>
                    {
                      findPwHook.statusValue.isSend ? <button onClick={findPwHook.reSendAuthCode}>재발송</button> : ""
                    }
                </div>
                {
                  findPwHook.statusValue.isAuthCheckFail ? <div className="warning on">!인증번호가 일치하지 않습니다.</div> : ""
                }
                {
                  findPwHook.statusValue.isAuthTimeout ? <div className="warning on">!인증시간 초과</div> : ""
                }
                <button className="on100" onClick={findPwHook.checkAuthCode}>이메일 인증</button>
            </div>
        </div>
        {
          findPwHook.statusValue.isPopupOpen ?
          <DefaultPopup zIndex={100}>
            <BasicPopup message={findPwHook.statusValue.popupMessage} onClick={findPwHook.sendAuthCodeSuccess}/>
          </DefaultPopup> : ""
        }

        {
          findPwHook.statusValue.isSuccessChPwHookOpen ?
          <DefaultPopup zIndex={100}>
            <BasicPopup message={findPwHook.statusValue.popupMessage} onClick={findPwHook.successUpdateUserPassword}/>
          </DefaultPopup> : ""
        }

        {
          findPwHook.statusValue.isChPwPopupHookOpen ?
          <DefaultPopup zIndex={50}>
            <ChangePwPopup 
              onCancle={findPwHook.onClickChPwPopup} 
              onConfirm={findPwHook.updateUserPassword}
              onChange={findPwHook.onChangeUpdateUserPassword}/>
          </DefaultPopup> : ""
        }
    </>
  )
}

export default FindPw