import { useContext, useEffect, useState } from "react"
import { UserAskCreateAnState } from "./UserAskCreateAn.model";
import { useLocation } from "react-router-dom";
import { fn_Debug } from "../../../../../common/module";
import { DEFINE_USERASK_APP_CREATE_ANSWER, DEFINE_USERASK_APP_DELETE_ANSWER, DEFINE_USERASK_CREATE_ANSWER, DEFINE_USERASK_DELETE_ANSWER, MBAAS_APP_CODE, RESPONSE_RESULT_FAIL, RESPONSE_RESULT_SUCCESS } from "../../../../../common/const";
import { useAxios } from "../../../../../common/hooks";
import { AlertContext } from "../../../../../common/context";


export const useUserAskCreateAn = (callback?: () => void) => {
    const location = useLocation();
    const alertContext = useContext(AlertContext);

    const [statusValue, setStatusValue] = useState<UserAskCreateAnState>({
        userAskCreateAnRequest: {
            appCode: sessionStorage.getItem(MBAAS_APP_CODE) ?? undefined,
            title: "",
            content: "",
            qnaCode: location.state["qnaCode"],
        },
        isOpen: false,
    });

    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<boolean>(false);

    const axios = useAxios();

    useEffect(() => {
        fn_Debug("USER_ASK_CREATE_AN_STATE");
        fn_Debug(statusValue);
    }, [statusValue])

    const onChange = (e: any) => {
        setStatusValue({
            ...statusValue,
            userAskCreateAnRequest: {
                ...statusValue.userAskCreateAnRequest,
                [e.target.name] : e.target.value,
            }
        })
    }

    const onChangeContent = (val: string) => {
        setStatusValue({
            ...statusValue,
            userAskCreateAnRequest: {
                ...statusValue.userAskCreateAnRequest,
                content: val,
            }
        })
    }

    const openAnswer = () => {
        setStatusValue({
            ...statusValue,
            isOpen: !statusValue.isOpen
        })
    }

    const onChangeFileId = (fileId: string) => {
        setStatusValue({
            ...statusValue,
            userAskCreateAnRequest: {
                ...statusValue.userAskCreateAnRequest,
                fileId: fileId,
            }
        })
    }

    const validCreateAnswer = ():boolean => {
        if(!statusValue.userAskCreateAnRequest.title) {
            alertContext.openAlert("제목을 입력해주세요", "warning");
            return false;
        }
        if(!statusValue.userAskCreateAnRequest.content) {
            alertContext.openAlert("내용을 입력해주세요", "warning");
            return false;
        }
        if(!statusValue.userAskCreateAnRequest.qnaCode) {
            alertContext.openAlert("잘못된 요청입니다.", "warning");
            return false;
        }

        return true;
    }

    const createAnswer = async() => {
        if(!validCreateAnswer()) return;

        const response = await axios.postData(
            sessionStorage.getItem(MBAAS_APP_CODE) ? DEFINE_USERASK_APP_CREATE_ANSWER : DEFINE_USERASK_CREATE_ANSWER
            , statusValue.userAskCreateAnRequest)

        fn_Debug("CREATE_ANSWER_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setStatusValue({
                ...statusValue,
                userAskCreateAnRequest: {
                    ...statusValue.userAskCreateAnRequest,
                    content: "",
                    fileId: "",
                    title: "",
                },
                isOpen: false,
            })
            alertContext.openAlert("답변이 등록되었습니다.", "success");
            window.location.reload();
        }
        else if(response.result === RESPONSE_RESULT_FAIL) {
            alertContext.openAlert(response.message, "error");
            setIsDeletePopupOpen(false);
        }
    }

    const deleteAnswer = async(replyCode: string) => {
        const response = await axios.deleteData(
            sessionStorage.getItem(MBAAS_APP_CODE) ? DEFINE_USERASK_APP_DELETE_ANSWER : DEFINE_USERASK_DELETE_ANSWER
            , {
            data: {
                appCode: sessionStorage.getItem(MBAAS_APP_CODE),
                replyCodeList: [replyCode],
            },
            
        });

        fn_Debug("DELETE_REPLY");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            alertContext.openAlert("답변이 삭제되었습니다.", "success");
            setIsDeletePopupOpen(false);
            window.location.reload();
        }
        else if(response.result === RESPONSE_RESULT_FAIL) {
            alertContext.openAlert(response.message, "error");
            setIsDeletePopupOpen(false);
        }
    }

    const openDeletePopup = () => {
        setIsDeletePopupOpen(!isDeletePopupOpen);
    }

    return {
        statusValue,
        onChange,
        onChangeContent,
        openAnswer,
        onChangeFileId,
        createAnswer,
        deleteAnswer,
        isDeletePopupOpen,
        openDeletePopup,
    }
}