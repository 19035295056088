import React from 'react'

interface Props {
    display?: boolean
}

const Loading:React.FC<Props> = ({display = false,}) => {
  return (
    <>
        {/* <div>loading</div> */}
        <div className="popWrap" style={{display: display ? "flex" : "none"}}>

            <img src={require("../../img/loading.png")} alt="loading" className='loading'/>

        </div>
    </>
  )
}

export default Loading