import React from 'react'
import { useNavigate } from 'react-router-dom'
import { RESERVE_LIST_PATH } from '../../../../../common/path';
import Editor from '../../../../common/Editor';
import FileUpload from '../../../../common/FileUpload';
import { useReserveUpdateHook } from './ReserveUpdate.hook';

const ReserveUpdate = () => {
  const navigator = useNavigate();
  const reserveUpdateHook = useReserveUpdateHook();

  return (
    <>
        <h2>클래스({reserveUpdateHook.statusValue.reserveUpdateRequest.name}) 수정</h2>

        <div className="boxContents">
        <table className="tableCol">
            <tr>
            <th>이름</th>
            <td>
                <input type="text" 
                    placeholder="이름을 입력하세요."
                    name='name'
                    onChange={reserveUpdateHook.onChange}
                    value={reserveUpdateHook.statusValue.reserveUpdateRequest.name}/>
            </td>
            </tr>
            <tr>
            <th>가격</th>
            <td>
                <input type="number" 
                    placeholder="가격을 입력하세요."
                    name='price'
                    onChange={reserveUpdateHook.onChange}
                    value={reserveUpdateHook.statusValue.reserveUpdateRequest.price}/>
            </td>
            </tr>
            <tr>
                <th>클래스 강사명</th>
                <td>
                    <input type="text" 
                        placeholder='강사 이름을 입력하세요.'
                        name='tutor'
                        onChange={reserveUpdateHook.onChange}
                        value={reserveUpdateHook.statusValue.reserveUpdateRequest.tutor}/>
                </td>
            </tr>
            <tr>
                <th>클래스 최대수강생</th>
                <td>
                    <input type="number" placeholder='최대수강생을 입력하세요.' name='maxStudent'
                        onChange={reserveUpdateHook.onChange}
                        value={reserveUpdateHook.statusValue.reserveUpdateRequest.maxStudent}/>
                </td>
            </tr>
            <tr>
                <th>강의 기간</th>
                <td>
                    <input name='start' 
                        onChange={reserveUpdateHook.onChange} 
                        type='date'
                        value={reserveUpdateHook.statusValue.reserveUpdateRequest.start}/>
                     &nbsp; ~ &nbsp;<input name='end' 
                        onChange={reserveUpdateHook.onChange} 
                        type='date'
                        value={reserveUpdateHook.statusValue.reserveUpdateRequest.end}/>
                </td>
            </tr>
            <tr>
                <th>시간</th>
                <td>
                    <div>
                        <input type="date" name='classDate'
                             onChange={reserveUpdateHook.onChangeOnedayClass}/> &nbsp;
                        <input type="time" step="300" name='classStart'
                            onChange={reserveUpdateHook.onChangeOnedayClass}/>&nbsp;
                            ~&nbsp; <input type="time" step="300" name='classEnd'
                            onChange={reserveUpdateHook.onChangeOnedayClass}/> 
                        <button onClick={reserveUpdateHook.addClass}>+</button>
                    </div>
                    <div>
                    {
                        reserveUpdateHook.statusValue.reserveUpdateRequest.oneDayClassList.map(odc => (
                            <>
                                <div>
                                    <input type='date' value={odc.classDate} onClick={(e) => {e.preventDefault()}}/> &nbsp;
                                    <input type='time' value={odc.classStart} disabled/>&nbsp;
                                    ~&nbsp; <input type='time' value={odc.classEnd} disabled/>
                                    <button onClick={() => {reserveUpdateHook.removeClass(odc.key, odc.classCode)}}>-</button>
                                </div>
                            </>
                        ))
                    }
                    </div>
                </td>
            </tr>
            <tr>
            <th>설명</th>
            <td>
                <div>
                    {
                        <Editor 
                            initialData={reserveUpdateHook.statusValue.reserveUpdateRequest.content}
                            onChange={reserveUpdateHook.onChangeContent}/>
                    }
                </div>
            </td>
            </tr>
            <tr>
            <th>이미지<br/>(가로 360px * 세로 270px)</th>
                <td>
                    <FileUpload
                        fleGrpId={reserveUpdateHook.statusValue.reserveUpdateRequest.fileId}
                        afterPost={reserveUpdateHook.onChangeFileId}
                        required={true}
                    />
                </td>
            </tr>
            {/* <tr>
            <th>사용 여부</th>
            <td>
                <label>
                <input type="radio" name="use" checked/> 사용
                </label>
                <label>
                <input type="radio" name="use"/> 미사용
                </label>
            </td>
            </tr> */}
            <div className='explanationWrap'>
            <div className='tip'>{/* <img src={require("../../../../../img/icon_info.png")} alt="" />&nbsp; */}예약설정 하는 법</div>
            <div className='explanatioCon'>
                <ul>
                    <li>1. 강의 관련 정보를 입력해 줍니다.</li>
                    <li>2. 강의 기간을 설정해줍니다.</li>
                    <li>3. 강의 시간을 입력후 플러스 버튼을 눌러 추가해줍니다. 추가된 시간은 하단에 나열됩니다.</li>
                    <li>&nbsp;&nbsp;&nbsp; - 시간은 강의 기간 이전이나 초과할 수 없습니다. </li>
                    <li>&nbsp;&nbsp;&nbsp; - 필요없는 시간은 마이너스 버튼을 눌러 삭제시켜 줍니다.</li>
                </ul>
            </div>
        </div>
        </table>
        <div className="btnBoxB">
            <div>
            <button className="off" onClick={() => {navigator(RESERVE_LIST_PATH)}}>목록</button>
            {/* <button className="off">저장값 복원</button> */}
            </div>
            <button className="on" onClick={reserveUpdateHook.updateClass}>저장</button>
        </div>
        </div>
    </>
  )
}

export default ReserveUpdate