import React from 'react'
import { useNavigate } from 'react-router-dom'
import { RESERVE_LIST_PATH, RESERVE_UPDATE_PATH  } from '../../../../../common/path';
import { useReserveReadHook } from './ReserveRead.hook';
import HTMLReactParser from 'html-react-parser';

const ReserveRead = () => {
  const navigator = useNavigate();
  const reserveReadHook = useReserveReadHook();

  return (
    <>
        <h2>클래스(강좌) 상세</h2>

        <div className="boxContents">
        <table className="tableCol">
            <tr>
            <th>이름</th>
            <td>{reserveReadHook.statusValue.name} </td>
            </tr>
            <tr>
            <th>가격</th>
            <td>{reserveReadHook.statusValue.price} 원</td>
            </tr>
            <tr>
              <th>클래스 강사명</th>
              <td>{reserveReadHook.statusValue.tutor}</td>
            </tr>
            <tr>
              <th>시간</th>
              <td>
              {
                reserveReadHook.statusValue.oneDayClassList.map(odc => (
                  <div>{odc.classDate} {odc.classStart} ~ {odc.classEnd}</div>
                ))
              }
              </td>
            </tr>
            <tr>
            <th>설명</th>
            <td> {HTMLReactParser(reserveReadHook.statusValue.content)} </td>
            </tr>
            <tr>
            <th>이미지<br/>(가로 360px * 세로 270px)</th>
            <td>
                <ul className="itemImg">
                  {
                    reserveReadHook.statusValue.fileInfoList ? 
                    <img src={reserveReadHook.statusValue.fileInfoList[0].fileUrl} alt={reserveReadHook.statusValue.fileInfoList[0].fileUrl}/> : ""
                  }
                </ul>
            </td>
            </tr>
            {/* <tr>
            <th>사용 여부</th>
            <td>value</td>
            </tr> */}
        </table>
        <div className="btnBoxB">
            <button className="off" onClick={() => {navigator(RESERVE_LIST_PATH)}}>목록</button>
            <button className="on" onClick={() => {navigator(RESERVE_UPDATE_PATH, {state: {data: reserveReadHook.statusValue}} )}}>수정</button>
        </div>
        </div>
    </>
  )
}

export default ReserveRead