import React from 'react'
import { useNavigate } from 'react-router-dom'
import { SIM_APP_LIST_PATH } from '../../../../../common/path';
import Editor from '../../../../common/Editor';
import { useSimAppUpdateHook } from './SimAppUpdate.hook';
import FileUpload from '../../../../common/FileUpload';

const SimAppUpdate = () => {
  const navigate = useNavigate();
  const simAppUpdateHook = useSimAppUpdateHook();

  return (
    <>
        <h2>유사앱 관리 수정</h2>

        <div className="boxContents">
        <table className="tableCol">
            <tr>
            <th>이름</th>
            <td>
                <input 
                    type="text" 
                    placeholder="이름을 입력하세요."
                    name='name'
                    value={simAppUpdateHook.statusValue.simAppUpdateRequest.name}
                    onChange={simAppUpdateHook.onChange}/>
            </td>
            </tr>
            <tr>
            <th>앱용도</th>
            <td>
                {
                    simAppUpdateHook.templateHook.statusValue?.temCategoryResponse.categoryList.map(cate => (
                        <label>
                            <input 
                                type="checkbox" 
                                name="usage" 
                                value={JSON.stringify(cate)}
                                onClick={simAppUpdateHook.onChangeCate}
                                defaultChecked={simAppUpdateHook.appUseDefaultChecked(cate)}/>
                            <span>{cate.name}</span>
                        </label>
                    ))
                }
            </td>
            </tr>
            <tr>
            <th>설명</th>
            <td>
                <div>
                    <Editor 
                        placeholder="소개문구를 입력하세요"
                        initialData={simAppUpdateHook.statusValue.simAppUpdateRequest.content}
                        onChange={simAppUpdateHook.onChangeContent}/>
                </div>
            </td>
            </tr>
            <tr>
                <th>예제 이미지<br/>(가로 360px * 세로360px)</th>
                <td>
                    <FileUpload
                        afterPost={simAppUpdateHook.onChangeFileId}
                        maxFile={5}
                        fleGrpId={simAppUpdateHook.statusValue.simAppUpdateRequest.fileId}
                        required={true}
                    />
                </td>
            </tr>
            <tr>
            <th>추천 유형</th>
            <td>
                <label>
                <input 
                    type="radio" 
                    name="randomType" 
                    value="BASIC" 
                    defaultChecked={simAppUpdateHook.statusValue.simAppUpdateRequest.randomType === "BASIC"}
                    onClick={simAppUpdateHook.onChange}/>
                <span>기본 추천</span>
                </label>
                <label>
                <input 
                    type="radio" 
                    name="randomType" 
                    value="RANDOM" 
                    defaultChecked={simAppUpdateHook.statusValue.simAppUpdateRequest.randomType === "RANDOM"}
                    onClick={simAppUpdateHook.onChange}/>
                <span>랜덤 추천</span>
                </label>
            </td>
            </tr>
            <tr>
            <th>사용 여부</th>
            <td>
                <label>
                <input 
                    type="radio" 
                    name="status" 
                    value="Y" 
                    defaultChecked={simAppUpdateHook.statusValue.simAppUpdateRequest.status === "Y"}
                    onClick={simAppUpdateHook.onChange}/>
                <span>사용</span>
                </label>
                <label>
                <input 
                    type="radio" 
                    name="status" 
                    value="N" 
                    defaultChecked={simAppUpdateHook.statusValue.simAppUpdateRequest.status === "N"}
                    onClick={simAppUpdateHook.onChange}/>
                <span>미사용</span>
                </label>
            </td>
            </tr>
        </table>
        <div className="btnBoxB">
            <div>
            <button className="off" onClick={() => {navigate(SIM_APP_LIST_PATH)}}>목록</button>
            {/* <button className="off">저장값 복원</button> */}
            </div>
            <button className="on" onClick={simAppUpdateHook.updateSimApp}>저장</button>
        </div>
        </div>
    </>
  )
}

export default SimAppUpdate