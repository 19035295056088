/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { DEFINE_COMPANY_READ, RESPONSE_RESULT_SUCCESS } from "../../../../../common/const";
import { useAxios } from "../../../../../common/hooks";
import { CompInfoReadResponse } from "../../../../bo/company/read/CompInfoRead.model";
import { useHelpApReadHook } from "../../../../bo/template/helpAp/read/HelpApRead.hook"
import { AlertContext } from "../../../../../common/context";
import { useNavigate } from "react-router-dom";
import { PLANNING_COMPANYINFO_UPDATE_PATH } from "../../../../../common/path";

export const useCompanyInfoHook = (setOver?: (flag: boolean) => void) => {
    const axios = useAxios();
    const helpApReadHook = useHelpApReadHook();
    const alertContext = useContext(AlertContext);
    const [compInfo, setCompInfo] = useState<CompInfoReadResponse>();
    const navigator = useNavigate();

    useEffect(() => {
        if(helpApReadHook.statusValue.helpApReadResponse) {
            getCompInfo();
        }
    }, [helpApReadHook.statusValue.helpApReadResponse])

    const getCompInfo = async() => {
        const response = await axios.getData<CompInfoReadResponse>(DEFINE_COMPANY_READ, {}, true, [helpApReadHook.statusValue.helpApReadResponse?.companyCode ?? ""])

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setCompInfo(response.data);
            if(setOver) {
                setOver(true);
            }
        } else {
            alertContext.openAlert(response.message, "error");
        }
    }

    const toUpdatePage = () => {
        if(!compInfo?.slot) {
            alertContext.openAlert("회사 등록 후 수정가능합니다.", "warning");
        } else {
            navigator(PLANNING_COMPANYINFO_UPDATE_PATH);
        }
    }

    return {
        compInfo,
        toUpdatePage
    }
}