import { useContext, useEffect, useState } from "react"
import { ReserveUpdateState } from "./ReserveUpdate.model";
import { useLocation, useNavigate } from "react-router-dom";
import { OnedayClass } from "../Reserve.model";
import { AlertContext } from "../../../../../common/context";
import { useAxios } from "../../../../../common/hooks";
import { DEFINE_RESERVE_UPDATE, MBAAS_APP_CODE, RESPONSE_RESULT_SUCCESS } from "../../../../../common/const";
import { fn_Debug } from "../../../../../common/module";

export const useReserveUpdateHook = () => {
    const location = useLocation()
    const alertContext = useContext(AlertContext);
    const axios = useAxios();
    const navigator = useNavigate();

    const [statusValue, setStatusValue] = useState<ReserveUpdateState>({
        reserveUpdateRequest: {
            ...location.state.data,
            appCode: sessionStorage.getItem(MBAAS_APP_CODE)
        }
    });
    const [onedayClassObj, setOnedayClassObj] = useState<OnedayClass>({
        classCode: "",
        key: "",
        classEnd: "",
        classDate: "",
        classStart: "",
        // maxStudent: 0,
    });

    const onChange = (e:any) => {
        setStatusValue({
            ...statusValue,
            reserveUpdateRequest: {
                ...statusValue.reserveUpdateRequest,
                [e.target.name]: e.target.value
            }
        })
    }

    const onChangeOnedayClass = (e: any) => {
        setOnedayClassObj({
            ...onedayClassObj,
            [e.target.name]: e.target.value,
        })
    }

    const onChangeFileId = (fileId: string) => {
        setStatusValue({
            ...statusValue,
            reserveUpdateRequest: {
                ...statusValue.reserveUpdateRequest,
                fileId: fileId,
            }
        })
    }

    const onChangeContent = (data: string) => {
        setStatusValue({
            ...statusValue,
            reserveUpdateRequest: {
                ...statusValue.reserveUpdateRequest,
                content: data,
            }
        })
    }

    const validAddClass = (): boolean => {
        if(!onedayClassObj.classDate) {
            alertContext.openAlert("강의 일자를 입력해주세요.", "warning");
            return false;
        }
        if(!onedayClassObj.classStart) {
            alertContext.openAlert("강의 시작시간을 입력해주세요.", "warning");
            return false;
        }
        if(!onedayClassObj.classEnd) {
            alertContext.openAlert("강의 종료시간을 입력해주세요.", "warning");
            return false;
        }
        if(onedayClassObj.classStart >= onedayClassObj.classEnd) {
            alertContext.openAlert("강의 시간을 확인해주세요.", "warning");
            return false;
        }
        
        for(let i = 0; i < statusValue.reserveUpdateRequest.oneDayClassList.length; i++) {
            if(statusValue.reserveUpdateRequest.oneDayClassList[i].key 
                === onedayClassObj.classDate + onedayClassObj.classStart + onedayClassObj.classEnd) {
                    alertContext.openAlert("동일한 강의가 등록되어있습니다.", "warning");
                    return false;
            }
        }

        return true;
    }

    const addClass = () => {
        if(!validAddClass()) return;

        setStatusValue({
            ...statusValue,
            reserveUpdateRequest: {
                ...statusValue.reserveUpdateRequest,
                oneDayClassList: [
                    ...statusValue.reserveUpdateRequest.oneDayClassList,
                    {
                        ...onedayClassObj,
                        key: onedayClassObj.classDate
                            + onedayClassObj.classStart
                            + onedayClassObj.classEnd,
                    },
                ]
            }
        })
    }

    const removeClass = (key: string, code: string) => {
        if(key) {
            setStatusValue({
                ...statusValue,
                reserveUpdateRequest: {
                    ...statusValue.reserveUpdateRequest,
                    oneDayClassList: statusValue.reserveUpdateRequest.oneDayClassList.filter(cls => cls.key !== key)
                }
            })
        } else {
            setStatusValue({
                ...statusValue,
                reserveUpdateRequest: {
                    ...statusValue.reserveUpdateRequest,
                    oneDayClassList: statusValue.reserveUpdateRequest.oneDayClassList.filter(cls => cls.classCode !== code || cls.key)
                }
            })
        }
    }

    // const removeCodeClass = (code: string) => {
    //     setStatusValue({
    //         ...statusValue,
    //         reserveUpdateRequest: {
    //             ...statusValue.reserveUpdateRequest,
    //             oneDayClassList: statusValue.reserveUpdateRequest.oneDayClassList.filter(cls => cls.code !== code)
    //         }
    //     })
    // }

    const validCreateReserve = ():boolean => {
        if(!statusValue.reserveUpdateRequest.name) {
            alertContext.openAlert("강의 이름을 입력해주세요.", "warning");
            return false;
        }
        if(!statusValue.reserveUpdateRequest.price) {
            alertContext.openAlert("강의 가격을 입력해주세요.", "warning");
            return false;
        }
        if(!statusValue.reserveUpdateRequest.tutor) {
            alertContext.openAlert("강사 이름을 입력해주세요.", "warning");
            return false;
        }
        if(!statusValue.reserveUpdateRequest.maxStudent) {
            alertContext.openAlert("강의 최대 수강생을 입력해주세요.", "warning");
            return false;
        }
        if(!statusValue.reserveUpdateRequest.start || !statusValue.reserveUpdateRequest.end) {
            alertContext.openAlert("강의 기간을 입력해주세요.", "warning");
            return false;
        }
        if(statusValue.reserveUpdateRequest.oneDayClassList.length === 0) {
            alertContext.openAlert("강의 시간을 입력해주세요.", "warning");
            return false;
        }
        if(!statusValue.reserveUpdateRequest.content) {
            alertContext.openAlert("강의 설명을 입력해주세요.", "warning");
            return false;
        }
        if(!statusValue.reserveUpdateRequest.name) {
            alertContext.openAlert("강의 이미지를 입력해주세요.", "warning");
            return false;
        }
        for(let i = 0; i < statusValue.reserveUpdateRequest.oneDayClassList.length; i++) {
            if(
                statusValue.reserveUpdateRequest.oneDayClassList[i].classDate < statusValue.reserveUpdateRequest.start
                    || statusValue.reserveUpdateRequest.oneDayClassList[i].classDate > statusValue.reserveUpdateRequest.end
            ) {
                alertContext.openAlert("강의 기간이 올바르지 않습니다", "warning");
                return false;
            }
        }

        return true;
    }

    const updateClass = async() => {
        if(!validCreateReserve()) return;

        const response = await axios.putData(DEFINE_RESERVE_UPDATE, statusValue.reserveUpdateRequest);

        fn_Debug("RESPONSE_UPDATE_CLASS");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            alertContext.openAlert("강의가 수정되었습니다.", "success");
            navigator(-2);
        } else {
            alertContext.openAlert(response.message, "error");
        }
    }

    return {
        statusValue,
        addClass,
        removeClass,
        onChangeOnedayClass,
        onChange,
        onChangeFileId,
        onChangeContent,
        updateClass,
    }
}