import { gatewayPort, gatewayUrl } from "../config";
import axios from 'axios';
import { DEFINE_ADDFUNC_CREATE, DEFINE_ADDFUNC_LIST, DEFINE_ADDFUNC_READ, DEFINE_ADDFUNC_UPDATE, DEFINE_ALL_CAPABILITY_LIST, DEFINE_ALL_TEMPLATE_LIST, DEFINE_APHELP_READ, DEFINE_APHELP_UPDATE, DEFINE_CHECK_AUTH_CODE, DEFINE_COMMON_GET_FILE, DEFINE_COMMON_MODIFY_FILE, DEFINE_COMMON_UPLOAD_FILE, DEFINE_COMPANY_CREATE, DEFINE_COMPANY_DELETE, DEFINE_COMPANY_LIST, DEFINE_COMPANY_READ, DEFINE_COMPANY_UPDATE, DEFINE_CREATE_APP_NOTICE, DEFINE_CREATE_NOTICE, DEFINE_DELETE_APP_NOTICE, DEFINE_DELETE_NOTICE, DEFINE_GET_APP_NOTICE_LIST, DEFINE_GET_APP_NOTICE_READ, DEFINE_GET_AP_MEM_LIST, DEFINE_GET_MY_INFO, DEFINE_GET_M_ADMIN_LIST, DEFINE_GET_M_MEM_LIST, DEFINE_GET_M_MEM_READ, DEFINE_GET_NOTICE_LIST, DEFINE_GET_NOTICE_READ, DEFINE_ITEM_CREATE, DEFINE_ITEM_DELETE, DEFINE_ITEM_LIST, DEFINE_ITEM_READ, DEFINE_ITEM_UPDATE, DEFINE_RESERVE_LIST, DEFINE_RESERVE_CREATE, DEFINE_RESERVE_READ, DEFINE_RESERVE_UPDATE, DEFINE_JOIN, DEFINE_LOGIN, DEFINE_MYPAGE_UPDATE, DEFINE_SEND_AUTH_CODE_EMAIL, DEFINE_SIMAPP_CREATE, DEFINE_SIMAPP_LIST, DEFINE_SIMAPP_LIST_TAG, DEFINE_SIMAPP_READ, DEFINE_SIMAPP_UPDATE, DEFINE_TEMCATEGORY_LIST, DEFINE_TEMPLATE_CREATE, DEFINE_TEMPLATE_LIST, DEFINE_TEMPLATE_READ, DEFINE_TEMPLATE_UPDATE, DEFINE_UPDATE_APP_FILEID_NOTICE, DEFINE_UPDATE_APP_NOTICE, DEFINE_UPDATE_FILEID_NOTICE, DEFINE_UPDATE_NOTICE, DEFINE_UPDATE_PASSWORD, DEFINE_USERASK_APP_CREATE_ANSWER, DEFINE_USERASK_APP_DELETE_ANSWER, DEFINE_USERASK_APP_LIST, DEFINE_USERASK_APP_READ, DEFINE_USERASK_APP_UPDATE_ANSWER, DEFINE_USERASK_CREATE_ANSWER, DEFINE_USERASK_DELETE_ANSWER, DEFINE_USERASK_LIST, DEFINE_USERASK_READ, DEFINE_USERASK_UPDATE_ANSWER, DEFINE_UPDATE_KICK, DEFINE_UPDATE_BAN, DEFINE_UPDATE_ACTIVE, DEFINE_UPDATE_MYPAGE_PASSWORD, DEFINE_GET_AP_MEM_READ, DEFINE_RESERVE_DELETE, DEFINE_COMPANY_CREATE_AI_LOGO } from "../const";

const memberServerPrefix: string = "member";

export let define: any = {
    [DEFINE_LOGIN] : {
        "url" : `${gatewayUrl}${gatewayPort}/${memberServerPrefix}/login`,
        "method" : "POST",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    }, 
    [DEFINE_JOIN] : {
        "url" : `${gatewayUrl}${gatewayPort}/${memberServerPrefix}`,
        "method" : "POST",
        "getClient" : () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        })
    },
    [DEFINE_SEND_AUTH_CODE_EMAIL] : {
        "url" : `${gatewayUrl}${gatewayPort}/${memberServerPrefix}/auth/send`,
        "method" : "POST",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_CHECK_AUTH_CODE] : {
        "url" : `${gatewayUrl}${gatewayPort}/${memberServerPrefix}/auth/code`,
        "method" : "POST",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_UPDATE_PASSWORD] : {
        "url" : `${gatewayUrl}${gatewayPort}/${memberServerPrefix}/auth/password`,
        "method" : "POST",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_UPDATE_MYPAGE_PASSWORD] : {
        "url" : `${gatewayUrl}${gatewayPort}/${memberServerPrefix}/update/password`,
        "method" : "POST",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_GET_MY_INFO]: {
        "url" : `${gatewayUrl}${gatewayPort}/${memberServerPrefix}/`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_GET_M_MEM_LIST]: {
        "url" : `${gatewayUrl}${gatewayPort}/${memberServerPrefix}/info/list`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_GET_M_MEM_READ]: {
        "url" : `${gatewayUrl}${gatewayPort}/${memberServerPrefix}`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_GET_M_ADMIN_LIST]: {
        "url" : `${gatewayUrl}${gatewayPort}/${memberServerPrefix}/info/list`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_GET_AP_MEM_LIST]: {
        "url" : `${gatewayUrl}${gatewayPort}/${memberServerPrefix}/list/app`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },

    [DEFINE_GET_AP_MEM_READ]: {
        "url" : `${gatewayUrl}${gatewayPort}/${memberServerPrefix}/list/app`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },

    [DEFINE_UPDATE_ACTIVE]: {
        "url" : `${gatewayUrl}${gatewayPort}/${memberServerPrefix}/info/active`,
        "method" : "POST",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_UPDATE_BAN]: {
        "url" : `${gatewayUrl}${gatewayPort}/${memberServerPrefix}/info/ban`,
        "method" : "POST",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_UPDATE_KICK]: {
        "url" : `${gatewayUrl}${gatewayPort}/${memberServerPrefix}/info/kick`,
        "method" : "POST",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    
    [DEFINE_GET_NOTICE_LIST]: {
        "url" : `${gatewayUrl}${gatewayPort}/board/notice`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_GET_NOTICE_READ]: {
        "url" : `${gatewayUrl}${gatewayPort}/board/notice`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_UPDATE_NOTICE]: {
        "url" : `${gatewayUrl}${gatewayPort}/board/notice`,
        "method" : "PUT",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_UPDATE_FILEID_NOTICE]: {
        "url" : `${gatewayUrl}${gatewayPort}/board/notice/file`,
        "method" : "POST",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_CREATE_NOTICE]: {
        "url" : `${gatewayUrl}${gatewayPort}/board/notice`,
        "method" : "POST",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_DELETE_NOTICE]: {
        "url" : `${gatewayUrl}${gatewayPort}/board/notice`,
        "method" : "DELETE",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },

    [DEFINE_GET_APP_NOTICE_LIST]: {
        "url" : `${gatewayUrl}${gatewayPort}/app-board/admin/notice`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_GET_APP_NOTICE_READ]: {
        "url" : `${gatewayUrl}${gatewayPort}/app-board/admin/notice`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_UPDATE_APP_NOTICE]: {
        "url" : `${gatewayUrl}${gatewayPort}/app-board/admin/notice`,
        "method" : "PUT",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_UPDATE_APP_FILEID_NOTICE]: {
        "url" : `${gatewayUrl}${gatewayPort}/app-board/admin/notice/file`,
        "method" : "POST",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_CREATE_APP_NOTICE]: {
        "url" : `${gatewayUrl}${gatewayPort}/app-board/admin/notice`,
        "method" : "POST",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_DELETE_APP_NOTICE]: {
        "url" : `${gatewayUrl}${gatewayPort}/app-board/admin/notice`,
        "method" : "DELETE",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },


    [DEFINE_USERASK_LIST]: {
        "url" : `${gatewayUrl}${gatewayPort}/board/qna`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_USERASK_READ]: {
        "url" : `${gatewayUrl}${gatewayPort}/board/qna`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_USERASK_CREATE_ANSWER]: {
        "url" : `${gatewayUrl}${gatewayPort}/board/qna/reply`,
        "method" : "POST",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_USERASK_DELETE_ANSWER]: {
        "url" : `${gatewayUrl}${gatewayPort}/board/qna/reply`,
        "method" : "DELETE",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_USERASK_UPDATE_ANSWER]: {
        "url" : `${gatewayUrl}${gatewayPort}/board/qna/reply`,
        "method" : "PUT",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },

    [DEFINE_USERASK_APP_LIST]: {
        "url" : `${gatewayUrl}${gatewayPort}/app-board/admin/qna`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_USERASK_APP_READ]: {
        "url" : `${gatewayUrl}${gatewayPort}/app-board/admin/qna`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_USERASK_APP_CREATE_ANSWER]: {
        "url" : `${gatewayUrl}${gatewayPort}/app-board/admin/qna/reply`,
        "method" : "POST",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_USERASK_APP_DELETE_ANSWER]: {
        "url" : `${gatewayUrl}${gatewayPort}/app-board/admin/qna/reply`,
        "method" : "DELETE",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_USERASK_APP_UPDATE_ANSWER]: {
        "url" : `${gatewayUrl}${gatewayPort}/app-board/admin/qna/reply`,
        "method" : "PUT",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },

    [DEFINE_COMPANY_LIST]: {
        "url" : `${gatewayUrl}${gatewayPort}/company`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_COMPANY_READ]: {
        "url" : `${gatewayUrl}${gatewayPort}/company`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_COMPANY_CREATE]: {
        "url" : `${gatewayUrl}${gatewayPort}/company`,
        "method" : "POST",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_COMPANY_DELETE]: {
        "url" : `${gatewayUrl}${gatewayPort}/company/update`,
        "method" : "DELETE",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_COMPANY_UPDATE]: {
        "url" : `${gatewayUrl}${gatewayPort}/company/update`,
        "method" : "PUT",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },


    [DEFINE_COMMON_UPLOAD_FILE]: {
        "url" : `${process.env.REACT_APP_FILE_HOST_URL}/v1/file`,
        "method" : "POST",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_COMMON_GET_FILE]: {
        "url" : `${process.env.REACT_APP_FILE_HOST_URL}/v1/fileDetail`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_COMMON_MODIFY_FILE]: {
        "url" : `${process.env.REACT_APP_FILE_HOST_URL}/v1/fileModify`,
        "method" : "POST",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },

    [DEFINE_TEMCATEGORY_LIST]: {
        "url" : `${gatewayUrl}${gatewayPort}/template/category`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },

    [DEFINE_TEMPLATE_LIST]: {
        "url" : `${gatewayUrl}${gatewayPort}/template/admin`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_TEMPLATE_READ]: {
        "url" : `${gatewayUrl}${gatewayPort}/template/admin`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_TEMPLATE_CREATE]: {
        "url" : `${gatewayUrl}${gatewayPort}/template/admin`,
        "method" : "POST",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_TEMPLATE_UPDATE]: {
        "url" : `${gatewayUrl}${gatewayPort}/template/admin`,
        "method" : "POST",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },

    [DEFINE_ADDFUNC_LIST]: {
        "url" : `${gatewayUrl}${gatewayPort}/template/admin/capability`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_ADDFUNC_READ]: {
        "url" : `${gatewayUrl}${gatewayPort}/template/admin/capability`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_ADDFUNC_CREATE]: {
        "url" : `${gatewayUrl}${gatewayPort}/template/admin/capability`,
        "method" : "POST",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_ADDFUNC_UPDATE]: {
        "url" : `${gatewayUrl}${gatewayPort}/template/admin/capability`,
        "method" : "PUT",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },

    [DEFINE_SIMAPP_LIST]: {
        "url" : `${gatewayUrl}${gatewayPort}/template/admin/tag`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_SIMAPP_LIST_TAG]: {
        "url" : `${gatewayUrl}${gatewayPort}/template/tag`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_SIMAPP_READ]: {
        "url" : `${gatewayUrl}${gatewayPort}/template/admin/tag`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_SIMAPP_CREATE]: {
        "url" : `${gatewayUrl}${gatewayPort}/template/admin/tag`,
        "method" : "POST",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_SIMAPP_UPDATE]: {
        "url" : `${gatewayUrl}${gatewayPort}/template/admin/tag`,
        "method" : "PUT",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },

    [DEFINE_MYPAGE_UPDATE]: {
        "url" : `${gatewayUrl}${gatewayPort}/member/update`,
        "method" : "PUT",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },

    [DEFINE_APHELP_READ]: {
        "url" : `${gatewayUrl}${gatewayPort}/member/info`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_APHELP_UPDATE]: {
        "url" : `${gatewayUrl}${gatewayPort}/api/app/update`,
        "method" : "PUT",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },

    [DEFINE_ITEM_LIST]: {
        "url" : `${gatewayUrl}${gatewayPort}/content/partner/item`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_ITEM_CREATE]: {
        "url" : `${gatewayUrl}${gatewayPort}/content/partner/item`,
        "method" : "POST",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_ITEM_READ]: {
        "url" : `${gatewayUrl}${gatewayPort}/content/partner/item`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_ITEM_UPDATE]: {
        "url" : `${gatewayUrl}${gatewayPort}/content/partner/item`,
        "method" : "PUT",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_ITEM_DELETE]: {
        "url" : `${gatewayUrl}${gatewayPort}/content/partner/item`,
        "method" : "DELETE",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },

    [DEFINE_RESERVE_LIST]: {
        "url" : `${gatewayUrl}${gatewayPort}/content/partner/one_day_class`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_RESERVE_CREATE]: {
        "url" : `${gatewayUrl}${gatewayPort}/content/partner/one_day_class`,
        "method" : "POST",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_RESERVE_READ]: {
        "url" : `${gatewayUrl}${gatewayPort}/content/partner/one_day_class`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_RESERVE_UPDATE]: {
        "url" : `${gatewayUrl}${gatewayPort}/content/partner/one_day_class`,
        "method" : "PUT",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_RESERVE_DELETE]: {
        "url" : `${gatewayUrl}${gatewayPort}/content/partner/one_day_class`,
        "method" : "DELETE",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },

    [DEFINE_ALL_TEMPLATE_LIST]: {
        "url" : `${gatewayUrl}${gatewayPort}/template`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
    [DEFINE_ALL_CAPABILITY_LIST]: {
        "url" : `${gatewayUrl}${gatewayPort}/template/capability`,
        "method" : "GET",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },

    [DEFINE_COMPANY_CREATE_AI_LOGO]: {
        "url" : `${gatewayUrl}${gatewayPort}/company/icon`,
        "method" : "POST",
        "getClient": () => axios.create({
            baseURL: `${gatewayUrl}${gatewayPort}`
        }),
    },
};