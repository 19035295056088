import React from 'react'
import { useNavigate } from 'react-router-dom'
import { SIM_APP_LIST_PATH } from '../../../../../common/path';
import Editor from '../../../../common/Editor';
import { useSimAppCreateHook } from './SimAppCreate.hook';
import FileUpload from '../../../../common/FileUpload';

const SimAppCreate = () => {
  const navigator = useNavigate();
  const simAppCreateHook = useSimAppCreateHook();

  return (
    <>
        <h2>유사앱 관리 생성</h2>

        <div className="boxContents">
        <table className="tableCol">
            <tr>
            <th>이름</th>
            <td>
                <input 
                    type="text" 
                    placeholder="이름을 입력하세요."
                    name='name'
                    onChange={simAppCreateHook.onChange}/>
            </td>
            </tr>
            <tr>
            <th>앱용도</th>
            <td>
                {
                    simAppCreateHook.templateHook.statusValue?.temCategoryResponse.categoryList ?
                    simAppCreateHook.templateHook.statusValue?.temCategoryResponse.categoryList.map(cate => (
                        <label>
                            <input type="checkbox" name="usage" value={JSON.stringify(cate)} onClick={simAppCreateHook.onChangeCate}/>
                            <span>{cate.name}</span>
                        </label>
                    )) : ""
                }
            </td>
            </tr>
            <tr>
            <th>설명</th>
            <td>
                <div>
                    <Editor 
                        placeholder="소개문구를 입력하세요"
                        onChange={simAppCreateHook.onChangeContent}/>
                </div>
            </td>
            </tr>
            <tr>
                <th>예제 이미지<br/>(가로 360px * 세로360px)</th>
                <td>
                    <FileUpload
                        afterPost={simAppCreateHook.onChangeFileId}
                        maxFile={5}
                    />
                </td>
            </tr>
            <tr>
            <th>추천 유형</th>
            <td>
                <label>
                    <input 
                        type="radio" 
                        name="randomType" 
                        value="BASIC"
                        onClick={simAppCreateHook.onChange} 
                        defaultChecked/>
                    <span>기본 추천</span>
                </label>
                <label>
                    <input 
                        type="radio" 
                        name="randomType" 
                        value="RANDOM"
                        onClick={simAppCreateHook.onChange} 
                        />
                    <span>랜덤 추천</span>
                </label>
            </td>
            </tr>
            <tr>
            <th>사용 여부</th>
            <td>
                <label>
                    <input 
                        type="radio" 
                        name="status" 
                        value="Y"
                        onClick={simAppCreateHook.onChange} 
                        defaultChecked/>
                <span>사용</span>
                </label>
                <label>
                    <input 
                        type="radio" 
                        name="status" 
                        value="N"
                        onClick={simAppCreateHook.onChange} 
                        />
                <span>미사용</span>
                </label>
            </td>
            </tr>
        </table>
        <div className="btnBoxB">
            <button className="off" onClick={() => {navigator(SIM_APP_LIST_PATH)}}>목록</button>
            <div>
            <button className="off" onClick={() => {navigator(-1)}}>취소</button>
            <button className="on" onClick={simAppCreateHook.createSimApp}>생성</button>
            </div>
        </div>
        </div>
    </>
  )
}

export default SimAppCreate