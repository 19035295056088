import React from 'react'
import { useNavigate } from 'react-router-dom'
import { TEM_LIST_PATH } from '../../../../../common/path';
import Editor from '../../../../common/Editor';
import { useTemCreateHook } from './TemCreate.hook';
import FileUpload from '../../../../common/FileUpload';
import { EXCEL_ACCEPT, IMG_ACCEPT } from '../../../../../common/const';

const TemCreate = () => {
  const navigator = useNavigate();
  const temCreateHook = useTemCreateHook();

  return (
    <>
        <h2>템플릿 관리 생성</h2>

        <div className="boxContents">
        <table className="tableCol">
            <tr>
            <th>이름</th>
            <td>
                <input type="text" placeholder="이름을 입력하세요." 
                    name='name' onChange={temCreateHook.onChange}/>
            </td>
            </tr>
            <tr>
            <th>앱용도</th>
            <td>
                {
                    temCreateHook.templateHook.statusValue?.temCategoryResponse.categoryList ? temCreateHook.templateHook.statusValue?.temCategoryResponse.categoryList.map(cate => (
                        <label>
                            <input type="checkbox" name="categoryList" 
                                value={JSON.stringify(cate)} onChange={temCreateHook.onChangeCate}/>
                            <span>{cate.name}</span>
                        </label> 
                    )) : ""
                }
            </td>
            </tr>
            <tr>
            <th>git url</th>
            <td>
                <input type="text" placeholder="git url을 입력하세요." 
                    name='gitPath' onChange={temCreateHook.onChange}/>
            </td>
            </tr>
            <tr>
            <th>관련 기획서 표준 양식</th>
            <td>
                <FileUpload
                    isSingle={true}
                    afterPost={temCreateHook.onChangePlanFileId}
                    accept={EXCEL_ACCEPT}
                    idx={0}
                />                
            </td>
            </tr>
            <tr>
            <th>관련 기획서 표준 URL</th>
            <td><input type="text" placeholder="URL 주소를 입력해주세요"/></td>
            </tr>
            <tr>
                <th>메인색상</th>
                <td>
                    <select name="color" onChange={temCreateHook.onChange}>
                        <option value="">색상을 선택해주세요.</option>
                        <option value={"DEFAULT"}>DEFAULT</option>
                        <option value={"MONO"}>MONO</option>
                        <option value={"ORANGE"}>ORANGE</option>
                        <option value={"GREEN"}>GREEN</option>
                        <option value={"YELLOW_GREEN"}>YELLOW_GREEN</option>
                        <option value={"PURPLE"}>PURPLE</option>
                    </select>
                </td>
            </tr>
            <tr>
            <th>설명</th>
            <td>
                <div>
                    <Editor placeholder="소개문구를 입력하세요"
                        onChange={temCreateHook.onChangeContent}/>
                </div>
            </td>
            </tr>
            <tr>
            <th>예제 이미지<br/>(가로 360px * 세로640px, 최대 5개)</th>
            <td>
                <FileUpload
                    afterPost={temCreateHook.onChangeFileId}
                    maxFile={5}
                    accept={IMG_ACCEPT}
                    idx={1}
                />
            </td>
            </tr>
            <tr>
            <th>추천 유형</th>
            <td>
                <label>
                <input type="radio" name="randomType" value="BASIC" 
                    onClick={temCreateHook.onChange} 
                    defaultChecked={temCreateHook.statusValue.temCreateRequest.randomType === "BASIC"}/>
                <span>기본 추천</span>
                </label>
                <label>
                <input type="radio" name="randomType" value="RANDOM" 
                    onClick={temCreateHook.onChange}
                    defaultChecked={temCreateHook.statusValue.temCreateRequest.randomType === "RANDOM"}/>
                <span>랜덤 추천</span>
                </label>
            </td>
            </tr>
            <tr>
            <th>사용 여부</th>
            <td>
                <label>
                <input type="radio" name="status" value="Y" 
                    onClick={temCreateHook.onChange} 
                    defaultChecked={temCreateHook.statusValue.temCreateRequest.status === "Y"}/>
                <span>사용</span>
                </label>
                <label>
                <input type="radio" name="status" value="N" 
                    onClick={temCreateHook.onChange}
                    defaultChecked={temCreateHook.statusValue.temCreateRequest.status === "N"}/>
                <span>미사용</span>
                </label>
            </td>
            </tr>
        </table>
        <div className="btnBoxB">
            <button className="off" onClick={() => {navigator(TEM_LIST_PATH, {replace: true})}}>목록</button>
            <div>
            <button className="off" onClick={() => {navigator(TEM_LIST_PATH, {replace: true})}}>취소</button>
            <button className="on" onClick={temCreateHook.createTemplate}>생성</button>
            </div>
        </div>
        </div>
    </>
  )
}

export default TemCreate