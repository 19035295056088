import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ROOT_PATH } from '../../../common/path';

const SysSetting = () => {
  const navigator = useNavigate();

  return (
    <>
        <h2>시스템설정</h2>

        <div className="boxContents">
        <table className="tableCol">
            <tr>
            <th>관리자 자동 로그아웃 간격</th>
            <td>
                <input type="text"/>&nbsp;&nbsp;
                <label>
                <input type="radio" name="Ad_logoutInterval" checked/> 사용
                </label>
                <label>
                <input type="radio" name="Ad_logoutInterval"/> 미사용
                </label>
            </td>
            </tr>
            <tr>
            <th>관리자 자동 로그아웃 경고 알림</th>
            <td>
                <input type="text"/>&nbsp;&nbsp;
                <label>
                <input type="radio" name="Ad_logoutInterval_wrn" checked/> 사용
                </label>
                <label>
                <input type="radio" name="Ad_logoutInterval_wrn"/> 미사용
                </label>
            </td>
            </tr>
            <tr>
            <th>사용자 자동 로그아웃 간격</th>
            <td>
                <input type="text"/>&nbsp;&nbsp;
                <label>
                <input type="radio" name="Mem_logoutInterval" checked/> 사용
                </label>
                <label>
                <input type="radio" name="Mem_logoutInterval"/> 미사용
                </label>
            </td>
            </tr>
            <tr>
            <th>사용자 자동 로그아웃 경고 알림</th>
            <td>
                <input type="text"/>&nbsp;&nbsp;
                <label>
                <input type="radio" name="Mem_logoutInterval_wrn" checked/> 사용
                </label>
                <label>
                <input type="radio" name="Mem_logoutInterval_wrn"/> 미사용
                </label>
            </td>
            </tr>
            <tr>
            <th>앱 생성 최대 허용 개수</th>
            <td>
                <input type="text"/>&nbsp;&nbsp;
                <label>
                <input type="radio" name="appCreate" checked/> 사용
                </label>
                <label>
                <input type="radio" name="appCreate"/> 미사용
                </label>
            </td>
            </tr>
            <tr>
            <th>한 앱에서 버전 최대 허용 개수</th>
            <td>
                <input type="text"/>&nbsp;&nbsp;
                <label>
                <input type="radio" name="appVersion" checked/> 사용
                </label>
                <label>
                <input type="radio" name="appVersion"/> 미사용
                </label>
            </td>
            </tr>
            <tr>
            <th>한 앱에서 게시판 최대 허용 개수</th>
            <td>
                <input type="text"/>&nbsp;&nbsp;
                <label>
                <input type="radio" name="appBoard" checked/> 사용
                </label>
                <label>
                <input type="radio" name="appBoard"/> 미사용
                </label>
            </td>
            </tr>
            <tr>
            <th>한 앱에서 예약 최대 허용 개수</th>
            <td>
                <input type="text"/>&nbsp;&nbsp;
                <label>
                <input type="radio" name="appReservation" checked/> 사용
                </label>
                <label>
                <input type="radio" name="appReservation"/> 미사용
                </label>
            </td>
            </tr>
            <tr>
            <th>UMS 발송 서버/포트</th>
            <td>
                <input type="text"/>&nbsp;&nbsp;
                <label>
                <input type="radio" name="umsPort" checked/> 사용
                </label>
                <label>
                <input type="radio" name="umsPort"/> 미사용
                </label>
            </td>
            </tr>
            <tr>
            <th>카카오 알림톡 발송 서버/포트</th>
            <td>
                <input type="text"/>&nbsp;&nbsp;
                <label>
                <input type="radio" name="kakaoPort" checked/> 사용
                </label>
                <label>
                <input type="radio" name="kakaoPort"/> 미사용
                </label>
            </td>
            </tr>
            <tr>
            <th>전자결제 서버/포트</th>
            <td>
                <input type="text"/>&nbsp;&nbsp;
                <label>
                <input type="radio" name="paymentPort" checked/> 사용
                </label>
                <label>
                <input type="radio" name="paymentPort"/> 미사용
                </label>
            </td>
            </tr>
            <tr>
            <th>신규 엠바스 회원 충전 금액</th>
            <td>
                <input type="text"/>&nbsp;&nbsp;
                <label>
                <input type="radio" name="newmemCharge" checked/> 사용
                </label>
                <label>
                <input type="radio" name="newmemCharge"/> 미사용
                </label>
            </td>
            </tr>
            <tr>
            <th>엠바스 회원 이벤트 일괄 충전 금액</th>
            <td>
                <input type="text"/>&nbsp;&nbsp;
                <label>
                <input type="radio" name="eventCharge" checked/> 사용
                </label>
                <label>
                <input type="radio" name="eventCharge"/> 미사용
                </label>
                <button>포인트 일괄 지급</button>
            </td>
            </tr>
        </table>
        <div className="btnBoxB">
            <button className="off" onClick={() => {navigator(-1)}}>취소</button>
            <button className="on" onClick={() => {navigator(ROOT_PATH)}}>저장</button>
        </div>
        </div>
    </>
  )
}

export default SysSetting