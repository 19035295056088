import ReactPaginate from 'react-paginate';
import { ITEMS_PER_PAGE } from '../../common/config';

export interface Props {
    itemOffset: number, 
    itemsLength: number,
    setItemOffset: (newOffset: number) => void,
}

const Paging:React.FC<Props> = ({itemOffset, itemsLength, setItemOffset}) => {
//   const [itemOffset, setItemOffset] = useState(0);
  const pageCount = Math.ceil(itemsLength / ITEMS_PER_PAGE);
  // const currPage = itemOffset / ITEMS_PER_PAGE;

  const handlePageClick = (event:any) => {
    const newOffset = (event.selected * ITEMS_PER_PAGE) % itemsLength;
    setItemOffset(newOffset);
  };

  return (
    <>
      <ReactPaginate
        breakLabel=""
        onPageChange={handlePageClick}
        pageRangeDisplayed={10}
        marginPagesDisplayed={0}
        pageCount={pageCount}
        renderOnZeroPageCount={null}
        containerClassName="pagination modal"
        activeClassName='active'
        pageClassName='num'
        previousClassName='arrow_left'
        nextClassName='arrow_right'
      />
    </>
  );
}

export default Paging