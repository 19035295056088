/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react"
import { UserAskListResponse, UserAskListState } from "./UserAskList.model";
import { ITEMS_PER_PAGE } from "../../../../common/config";
import { DEFINE_USERASK_APP_LIST, DEFINE_USERASK_LIST, MBAAS_APP_CODE, RESPONSE_RESULT_FAIL, RESPONSE_RESULT_SUCCESS } from "../../../../common/const";
import { fn_Debug } from "../../../../common/module";
import { useAxios } from "../../../../common/hooks";
import { AlertContext } from "../../../../common/context";

export const useUserAskListHook = () => {
    const alertContext = useContext(AlertContext);
    const [statusValue, setStatusValue] = useState<UserAskListState>({
        userAskListRequest: {
            pageable: {
                page: 0,
                size: ITEMS_PER_PAGE,
            },
        },
        pagination: {
            itemOffset: 0,
        },
        totalCount: 0,
    });
    const axios = useAxios();

    useEffect(() => {
        getUserAskList();
    }, [statusValue.userAskListRequest.pageable.page])

    const getUserAskList = async () => {
        const response = await axios.getData<UserAskListResponse>(
            sessionStorage.getItem(MBAAS_APP_CODE) ? DEFINE_USERASK_APP_LIST : DEFINE_USERASK_LIST,
            {params: {...statusValue.userAskListRequest.pageable}},
            !(!sessionStorage.getItem(MBAAS_APP_CODE)),
            sessionStorage.getItem(MBAAS_APP_CODE) ? [sessionStorage.getItem(MBAAS_APP_CODE)!] : []
        );
        fn_Debug("USERASK_LIST_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setStatusValue({
                ...statusValue,
                userAskListResponse: response.data,
                totalCount: response.data.totalCount ?? 0,
            })
        }
        else if(response.result === RESPONSE_RESULT_FAIL) {
            alertContext.openAlert(response.message, "error");
        }
    }

    const setItemOffset = (newOffset: number) => {
        setStatusValue({
            ...statusValue,
            userAskListRequest: {
                ...statusValue.userAskListRequest,
                pageable: {
                    ...statusValue.userAskListRequest.pageable,
                    page: newOffset / 10,
                    size: 10,
                }
            },
            pagination: {
                itemOffset: newOffset
            }
        })
    }

    return {
        statusValue,
        setItemOffset,
    }
}