/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { LoginResponse, LoginStatus } from "./Login.model";
import { DEFINE_LOGIN, EMAIL_REGEX, MBAAS_ACCESS_TOKEN, MBAAS_SAVED_ID, RESPONSE_RESULT_FAIL } from "../../../common/const";
import { fn_Debug, fn_Empty, fn_Encrypt } from "../../../common/module";
import { useNavigate } from "react-router-dom";
import { ROOT_PATH } from "../../../common/path";
import { useAxios, usePopupHook } from "../../../common/hooks";

/**
 * 
 * @param initialValue 설정할 초기 파라미터
 * @param validator 유효성 검사 함수
 * @returns 초기값, 변경함수
 */
export const useLoginHook = () => {
    const savedId = localStorage.getItem(MBAAS_SAVED_ID);
    const [statusValue, setStatusValue] = useState<LoginStatus>({
        isSaveId: !fn_Empty(savedId),
        loginRequest: {
            memberEmail: "",
            memberPassword: "",
        },
        loginResponse: {
            result: "",
            message: "",
            errorCode: "",
            data: {
                accessToken: "",
            }
        }
    });
    const {isOpen, onClick} = usePopupHook(false);

    
    const navigator = useNavigate();
    const axios = useAxios();

    useEffect(() => {
        fn_Debug(statusValue);
    }, [statusValue])

    useEffect(() => {
        if(!fn_Empty(savedId)) {
            setStatusValue({
                ...statusValue,
                isSaveId: true,
                loginRequest: {
                    ...statusValue.loginRequest,
                    memberEmail : savedId!
                }
            })
        }
    }, [])

    const onChange = (event:any) => {
        setStatusValue({
            ...statusValue,
            loginRequest: {
                ...statusValue.loginRequest,
                [event.target.name]: event.target.value
            }
        })
    }

    const saveId = () => {
        let flag = !statusValue.isSaveId;
        fn_Debug(`SAVE_ID LOG : ${flag}`);
        setStatusValue({
            ...statusValue,
            isSaveId: flag,
        })
    }

    const loginValidator = (): boolean => {
        setStatusValue({
            ...statusValue,
            loginResponse: {
                ...statusValue.loginResponse,
                message: ""
            }
        })
        if(fn_Empty(statusValue.loginRequest.memberEmail)) {
            setStatusValue({
                ...statusValue,
                loginResponse: {
                    ...statusValue.loginResponse,
                    message: "이메일을 입력해주세요"
                }
            })
            onClick();
            return false;
        }
        if(!EMAIL_REGEX.test(statusValue.loginRequest.memberEmail)) {
            setStatusValue({
                ...statusValue,
                loginResponse: {
                    ...statusValue.loginResponse,
                    message: "아이디는 이메일 형식입니다."
                }
            })
            onClick();
            return false;
        }
        if(fn_Empty(statusValue.loginRequest.memberPassword)) {
            setStatusValue({
                ...statusValue,
                loginResponse: {
                    ...statusValue.loginResponse,
                    message: "비밀번호를 입력해주세요"
                }
            })
            onClick();
            return false;
        }
        // 비밀번호 정규식 체크
        // if(!PASSWORD_REGEX.test(statusValue.loginRequest.memberPassword)) {
        //     setStatusValue({
        //         ...statusValue,
        //         loginResponse: {
        //             ...statusValue.loginResponse,
        //             message: "비밀번호 형식을 확인해주세요."
        //         }
        //     })
        //     onClick();
        //     return false;
        // }

        return true;
    }
    
    const login = async():Promise<void> => {
        try {
          if(!loginValidator()) {
            return;
          }

          const response = await axios.postData<LoginResponse>(DEFINE_LOGIN, statusValue.loginRequest);

          fn_Debug("LOGIN_RESULT_DATA:")
          fn_Debug(response);
          
          if(response.result === RESPONSE_RESULT_FAIL) {
            onClick();
            setStatusValue({
                ...statusValue,
                loginResponse: response
            })
            return;
          } 
          
        const accessToken = response.data.accessToken;
        const encryptAccessToken = fn_Encrypt(accessToken);
        sessionStorage.setItem(MBAAS_ACCESS_TOKEN, encryptAccessToken);
    
        if(statusValue.isSaveId) {
            localStorage.setItem(MBAAS_SAVED_ID, statusValue.loginRequest.memberEmail);
        } else {
            localStorage.removeItem(MBAAS_SAVED_ID);
        }
        setStatusValue({
            ...statusValue,
            loginResponse: response
        })
        navigator(ROOT_PATH);
      
        } catch(error) {
          console.error(error);
        }
    }

    const keyPressEvent = (e: any) => {
        if(e.key === 'Enter') {
            login();
        }
    }
    
    return {
        statusValue
        , onChange
        , saveId
        , login
        , onClick
        , keyPressEvent
        , isOpen
    };
  }