import React from "react";
import { useCompanyInfoHook } from "./CompanyInfo.hook";
import HTMLReactParser from "html-react-parser";

interface Props {
  setOver: (flag: boolean) => void,
}

const CompanyInfoForPdf:React.FC<Props> = (props: Props) => {
  const companyInfoHook = useCompanyInfoHook(props.setOver);

  return (
    <>
      <div className="inputWrap">
        <ul>
          <li>
            <h4>회사명</h4>
            <input
              type="text"
              placeholder="회사명을 입력해주세요."
              value={companyInfoHook.compInfo?.name}
              disabled
            />
          </li>
          <li>
            <h4>대표자명</h4>
            <input
              type="text"
              placeholder="대표자 이름을 입력해주세요."
              value={companyInfoHook.compInfo?.owner}
              disabled
            />
          </li>
          <li>
            <h4>대표 이메일</h4>
            <input
              type="text"
              placeholder="대표 이메일을 입력해주세요."
              value={companyInfoHook.compInfo?.email}
              disabled
            />
          </li>
          <li>
            <h4>사업자등록번호</h4>
            <input
              type="text"
              placeholder="사업자등록번호를 입력해주세요."
              value={companyInfoHook.compInfo?.businessNumber}
              disabled
            />
          </li>
          <li>
            <h4>통신판매업번호</h4>
            <input
              type="text"
              placeholder="통신판매업번호를 입력해주세요."
              value={companyInfoHook.compInfo?.mailOrder}
              disabled
            />
          </li>
          {/* <li>
              <h4>통신판매중개업번호</h4>
              <input
                type="text"
                placeholder="통신판매중개업번호를 입력해주세요."
              />
            </li> */}
          <li>
            <h4>회사주소</h4>
            <div className="flex smallInput">
              <input
                type="text"
                placeholder="회사 우편번호"
                value={companyInfoHook.compInfo?.address?.postNum}
                disabled
              />
              {/* <div>
                <input type="checkbox" id="mapCheckbox" name="mapCheckbox" />
                <label htmlFor="mapCheckbox">지도로 표시</label>
              </div> */}
            </div>
            <input
              type="text"
              placeholder="회사 주소"
              value={companyInfoHook.compInfo?.address?.fullLocation}
              disabled
            />
            <br />
            <input
              type="text"
              placeholder="회사 상세 주소"
              value={companyInfoHook.compInfo?.address?.detail}
              disabled
            />
          </li>
          <li>
            <h4>회사 소개</h4>
            <div className="textareaWrap">
              {HTMLReactParser(companyInfoHook.compInfo?.introduction ?? "")}
            </div>
          </li>
          <li>
            <h4>회사 연혁</h4>
            <div className="textareaWrap">
              {HTMLReactParser(companyInfoHook.compInfo?.history ?? "")}
            </div>
          </li>
          <li>
            <h4>오시는길</h4>
            <div className="textareaWrap">
              {HTMLReactParser(
                companyInfoHook.compInfo?.address?.directions ?? ""
              )}
            </div>
          </li>
          {/* <li>
              <h4>회사정보와 관련하여 원하는 내용들을 자유롭게 적어주세요.</h4>
              <textarea placeholder="ex. 회사 연혁 부분은 빼고 관련 뉴스 정보를 올릴 게시판이 필요합니다.  "></textarea>
            </li> */}
        </ul>
      </div>
    </>
  );
};

export default CompanyInfoForPdf;
