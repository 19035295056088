import React, { useContext, useLayoutEffect, useRef, useState } from 'react'
import { useHelpApHook } from '../../../../bo/template/helpAp/update/HelpApUpdate.hook';
import HTMLReactParser from 'html-react-parser';
import FileUpload from '../../../../common/FileUpload';
import { useChatGPT } from '../../../../../common/hooks';
import { AlertContext } from '../../../../../common/context';
import { fn_Debug } from '../../../../../common/module';
import { CopyBlock, a11yDark } from 'react-code-blocks';

const AppInfoUpdate = () => {
  const helpApUpdateHook = useHelpApHook();

  const chatGPTHook = useChatGPT();
  const alertContext = useContext(AlertContext);
  const [gptCode, setGptCode] = useState<string | null>(localStorage.getItem("GPT_GENERATED_CODE") ?? "");
  
  const getGptCode = async() => {
    const content = helpApUpdateHook.statusValue.helpApreadRequest.appAsk;
    if(!content) {
      alertContext.openAlert("의견을 입력해주세요.", "warning");
      return;
    }
    fn_Debug("SEND_GPT_CODE_REQUEST");
    const response = await chatGPTHook.sendContent(content!);
    fn_Debug(response);
    localStorage.setItem("GPT_GENERATED_CODE", response.choices[0].message.content);
    setGptCode(response.choices[0].message.content);
  }

  const textbox = useRef<HTMLTextAreaElement>(null);

  function adjustHeight() {
    textbox.current!.style.height = "inherit";
    textbox.current!.style.height = `${textbox.current!.scrollHeight}px`;
  }

  useLayoutEffect(adjustHeight, []);

  return (
    <div className="inputWrap">
        <div className="updateBtnWrap">
          <button  className="updateBtn" onClick={helpApUpdateHook.updateApp}>수정완료</button>
        </div>
        <ul>
        <li>
            <h4>앱 이름*</h4>
            <input 
                type="text" 
                name='appName'
                onChange={helpApUpdateHook.onChange}
                value={helpApUpdateHook.statusValue.helpApreadRequest?.appName} />
        </li>
        <li>
            <h4>앱 유형*</h4>
            <input 
                type="text" 
                placeholder="앱 유형을 입력해 주세요." 
                value={helpApUpdateHook.statusValue.helpApReadResponse?.appCategory} disabled/>
            {/* <select name="" id="">
            <option selected value="">- - 선택 - -</option>  
            <option value="">테이크아웃 앱</option>
            <option value="">협회 앱</option>
            <option value="">사진공유 앱</option>
            <option value="">원데이클래스 앱</option>
            </select> */}
        </li>
        <li className="appIconWrap">
            <h4>앱 아이콘</h4>
            {/* <div className='appIcon'> */}
            <FileUpload
                afterPost={helpApUpdateHook.onChangeFileId}
                fleGrpId={helpApUpdateHook.statusValue.helpApreadRequest.iconFile}
                maxFile={1}/>
            {/* </div> */}
        </li>
        <li>
            <h4>회사명</h4>
            <input 
                type="text" 
                value={helpApUpdateHook.statusValue.helpApReadResponse?.companyName} 
                disabled/>
            {/* <select name="" id="">
            <option selected value="">- - 선택 - -</option>  
            <option value="">엠바스 1</option>
            <option value="">엠바스 2</option>
            <option value="">엠바스 3</option>
            </select> */}
        </li>
        <li>
            <h4>템플릿*</h4>
            <ul className='planTemp'>
                {
                    helpApUpdateHook.temResponse?.defaultList.map(tem => (
                        <li key={tem.code}>
                            <label>
                                <input type="radio" name='templateCode' value={tem.code} onChange={helpApUpdateHook.onChange}
                                    defaultChecked={tem.code === helpApUpdateHook.statusValue.helpApreadRequest.templateCode}/> {tem.name}
                                <div className="myImgWrap">
                                    <img src={tem.fileUrl[0]} alt={tem.fileUrl[0]}/>
                                </div>
                            </label>
                        </li>
                    ))
                }
                {
                    helpApUpdateHook.temResponse?.randomList.map(tem => (
                        <li key={tem.code}>
                            <label>
                                <input type="radio" name='templateCode' value={tem.code} onChange={helpApUpdateHook.onChange}
                                    defaultChecked={tem.code === helpApUpdateHook.statusValue.helpApreadRequest.templateCode}/> {tem.name}

                                <div className="myImgWrap">
                                    <img src={tem.fileUrl[0]} alt={tem.fileUrl[0]}/>
                                </div>
                            </label>
                        </li>
                    ))
                }
            </ul>
            {/* <div className="myImgWrap"> */}
            {
                // helpApUpdateHook.statusValue.helpApReadResponse ?
                // helpApUpdateHook.statusValue.helpApReadResponse?.templateFileUrl!.map(url => (
                //   <img src={url} alt={url}/>
                // )) : ""
            }
            {/* </div> */}
        </li>
        <li>
            <h4>추가기능</h4>
            <table className="addFuctWrap">
            {
                helpApUpdateHook.capaResponse?.map(capa => (
                <>
                    <thead key={capa.code}>
                    <th>{capa.name}</th>
                    <th>
                        <select onChange={helpApUpdateHook.onChangeSelectCapabilities}>
                            <option value={`${capa.name}^${capa.code}`} 
                                selected={!helpApUpdateHook.checkSelectedCapa(capa.code)}>사용</option>
                            <option value=""
                                selected={!helpApUpdateHook.checkSelectedCapa(capa.code)}>미사용</option>
                        </select>
                    </th>
                    </thead>
                    <tbody>
                    <td>
                        <img src={capa.fileUrl[0]} alt="소셜 로그인 이미지"/>
                    </td>
                    <td>
                        {HTMLReactParser(capa.content ?? "")}
                    </td>
                    </tbody>
                </>
                ))
            }
            {/* <thead>
                <th>길찾기</th>
                <th>
                <select name="" id="">
                    <option value="">사용</option>
                    <option value="">미사용</option>
                </select>
                </th>
            </thead>
            <tbody>
                <td>
                <img src={require("../../../../img/addFuct3.png")} alt="길찾기 이미지"/>
                </td>
                <td>
                <p>지도 API를 제공하여 사용자에게 위치 정보를 전달하여 쉽게 접근할 수 있도록 도움을 줄 수 있습니다.</p>
                </td>
            </tbody> */}
            </table>
        </li>
        <li>
            <h4>앱의 기능이나 템플릿과 관련하여 원하는 내용을 자유롭게 적어주세요.</h4>
            <textarea 
                ref={textbox}
                placeholder="ex. 테이크 아웃앱에서 스탬프와 쿠폰 기능을 사용하고 싶습니다. 그리고 전체적인 컬러감은 저희 로고의 브라운과 비슷한 계열로 맞추고 싶습니다. "
                onChange={(e) => {
                    helpApUpdateHook.onChange(e);
                    adjustHeight();
                }}
                name='appAsk'
                defaultValue={helpApUpdateHook.statusValue.helpApreadRequest.appAsk ?? ""}>
            </textarea>
        </li>
        <button onClick={getGptCode}>생성</button>
        <li>
            <h4>코드</h4>
            {
            gptCode ? 
            <CopyBlock
                text={gptCode}
                language='dart'
                theme={a11yDark}
                codeBlock={true}
                onCopy={() => {
                alertContext.openAlert("코드가 복사되었습니다.", "success");
                }}
                customStyle={{
                height: '800px',
                overflow: 'scroll',
                padding: "0px",
                backgroudColor: "none"
                }}
            />
            : ""
          }
        </li>
        </ul>
        
    </div>

  
  )
}

export default AppInfoUpdate