import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ITEM_LIST_PATH, ITEM_UPDATE_PATH } from '../../../../../common/path';
import { useItemReadHook } from './ItemRead.hook';
import HTMLReactParser from 'html-react-parser';

const ItemRead = () => {
  const navigator = useNavigate();
  const itemReadHook = useItemReadHook();

  return (
    <>
        <h2>아이템 관리 상세</h2>

        <div className="boxContents">
        <table className="tableCol">
            <tr>
            <th>이름</th>
            <td>{itemReadHook.statusValue.itemReadResponse?.name}</td>
            </tr>
            <tr>
            <th>가격</th>
            <td>{itemReadHook.statusValue.itemReadResponse?.price}원</td>
            </tr>
            <tr>
            <th>설명</th>
            <td>{HTMLReactParser(itemReadHook.statusValue.itemReadResponse?.content ?? "")}</td>
            </tr>
            <tr>
            <th>이미지<br/>(가로 000px * 세로 000px)</th>
            <td>
                <ul className="itemImg">
                  {
                    itemReadHook.statusValue.itemReadResponse ?
                    itemReadHook.statusValue.itemReadResponse.fileInfoList.map(file => (
                      <li><img src={file.fileUrl} alt={file.fileUrl}/></li>
                    )) : ""
                  }
                
                </ul>
            </td>
            </tr>
            {/* <tr>
            <th>사용 여부</th>
            <td>value</td>
            </tr> */}
        </table>
        <div className="btnBoxB">
            <button className="off" onClick={() => {navigator(ITEM_LIST_PATH)}}>목록</button>
            <button className="on" onClick={() => {navigator(ITEM_UPDATE_PATH, {state: {itemCode: itemReadHook.statusValue.itemReadRequest.itemCode}})}}>수정</button>
        </div>
        </div>
    </>
  )
}

export default ItemRead