import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Dashboard from "./components/bo/dashboard/Dashboard";
import Layout from "./components/common/Layout";
import MMemberList from "./components/bo/memList/m/member/list/MMemList";
import MemberLayout from "./components/bo/memList/MemberLayout";
import { ADD_FUNC_CREATE_PATH, ADD_FUNC_LIST_PATH, ADD_FUNC_READ_PATH, ADD_FUNC_UPDATE_PATH, ADV_CREATE_PATH, ADV_LIST_PATH, ADV_READ_PATH, ADV_UPDATE_PATH, APHELP_CREATE_PATH, APHELP_LIST_PATH, APHELP_READ_PATH, APHELP_UPDATE_PATH, APP_ADMIN_READ_PATH, APP_MEMBER_READ_PATH, APP_USE_CREATE_PATH, APP_USE_LIST_PATH, APP_USE_READ_PATH, APP_USE_UPDATE_PATH, AP_LIST_PATH, AP_READ_PATH, AP_UPDATET_PATH, BRIDGE_PATH, COMPANY_CREATE_PATH, COMPANY_READ_PATH, COM_ADV_PATH, COM_APHELP_PATH, COM_APP_USE_PATH, COM_AP_PATH, COM_BOARD_PATH, COM_COMPANY_PATH, COM_CONSULT_PATH, COM_FUNC_PATH, COM_ITEM_PATH, COM_MEMBER_PATH, COM_MYPAGE_PATH, COM_RESERVE_PATH, COM_SETTING_PATH, COM_SIM_APP_PATH, COM_TEMPLATE_PATH, COM_TEM_PATH, COM_USERASK_ANSWER_PATH, COM_USERASK_PATH, CONSULT_LIST_PATH, CONSULT_READ_PATH, CONSULT_UPDATE_PATH, FIND_PW_PATH, ITEM_CREATE_PATH, ITEM_LIST_PATH, ITEM_READ_PATH, ITEM_UPDATE_PATH, LOGIN_PATH, MYPAGE_READ_PATH, MYPAGE_UPDATE_PATH, M_ADMIN_READ_PATH, M_BOARD_CREATE_PATH, M_BOARD_LIST_PATH, M_BOARD_READ_PATH, M_BOARD_UPDATE_PATH, M_MEMBER_READ_PATH, M_MEMBER_UPDATE_PATH, PLANNING_APPINFO_PATH, PLANNING_APPINFO_UPDATE_PATH, PLANNING_BRIDGE_PATH, PLANNING_COMPANYINFO_PATH, PLANNING_COMPANYINFO_UPDATE_PATH, PLANNING_PATH, PLANNING_SIMAPPINFO_PATH, RESERVE_CREATE_PATH, RESERVE_LIST_PATH, RESERVE_READ_PATH, RESERVE_UPDATE_PATH, ROOT_PATH, SETTING_IMG_PATH, SETTING_SYSTEM_PATH, SIM_APP_CREATE_PATH, SIM_APP_LIST_PATH, SIM_APP_READ_PATH, SIM_APP_UPDATE_PATH, TEM_CREATE_PATH, TEM_LIST_PATH, TEM_READ_PATH, TEM_UPDATE_PATH, USERASK_ANSWER_READ_PATH, USERASK_CREATE_PATH, USERASK_LIST_PATH, USERASK_READ_PATH, USERASK_UPDATE_PATH } from "./common/path";
import MAdminList from "./components/bo/memList/m/admin/list/MAdminList";
import { ADMIN, APP, MBAAS, MEMBER } from "./common/const";
import ApAdminList from "./components/bo/memList/ap/admin/list/ApAdminList";
import ApMemList from "./components/bo/memList/ap/member/list/ApMemList";
import Login from "./components/bo/login/Login";
import FindPw from "./components/bo/findPw/FindPw";
import MMemberUpdate from "./components/bo/memList/m/member/read/MMemberRead";
import MAdminRead from "./components/bo/memList/m/admin/read/MAdminRead";
import ApAdminRead from "./components/bo/memList/ap/admin/read/ApAdminRead";
import ApMemRead from "./components/bo/memList/ap/member/read/ApMemRead";
import AddFuncCreate from "./components/bo/template/addFunc/create/AddFuncCreate";
import AddFuncList from "./components/bo/template/addFunc/list/AddFuncList";
import FuncLayout from "./components/bo/template/addFunc/FuncLayout";
import AddFuncRead from "./components/bo/template/addFunc/read/AddFuncRead";
import AddFuncUpdate from "./components/bo/template/addFunc/update/AddFuncUpdate";
import AppUseList from "./components/bo/template/appUse/list/AppUseList";
import AppUseCreate from "./components/bo/template/appUse/create/AppUseCreate";
import AppUseRead from "./components/bo/template/appUse/read/AppUseRead";
import AppUseUpdate from "./components/bo/template/appUse/update/AppUseUpdate";
import SimAppCreate from "./components/bo/template/simApp/create/SimAppCreate";
import SimAppList from "./components/bo/template/simApp/list/SimAppList";
import SimAppRead from "./components/bo/template/simApp/read/SimAppRead";
import SimAppUpdate from "./components/bo/template/simApp/update/SimAppUpdate";
import TemList from "./components/bo/template/tem/list/TemList";
import TemCreate from "./components/bo/template/tem/create/TemCreate";
import TemRead from "./components/bo/template/tem/read/TemRead";
import TemUpdate from "./components/bo/template/tem/update/TemUpdate";
import NotFound from "./components/common/NotFound";
import AdvList from "./components/bo/template/adv/list/AdvList";
import AdvCreate from "./components/bo/template/adv/create/AdvCreate";
import AdvRead from "./components/bo/template/adv/read/AdvRead";
import AdvUpdate from "./components/bo/template/adv/update/AdvUpdate";
import ApList from "./components/bo/template/ap/list/ApList";
import ApRead from "./components/bo/template/ap/read/ApRead";
import ApUpdate from "./components/bo/template/ap/update/ApUpdate";
import ConsultList from "./components/bo/consult/list/ConsultList";
import ConsultRead from "./components/bo/consult/read/ConsultRead";
import ConsultUpdate from "./components/bo/consult/update/ConsultUpdate";
import ImgSetting from "./components/bo/setting/ImgSetting";
import ItemList from "./components/bo/template/item/list/ItemList";
import ItemRead from "./components/bo/template/item/read/ItemRead";
import ItemUpdate from "./components/bo/template/item/update/ItemUpdate";
import ItemCreate from "./components/bo/template/item/create/ItemCreate";
import SysSetting from "./components/bo/setting/SysSetting";
import BoardList from "./components/bo/board/m/list/BoardList";
import BoardCreate from "./components/bo/board/m/create/BoardCreate";
import BoardRead from "./components/bo/board/m/read/BoardRead";
import BoardUpdate from "./components/bo/board/m/update/BoardUpdate";
import MyPageRead from "./components/bo/mypage/read/MyPageRead";
import MyPageUpdate from "./components/bo/mypage/update/MyPageUpdate";
import UserAskList from "./components/bo/userAsk/list/UserAskList";
import UserAskCreate from "./components/bo/userAsk/create/UserAskCreate";
import UserAskRead from "./components/bo/userAsk/read/UserAskRead";
import UserAskUpdate from "./components/bo/userAsk/update/UserAskUpdate";
import UserAskReadAn from "./components/bo/userAsk/answer/read/UserAskReadAn";
import MMemberRead from "./components/bo/memList/m/member/read/MMemberRead";
import HelpApCreate from "./components/bo/template/helpAp/create/HelpApCreate";
import HelpApRead from "./components/bo/template/helpAp/read/HelpApRead";
import Company from "./components/bo/company/main/Company";
import Bridge from "./components/bo/bridge/Bridge";
import HelpApList from "./components/bo/template/helpAp/list/HelpApList";
import HelpApUpdate from "./components/bo/template/helpAp/update/HelpApUpdate";
import AppInfo from "./components/fo/planning/appInfo/read/AppInfo";
import PlanningLayout from "./components/fo/common/PlanningLayout";
import CompanyInfo from "./components/fo/planning/companyInfo/read/CompanyInfo";
import PlanningBridge from "./components/fo/bridge/PlanningBridge";
import ReserveList from "./components/bo/template/reserve/list/ReserveList";
import ReserveRead from "./components/bo/template/reserve/read/ReserveRead";
import ReserveUpdate from "./components/bo/template/reserve/update/ReserveUpdate";
import ReserveCreate from "./components/bo/template/reserve/create/ReserveCreate";
import AllPlanning from "./components/fo/planning/AllPlanning";
import CompanyInfoUpdate from "./components/fo/planning/companyInfo/update/CompanyInfoUpdate";
import Error from "./components/common/Error";
import AppInfoUpdate from "./components/fo/planning/appInfo/update/AppInfoUpdate";
import SimAppInfo from "./components/fo/planning/simAppInfo/SimAppInfo";
import AllPlanningLayout from "./components/fo/common/AllPlanningLayout";

function App() {
  const router = [
    {
      path: ROOT_PATH,
      element: <Layout/>,
      children: [
        {
          index: true,
          element: <Dashboard/>,
        },

        {
          path: COM_MEMBER_PATH,
          element: <MemberLayout/>,
          children: [
            {
              path: MBAAS,
              children: [
                {
                  path: MEMBER,
                  element: <MMemberList/>,
                },
                {
                  path: ADMIN,
                  element: <MAdminList/>
                },
              ],
            },
            {
              path: APP,
              children: [
                {
                  path: ADMIN,
                  element: <ApAdminList/>
                },
                {
                  path: MEMBER,
                  element: <ApMemList/>
                }
              ]
            }
          ]
        },
        {
          path: M_MEMBER_UPDATE_PATH,
          element: <MMemberUpdate/>
        },
        {
          path: M_MEMBER_READ_PATH,
          element: <MMemberRead/>
        },
        {
          path: M_ADMIN_READ_PATH,
          element: <MAdminRead/>
        },
        {
          path: APP_ADMIN_READ_PATH,
          element: <ApAdminRead/>  
        },
        {
          path: APP_MEMBER_READ_PATH,
          element: <ApMemRead/>
        },

        // /template/~/~
        {
          path: COM_TEMPLATE_PATH,
          element: <FuncLayout/>,
          children: [
            {
              path: COM_FUNC_PATH,
              children: [
                {
                  path: ADD_FUNC_CREATE_PATH,
                  element: <AddFuncCreate/>
                },
                {
                  path: ADD_FUNC_LIST_PATH,
                  element: <AddFuncList/>
                },
                {
                  path: ADD_FUNC_READ_PATH,
                  element: <AddFuncRead/>
                },
                {
                  path: ADD_FUNC_UPDATE_PATH,
                  element: <AddFuncUpdate/>
                }
              ],
            },
            {
              path: COM_APP_USE_PATH,
              children: [
                {
                  path: APP_USE_LIST_PATH,
                  element: <AppUseList/>
                },
                {
                  path: APP_USE_CREATE_PATH,
                  element: <AppUseCreate/>
                },
                {
                  path: APP_USE_READ_PATH,
                  element: <AppUseRead/>
                },
                {
                  path: APP_USE_UPDATE_PATH,
                  element: <AppUseUpdate/>
                }
              ]
            },
            {
              path: COM_SIM_APP_PATH,
              children: [
                {
                  path: SIM_APP_LIST_PATH,
                  element: <SimAppList/>
                },
                {
                  path: SIM_APP_CREATE_PATH,
                  element: <SimAppCreate/>
                },
                {
                  path: SIM_APP_READ_PATH,
                  element: <SimAppRead/>
                },
                {
                  path: SIM_APP_UPDATE_PATH,
                  element: <SimAppUpdate/>
                }
              ]
            },
            {
              path: COM_TEM_PATH,
              children: [
                {
                  path: TEM_LIST_PATH,
                  element: <TemList/>
                },
                {
                  path: TEM_CREATE_PATH,
                  element: <TemCreate/>
                },
                {
                  path: TEM_READ_PATH,
                  element: <TemRead/>
                },
                {
                  path: TEM_UPDATE_PATH,
                  element: <TemUpdate/>
                },
              ]
            },
            {
              path: COM_ADV_PATH,
              children: [
                {
                  path: ADV_LIST_PATH,
                  element: <AdvList/>
                },
                {
                  path: ADV_CREATE_PATH,
                  element: <AdvCreate/>
                },
                {
                  path: ADV_READ_PATH,
                  element: <AdvRead/>
                },
                {
                  path: ADV_UPDATE_PATH,
                  element: <AdvUpdate/>
                },
              ]
            },
            {
              path: COM_AP_PATH,
              children: [
                {
                  path: AP_LIST_PATH,
                  element: <ApList/>
                },
                {
                  path: AP_READ_PATH,
                  element: <ApRead/>
                },
                {
                  path: AP_UPDATET_PATH,
                  element: <ApUpdate/>
                },
              ]
            },
            {
              path: COM_CONSULT_PATH,
              children: [
                {
                  path: CONSULT_LIST_PATH,
                  element: <ConsultList/>
                },
                {
                  path: CONSULT_READ_PATH,
                  element: <ConsultRead/>
                },
                {
                  path: CONSULT_UPDATE_PATH,
                  element: <ConsultUpdate/>
                },
              ]
            },
            {
              path: COM_ITEM_PATH,
              children: [
                {
                  path: ITEM_LIST_PATH,
                  element: <ItemList/>
                },
                {
                  path: ITEM_READ_PATH,
                  element: <ItemRead/>
                },
                {
                  path: ITEM_UPDATE_PATH,
                  element: <ItemUpdate/>
                },
                {
                  path: ITEM_CREATE_PATH,
                  element: <ItemCreate/>
                },
              ]
            },
            {
              path: COM_RESERVE_PATH,
              children: [
                {
                  path: RESERVE_LIST_PATH,
                  element: <ReserveList/>
                },
                {
                  path: RESERVE_READ_PATH,
                  element: <ReserveRead/>
                },
                {
                  path: RESERVE_UPDATE_PATH,
                  element: <ReserveUpdate/>
                },
                {
                  path: RESERVE_CREATE_PATH,
                  element: <ReserveCreate/>
                }
              ]
            },
            {
              path: COM_APHELP_PATH,
              children: [
                {
                  path: APHELP_CREATE_PATH,
                  element: <HelpApCreate/>
                },
                {
                  path: APHELP_READ_PATH,
                  element: <HelpApRead/>
                },
                {
                  path: APHELP_LIST_PATH,
                  element: <HelpApList/>
                },
                {
                  path: APHELP_UPDATE_PATH,
                  element: <HelpApUpdate/>
                },
              ]
            },
          ],
        },

        {
          path: COM_BOARD_PATH,
          children: [
            {
              path: APP,
              children: []
            },
            {
              path: MBAAS,
              children: [
                {
                  path: M_BOARD_LIST_PATH,
                  element: <BoardList/>
                },
                {
                  path: M_BOARD_CREATE_PATH,
                  element: <BoardCreate/>
                },
                {
                  path: M_BOARD_READ_PATH,
                  element: <BoardRead/>
                },
                {
                  path: M_BOARD_UPDATE_PATH,
                  element: <BoardUpdate/>
                }
              ]
            }
          ]
        },
        {
          path: COM_MYPAGE_PATH,
          children: [
            {
              path: MYPAGE_READ_PATH,
              element: <MyPageRead/>
            },
            {
              path: MYPAGE_UPDATE_PATH,
              element: <MyPageUpdate/>
            },
          ]          
        },
        {
          path: COM_USERASK_PATH,
          children: [
            {
              path: USERASK_LIST_PATH,
              element: <UserAskList/>
            },
            {
              path: USERASK_CREATE_PATH,
              element: <UserAskCreate/>
            },
            {
              path: USERASK_READ_PATH,
              element: <UserAskRead/>
            },
            {
              path: USERASK_UPDATE_PATH,
              element: <UserAskUpdate/>
            },
            {
              path: COM_USERASK_ANSWER_PATH,
              children: [
                {
                  path: USERASK_ANSWER_READ_PATH,
                  element: <UserAskReadAn/>
                }
              ]
            }
          ]
        },
        {
          path: COM_SETTING_PATH,
          children: [
            {
              path: SETTING_IMG_PATH,
              element: <ImgSetting/>
            },
            {
              path: SETTING_SYSTEM_PATH,
              element: <SysSetting/>
            }
          ]
        },
        {
          path: COM_COMPANY_PATH,
          element: <Company/>,
          // children: [
          //   {
          //     path: "",
          //     element: <CompInfoCreate/>
          //   },
          //   {
          //     path: COMPANY_READ_PATH,
          //     element: <CompInfoRead/>
          //   },
          // ]
        }
      ]
      // loader: async() => {

      // }
      // 받아올때는 const data = useLoaderData() 이런식으로
    },
    {
      path: LOGIN_PATH,
      element: <Login/>,
    },
    {
      path: BRIDGE_PATH,
      element: <Bridge/>,
    },
    {
      path: FIND_PW_PATH,
      element: <FindPw/>
    },
    {
      path: "/allPlanning/pdf",
      element: <AllPlanningLayout/>      
    },
    {
      path: PLANNING_PATH,
      element: <PlanningLayout/>,
      children: [
        {
          index: true,
          path: PLANNING_BRIDGE_PATH,
          element: <PlanningBridge/>
        },
        {
          path: PLANNING_COMPANYINFO_PATH,
          element: <CompanyInfo/>
        },
        {
          path: PLANNING_COMPANYINFO_UPDATE_PATH,
          element: <CompanyInfoUpdate/>
        },
        {
          path: PLANNING_SIMAPPINFO_PATH,
          element: <SimAppInfo/>
        },
        {
          path: PLANNING_APPINFO_PATH,
          element: <AppInfo/>
        },
        {
          path: PLANNING_APPINFO_UPDATE_PATH,
          element: <AppInfoUpdate/>
        },
      ]
    },
    {
      path: "*",
      element: <NotFound/>
    },
    {
      path: "/error",
      element: <Error/>
    }
  ];

  return (
    // <Suspense>
      <RouterProvider router={createBrowserRouter(router)}/>
    // </Suspense>
  );
}

export default App;
