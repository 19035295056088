import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ROOT_PATH } from '../../../common/path';

const ImgSetting = () => {
  const navigator = useNavigate();

  return (
    <>
        <h2>공통이미지 설정</h2>

        <div className="boxContents">
        <table className="tableCol">
            <tr>
            <th>구글 로고</th>
            <td>
                <button>미리 보기</button>
                <input type="file" id="imgFile"/>&nbsp;&nbsp;
                <label>
                <input type="radio" name="googleLogo" checked/> 사용
                </label>
                <label>
                <input type="radio" name="googleLogo"/> 미사용
                </label>
            </td>
            </tr>
            <tr>
            <th>페이스북 로고</th>
            <td>
                <button>미리 보기</button>
                <input type="file" id="imgFile"/>&nbsp;&nbsp;
                <label>
                <input type="radio" name="facebookLogo" checked/> 사용
                </label>
                <label>
                <input type="radio" name="facebookLogo"/> 미사용
                </label>
            </td>
            </tr>
            <tr>
            <th>네이버 로고</th>
            <td>
                <button>미리 보기</button>
                <input type="file" id="imgFile"/>&nbsp;&nbsp;
                <label>
                <input type="radio" name="naverLogo" checked/> 사용
                </label>
                <label>
                <input type="radio" name="naverLogo"/> 미사용
                </label>
            </td>
            </tr>
            <tr>
            <th>카카오 로고</th>
            <td>
                <button>미리 보기</button>
                <input type="file" id="imgFile"/>&nbsp;&nbsp;
                <label>
                <input type="radio" name="kakaoLogo" checked/> 사용
                </label>
                <label>
                <input type="radio" name="kakaoLogo"/> 미사용
                </label>
            </td>
            </tr>
            <tr>
            <th>회사 기본 로고 이미지</th>
            <td>
                <button>미리 보기</button>
                <input type="file" id="imgFile"/>&nbsp;&nbsp;
                <label>
                <input type="radio" name="defaultLogo" checked/> 사용
                </label>
                <label>
                <input type="radio" name="defaultLogo"/> 미사용
                </label>
            </td>
            </tr>
            <tr>
            <th>이미지 없음 기본 이미지</th>
            <td>
                <button>미리 보기</button>
                <input type="file" id="imgFile"/>&nbsp;&nbsp;
                <label>
                <input type="radio" name="nullImg" checked/> 사용
                </label>
                <label>
                <input type="radio" name="nullImg"/> 미사용
                </label>
            </td>
            </tr>
            <tr>
            <th>엠바스 기본 로고 이미지</th>
            <td>
                <button>미리 보기</button>
                <input type="file" id="imgFile"/>&nbsp;&nbsp;
                <label>
                <input type="radio" name="mbaasLogo" checked/> 사용
                </label>
                <label>
                <input type="radio" name="mbaasLogo"/> 미사용
                </label>
            </td>
            </tr>
        </table>
        <div className="btnBoxB">
            <button className="off" onClick={() => {navigator(-1)}}>취소</button>
            <button className="on" onClick={() => {navigator(ROOT_PATH)}}>저장</button>
        </div>
        </div>
    </>
  )
}

export default ImgSetting