import React from 'react'

const ApAdminRead = () => {
  return (
    <>
        <h2>앱관리자 <span>김소연</span> 님의 상세 정보</h2>

        <div className="boxContentsBg">
            <div className="boxContents">
                <div className="flex">
                    <div>
                        <div className="detailTop flex">
                            <div className="contentTit">이름</div>
                            <div className="contentCon">김소연</div>
                        </div>
                        <div className="detailTop flex">
                            <div className="contentTit">아이디</div>
                            <div className="contentCon">user123</div>
                        </div>
                        <div className="detailTop flex">
                            <div className="contentTit">회원유형</div>
                            <div className="contentCon">앱관리자</div>
                        </div>
                        <div className="detailTop flex">
                            <div className="contentTit">연락처</div>
                            <div className="contentCon">010-0000-0000</div>
                        </div>
                    </div>
                    <div>
                        <div className="detailTop flex">
                            <div className="contentTit">회사명</div>
                            <div className="contentCon">엠바스</div>
                        </div>
                        <div className="detailTop flex">
                            <div className="contentTit">회사주소</div>
                            <div className="contentCon">부산광역시 동구 중앙대로 270, 6층 610호 (강남빌딩)</div>
                        </div>
                        <div className="detailTop flex">
                            <div className="contentTit">업종</div>
                            <div className="contentCon">통신판매</div>
                        </div>
                        <div className="detailTop flex">
                            <div className="contentTit">창업연도</div>
                            <div className="contentCon">2023-07-15</div>
                        </div>
                        <div className="detailTop flex">
                            <div className="contentTit">가입일</div>
                            <div className="contentCon">2023-07-15</div>
                        </div>
                    </div>
                </div>
                <div className="flex">
                    <select id="staus">
                        <option value="act" selected>사용가능</option>
                        <option value="stop">사용중지</option>
                        <option value="fire">강퇴</option>
                    </select>
                    <div className="selectOption">
                        <ul>
                            <li className="sleep">휴면일시 : 2023-07-15</li>
                            <li className="wthdr"><input type="date" date-placeholder="기간(시작)" required/> ~ <input type="date" date-placeholder="기간(종료)" required/></li>
                            <li className="stop"><input type="date" date-placeholder="기간(시작)" required/> ~ <input type="date" date-placeholder="기간(종료)" required/></li>
                            <li className="fire">강퇴일시 : 2023-07-15</li>
                        </ul>
                    </div>
                </div>
                
                <table className="table table--block">
                    <thead>
                        <th>관리앱</th>
                        <th>엠바스회원아이디</th>
                        <th>앱회원수</th>
                        <th>생성일</th>
                        <th>상태</th>
                    </thead>
                    <tbody>
                        <td><span className="tdLink">앱명1 v1.0(앱코드2)</span></td>
                        <td><span className="tdLink">user245</span></td>
                        <td>50</td>
                        <td>2023-07-17</td>
                        <td><span className="act">사용가능</span></td>
                    </tbody>
                    <tbody>
                        <td><span className="tdLink">앱명1 v1.0(앱코드2)</span></td>
                        <td><span className="tdLink">user245</span></td>
                        <td>50</td>
                        <td>2023-07-17</td>
                        <td><span className="stop">사용중지</span></td>
                    </tbody>
                    <tbody>
                        <td><span className="tdLink">앱명1 v1.0(앱코드2)</span></td>
                        <td><span className="tdLink">user245</span></td>
                        <td>50</td>
                        <td>2023-07-17</td>
                        <td><span className="fire">강퇴</span></td>
                    </tbody>
                </table>
                <div className="btnBox">
                    <button className="off">닫기</button>
                    <button className="on">수정</button>
                </div>
            </div>
        </div>
    </>
  )
}

export default ApAdminRead