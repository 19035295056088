import React from 'react'
import { useNavigate } from 'react-router-dom'
import { APHELP_CREATE_PATH, AP_READ_PATH } from '../../../../../common/path';

const ApList = () => {
  const navigator = useNavigate();

  return (
    <>
        <h2>앱 관리</h2>
        <div className="flex flexE"><button className="on " onClick={() => {navigator(APHELP_CREATE_PATH)}} style={{marginBottom:"10px"}}>앱생성</button></div>
        <div className="flex spaceB alignC" style={{marginTop: "20px"}}>
            <div className="search">
            <select>
                <option value="all">전체</option>
                <option value="name" selected>이름</option>
                <option value="explanation">앱코드</option>
                <option value="explanation">앱생성자아이디</option>
                <option value="explanation">앱생성자명</option>
                <option value="explanation">앱관리자 아이디</option>
                <option value="explanation">앱관리자명</option>
                <option value="explanation">회사명</option>
                <option value="explanation">사업자등록번호</option>
            </select>
            <input type="text" placeholder="검색어를 입력해주세요."/>
            <button><img src={require("../../../../../img/icon_search_white.png")} alt="검색" /></button>
            </div>
            <div className="filter">
            <select>
                <option value="all">전체</option>
                <option value="use" selected>사용</option>
                <option value="notUse">미사용</option>
                <option value="sleep">휴면</option>
                <option value="stop">사용중지</option>
                <option value="fire">강퇴</option>
            </select>
            <select>
                <option value="member" selected>회원수</option>
                <option value="name">이름순</option>
                <option value="date">생성일순</option>
            </select>
            </div>
        </div>

        <div className="boxContents">
            <table className="table table--block">
            <thead>
                <tr>
                <th className="tableShort">No</th>
                <th>이름</th>
                <th>앱생성자</th>
                <th className="tableLong">버전</th>
                <th>앱관리자</th>
                <th>회사명</th>
                <th>회원수</th>
                <th>생성일</th>
                <th>상태</th>
                </tr>
            </thead>
            <tbody>
                <tr onClick={() => {navigator(AP_READ_PATH)}}>
                <td data-name="No.">100</td>
                <td data-name="이름">김소연</td>
                <td data-name="앱생성자">user123</td>
                <td data-name="버전">버전5(앱코드5)<br/>버전4(앱코드4)<br/>버전3(앱코드3)</td>
                <td data-name="앱관리자">user245<br/>user246</td>
                <td data-name="회사명">엠바스</td>
                <td data-name="회원수">50</td>
                <td data-name="생성일">YYYY-MM-DD<br/>YYYY-MM-DD<br/>YYYY-MM-DD</td>
                <td data-name="상태"><span className="use">사용</span><br/><span className="notuse">미사용</span><br/><span className="sleep">휴면</span></td>
                </tr>
                <tr>
                <td data-name="No.">99</td>
                <td data-name="이름">김소연</td>
                <td data-name="앱생성자">user123</td>
                <td data-name="버전">버전5(앱코드5)</td>
                <td data-name="앱관리자">user245<br/>user246</td>
                <td data-name="회사명">엠바스</td>
                <td data-name="회원수">50</td>
                <td data-name="생성일">YYYY-MM-DD</td>
                <td data-name="상태"><span className="notuse">미사용</span></td>
                </tr><tr>
                <td data-name="No.">98</td>
                <td data-name="이름">김소연</td>
                <td data-name="앱생성자">user123</td>
                <td data-name="버전">버전5(앱코드5)</td>
                <td data-name="앱관리자">user245<br/>user246</td>
                <td data-name="회사명">엠바스</td>
                <td data-name="회원수">50</td>
                <td data-name="생성일">YYYY-MM-DD</td>
                <td data-name="상태"><span className="stop">사용중지</span></td>
                </tr><tr>
                <td data-name="No.">97</td>
                <td data-name="이름">김소연</td>
                <td data-name="앱생성자">user123</td>
                <td data-name="버전">버전5(앱코드5)</td>
                <td data-name="앱관리자">user245<br/>user246</td>
                <td data-name="회사명">엠바스</td>
                <td data-name="회원수">50</td>
                <td data-name="생성일">YYYY-MM-DD</td>
                <td data-name="상태"><span className="fire">강퇴</span></td>
                </tr><tr>
                <td data-name="No.">96</td>
                <td data-name="이름">김소연</td>
                <td data-name="앱생성자">user123</td>
                <td data-name="버전">버전5(앱코드5)</td>
                <td data-name="앱관리자">user245<br/>user246</td>
                <td data-name="회사명">엠바스</td>
                <td data-name="회원수">50</td>
                <td data-name="생성일">YYYY-MM-DD</td>
                <td data-name="상태"><span className="sleep">휴면</span></td>
                </tr><tr>
                <td data-name="No.">95</td>
                <td data-name="이름">김소연</td>
                <td data-name="앱생성자">user123</td>
                <td data-name="버전">버전5(앱코드5)</td>
                <td data-name="앱관리자">user245<br/>user246</td>
                <td data-name="회사명">엠바스</td>
                <td data-name="회원수">50</td>
                <td data-name="생성일">YYYY-MM-DD</td>
                <td data-name="상태"><span className="use">사용</span></td>
                </tr>
            </tbody>
            </table>
            <div className="page">
            <ul className="pagination modal">
                <li className="arrow_left">처음</li>
                <li className="num active">1</li>
                <li className="num">2</li>
                <li className="num">3</li>
                <li className="num">4</li>
                <li className="num">5</li>
                <li className="arrow_right">다음</li>
            </ul>
            </div>
        </div>
    </>
  )
}

export default ApList