import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";

const Tracker = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState<boolean>(false);
  
    // 구글 애널리틱스 운영서버만 적용
    useEffect(() => {
      if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
        setInitialized(true);
      }
    }, []);
  
    // location 변경 감지시 pageview 이벤트 전송
    useEffect(() => {
      if (initialized) {
        ReactGA.set({ page: location.pathname });
        ReactGA.send("pageview");
      }
    }, [initialized, location]);

    return (<></>);
}

export default Tracker