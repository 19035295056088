import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom"
import { SimAppReadResponse, SimAppReadState } from "./SimAppRead.model";
import { useAxios } from "../../../../../common/hooks";
import { DEFINE_SIMAPP_READ, RESPONSE_RESULT_SUCCESS } from "../../../../../common/const";
import { fn_Debug } from "../../../../../common/module";
import { AlertContext } from "../../../../../common/context";

export const useSimAppReadHook = () => {
    const location = useLocation();
    const axios = useAxios();
    const alertContext = useContext(AlertContext);
    const [statusValue, setStatusValue] = useState<SimAppReadState>({
        simAppReadRequest: {
            code: location.state['code']
        }
    })

    useEffect(() => {
        getSimAppRead();
    }, [])

    const getSimAppRead = async() => {
        const response = await axios.getData<SimAppReadResponse>(DEFINE_SIMAPP_READ, {}, true, [statusValue.simAppReadRequest.code]);

        fn_Debug("READ_SIMAPP_RESPONSE");
        fn_Debug(response)

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setStatusValue({
                ...statusValue,
                simAppReadResponse: response.data
            })
        } else {
            alertContext.openAlert(response.message, "error");
        }
    }

    const printCategory = ():string => {
        const arr:string[] = [];

        statusValue.simAppReadResponse?.categoryList.forEach(cate => {
            arr.push(cate.name);
        })

        return arr.join(",");
    }

    return {
        statusValue,
        printCategory,
    }
}