import { useContext, useEffect, useState } from "react"
import { ApMemReadState, ApMemResponse } from "./ApMemRead.model";
import { useAxios } from "../../../../../../common/hooks";
import { AlertContext } from "../../../../../../common/context";
import { DEFINE_GET_AP_MEM_READ, RESPONSE_RESULT_SUCCESS } from "../../../../../../common/const";
import { useLocation } from "react-router-dom";

export const useApMemReadHook = () => {
    const axios = useAxios();
    const location = useLocation();
    const alertContext = useContext(AlertContext);

    const [statusValue, setStatusValue] = useState<ApMemReadState>({
        apMemResponse: JSON.parse(location.state.member)
    });

    useEffect(() => {
        // getMemInfo();
    }, [])

    const getMemInfo = async() => {
        const response = await axios.getData<ApMemResponse>(DEFINE_GET_AP_MEM_READ, {params: {memberCode: location.state.memberCode}});

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setStatusValue({
                ...statusValue,
                apMemResponse: response.data
            })
        }
        else {
            alertContext.openAlert(response.message, "error");
        }
    }

    return {statusValue};
}