import React from 'react'
import { useNavigate } from 'react-router-dom'
import parse from 'html-react-parser';
import { TEM_LIST_PATH, TEM_UPDATE_PATH } from '../../../../../common/path';
import { useTemReadHook } from './TemRead.hook';
import Slider from 'react-slick';

const TemRead = () => {
  const navigator = useNavigate();
  const temReadHook = useTemReadHook();

  return (
    <>
        <h2>템플릿 관리 상세</h2>
        
        <div className="boxContents">
            <table className="tableCol">
                <tr>
                    <th>이름</th>
                    <td>{temReadHook.statusValue.temReadResponse?.name}</td>
                </tr>
                <tr>
                    <th>앱용도</th>
                    <td>
                    {
                        temReadHook.convertAppUseName()
                    }
                    </td>
                </tr>
                <tr>
                    <th>git url</th>
                    <td>{temReadHook.statusValue.temReadResponse?.gitPath}</td>
                </tr>
                <tr>
                    <th>관련 기획서 표준 양식</th>
                    <td>{temReadHook.statusValue.temReadResponse?.planFileId}</td>
                </tr>
                <tr>
                    <th>메인 색상</th>
                    <td>
                        <div className='colorCon' style={{backgroundColor: "#" + temReadHook.statusValue.temReadResponse?.appColor.appFullBtnFontColor ?? ""}}></div>
                    </td>
                </tr>
                <tr>
                    <th>설명</th>
                    <td>{parse(temReadHook.statusValue.temReadResponse ? temReadHook.statusValue.temReadResponse?.content! : "")}</td>
                </tr>
                <tr>
                <th>이미지<br/>(가로 360px * 세로640px)</th>
                <td className="temImgWrap">
                    {
                        temReadHook.statusValue.temReadResponse?.fileUrl ? 
                        <ul className="temImg">
                            <Slider>
                                {
                                    temReadHook.statusValue.temReadResponse?.fileUrl.map(url => (
                                        <li><img src={url} alt="유사앱이미지2"/></li>        
                                    ))
                                }
                            </Slider>
                        </ul>: ""
                    }
                    {/* <li><img src={require("../../../../../img/slickimg1.png")} alt="유사앱이미지1"/></li>
                    <li><img src={require("../../../../../img/slickimg2.png")} alt="유사앱이미지2"/></li>
                    <li><img src={require("../../../../../img/slickimg3.png")}alt="유사앱이미지3"/></li> */}
                    
                </td>
                </tr>
                <tr>
                <th>추천 유형</th>
                <td>{temReadHook.statusValue.temReadResponse?.randomType === "BASIC" ? "기본추천" : "랜덤추천"}</td>
                </tr>
                <tr>
                <th>사용 여부</th>
                <td>{temReadHook.statusValue.temReadResponse?.status === "Y" ? "사용중" : "미사용"}</td>
                </tr>
            </table>
            <div className="btnBoxB">
            <button className="off" onClick={() => {navigator(TEM_LIST_PATH)}}>목록</button>
            <button className="on" onClick={() => {navigator(TEM_UPDATE_PATH, {state: {template: temReadHook.statusValue.temReadResponse!}})}}>수정</button>
            </div>
        </div>
    </>
  )
}

export default TemRead