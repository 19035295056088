/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react"
import { CompInfoReadResponse, CompInfoReadState } from "./CompInfoRead.model"
import { BEARER_STRING, DEFINE_COMPANY_DELETE, DEFINE_COMPANY_READ, DEFINE_COMPANY_UPDATE, MBAAS_ACCESS_TOKEN, PHONE_REGEX, RESPONSE_RESULT_SUCCESS } from "../../../../common/const";
import { fn_Debug, fn_Decrypt } from "../../../../common/module";
import { useAxios, useFileHook } from "../../../../common/hooks";
import { Address } from "react-daum-postcode";
import { AlertContext } from "../../../../common/context";

export const useCompInfoReadHook = (initialData: string, refreshData: () => void) => {
    const alertContext = useContext(AlertContext);
    const fileHook = useFileHook();
    const ref = useRef<HTMLTableElement>(null);
    const [statusValue, setStatusValue] = useState<CompInfoReadState>({
        compInfoReadRequest: {
            companyCode: initialData,
        },
        isUpdate: false,
        loading: true,
        isAddressPopupOpen: false,
    });
    const axios = useAxios();

    const [deletePopup, setDeletePopup] = useState(false)

    useEffect(() => {
        getCompanyInfoRead();
    }, [])

    useEffect(() => {
        fn_Debug("COMP_INFO_READ_STATUS");
        fn_Debug(statusValue);
    }, [statusValue])

    const getCompanyInfoRead = async () => {
        const response = await axios.getData<CompInfoReadResponse>(DEFINE_COMPANY_READ, {}, true, [statusValue.compInfoReadRequest.companyCode]);
        setStatusValue({
            ...statusValue,
            loading: true,
        })

        fn_Debug("GET_COMPANY_READ_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setStatusValue({
                ...statusValue,
                compInfoReadResponse: response.data,
                compInfoUpdateRequest: {
                    companyCode: initialData,
                    address: response.data.address,
                    email: response.data.email,
                    history: response.data.history,
                    introduction: response.data.introduction,
                    mailOrder: response.data.mailOrder,
                    name: response.data.name,
                    phone: response.data.phone,
                    slot: response.data.slot,
                    businessNumber: response.data.businessNumber,
                    fileId: response.data.fileId,
                    owner: response.data.owner,
                },
                isUpdate: false,
                loading: false,
            })
            // if(response.data.fileId) {
            //     const fileResponse = await fileHook.getFileByGrpId(response.data.fileId!);
            //     setStatusValue({
            //         ...statusValue,
            //         compInfoReadResponse: response.data,
            //         compInfoFileResponse: fileResponse,
            //         compInfoUpdateRequest: {
            //             companyCode: initialData,
            //             address: response.data.address,
            //             email: response.data.email,
            //             history: response.data.history,
            //             introduction: response.data.introduction,
            //             mailOrder: response.data.mailOrder,
            //             name: response.data.name,
            //             phone: response.data.phone,
            //             slot: response.data.slot,
            //             businessNumber: response.data.businessNumber,
            //             fileId: response.data.fileId,
            //             owner: response.data.owner,
            //         },
            //         isUpdate: false,
            //         loading: false,
            //     })
            // } else {
            //     setStatusValue({
            //         ...statusValue,
            //         compInfoReadResponse: response.data,
            //         compInfoUpdateRequest: {
            //             companyCode: initialData,
            //             address: response.data.address,
            //             email: response.data.email,
            //             history: response.data.history,
            //             introduction: response.data.introduction,
            //             mailOrder: response.data.mailOrder,
            //             name: response.data.name,
            //             phone: response.data.phone,
            //             slot: response.data.slot,
            //             businessNumber: response.data.businessNumber,
            //             fileId: response.data.fileId,
            //             owner: response.data.owner,
            //         },
            //         isUpdate: false,
            //         loading: false,
            //     })
            // }
        }
    }

    const deleteCompanyInfo = async() => {
        const response = await axios.deleteData(DEFINE_COMPANY_DELETE, {
            headers: {
                Authorization: `${BEARER_STRING}${fn_Decrypt(sessionStorage.getItem(MBAAS_ACCESS_TOKEN)!)}`
            }
        }, true, [statusValue.compInfoReadRequest.companyCode]);

        fn_Debug("DELETE_COMPANY_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            getCompanyInfoRead();
            setDeletePopup(false);
            refreshData();
        } else {
            alertContext.openAlert(response.message, "error");
            setDeletePopup(false);
        }
    }

    const changeDeletePopup = () => {
        setDeletePopup(!deletePopup);
    }

    const changeUpdate = () => {
        ref.current?.scrollIntoView({behavior: "smooth"})
        setStatusValue({
            ...statusValue,
            isUpdate: !statusValue.isUpdate
        })
    }

    const onChange = (e:any) => {
        setStatusValue({
            ...statusValue,
            compInfoUpdateRequest: {
                ...statusValue.compInfoUpdateRequest!,
                [e.target.name] : e.target.value
            }
        })
    }

    const comfirmAddressPopup = (address: Address) => {
        fn_Debug("ADDRESS");
        fn_Debug(address);
        setStatusValue({
            ...statusValue,
            compInfoUpdateRequest: {
                ...statusValue.compInfoUpdateRequest!,
                address: {
                    ...statusValue.compInfoUpdateRequest!.address,
                    depthOne: address.sido,
                    depthTwo: address.sigungu,
                    depthThree: address.bname,
                    fullLocation: address.address,
                    postNum: address.zonecode,
                    street: address.roadAddress,
                    detail: "",
                },
            },
            isAddressPopupOpen: false,
        })
    }

    const changeAddressPopup = () => {
        setStatusValue({
            ...statusValue,
            isAddressPopupOpen: !statusValue.isAddressPopupOpen,
        })
    }

    const onChangeAddressDetail = (e: any) => {
        setStatusValue({
            ...statusValue,
            compInfoUpdateRequest: {
                ...statusValue.compInfoUpdateRequest!,
                address: {
                    ...statusValue.compInfoUpdateRequest!.address!,
                    detail: e.target.value,
                },
            },
            isAddressPopupOpen: false,
        })
    }

    const onChangeFileId = (fileId: string) => {
        setStatusValue({
            ...statusValue,
            compInfoUpdateRequest: {
                ...statusValue.compInfoUpdateRequest!,
                fileId: fileId,
            }
        })
    }

    const onChangeIntroduction = (data: string) => {
        setStatusValue({
            ...statusValue,
            compInfoUpdateRequest: {
                ...statusValue.compInfoUpdateRequest!,
                introduction: data,
            }
        })
    }

    const onChangeHistory = (data: string) => {
        setStatusValue({
            ...statusValue,
            compInfoUpdateRequest: {
                ...statusValue.compInfoUpdateRequest!,
                history: data,
            }
        })
    }

    const onChangeDirections = (data: string) => {
        setStatusValue({
            ...statusValue,
            compInfoUpdateRequest: {
                ...statusValue.compInfoUpdateRequest!,
                address: {
                    ...statusValue.compInfoUpdateRequest!.address!,
                    directions: data,
                }
            }
        })
    }

    const validCreateCompany =  ():boolean => {
        if(!statusValue.compInfoUpdateRequest!.name) {
            alertContext.openAlert("회사이름은 필수 입니다.", "warning");
            return false;
        }
        if(!statusValue.compInfoUpdateRequest!.owner) {
            alertContext.openAlert("대표자명은 필수 입니다.", "warning");
            return false;
        }
        if(!statusValue.compInfoUpdateRequest!.email) {
            alertContext.openAlert("회사이메일은 필수 입니다.", "warning");
            return false;
        }
        if(!statusValue.compInfoUpdateRequest!.phone) {
            alertContext.openAlert("회사대표번호는 필수 입니다.", "warning");
            return false;
        }
        if(!PHONE_REGEX.test(statusValue.compInfoUpdateRequest!.phone)) {
            alertContext.openAlert("회사대표번호는 숫자만 입력가능합니다.", "warning");
            return false;
        }
        if(!statusValue.compInfoUpdateRequest!.introduction) {
            alertContext.openAlert("회사소개는 필수 입니다.", "warning");
            return false;
        }
        if(!statusValue.compInfoUpdateRequest!.history) {
            alertContext.openAlert("연혁은 필수 입니다.", "warning");
            return false;
        }

        return true;
    }

    const updateCompany = async() => {
        if(!validCreateCompany()) return;

        const response = await axios.putData(
            DEFINE_COMPANY_UPDATE
            , statusValue.compInfoUpdateRequest, {
                headers: {
                    Authorization: `${BEARER_STRING}${fn_Decrypt(sessionStorage.getItem(MBAAS_ACCESS_TOKEN)!)}`
                }
            }
        )

        fn_Debug("UPDATE_COMPANY_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            getCompanyInfoRead();
            alertContext.openAlert("회사정보가 수정되었습니다.", "success");
        }
    }

    return {
        statusValue,
        deletePopup,
        deleteCompanyInfo,
        changeDeletePopup,
        changeUpdate,
        ref,
        onChange,
        comfirmAddressPopup,
        changeAddressPopup,
        onChangeAddressDetail,
        onChangeFileId,
        onChangeIntroduction,
        onChangeHistory,
        onChangeDirections,
        updateCompany,
    }
}