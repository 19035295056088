import React from 'react'

const MAdminRead = () => {
  return (
    <>
        <h2>엠바스 관리자 <span>김소연</span> 님의 상세 정보</h2>

        <div className="boxContentsBg">
            <div className="boxContents">
                <div className="flex">
                    <div>
                        <div className="detailTop flex">
                            <div className="contentTit">이름</div>
                            <div className="contentCon">김소연</div>
                        </div>
                        <div className="detailTop flex">
                            <div className="contentTit">아이디</div>
                            <div className="contentCon">user123</div>
                        </div>
                        <div className="detailTop flex">
                            <div className="contentTit">부서명</div>
                            <div className="contentCon">디자인팀</div>
                        </div>
                        <div className="detailTop flex">
                            <div className="contentTit">연락처</div>
                            <div className="contentCon">010-0000-0000</div>
                        </div>
                    </div>
                    <div>
                        <div className="detailTop flex">
                            <div className="contentTit">사번</div>
                            <div className="contentCon">20230405001</div>
                        </div>
                        <div className="detailTop flex">
                            <div className="contentTit">회사명</div>
                            <div className="contentCon">엠바스</div>
                        </div>
                        <div className="detailTop flex">
                            <div className="contentTit">회사주소</div>
                            <div className="contentCon">부산광역시 동구 중앙대로 270, 6층 610호 (강남빌딩)</div>
                        </div>
                        <div className="detailTop flex">
                            <div className="contentTit">가입일</div>
                            <div className="contentCon">2023-07-15</div>
                        </div>
                        <div className="detailTop flex">
                            <div className="contentTit">휴면일</div>
                            <div className="contentCon">-</div>
                        </div>
                        <div className="detailTop flex">
                            <div className="contentTit">탈퇴일</div>
                            <div className="contentCon">-</div>
                        </div>
                    </div>
                </div>
                {/* <div className="flex">
                    <select id="staus">
                        <option value="act" selected>사용가능</option>
                        <option value="stop">사용중지</option>
                        <option value="fire">강퇴</option>
                    </select>
                    <div className="selectOption">
                        <ul>
                            <li className="sleep">휴면일시 : 2023-07-15</li>
                            <li className="wthdr"><input type="date" date-placeholder="기간(시작)" required/> ~ <input type="date" date-placeholder="기간(종료)" required/></li>
                            <li className="stop"><input type="date" date-placeholder="기간(시작)" required/> ~ <input type="date" date-placeholder="기간(종료)" required/></li>
                            <li className="fire">강퇴일시 : 2023-07-15</li>
                        </ul>
                    </div>
                </div>
                
                <table className="table table--block">
                    <thead>
                        <th>작업 메뉴</th>
                        <th>작업 내용</th>
                        <th>일시</th>
                    </thead>
                    <tbody>
                        <td>메뉴명1</td>
                        <td>접속/저장/수정/삭제</td>
                        <td>YYYY-MM-DD hh:mm:ss</td>
                    </tbody>
                    <tbody>
                        <td>메뉴명2</td>
                        <td>접속/저장/수정/삭제</td>
                        <td>YYYY-MM-DD hh:mm:ss</td>
                    </tbody>
                </table> */}
                <div className="btnBox">
                    <button className="off">닫기</button>
                    {/* <button className="on">수정</button> */}
                </div>
            </div>
        </div>
    </>
  )
}

export default MAdminRead