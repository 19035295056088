import React from 'react'

interface Props {
  onConfirm: () => void,
  onCancle: () => void,
}

const DeleteNoticePopup:React.FC<Props> = (props:Props) => {
  return (
    <>
        <div className="popBg">
          <p className="center bold">공지사항을 삭제하시겠습니까?</p>
          <div className="btnBox">
            <button className="off" onClick={props.onCancle}>취소</button>
            <button className="on" onClick={props.onConfirm}>확인</button>
          </div>
        </div>
    </>
  )
}

export default DeleteNoticePopup