/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react'
import { LayoutPopupProps } from '../../common/model/popup.model'

const DefaultPopup:React.FC<LayoutPopupProps> = (props: LayoutPopupProps) => {
  const popupRef = useRef<any>();

  useEffect(() => {
    popupRef.current && popupRef.current.focus();
    popupRef.current.style.zIndex = props.zIndex ?? 0;
  }, [])

  return (
    <div className="popWrap" ref={popupRef}>
        {props.children}
    </div>
  )
}

export default DefaultPopup