import { useEffect, useState } from "react";
import { FileResponseV2 } from "../../common/model/file.model";

interface Props {
  createAi: () => void,
  onChangePrompt: (val: string) => void,
  aiImageList: FileResponseV2[],
  onClickSelect: (fileId: string) => void,
  selectImg: string,
}

const ImageAi:React.FC<Props> = (props:Props) => {
  const [selectImg, setSelectImg] = useState<string>(props.selectImg);
  
  useEffect(() => {
    console.log(selectImg);
  }, [selectImg])

  return (
    <>
      <div className='imgCreateWrap'>
        <input type="text" placeholder='생성하고 싶은 이미지에 대한 설명 문구를 작성해주세요.' onChange={(e) => {props.onChangePrompt(e.target.value)}}/> 
          <button onClick={props.createAi}>이미지 생성</button>
      </div>
      {/* 이미지 생성 버튼클릭시 보이게, 이미지ai 결과 */}
      <div className='imgResultWrap'>
        <p>사용하실 이미지를 선택해 주세요. (선택되지않은 이미지는 자동 삭제됩니다.)</p>
          <ul className='flex'>
            {
              props.aiImageList.map((aiImg) => (
                <li>
                  <div>
                    <input type="radio" 
                      id={aiImg.fileId} value={aiImg.fileId} 
                      name="imgAi" 
                      onChange={(e) => {setSelectImg(aiImg.fileId)}}
                      defaultChecked={selectImg === aiImg.fileId}/>
                      <br/>
                      <img src={aiImg.fileUrl} alt="aiImg.fileUrl" />
                  </div>
                </li>
              ))
            }
            {/* <li>
              <div>
                <input type="radio" id="result3" name="imgAi" /><br/>
                  <img src={require("../../img/Ai.app_logo.png")} alt="" />
              </div>
            </li>
            <li>
              <div>
                <input type="radio" id="result3" name="imgAi" /><br/>
                  <img src={require("../../img/Ai.app_logo.png")} alt="" />
              </div>
            </li> */}
          </ul>
          <button onClick={() => {props.onClickSelect(selectImg)}}>선택완료</button>
        </div>

    </>
  )

}

export default ImageAi;