/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { SimAppListResponse, SimAppListState } from "./SimAppList.model"
import { useAxios, useFileHook } from "../../../../../common/hooks";
import { DEFINE_SIMAPP_LIST, RESPONSE_RESULT_SUCCESS } from "../../../../../common/const";
import { fn_Debug } from "../../../../../common/module";
import { FILE_HOST, ITEMS_PER_PAGE } from "../../../../../common/config";
import { AppUseCate } from "../../tem/list/TemList.model";

export const useSimAppListHook = () => {
    const axios = useAxios();
    const fileHook = useFileHook();
    const [statusValue, setStatusValue] = useState<SimAppListState>({
        simAppListRequest: {
            pageable: {
                page: 0,
                size: ITEMS_PER_PAGE,
            }
        },
        pagination: {
            itemOffset: 0,
        },
        isImgPop: false,
        previewImgList: [],
    });

    useEffect(() => {
        getSimAppList();
    }, [statusValue.simAppListRequest.pageable.page])

    const getSimAppList = async() => {
        const response = await axios.getData<SimAppListResponse>(DEFINE_SIMAPP_LIST); 

        fn_Debug("SIM_APP_LIST_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setStatusValue({
                ...statusValue,
                simAppListResponse: response.data,
            })
        }
    }

    const setItemOffset = (newOffset: number) => {
        setStatusValue({
            ...statusValue,
            simAppListRequest: {
                ...statusValue.simAppListRequest,
                pageable: {
                    ...statusValue.simAppListRequest.pageable,
                    page: newOffset / ITEMS_PER_PAGE,
                    size: ITEMS_PER_PAGE,
                }
            },
            pagination: {
                itemOffset: newOffset
            }
        })
    }

    const printCategory = (categoryList: AppUseCate[]):string => {
        const arr:string[] = [];

        categoryList.forEach(cate => {
            arr.push(cate.name);
        })

        return arr.join(",");
    }

    const openPreviewPopup = async(e:any, fileId: string) => {
        e.stopPropagation();
        const response = await fileHook.getFileByGrpId(fileId);
        const fileUrl:string[] = [];

        response.forEach(file => {
            fileUrl.push(`${FILE_HOST}${file.fleUrl}`);
        })
        setStatusValue({
            ...statusValue,
            isImgPop: true,
            previewImgList: fileUrl,
        })
    }

    const closePreviewPopup = () => {
        setStatusValue({
            ...statusValue,
            isImgPop: false,
        })
    }

    return {
        statusValue,
        setItemOffset,
        printCategory,
        openPreviewPopup,
        closePreviewPopup
    }
}