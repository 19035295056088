import React from 'react'
import { useMMeberReadHook } from './MMemberRead.hook'
import { useNavigate } from 'react-router-dom';
import NoActionAlert from '../../../../../alert/NoActionAlert';

const MMemberRead = () => {
  const navigator = useNavigate();
  const memberReadHook = useMMeberReadHook();

  return (
    <>
        <h2>엠바스 회원 <span>{memberReadHook.statusValue.mMemberResponse?.memberName}</span> 님의 상세 정보</h2>

        <div className="boxContentsBg">
            <div className="boxContents">
                <div className="flex">
                    <div>
                        <div className="detailTop flex">
                            <div className="contentTit">이름</div>
                            <div className="contentCon">{memberReadHook.statusValue.mMemberResponse?.memberName}</div>
                        </div>
                        <div className="detailTop flex">
                            <div className="contentTit">아이디</div>
                            <div className="contentCon">{memberReadHook.statusValue.mMemberResponse?.memberEmail}</div>
                        </div>
                        <div className="detailTop flex">
                            <div className="contentTit">회원유형</div>
                            <div className="contentCon">{memberReadHook.statusValue.mMemberResponse?.businessType}</div>
                        </div>
                        <div className="detailTop flex">
                            <div className="contentTit">연락처</div>
                            <div className="contentCon">{memberReadHook.statusValue.mMemberResponse?.memberPhone}</div>
                        </div>
                    </div>
                    {/* <div>
                        <div className="detailTop flex">
                            <div className="contentTit">회사명</div>
                            <div className="contentCon">엠바스</div>
                        </div>
                        <div className="detailTop flex">
                            <div className="contentTit">회사주소</div>
                            <div className="contentCon">부산광역시 동구 중앙대로 270, 6층 610호 (강남빌딩)</div>
                        </div>
                        <div className="detailTop flex">
                            <div className="contentTit">업종</div>
                            <div className="contentCon">통신판매</div>
                        </div>
                        <div className="detailTop flex">
                            <div className="contentTit">창업연도</div>
                            <div className="contentCon">2023-07-15</div>
                        </div>
                        <div className="detailTop flex">
                            <div className="contentTit">가입일</div>
                            <div className="contentCon">2023-07-15</div>
                        </div>
                        <div className="detailTop flex alignC">
                            <div className="contentTit">메세지 발송 충전</div>
                            <div className="contentCon charging">1,000</div>
                            <button>충전</button>
                        </div>
                    </div> */}
                </div>
                <div className="flex"  style={{marginTop:"20px"}}>
                    <select id="staus" value={memberReadHook.statusValue.updateType} onChange={memberReadHook.onChangeUpdateType}>
                        {/* <option value="BLUE">휴면</option> */}
                        <option value="GREEN">활동</option>
                        {/* <option value="BLACK">탈퇴</option> */}
                        <option value="YELLOW">사용중지</option>
                        <option value="RED">강퇴</option>
                    </select>
                    <div className="selectOption">
                        <ul>
                            <li className="sleep">휴면일시 : 2023-07-15</li>
                            <li className="wthdr"><input type="date" date-placeholder="기간(시작)" required/> ~ <input type="date" date-placeholder="기간(종료)" required/></li>
                            <li className="stop"><input type="date" date-placeholder="기간(시작)" required/> ~ <input type="date" date-placeholder="기간(종료)" required/></li>
                            <li className="fire">강퇴일시 : 2023-07-15</li>
                        </ul>
                    </div>
                </div>
                {
                    memberReadHook.statusValue.mMemberResponse?.appList.length !== 0 ? 
                    <table className="table table--block">
                        <thead>
                            <th className='tableLong'>생성앱</th>
                            <th>앱관리자아이디</th>
                            <th>앱회원수</th>
                            <th>회사정보</th>
                            <th>생성일</th>
                            <th>상태</th>
                        </thead>
                        
                        {
                            memberReadHook.statusValue.mMemberResponse?.appList.map(app => (
                                <tbody>
                                    <td>{`${app.appName}(${app.appCode})`}</td>
                                    <td>{memberReadHook.statusValue.mMemberResponse?.memberEmail}</td>
                                    <td>{app.appMemberCount}</td>
                                    <td><button onClick={() => {memberReadHook.openPopup(app.companyCode)}}>정보보기</button></td>
                                    <td>{}</td>
                                    <td><span className={app.appStatus === "GREEN" ? "use" : "unuse"}>{app.appStatus === "GREEN" ? "사용" : "미사용"}</span></td>
                                </tbody>
                            ))
                        }
                    </table> : <NoActionAlert alertText='생성된 앱이 없습니다.' open={true} serverity='warning'/>
                }
                <div className="btnBox">
                    <button className="off" onClick={() => {navigator(-1)}}>닫기</button>
                    <button className="on" onClick={memberReadHook.updateStatus}>수정</button>
                </div>
            </div>
        </div>
        
        {
            memberReadHook.statusValue.isOpen ? 
            <div className='companyPopWrap'>
                <div className='companyPop'>
                    <h2>회사정보</h2>
                    <div>
                        <div className="detailTop flex">
                            <div className="contentTit">회사명</div>
                            <div className="contentCon">{memberReadHook.companyResponse?.name}</div>
                        </div>
                        <div className="detailTop flex">
                            <div className="contentTit">회사주소</div>
                            <div className="contentCon">{memberReadHook.companyResponse?.address?.fullLocation}</div>
                        </div>
                        <div className="detailTop flex">
                            <div className="contentTit">사업자번호</div>
                            <div className="contentCon">{memberReadHook.companyResponse?.businessNumber}</div>
                        </div>
                        <div className="detailTop flex">
                            <div className="contentTit">이메일</div>
                            <div className="contentCon">{memberReadHook.companyResponse?.email}</div>
                        </div>
                        <div className="detailTop flex">
                            <div className="contentTit">창업자</div>
                            <div className="contentCon">{memberReadHook.companyResponse?.owner}</div>
                        </div>
                        <div className="detailTop flex">
                            <div className="contentTit">연락처</div>
                            <div className="contentCon">{memberReadHook.companyResponse?.phone}</div>
                        </div>
                        {/* <div className="detailTop flex alignC">
                            <div className="contentTit">메세지 발송 충전</div>
                            <div className="contentCon charging">1,000</div>
                            <button>충전</button>
                        </div> */}
                    </div>
                    <button className='closeBtn'><img src={require("../../../../../../img/icon_close.png")} onClick={memberReadHook.closePopup} alt='close'/></button>
                </div>
            </div> : ""
        }
    </>
  )
}

export default MMemberRead