import React from 'react'
import { useNavigate } from 'react-router-dom'
import { MYPAGE_UPDATE_PATH } from '../../../../common/path';
import { useMyPageReadHook } from './MyPageReadHook';
import { ROLE_ADMIN } from '../../../../common/const';

const MyPageRead = () => {
  const naviagtor = useNavigate();
  const myPageHook = useMyPageReadHook();

  return (
    <>
        <h2>마이페이지</h2>
        <div className="boxContents">
            <table className="tableCol">
            <tr>
                <th>이름</th>
                <td>{myPageHook.statusValue.myPageReadResponse?.memberName}</td>
            </tr>
            <tr>
                <th>아이디</th>
                <td>{myPageHook.statusValue.myPageReadResponse?.memberEmail}</td>
            </tr>
            <tr>
                <th>연락처</th>
                <td>{myPageHook.statusValue.myPageReadResponse?.memberPhone}</td>
            </tr>
            <tr>
                <th>가입일</th>
                <td>{myPageHook.statusValue.myPageReadResponse?.createAt}</td>
            </tr>
            <tr>
                <th>권한</th>
                <td>{myPageHook.statusValue.myPageReadResponse?.role === ROLE_ADMIN ? "관리자" : "유저"}</td>
            </tr>
            </table>
            <div className="btnBox">
            <button className="on" onClick={() => {naviagtor(MYPAGE_UPDATE_PATH, {state: {me: myPageHook.statusValue.myPageReadResponse}})}}>수정하기</button>
            </div>
        </div>
    </>
  )
}

export default MyPageRead