import React from 'react'

const ApUpdateT1 = () => {
  return (
    <>
       <table className="tableCol">
          <tr>
            <th>회사 주소</th>
            <td>
              <input type="text" placeholder="기본주소" style={{marginBottom: "8px"}}/>&nbsp;&nbsp;<button>우편주소찾기</button><br/>
              <input type="text" placeholder="상세주소"/>&nbsp;&nbsp;<input type="text" />
            </td>
          </tr>
          <tr>
            <th>회사 대표번호</th>
            <td><input type="text"/>&nbsp;&nbsp;( - 없이 숫자만 입력해 주세요. )</td>
          </tr>
          <tr>
            <th>회사 소개</th>
            <td>
              <div>
                <textarea></textarea>
              </div>
            </td>
          </tr>
          <tr>
            <th>회사 연혁</th>
            <td>
              <div>
                <textarea></textarea>
              </div>
            </td>
          </tr>
          <tr>
            <th>오시는길<br/>(위치안내)</th>
            <td>
              <div>
                <textarea></textarea>
              </div>
            </td>
          </tr>
        </table>
    </>
  )
}

export default ApUpdateT1