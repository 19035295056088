import React from 'react'
import DaumPostcode, { Address } from "react-daum-postcode";

export interface Props {
  onConfirm: (address: Address) => void,
  onCancle: () => void,
}

const AddressPopup:React.FC<Props> = (props: Props) => {
  return (
    <>
      {/* <div className="popBg"> */}
        <div style={{width:"600px", height: "450px"}}>
          <DaumPostcode
          onComplete={props.onConfirm} style={{ width: '100%', height: '100%' }}
          />
        </div>
        {/* <div className="btnBox">
            <button className="on" onClick={props.onConfirm}>확인</button>
            <button className="on" onClick={props.onCancle}>취소</button>
          </div> */}
      {/* </div> */}
    </>
  )
}

export default AddressPopup