import React from 'react'
import { useNavigate } from 'react-router-dom'
import { SIM_APP_CREATE_PATH, SIM_APP_READ_PATH } from '../../../../../common/path';
import { useSimAppListHook } from './SimAppList.hook';
import Paging from '../../../../common/Paging';
import NoActionAlert from '../../../../alert/NoActionAlert';

const SimAppList = () => {
  const navigator = useNavigate();
  const simAppListHook = useSimAppListHook();

  return (
    <>
        {
            simAppListHook.statusValue.isImgPop ?
            <div className='previewWrap'>
                <div className='previewPop'>
                    <div className='closeBtnWrap'>
                        <button className='closeBtn'>
                            <img src={require("../../../../../img/icon_close.png")} onClick={simAppListHook.closePreviewPopup} alt=''/>
                        </button>
                    </div>
                    <div className='previewIcon'>
                        {
                            simAppListHook.statusValue.previewImgList.length !== 0 ?
                            simAppListHook.statusValue.previewImgList.map(url => (
                                <img src={url} alt={url}/>
                            )) : ""
                        }
                    </div>
                </div>
            </div> : ""
        }
        
        <h2>유사앱 관리</h2>
        <div className="flex flexE" onClick={() => {navigator(SIM_APP_CREATE_PATH)}} style={{marginBottom:"10px"}}><button className="on">유사앱 등록</button></div>
        <div className="flex spaceB alignC" style={{marginTop: "20px"}}>
            <div className="search">
                <select>
                    <option value="all">전체</option>
                    <option value="name" selected>이름</option>
                    <option value="explanation">설명</option>
                </select>
                <input type="text" placeholder="검색어를 입력해주세요."/>
                <button><img src={require("../../../../../img/icon_search_white.png")} alt="검색" /></button>
            </div>
            <div className="filter">
                <select>
                    <option value="all">전체</option>
                    <option value="use" selected>사용</option>
                    <option value="notUse">미사용</option>
                </select>
                <select>
                    <option value="all" selected>전체</option>
                    <option value="default">기본 추천</option>
                    <option value="random">랜덤 추천</option>
                </select>
            </div>
        </div>

        {
            simAppListHook.statusValue.simAppListResponse?.totalCount ?
            <div className="boxContents">
                <table className="table table--block">
                    <thead>
                        <tr>
                            <th className="tableShort">No</th>
                            <th>이름</th>
                            <th>앱용도</th>
                            <th>추천 유형</th>
                            <th>이미지</th>
                            <th>생성일</th>
                            <th>상태</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            simAppListHook.statusValue.simAppListResponse?.tagList?.map((sim, idx) => (
                                <tr key={sim.code} onClick={() => {navigator(SIM_APP_READ_PATH, {state: {code: sim.code}})}}>
                                    <td data-name="No.">{idx}</td>
                                    <td data-name="이름">{sim.name}</td>
                                    <td data-name="앱용도">
                                         {simAppListHook.printCategory(sim.categoryList)}
                                    </td>
                                    <td data-name="추천 유형">{sim.randomType}</td>
                                    <td data-name="이미지">
                                        <button onClick={(e) => {simAppListHook.openPreviewPopup(e, sim.fileId)}}>미리보기</button>
                                    </td>
                                    <td data-name="생성일">YYYY-MM-DD</td>
                                    <td data-name="상태"><span className={sim.status === "Y" ? "use" : "notuse"}>{sim.status === "Y" ? "사용중" : "미사용"}</span></td>
                                </tr>
                            ))
                        }
                    </tbody>
                    </table>
                    <div className="page">
                        <Paging
                            itemOffset={simAppListHook.statusValue.pagination.itemOffset}
                            setItemOffset={simAppListHook.setItemOffset}
                            itemsLength={simAppListHook.statusValue.simAppListResponse?.totalCount ?? 0}
                        />
                    </div>
            </div> : <NoActionAlert alertText='등록된 유사앱이 없습니다.' open={true} serverity='warning'/>
        }
        
    </>
  )
}

export default SimAppList