/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom"
import { DEFINE_APHELP_READ, DEFINE_GET_MY_INFO, MBAAS_ACCESS_TOKEN, MBAAS_APP_CODE, RESPONSE_RESULT_SUCCESS } from "../../common/const"
import { LOGIN_PATH } from "../../common/path";
import { useEffect, useState } from "react";
import { fn_Debug, fn_Decrypt } from "../../common/module";
import { AxiosRequestConfig } from "axios";
import { MemberResponse } from "../../common/model/member.model";
import { useAxios } from "../../common/hooks";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"
import { HelpApReadResponse } from "../bo/template/helpAp/read/HelpApRead.model";

export interface LayoutState {
    isError: boolean,
    errorMessage:string,
    wrongEnter: () => void,
    memberData?: MemberResponse,
}

export const useLayoutHook = () => {
    const navigator = useNavigate();
    const [statusValue, setStatusValue] = useState<LayoutState>({
        errorMessage: "",
        isError: false,
        wrongEnter: () => {
            navigator(LOGIN_PATH, {replace: true});
        },
    });
    const [openQr, setOpenQr] = useState<boolean>(false);
    const [appDownUrl, setAppDownUrl] = useState<string>("");
    const axios = useAxios();
    
    const checkLogin = () => {
        fn_Debug(sessionStorage.getItem(MBAAS_ACCESS_TOKEN));
        if(!sessionStorage.getItem(MBAAS_ACCESS_TOKEN)){
            setStatusValue({
                ...statusValue,
                errorMessage: "잘못된 접근입니다.",
                isError: true,
            })
        }
        else {
            getMyInfo();
        }
    }

    const getMyInfo = async() => {
        const header: AxiosRequestConfig = {
            headers: {
                Authorization: `Bearer ${fn_Decrypt(sessionStorage.getItem(MBAAS_ACCESS_TOKEN) ?? "")}`,
            }
        }
        const response = await axios.getData<MemberResponse>(DEFINE_GET_MY_INFO, header);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setStatusValue({
                ...statusValue,
                memberData: response.data
            });
        }
        fn_Debug("GET_MY_INFO_RESPONSE");
        fn_Debug(response);

        // if(response.result === RESPONSE_RESULT_SUCCESS) {
        //     if(response.data.role === ROLE_USER) {
        //         sessionStorage.setItem(MBAAS_APP_CODE, "kr.mbaas.shop.app4");
        //     }
        // }
    }
    const onClickQrBtn = () => {
        setOpenQr(!openQr);
    }

    const getHelpApRead = async() => {
        const response = await axios.getData<HelpApReadResponse>(DEFINE_APHELP_READ, {}, true, [sessionStorage.getItem(MBAAS_APP_CODE) ?? ""]);
        fn_Debug("HELP_AP_READ_RESPONSE");
        fn_Debug(response);
        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setAppDownUrl(response.data.apkPath);
        }
    }

    useEffect(() => {
        checkLogin();
        getHelpApRead();
    }, [])

    return {
        statusValue,
        openQr,
        onClickQrBtn,
        appDownUrl,
    }
}