import parse from 'html-react-parser';
import { useUserAskReadHook } from './UserAskRead.hook';
import Editor from '../../../common/Editor';
import FileUpload from '../../../common/FileUpload';
import { useUserAskCreateAn } from '../answer/create/UserAskCreateAn.hook';
import MovePopup from '../../../alert/MovePopup';
import BasicConfirmPopup from '../../../popup/BasicConfirmPopup';
import { useUserAskUpdateAn } from '../answer/update/UserAskUpdateAn.hook';

const UserAskRead = () => {
  const userAskReadHook = useUserAskReadHook();
  const userAskCreateAnHook = useUserAskCreateAn(userAskReadHook.getUserAskRead);
  const userAskUpdateAnHook = useUserAskUpdateAn(userAskReadHook.getUserAskRead);

  return (
    <>
        <h2>1:1 문의 상세</h2>
        <div className="boxContents">
            <table className="tableCol">
            <tr>
                <th>제목</th>
                <td>{userAskReadHook.statusValue.userAskReadResponse?.title}</td>
                <th>답변상태</th>
                {
                    userAskReadHook.statusValue.userAskReadResponse?.answer ?
                        <td><span className="use">답변</span></td> :
                        <td><span className="notuse">미답변</span></td>
                }
            </tr>
            <tr>
                <th>문의자</th>
                <td colSpan={3}>{userAskReadHook.statusValue.userAskReadResponse?.name}</td>
            </tr>
            <tr>
                <th>문의일</th>
                <td colSpan={3}>{userAskReadHook.statusValue.userAskReadResponse?.createAt}</td>
            </tr>
            <tr>
                <th>내용</th>
                <td colSpan={3}>
                <div>
                    {userAskReadHook.statusValue.userAskReadResponse?.content}
                </div>
                </td>
            </tr>
            <tr>
                <th>첨부파일</th>
                <td colSpan={3}>
                    {
                        userAskReadHook.statusValue.userAskReadResponse &&
                        userAskReadHook.statusValue.userAskReadResponse?.fileUrl ?
                        userAskReadHook.statusValue.userAskReadResponse?.fileUrl.map(url => (
                            <img src={url} alt={url} style={{width: "350px", height: "250px", objectFit: "contain"}}/>
                        )) : "첨부파일 없음."
                    }
                </td>
            </tr>
            </table>
            {
                userAskReadHook.statusValue.userAskReadResponse?.answer ? 
                "" : 
                <div className="btnBox">
                    <button className="on" onClick={userAskCreateAnHook.openAnswer}>답변하기</button>
                </div>
            }
        </div>
        {
            userAskCreateAnHook.statusValue.isOpen ? 
                <>
                    <h2 style={{marginTop: "60px"}}>1:1 문의 답변하기</h2>
                    <div className="boxContents" style={{marginTop: "20px"}}>
                        <table className="tableCol">
                            <tr>
                                <th>제목</th>
                                <td> <input type="text" name='title' onChange={userAskCreateAnHook.onChange} placeholder="제목을 입력해주세요."/></td>
                            </tr>
                            <tr>
                                <th>내용</th>
                                <td colSpan={3}>
                                    <Editor
                                        onChange={userAskCreateAnHook.onChangeContent}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>첨부파일</th>
                                <td colSpan={3}>
                                    <FileUpload
                                        afterPost={userAskCreateAnHook.onChangeFileId}
                                    />
                                </td>
                            </tr>
                        </table>
                        <div className="btnBox">
                            <button className="on" onClick={userAskCreateAnHook.createAnswer}>답변하기</button>
                        </div>
                    </div>
                </> : ""
        }

        {
            userAskReadHook.statusValue.userAskReadResponse?.answer ? 
                <>
                    <h2 style={{marginTop: "60px"}}>1:1 문의 {userAskUpdateAnHook.statusValue.isUpdate ? "수정" : "답변"}</h2>
                    <div className="boxContents" style={{marginTop: "20px"}}>
                        <table className="tableCol">
                            <tr>
                                <th>제목</th>
                                <td>
                                {
                                    userAskUpdateAnHook.statusValue.isUpdate ?
                                        <input type="text" name='title' 
                                            defaultValue={userAskReadHook.statusValue.userAskReadResponse.reply?.title}
                                            onChange={userAskUpdateAnHook.onChange} 
                                            placeholder="제목을 입력해주세요."
                                        />
                                        : <div>{userAskReadHook.statusValue.userAskReadResponse.reply?.title}</div>
                                }
                                </td>
                            </tr>
                            <tr>
                                <th>내용</th>
                                <td colSpan={3}>
                                    {
                                        userAskUpdateAnHook.statusValue.isUpdate ?
                                            <Editor
                                                initialData={userAskReadHook.statusValue.userAskReadResponse?.reply!.content}
                                                onChange={userAskUpdateAnHook.onChangeContent}
                                            /> : 
                                            <div>{parse(userAskReadHook.statusValue.userAskReadResponse?.reply!.content)}</div>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>첨부파일</th>
                                <td colSpan={3}>
                                    {
                                        userAskUpdateAnHook.statusValue.isUpdate ?
                                            <FileUpload
                                                fleGrpId={userAskReadHook.statusValue.userAskReadResponse.reply!.fileId}
                                                afterPost={userAskUpdateAnHook.onChangeFileId}
                                            /> : 
                                            
                                                userAskUpdateAnHook.statusValue.userAskUpdateAnRequest?.fileUrl?.map(url => (
                                                    <img src={url} alt={url} style={{width: "350px", height: "250px", objectFit: "contain"}}/>
                                                ))
                                            
                                    }
                                </td>
                            </tr>
                        </table>
                        {
                            userAskUpdateAnHook.statusValue.isUpdate ?
                                <div className="btnBox">
                                    <button className="off" onClick={userAskUpdateAnHook.onChangeUpdate}>취소</button>
                                    <button className="on" onClick={userAskUpdateAnHook.updateReply}>수정하기</button>
                                </div> :
                                <div className="btnBox">
                                    <button className="off" onClick={userAskCreateAnHook.openDeletePopup}>삭제</button>
                                    <button className="on" onClick={userAskUpdateAnHook.onChangeUpdate}>수정하기</button>
                                </div>
                        }
                    </div>
                </> : ""
        }

        {
            userAskCreateAnHook.isDeletePopupOpen ? 
            <MovePopup>
                <BasicConfirmPopup
                    message='답변을 삭제하시겠습니까?'
                    onConfirm={() => {userAskCreateAnHook.deleteAnswer(userAskReadHook.statusValue.userAskReadResponse?.reply?.replyCode!)}}
                    onCancle={userAskCreateAnHook.openDeletePopup}
                />
            </MovePopup> : ""
        }
    </>
  )
}

export default UserAskRead