import React from 'react'

export interface Props {
  message: string
  onConfirm: () => void,
  onCancle: () => void,
}

const BasicConfirmPopup:React.FC<Props> = (props: Props) => {
  return (
    <>
      <div className="popBg">
          <p className="center bold">{props.message}</p>
          <div className="btnBox">
            <button className="on" onClick={props.onConfirm}>확인</button>
            <button className="off" onClick={props.onCancle}>취소</button>
          </div>
      </div>
    </>
  )
}

export default BasicConfirmPopup