/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { CompInfoListResponse, CompInfoListState } from "./CompInfoList.model";
import { BEARER_STRING, DEFINE_COMPANY_LIST, MBAAS_ACCESS_TOKEN, RESPONSE_RESULT_SUCCESS } from "../../../../common/const";
import { fn_Debug, fn_Decrypt } from "../../../../common/module";
import CompInfoRead from "../read/CompInfoRead";
import CompInfoCreate from "../create/CompInfoCreate";
import { useTabHook } from "../../../../ui/ui.hooks";
import { useAxios } from "../../../../common/hooks";

export const useCompInfoListHook = () => {
    const tabHook = useTabHook();
    const [statusValue, setStatusValue] = useState<CompInfoListState>({
        selectedIndex: 0,
        loading: true,
    });
    const axios = useAxios();

    useEffect(() => {
        getCompanyInfo();
    }, [])

    useEffect(() => {
        setStatusValue({
            ...statusValue,
            selectedIndex: tabHook.selectedIndex,
        })
    }, [tabHook.selectedIndex]);

    const getCompanyInfo = async() => {
        setStatusValue({
            ...statusValue,
            loading: true,
        });

        const response = await axios.getData<CompInfoListResponse[]>(DEFINE_COMPANY_LIST, {
            headers: {
                Authorization: `${BEARER_STRING}${fn_Decrypt(sessionStorage.getItem(MBAAS_ACCESS_TOKEN)!)}`
            },
        });

        fn_Debug("GET_COMPANY_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setStatusValue((currState) => {
                return {
                    ...currState,
                    compInfoReadResponse: response.data,
                    loading: false,
                };
            });
        }
    }

    const renderTabBody = ():React.ReactNode[] => {
        const resps = statusValue.compInfoReadResponse ?? [];

        const list: React.ReactNode[] = [];

        for(let i = 0; i < 3; i++) {
            for(let i = 0; i < 3; i++) {
                if(resps[i] && resps[i].slot === i + 1) {
                    list.push(<CompInfoRead key={i} companyCode={resps[i].companyCode} getData={getCompanyInfo}/>);
                } else if(resps[i] && resps[i].slot !== i + 1) {
                    for(let j = i; j < resps[i].slot-1; j++) {
                        list.push(<CompInfoCreate selectedIdx={statusValue.selectedIndex} getData={getCompanyInfo} key={j}/>);
                    }
                    list.push(
                        <CompInfoRead key={i} companyCode={resps[i].companyCode} getData={getCompanyInfo}/>
                    );
                    i++;
                } else {
                    list.push(<CompInfoCreate selectedIdx={statusValue.selectedIndex} getData={getCompanyInfo} key={i}/>);
                }
            }
        }
        
        return list;
    }

    const renderTabHeader = ():React.ReactNode[] => {
        const resps = statusValue.compInfoReadResponse ?? [];

        const list: React.ReactNode[] = [];
        for(let i = 0; i < 3; i++) {
            if(resps[i] && resps[i].slot === i + 1) {
                fn_Debug("CATCH CASE 1");
                list.push(
                    <li 
                        className={tabHook.selectedIndex === i ? "on" : ""}
                        key={resps[i].companyCode}
                        onClick={() => {tabHook.onChange(i)}}>
                        {resps[i].name}
                    </li>
                );
            } else if(resps[i] && resps[i].slot !== i + 1) {
                fn_Debug("CATCH CASE 2");
                let offset = i;
                for(let j = i; j < resps[i].slot-1; j++) {
                    list.push(
                        <li 
                            className={tabHook.selectedIndex === j ? "on" : ""}
                            key={j}
                            onClick={() => {tabHook.onChange(j)}}>
                            회사 생성
                        </li>
                    );
                    offset++;
                }
                list.push(
                    <li 
                        className={tabHook.selectedIndex === offset ? "on" : ""}
                        key={resps[i].companyCode}
                        onClick={() => {tabHook.onChange(offset)}}>
                        {resps[i].name}
                    </li>
                );
                i = offset;
            } else {
                fn_Debug("CATCH CASE 3");
                list.push(
                    <li 
                        className={tabHook.selectedIndex === i ? "on" : ""}
                        key={i}
                        onClick={() => {tabHook.onChange(i)}}>
                        회사 생성
                    </li>
                );
            }
        }

        return list;
    }

    const onChangeSelectedIndex = (idx:number) => {
        setStatusValue({
            ...statusValue,
            selectedIndex: idx,
        })
    }

    return {
        statusValue,
        renderTabBody,
        onChangeSelectedIndex,
        renderTabHeader,
    }
}