import React from 'react'
import { useNavigate } from 'react-router-dom'
import { APP_USE_LIST_PATH } from '../../../../../common/path';
import Editor from '../../../../common/Editor';

const AppUseUpdate = () => {
  const navigator = useNavigate();

  return (
    <>
        <h2>앱용도 관리 수정</h2>

        <div className="boxContents">
        <table className="tableCol">
            <tr>
            <th>이름</th>
            <td><input type="text"/></td>
            </tr>
            <tr>
            <th>설명</th>
            <td>
                <div>
                    <Editor/>
                </div>
            </td>
            </tr>
            <tr>
            <th>사용 여부</th>
            <td>
                <label>
                <input type="radio" name="contact" value="use" checked/>
                <span>사용</span>
                </label>
                <label>
                <input type="radio" name="contact" value="notUse"/>
                <span>미사용</span>
                </label>
            </td>
            </tr>
        </table>
        <div className="btnBoxB">
            <div>
            <button className="off" onClick={() => {navigator(APP_USE_LIST_PATH)}}>목록</button>
            {/* <button className="off">저장값 복원</button> */}
            </div>
            <button className="on" onClick={() => {navigator(APP_USE_LIST_PATH)}}>저장</button>
        </div>
        </div>
    </>
  )
}

export default AppUseUpdate