import React, { SyntheticEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { APHELP_UPDATE_PATH, PLANNING_APPINFO_PATH } from '../../../../../common/path'
import { useHelpApReadHook } from './HelpApRead.hook';
import { DEFAULT_APP_ICON } from '../../../../../common/config';

const HelpApRead = () => {
  const navigator = useNavigate();
  const helpApReadHook = useHelpApReadHook();

  return (
    <>
        {
            helpApReadHook.statusValue.isPreviewPopup ?
            <div className='previewWrap'>
                <div className='previewPop'>
                    <div className='closeBtnWrap'>
                        <button className='closeBtn' onClick={helpApReadHook.onChangePopup}>
                            <img src={require("../../../../../img/icon_close.png")} alt='close'/>
                        </button>
                    </div>
                    <div className='preview'>
                        {
                            helpApReadHook.statusValue.helpApReadResponse?.templateFileUrl.map(url => (
                                <img src={url} alt={url}/>
                            ))
                        }
                    </div>
                </div>
            </div> : ""
        }

        <h2>앱 상세</h2>
        <div className="boxContents">
            <table className="tableCol">
                <tr>
                    <th>앱이름</th>
                    <td>{helpApReadHook.statusValue.helpApReadResponse?.appName}</td>
                </tr>
                <tr>
                    <th>회사명</th>
                    <td>{helpApReadHook.statusValue.helpApReadResponse?.companyName ?? "엠바스"}</td>
                </tr>
                 <tr>
                     <th>앱유형</th>
                     <td>
                        {helpApReadHook.statusValue.helpApReadResponse?.appCategory}
                     </td>
                </tr>
                 <tr>
                     <th>앱 아이콘</th>
                     <td>
                        <div className='appIcon'>
                            <img 
                                src={helpApReadHook.appIconImg}
                                alt={DEFAULT_APP_ICON}
                                onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
                                    e.currentTarget.src = DEFAULT_APP_ICON
                                }}/>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>템플릿명</th>
                    <td>{helpApReadHook.statusValue.helpApReadResponse?.templateName} <button onClick={helpApReadHook.onChangePopup}>이미지 보기</button></td>
                </tr>
                <tr>
                    <th>추가기능</th>
                    <td className="flex addFunc" style={{gap: "10px"}}>
                        {
                            helpApReadHook.statusValue.helpApReadResponse?.allCapabilityList.map(capa => (
                                <p key={capa.capabilityCode} className={`${helpApReadHook.onCheckSelectedCapa(capa.capabilityCode)}`}>{capa.capabilityName}</p>       
                            ))
                        }
                    </td>
                </tr>
                <tr>
                    <th>기획서</th>
                    <td>
                        <button onClick={() => {navigator(PLANNING_APPINFO_PATH)}}>기획서 바로관리</button>
                    </td>
                </tr>
                <tr>
                    <th>생성일</th>
                    <td>{helpApReadHook.statusValue.helpApReadResponse?.createAt}</td>
                </tr>
                <tr>
                    <th>상태</th>
                    <td>
                        <span>{helpApReadHook.statusValue.helpApReadResponse?.appStatus}</span> 
                        {/* <button>앱 다운로드 URL 핸드폰 전송</button> */}
                    </td>
                </tr>
                <tr>
                    <th>의견</th>
                    <td>
                        <textarea defaultValue={helpApReadHook.statusValue.helpApReadResponse?.appAsk ?? ""} readOnly></textarea>
                    </td>
                </tr>
            </table>
            <div className="btnBoxE">
                <button className="on" onClick={() => {navigator(APHELP_UPDATE_PATH)}} >수정</button>
            </div>
        </div>
    </>
  )
}

export default HelpApRead