import React from 'react'
import { useNavigate } from 'react-router-dom'
import { APP_MEMBER_READ_PATH } from '../../../../../../common/path';
import { useApMemListHook } from './ApMemList.hook';
import NoActionAlert from '../../../../../alert/NoActionAlert';
import Paging from '../../../../../common/Paging';

const ApMemList = () => {
  const navigator = useNavigate();
  const apMemListHook = useApMemListHook();

  return (
    <>
      <ul className="tapContents">
        {
            apMemListHook.statusValue.apMemListResponse &&
            apMemListHook.statusValue.apMemListResponse?.appMemberInfoResponseList.length !== 0 ?
            <li>
                <div className="search">
                    <select>
                        <option value="all">전체</option>
                        <option value="name" selected>이름</option>
                        <option value="id">아이디</option>
                        <option value="company">회사명</option>
                        <option value="appname">앱 이름</option>
                    </select>
                    <input type="text" placeholder="검색어를 입력해주세요."/>
                    <button><img src={require("../../../../../../img/icon_search_white.png")} alt="검색"/></button>
                </div>
                <div className="filter">
                    <select>
                        <option value="all">전체</option>
                        <option value="act" selected>활동</option>
                        <option value="sleep">휴면</option>
                        <option value="wthdr">탈퇴</option>
                        <option value="stop">사용중지</option>
                        <option value="fire">강퇴</option>
                    </select>
                    <select>
                        <option value="join" selected>가입일순</option>
                        <option value="name">이름순</option>
                    </select>
                </div>
                <div>
                    <table className="table table--block">
                        <thead>
                            <tr>
                                <th style={{width: "10%"}}>No</th>
                                <th>이름</th>
                                <th>아이디(이메일)</th>
                                <th className='tableLong'>가입앱</th>
                                <th>가입일</th>
                                <th>상태</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                apMemListHook.statusValue.apMemListResponse!.appMemberInfoResponseList.map((mem, idx) => (
                                    <tr style={{cursor:"pointer"}} 
                                        onClick={() => {navigator(APP_MEMBER_READ_PATH, {state: {member: JSON.stringify(mem)}})}}>
                                        <td data-name="No">{idx}</td>
                                        <td data-name="이름">{mem.appMemberName}</td>
                                        <td data-name="아이디">{mem.appMemberEmail}</td>
                                        <td data-name="가입앱"></td>
                                        <td data-name="가입일">{mem.createAt}</td>
                                        <td data-name="상태"><span className="act">활동</span></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                <Paging
                    itemOffset={apMemListHook.statusValue.pagination.itemOffset}
                    itemsLength={apMemListHook.statusValue.totalCount}
                    setItemOffset={apMemListHook.setItemOffset}
                />
            </li> : <NoActionAlert alertText='가입된 회원이 없습니다.' open={true} serverity='warning'/>
        }
        
    </ul>
    </>
  )
}

export default ApMemList