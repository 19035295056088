/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'

interface Props {
    children: React.ReactNode[],
    selectedIndex?: number;
}

const Tab:React.FC<Props> = (props:Props) => {
    const [child, setChild] = useState<React.ReactNode>(null);

    useEffect(() => {
        const _child = props.children.filter((child, idx) => {
            return idx === (props.selectedIndex ?? 0);
        })
        setChild(_child);
    }, [props.selectedIndex])

    return (
        <div>
            {child}
        </div>
    )
}

export default Tab