import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ADD_FUNC_CREATE_PATH, ADD_FUNC_READ_PATH } from '../../../../../common/path'
import { useAddFuncListHook } from './AddFuncList.hook';
import Paging from '../../../../common/Paging';
import NoActionAlert from '../../../../alert/NoActionAlert';

const AddFuncList = () => {
  const navigator = useNavigate();
  const addFuncListHook = useAddFuncListHook();

  return (
    <>
        {
        addFuncListHook.statusValue.isImgPop ?
            <div className='previewWrap'>
                <div className='previewPop'>
                    <div className='closeBtnWrap'>
                        <button className='closeBtn'>
                            <img src={require("../../../../../img/icon_close.png")} onClick={addFuncListHook.closePreviewPopup}/>
                        </button>
                    </div>
                    <div className='preview'>
                        {
                            addFuncListHook.statusValue.previewImgList.map(url => (
                                <img src={url} alt={url}/>
                            ))
                        }
                    </div>
                </div>
            </div> : ""
        }

        <h2>추가기능 관리</h2>
        <Link to={ADD_FUNC_CREATE_PATH}><div className="flex flexE" style={{marginBottom:"10px"}}><button className="on">추가기능 등록</button></div></Link>
        <div className="flex spaceB alignC" style={{marginTop: "20px"}}>
            <div className="search">
                <select>
                    <option value="all">전체</option>
                    <option value="name" selected>이름</option>
                    <option value="explanation">설명</option>
                </select>
                <input type="text" placeholder="검색어를 입력해주세요."/>
                <button><img src={require("../../../../../img/icon_search_white.png")} alt="검색" /></button>
            </div>
            <div className="filter">
                <select>
                    <option value="all">전체</option>
                    <option value="use" selected>사용</option>
                    <option value="notUse">미사용</option>
                </select>
                <select>
                    <option value="all" selected>생성일순</option>
                    <option value="default">사용앱 수</option>
                    <option value="random">기능명순</option>
                </select>
            </div>
        </div>

        
            {
                addFuncListHook.statusValue.addFuncListResponse?.totalCount ?
                <div className="boxContents">
                    <table className="table table--block">
                    <thead>
                        <tr>
                            <th className="tableShort">No</th>
                            <th className="tableLong">앱용도</th>
                            <th>이름</th>
                            <th>예제 이미지</th>
                            <th>생성일</th>
                            <th>상태</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            addFuncListHook.statusValue.addFuncListResponse ? 
                                addFuncListHook.statusValue.addFuncListResponse?.capabilityList.map((capa, idx) => (
                                    <tr onClick={() => {navigator(ADD_FUNC_READ_PATH, {state: {code: capa.code}})}}>
                                        <td data-name="No.">{idx}</td>
                                        <td data-name="앱용도">{addFuncListHook.convertAddFuncAppCateName(capa.categoryList)}</td>
                                        <td data-name="이름">{capa.name}</td>
                                        <td data-name="예제 이미지"><button onClick={(e) => {addFuncListHook.openPreviewPopup(e, capa.fileUrl)}}>미리보기</button></td>
                                        <td data-name="생성일">{capa.createAt}</td>
                                        <td data-name="상태"><span className={capa.status === "Y" ? "use" : "notuse"}>{capa.status === "Y" ? "사용중" : "미사용"}</span></td>
                                    </tr>
                                )) : ""
                        }
                    </tbody>
                    </table>
                    <div className="page">
                        <Paging
                            itemOffset={addFuncListHook.statusValue.pagination.itemOffset}
                            setItemOffset={addFuncListHook.setItemOffset}
                            itemsLength={addFuncListHook.statusValue.addFuncListResponse?.totalCount ?? 0}
                        />
                    </div>
                </div> : <NoActionAlert alertText='등록된 추가기능이 없습니다.' open={true} serverity='warning'/>
            }
        
    </>
  )
}

export default AddFuncList