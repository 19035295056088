import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ITEM_READ_PATH, ITEM_CREATE_PATH } from '../../../../../common/path';
import { useItemListHook } from './ItemList.hook';
import Paging from '../../../../common/Paging';
import NoActionAlert from '../../../../alert/NoActionAlert';

const ItemList = () => {
  const navigator = useNavigate();
  const itemListHook = useItemListHook();

  return (
    <>
        <h2>아이템 관리</h2>
        <Link to={ITEM_CREATE_PATH}><div className="flex flexE" style={{marginBottom:"10px"}}><button className="on">아이템 등록</button></div></Link>
        {
            itemListHook.statusValue.itemListResponse&&
            itemListHook.statusValue.itemListResponse?.mainList.length !== 0 ?
            <>
                <div className="flex spaceB alignC" style={{marginTop: "20px"}}>
                    <div className="search">
                    <select>
                        <option value="all">전체</option>
                        <option value="name" selected>이름</option>
                        <option value="explanation">설명</option>

                    </select>
                    <input type="text" placeholder="검색어를 입력해주세요."/>
                    <button><img src={require("../../../../../img/icon_search_white.png")} alt="검색" /></button>
                    </div>
                    <div className="filter">
                    <select>
                        <option value="all" selected>전체</option>
                        <option value="use">사용</option>
                        <option value="notuse">미사용</option>
                    </select>
                    </div>
                </div>

                <div className="boxContents">
                    <button onClick={itemListHook.onClickDelete}>삭제</button>
                    <table className="table table--block">
                    <thead>
                        <tr>
                        <th><input type='checkbox' onClick={itemListHook.onClickCheckAll}/></th>
                        {/* <th className="tableShort">No</th> */}
                        <th>이름</th>
                        <th>가격</th>
                        {/* <th>총 예약자</th>
                        <th>예약 대기자</th> */}
                        <th>조회수</th>
                        {/* <th>상태</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            itemListHook.statusValue.itemListResponse!.mainList.map((item, idx) => (
                                <tr key={item.code} onClick={() => {navigator(ITEM_READ_PATH, {state: {itemCode: item.code}})}}>
                                    <td onClick={(e) => {e.stopPropagation();}}>
                                        <input type='checkbox' onClick={itemListHook.onClickCheckbox} value={item.code} 
                                            checked={itemListHook.statusValue.itemListDeleteRequest.itemCodeList.includes(item.code)}/>
                                    </td>
                                    {/* <td data-name="No.">{idx}</td> */}
                                    <td data-name="이름">{item.name}</td>
                                    <td data-name="가격">{item.price}</td>
                                    {/* <td data-name="총 에약자"><span>10</span>명</td>
                                    <td data-name="예약 대기자"><span>10</span>명</td> */}
                                    <td data-name="조회수">{item.hits}</td>
                                    {/* <td data-name="상태"><span className="use">사용</span></td> */}
                                </tr>
                            ))
                        }
                    </tbody>
                    </table>
                    <div className="page">
                        <Paging
                            itemOffset={itemListHook.statusValue.pagination.itemOffset}
                            setItemOffset={itemListHook.setItemOffset}
                            itemsLength={itemListHook.statusValue.itemListResponse?.totalCount ?? 0}
                        />
                    </div>
                </div>
            </> : <NoActionAlert alertText='등록된 아이템이 없습니다.' open={true} serverity='warning'/>
        }
        {/* <WaitingListPopup/> */}
    </>
  )
}

export default ItemList