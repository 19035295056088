import React from 'react'
import { useNavigate } from 'react-router-dom'
import { USERASK_READ_PATH } from '../../../../common/path';
import { useUserAskListHook } from './UserAskList.hook';
import Paging from '../../../common/Paging';
import NoActionAlert from '../../../alert/NoActionAlert';

const UserAskList = () => {
  const navigator = useNavigate();
  const userAskListHook = useUserAskListHook();

  return (
    <>
        <h2>1:1 문의</h2>
        {
            userAskListHook.statusValue.userAskListResponse?.totalCount ?
            <>
                <div className="flex spaceB alignC" style={{marginTop: "20px"}}>
                    <div className="search">
                    <select>
                        <option value="all">전체</option>
                        <option value="title" selected>제목</option>
                        <option value="inquirer">문의자</option>
                    </select>
                    <input type="text" placeholder="검색어를 입력해주세요."/>
                    <button><img src={require("../../../../img/icon_search_white.png")} alt="검색" /></button>
                    </div>
                    <div className="filter">
                    <select>
                        <option value="title">제목순</option>
                        <option value="questionDay" selected>문의일순</option>
                        <option value="answerDay">답변일순</option>
                    </select>
                    </div>
                </div>
                <div className="boxContents">
                    <table className="table table--block">
                    <thead>
                        <tr>
                        <th className="tableShort">No</th>
                        <th>유형</th>
                        <th className="tableLong">제목</th>
                        <th>문의자</th>
                        <th>문의일</th>
                        <th>답변자</th>
                        <th>답변일</th>
                        <th>답변여부</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            userAskListHook.statusValue.userAskListResponse?.qnaList.map((qna, index) => (
                                <tr key={qna.qnaCode} onClick={() => {navigator(USERASK_READ_PATH, {state: {qnaCode: qna.qnaCode}})}}>
                                    <td data-name="No.">{index}</td>
                                    <td data-name="유형">유형</td>
                                    <td data-name="제목">{qna.title}</td>
                                    <td data-name="문의자">{qna.name}</td>
                                    <td data-name="문의일">{qna.createAt}</td>
                                    <td data-name="답변자">답변자</td>
                                    <td data-name="답변일">{qna.replyCreateAt ?? "-"}</td>
                                    {
                                        qna.replyCreateAt ? 
                                        <td data-name="답변여부"><span className="use">답변</span></td> 
                                        : <td data-name="답변여부"><span className="notuse">미답변</span></td>
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                    </table>
                    <div className="page">
                        <Paging
                            itemOffset={userAskListHook.statusValue.pagination.itemOffset}
                            setItemOffset={userAskListHook.setItemOffset}
                            itemsLength={userAskListHook.statusValue.totalCount}
                        />
                    </div>
                </div>
            </> 
            : <NoActionAlert alertText='등록된 문의가 없습니다.' open={true} serverity='warning'/>
        }
    </>
  )
}

export default UserAskList