/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useHelpApReadHook } from "../../../../bo/template/helpAp/read/HelpApRead.hook"
import { AppInfoState } from "./AppInfo.model";
import { useAxios } from "../../../../../common/hooks";
import { DEFINE_ALL_CAPABILITY_LIST } from "../../../../../common/const";
import { AddFuncReadResponse } from "../../../../bo/template/addFunc/read/AddFuncRead.model";

export const useAppInfoHook = (setOver?: (flag: boolean) => void) => {
    const axios = useAxios();
    const helpApReadHook = useHelpApReadHook();
    const [statusValue, setStatusValue] = useState<AppInfoState>({});
    const [capaDetailList, setCapaDetailList] = useState<AddFuncReadResponse[]>([]);

    useEffect(() => {
        setStatusValue({
            ...statusValue,
            helpApReadResponse: helpApReadHook.statusValue.helpApReadResponse,
        })
        if(helpApReadHook.statusValue) {
            helpApReadHook.statusValue.helpApReadResponse?.appCapabilityList.forEach(capa => {
                getCapability(capa.capabilityCode);
            })
        }
    }, [helpApReadHook.statusValue.helpApReadResponse]);

    // useEffect(() => {
    //     console.log(capaDetailList);
    // }, [capaDetailList])

    const getCapability = async(capaCode: string) => {
        const response = await axios.getData<AddFuncReadResponse>(DEFINE_ALL_CAPABILITY_LIST, {}, true, [capaCode]);

        setCapaDetailList(capa => [...capa, response.data]);
        if(setOver) {
            setOver(true);
        }
    }



    return {
        statusValue,
        helpApReadHook,
        capaDetailList
    }
}