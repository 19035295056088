import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ITEM_LIST_PATH } from '../../../../../common/path';
import { useItemUpdateHook } from './ItemUpdate.hook';
import Editor from '../../../../common/Editor';
import FileUpload from '../../../../common/FileUpload';

const ItemUpdate = () => {
  const navigator = useNavigate();
  const itemUpdateHook = useItemUpdateHook();

  return (
    <>
        <h2>아이템 관리 수정</h2>

        <div className="boxContents">
        <table className="tableCol">
            <tr>
            <th>이름</th>
            <td>
                <input type="text" 
                    defaultValue={itemUpdateHook.statusValue.itemUpdateRequest.name}
                    value={itemUpdateHook.statusValue.itemUpdateRequest.name}
                    placeholder="이름을 입력하세요."
                    name='name'
                    onChange={itemUpdateHook.onChange}/>
            </td>
            </tr>
            <tr>
            <th>가격</th>
            <td>
                <input type="number" 
                    defaultValue={itemUpdateHook.statusValue.itemUpdateRequest.price}
                    value={itemUpdateHook.statusValue.itemUpdateRequest.price}
                    placeholder="가격을 입력하세요."
                    name='price'
                    onChange={itemUpdateHook.onChange}/>
            </td>
            </tr>
            <tr>
            <th>설명</th>
            <td>
                <div>
                    {
                        itemUpdateHook.statusValue.itemUpdateRequest.content ?
                        <Editor
                            initialData={itemUpdateHook.statusValue.itemUpdateRequest.content}
                            onChange={itemUpdateHook.onChangeContent}
                        /> : ""
                    }
                </div>
            </td>
            </tr>
            <tr>
            <th>이미지 1<br/>(가로 000px * 세로 000px)</th>
                <td>
                    <FileUpload
                        fleGrpId={itemUpdateHook.statusValue.itemUpdateRequest.fileId}
                        afterPost={itemUpdateHook.onChangeFileId}
                    />
                </td>
            </tr>
            {/* <tr>
            <th>사용 여부</th>
            <td>
                <label>
                <input type="radio" name="use" checked/> 사용
                </label>
                <label>
                <input type="radio" name="use"/> 미사용
                </label>
            </td>
            </tr> */}
        </table>
        <div className="btnBoxB">
            <div>
            <button className="off" onClick={() => {navigator(ITEM_LIST_PATH)}}>목록</button>
            {/* <button className="off">저장값 복원</button> */}
            </div>
            <button className="on" onClick={itemUpdateHook.updateItem}>저장</button>
        </div>
        </div>
    </>
  )
}

export default ItemUpdate