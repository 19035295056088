/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react"
import { TemCreateState } from "./TemCreate.model"
import { useAxios } from "../../../../../common/hooks";
import { useTemplateHook } from "../../Template.hook";
import { DEFINE_TEMPLATE_CREATE, RESPONSE_RESULT_SUCCESS } from "../../../../../common/const";
import { useNavigate } from "react-router-dom";
import { TEM_LIST_PATH } from "../../../../../common/path";
import { AlertContext } from "../../../../../common/context";

export const useTemCreateHook = () => {
    const axios = useAxios();
    const templateHook = useTemplateHook();
    const alertContext = useContext(AlertContext);
    const navigator = useNavigate();

    const [statusValue, setStatusValue] = useState<TemCreateState>({
        temCreateRequest: {
            name: "",
            content: "",
            randomType: "BASIC",
            fileId: "",
            gitPath: "",
            categoryList: [],
            color: "",
            status: "Y",
            planFileId: "",
        },
    });

    const onChange = (e:any) => {
        setStatusValue({
            ...statusValue,
            temCreateRequest: {
                ...statusValue.temCreateRequest,
                [e.target.name] : e.target.value,
            }
        })
    }

    const onChangeContent = (data:string) => {
        setStatusValue({
            ...statusValue,
            temCreateRequest: {
                ...statusValue.temCreateRequest,
                content: data,
            }
        })
    }

    const onChangeCate = (e: any) => {
        if(e.target.checked) {
            setStatusValue({
                ...statusValue,
                temCreateRequest: {
                    ...statusValue.temCreateRequest,
                    categoryList: [
                        ...statusValue.temCreateRequest.categoryList, JSON.parse(e.target.value)
                    ]
                }
            })
        }
        else {
            setStatusValue({
                ...statusValue,
                temCreateRequest: {
                    ...statusValue.temCreateRequest,
                    categoryList: 
                        statusValue.temCreateRequest.categoryList.filter(val => val.value !== JSON.parse(e.target.value).value)
                }
            })
        }
    }

    const onChangeFileId = (fileId: string) => {
        setStatusValue({
            ...statusValue,
            temCreateRequest: {
                ...statusValue.temCreateRequest,
                fileId: fileId,
            }
        })
    }

    const onChangePlanFileId = (fileId: string) => {
        setStatusValue({
            ...statusValue,
            temCreateRequest: {
                ...statusValue.temCreateRequest,
                planFileId: fileId,
            }
        })
    }

    const createTemplate = async() => {
        if(!validCreateTemplate()) return;

        const response = await axios.postData(DEFINE_TEMPLATE_CREATE, statusValue.temCreateRequest);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            navigator(TEM_LIST_PATH, {replace: true});
            alertContext.openAlert("템플릿이 생성되었습니다.", "success");
        }
        else {
            alertContext.openAlert(response.message, "error");
        }
    }

    const validCreateTemplate = ():boolean => {
        if(!statusValue.temCreateRequest.name) {
            alertContext.openAlert("템플릿의 이름은 필수값입니다.", "warning");
            return false;
        }
        if(statusValue.temCreateRequest.categoryList.length === 0) {
            alertContext.openAlert("템플릿의 앱용도는 필수값입니다.", "warning");
            return false;
        }
        if(!statusValue.temCreateRequest.gitPath) {
            alertContext.openAlert("git url은 필수값입니다.", "warning");
            return false;
        }
        if(!statusValue.temCreateRequest.content) {
            alertContext.openAlert("템플릿의 설명은 필수값입니다.", "warning");
            return false;
        }
        if(!statusValue.temCreateRequest.fileId) {
            alertContext.openAlert("템플릿의 이미지는 필수값입니다.", "warning");
            return false;
        }
        // if(!statusValue.temCreateRequest.planFileId) {
        //     alertContext.openAlert("템플릿의 기획서는 필수값입니다.", "warning");
        //     return false;
        // }
        if(!statusValue.temCreateRequest.color) {
            alertContext.openAlert("템플릿의 색상은 필수값입니다.", "warning");
            return false;
        }

        return true;
    }

    return {
        onChange,
        onChangeContent,
        onChangeCate,
        createTemplate,
        templateHook,
        statusValue,
        onChangeFileId,
        onChangePlanFileId
    }
}