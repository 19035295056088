/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useAxios } from "../../../../../common/hooks";
import { DEFINE_TEMPLATE_LIST, RESPONSE_RESULT_SUCCESS } from "../../../../../common/const";
import { fn_Debug } from "../../../../../common/module";
import { AppUseCate, TemListResponse, TemListState } from "./TemList.model";
import { ITEMS_PER_PAGE } from "../../../../../common/config";

export const useTemListHook = () => {
    const axios = useAxios();

    const [statusValue, setStatusValue] = useState<TemListState>({
        temListRequest: {
            pageable: {
                page: 0,
                size: ITEMS_PER_PAGE
            }
        },
        pagination: {
            itemOffset: 0,
        },
        isImgPop: false,
        previewImgList: [],
    });

    useEffect(() => {
        getTemplateList();
    }, [statusValue.temListRequest.pageable.page])

    const getTemplateList = async() => {
        const response = await axios.getData<TemListResponse>(DEFINE_TEMPLATE_LIST, {params: statusValue.temListRequest.pageable});

        fn_Debug("GET_TEMPLATELIST_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setStatusValue({
                ...statusValue,
                temListResponse: response.data
            })
        }
    }

    const getRecommandName = (type: string):string => {
        return type === "Y" ? "랜덤추천" : "기본추천";
    }


    const setItemOffset = (newOffset: number) => {
        setStatusValue({
            ...statusValue,
            temListRequest: {
                ...statusValue.temListRequest,
                pageable: {
                    ...statusValue.temListRequest.pageable,
                    page: newOffset / ITEMS_PER_PAGE,
                    size: ITEMS_PER_PAGE,
                }
            },
            pagination: {
                itemOffset: newOffset
            }
        })
    }

    const convertAddFuncAppCateName = (capa: AppUseCate[]) => {
        const arr:string[] = [];

        for(let i in capa) {
            arr.push(capa[i].name);
        }

        return arr.join(",");
    }

    const openPreviewPopup = (e:any, url: string[]) => {
        e.stopPropagation();
        setStatusValue({
            ...statusValue,
            isImgPop: true,
            previewImgList: url,
        })
    }

    const closePreviewPopup = () => {
        setStatusValue({
            ...statusValue,
            isImgPop: false,
        })
    }

    return {
        getRecommandName,
        setItemOffset,
        statusValue,
        convertAddFuncAppCateName,
        openPreviewPopup,
        closePreviewPopup
    }
}