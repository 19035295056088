/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { DEFINE_SIMAPP_LIST_TAG, RESPONSE_RESULT_SUCCESS } from "../../../../common/const";
import { useAxios } from "../../../../common/hooks"
import { useHelpApReadHook } from "../../../bo/template/helpAp/read/HelpApRead.hook";
import { SimAppModel } from "../../../bo/template/simApp/SimApp.model";

export const useSimAppInfoHook = (setOver?: (flag: boolean) => void) => {
    const axios = useAxios();
    const helpApReadHook = useHelpApReadHook();
    const [simAppList, setSimAppList] = useState<SimAppModel[]>([]);

    useEffect(() => {
        if(helpApReadHook.statusValue.helpApReadResponse) {
            getSimAppList();
        }
    }, [helpApReadHook.statusValue.helpApReadResponse])

    const getSimAppList = async() => {
        const response = await axios.getData<SimAppModel[]>(DEFINE_SIMAPP_LIST_TAG, {params: {category: helpApReadHook.statusValue.helpApReadResponse?.appCategory ?? ""}});

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setSimAppList(response.data);

            if(setOver) {
                setOver(true);
            }
        }
    }

    return {
        simAppList
    }
}