import React from 'react'
import Editor from '../../../../common/Editor'
import { useAddFuncUpdateHook } from './AddFuncUpdate.hook'
import FileUpload from '../../../../common/FileUpload';

const AddFuncUpdate = () => {
    const addFuncUpdateHook = useAddFuncUpdateHook();

    return (
        <>
            <h2>추가기능 관리 수정</h2>

            <div className="boxContents">
                <table className="tableCol">
                    <tr>
                        <th>앱용도</th>
                        <td>
                            {
                                addFuncUpdateHook.statusValue.oriCategoryList ? addFuncUpdateHook.statusValue.oriCategoryList!.map(cate => (
                                    <label>
                                        <input 
                                            type="checkbox" 
                                            name="categoryList" 
                                            value={JSON.stringify(cate)} 
                                            onClick={addFuncUpdateHook.onChangeCate}
                                            defaultChecked={addFuncUpdateHook.appUseDefaultChecked(cate)}/>
                                        <span>{cate.name}</span>
                                    </label>
                                )) : ""
                            }
                        </td>
                    </tr>
                    <tr>
                        <th>이름</th>
                        <td>
                            <input 
                                type="text" 
                                placeholder="이름을 입력하세요." 
                                name='name'
                                onChange={addFuncUpdateHook.onChange}
                                value={addFuncUpdateHook.statusValue.addFuncUpdateReqeust.name}/>
                        </td>
                    </tr>
                    <tr>
                        <th>설명</th>
                        <td>
                            <div>
                                <Editor placeholder="소개문구를 입력하세요" 
                                    initialData={addFuncUpdateHook.statusValue.addFuncUpdateReqeust.content}
                                    onChange={addFuncUpdateHook.onChangeContent}/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>예제 이미지 <br />(가로 270px * 세로130px)</th>
                        <td>
                            <FileUpload
                                afterPost={addFuncUpdateHook.onChangeFileId}
                                maxFile={3}
                                fleGrpId={addFuncUpdateHook.statusValue.addFuncUpdateReqeust.fileId}
                                required={true}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>AI 생성 표준 프롬프트<br /><button>복사</button></th>
                        <td>
                            <div>
                            <Editor placeholder="해당 기능 소스를 AI로 자동 생성시킬 표준 프롬프트를 입력하세요" 
                                    initialData={addFuncUpdateHook.statusValue.addFuncUpdateReqeust.prompt}
                                    onChange={addFuncUpdateHook.onChangePrompt}/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>AI 생성 표준 소스<br /><button>복사</button></th>
                        <td>
                            <div>
                            <Editor placeholder="AI로 자동 생성된 표준 소스를 입력하세요" 
                                    initialData={addFuncUpdateHook.statusValue.addFuncUpdateReqeust.ai}
                                    onChange={addFuncUpdateHook.onChangeAi}/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>사용 여부</th>
                        <td>
                            <label>
                                <input 
                                    type="radio" 
                                    name="status" 
                                    value="Y"
                                    onClick={addFuncUpdateHook.onChange}
                                    defaultChecked={addFuncUpdateHook.statusValue.addFuncUpdateReqeust.status === "Y"}/>
                                <span>사용</span>
                            </label>
                            <label>
                                <input 
                                    type="radio" 
                                    name="status" 
                                    value="N" 
                                    onClick={addFuncUpdateHook.onChange}
                                    defaultChecked={addFuncUpdateHook.statusValue.addFuncUpdateReqeust.status === "N"}/>
                                <span>미사용</span>
                            </label>
                        </td>
                    </tr>
                </table>
                <div className="btnBoxB">
                    <div>
                        <button className="off">목록</button>
                        {/* <button className="off">저장값 복원</button> */}
                    </div>
                    <button className="on" onClick={addFuncUpdateHook.updateAddFunc}>저장</button>
                </div>
            </div>
        </>
    )
}

export default AddFuncUpdate