import { useState, useEffect } from 'react'
import { Outlet, useNavigate, useLocation,  } from 'react-router-dom'
import { PLANNING_APPINFO_PATH, PLANNING_COMPANYINFO_PATH, PLANNING_SIMAPPINFO_PATH } from '../../../common/path';
import { AlertProvider, LoadingProvider } from '../../../common/context';
import { fn_Debug } from '../../../common/module';

const PlanningLayout = () => {
  const location = useLocation();
  const navigator = useNavigate();

  const [ScrollY, setScrollY] = useState(0);
  const [BtnStatus, setBtnStatus] = useState(false); // 버튼 상태

  const handleFollow = () => {
    setScrollY(window.pageYOffset);
    if(ScrollY > 100) {
      // 100 이상이면 버튼이 보이게
      setBtnStatus(true);
    } else {
      // 100 이하면 버튼이 사라지게
      setBtnStatus(false);
    }
  }

  const handleTop = () => {  // 클릭하면 스크롤이 위로 올라가는 함수
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    setScrollY(0);  // ScrollY 의 값을 초기화
    setBtnStatus(false); // BtnStatus의 값을 false로 바꿈 => 버튼 숨김
  }

  useEffect(() => {
    const watch = () => {
      window.addEventListener('scroll', handleFollow)
    }
    watch();
    return () => {
      window.removeEventListener('scroll', handleFollow)
    }
  })

  const onclickPdfPopupOpen = () => {
    fn_Debug(process.env.REACT_APP_PDF_URL + " open!!");
    window.open(process.env.REACT_APP_PDF_URL, "popup", "popup");
  }

  return (
    <div className="PlanningWrap">
        <div className='header'>
        <h1 className="logo">
            <img src={require("../../../img/Ai.app_logo.png")} alt="로고 이미지" />
        </h1>
            <ul className="gnb" >
                <li className={location.pathname.includes(PLANNING_COMPANYINFO_PATH) ? "on" : "" } onClick={() => {navigator(PLANNING_COMPANYINFO_PATH)}}>회사 정보</li>
                <li className={location.pathname.includes(PLANNING_APPINFO_PATH) ? "on" : "" } onClick={() => {navigator(PLANNING_APPINFO_PATH)}}>앱 정보</li>
                <li className={location.pathname.includes(PLANNING_SIMAPPINFO_PATH) ? "on" : "" } onClick={() => {navigator(PLANNING_SIMAPPINFO_PATH)}}>유사앱 정보</li>
            </ul>
        <div className="address">
            <h2>Office address.</h2>
            <p>부산광역시 동구 중앙대로 314, 3층 4호</p>
            <p>mbaas.help@gmail.com</p>
        </div>
        </div>
        <LoadingProvider>
            <AlertProvider>
                <div className='PlannginWrap'>
                    <div className='main'>
                        <div className="mainTit">
                            <h2>AiApp 온라인 기획서</h2>
                            {/* <div>
                                <div>
                                <h3>Proposal No.</h3>
                                <p>0000 0000 0001</p>
                                </div>
                                <div>
                                <h3>Proposal Date.</h3>
                                <p>2023.09.20</p>
                                </div>
                            </div> */}
                          <div className='pdfBtn' onClick={onclickPdfPopupOpen}><button>PDF로 다운로드</button></div>
                        </div>
                        <Outlet/>
                    </div>
                </div>
            </AlertProvider>
        </LoadingProvider>

        {/* <div className='footer'>
        <div className="btnWrap">
            <button>임시저장</button>
            <button className="on">저장</button>
        </div>
        </div> */}
        <button 
        className={BtnStatus ? "topBtn active" : "topBtn"} // 버튼 노출 여부
        onClick={handleTop}  // 버튼 클릭시 함수 호출
        >TOP</button>
    </div>
  )
}

export default PlanningLayout