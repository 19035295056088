/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { UserAskReadResponse, UserAskReadState } from "./UserAskRead.model";
import { useLocation } from "react-router-dom";
import { DEFINE_USERASK_APP_READ, DEFINE_USERASK_READ, MBAAS_APP_CODE, RESPONSE_RESULT_SUCCESS } from "../../../../common/const";
import { fn_Debug } from "../../../../common/module";
import { useAxios } from "../../../../common/hooks";

export const useUserAskReadHook = () => {
    const location = useLocation();

    const [statusValue, setStatusValue] = useState<UserAskReadState>({
        userAskReadRequest: {
            qnaCode: location.state["qnaCode"],
        }
    });
    const axios = useAxios();

    useEffect(() => {
        getUserAskRead();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        fn_Debug("USERASK_READ_STATE");
        fn_Debug(statusValue);
    }, [])

    const getUserAskRead = async() => {
        const response = await axios.getData<UserAskReadResponse>(
            sessionStorage.getItem(MBAAS_APP_CODE) ? DEFINE_USERASK_APP_READ : DEFINE_USERASK_READ, {}, true, 
            sessionStorage.getItem(MBAAS_APP_CODE) ? [sessionStorage.getItem(MBAAS_APP_CODE)!, statusValue.userAskReadRequest.qnaCode] : [statusValue.userAskReadRequest.qnaCode]
            )
        fn_Debug("USERASK_READ_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setStatusValue({
                ...statusValue,
                userAskReadResponse: response.data,
            });
        }
    }

    return {
        statusValue,
        getUserAskRead,
    }
}