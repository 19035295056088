import React from 'react'
import { useNavigate } from 'react-router-dom'
import parse from 'html-react-parser';
import { SIM_APP_LIST_PATH, SIM_APP_UPDATE_PATH } from '../../../../../common/path';
import { useSimAppReadHook } from './SimAppRead.hook';
import Slider from 'react-slick';

const SimAppRead = () => {
  const navigator = useNavigate();
  const simAppReadHook = useSimAppReadHook();

  return (
    <>
        <h2>유사앱 관리 상세</h2>
            
        <div className="boxContents">
            <table className="tableCol">
                <tr>
                <th>이름</th>
                <td>{simAppReadHook.statusValue.simAppReadResponse?.name}</td>
                </tr>
                <tr>
                <th>앱용도</th>
                <td>{simAppReadHook.printCategory()}</td>
                </tr>
                <tr>
                <th>설명</th>
                <td>{parse(simAppReadHook.statusValue.simAppReadResponse?.content ?? "")}</td>
                </tr>
                <tr>
                <th>이미지<br/>(가로 360px * 세로360px)</th>
                <td className="temImgWrap">
                    {
                      simAppReadHook.statusValue.simAppReadResponse?.fileUrl ?
                      <ul className="temImg" style={{height:"260px"}}>
                        <Slider>
                        {
                          simAppReadHook.statusValue.simAppReadResponse.fileUrl.map(url => (
                            <li><img src={url} alt={url}/></li>
                          ))
                        } 
                        </Slider>
                      </ul> : ""
                    } 
                </td>
                </tr>
                <tr>
                <th>추천 유형</th>
                <td>{simAppReadHook.statusValue.simAppReadResponse?.randomType === "RANDOM" ? "랜덤추천" : "기본추천"}</td>
                </tr>
                <tr>
                <th>사용 여부</th>
                <td>{simAppReadHook.statusValue.simAppReadResponse?.status === "Y" ? "사용중" : "미사용"}</td>
                </tr>
            </table>
            <div className="btnBoxB">
            <button className="off" onClick={() => {navigator(SIM_APP_LIST_PATH)}}>목록</button>
            <button className="on" onClick={() => {navigator(SIM_APP_UPDATE_PATH, {state: {simApp: simAppReadHook.statusValue.simAppReadResponse}})}}>수정</button>
            </div>
        </div>
    </>
  )
}

export default SimAppRead