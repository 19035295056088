import React from 'react'

const ApUpdateT2 = () => {
  return (
    <>
        <table className="tableCol">
        <tr>
        <th>템플릿</th>
        <td>
            <ul className="temFlex">
            <li>
                <label>
                <input type="radio" name="simTem"/> 템플릿명
                </label>
                <ul className="temImg">
                <li><img src={require("../../../../../img/slickimg1.png")} alt="템플릿1"/></li>
                <li><img src={require("../../../../../img/slickimg2.png")} alt="템플릿2"/></li>
                <li><img src={require("../../../../../img/slickimg3.png")} alt="템플릿3"/></li>
                </ul>
                <p>템플릿 설명</p>
            </li>
            <li>
                <label>
                <input type="radio" name="temSelct"/> 템플릿명
                </label>
                <ul className="temImg">
                <li><img src={require("../../../../../img/slickimg1.png")} alt="템플릿1"/></li>
                <li><img src={require("../../../../../img/slickimg2.png")} alt="템플릿2"/></li>
                <li><img src={require("../../../../../img/slickimg3.png")} alt="템플릿3"/></li>
                </ul>
                <p>템플릿 설명</p>
            </li>
            <li>
                <label>
                <input type="radio" name="temSelct"/> 템플릿명
                </label>simTem
                <ul className="temImg">
                <li><img src={require("../../../../../img/slickimg1.png")} alt="템플릿1"/></li>
                <li><img src={require("../../../../../img/slickimg2.png")} alt="템플릿2"/></li>
                <li><img src={require("../../../../../img/slickimg3.png")} alt="템플릿3"/></li>
                </ul>
                <p>템플릿 설명</p>
            </li>
            </ul>
        </td>
        </tr>
        <tr>
        <th>기획서</th>
        <td><input type="file"/>&nbsp;&nbsp;<button>기획서 업로드</button>&nbsp;&nbsp;<button>기획서 관리 바로가기</button></td>
        </tr>
        <tr>
        <th>유사앱 추천</th>
        <td>
            <ul className="temFlex">
            <li>
                <label>
                <input type="radio" name="simTem"/> 템플릿명
                </label>
                <ul className="temImg">
                <li><img src={require("../../../../../img/slickimg1.png")} alt="템플릿1"/></li>
                <li><img src={require("../../../../../img/slickimg2.png")} alt="템플릿2"/></li>
                <li><img src={require("../../../../../img/slickimg3.png")} alt="템플릿3"/></li>
                </ul>
                <p>템플릿 설명</p>
            </li>
            <li>
                <label>
                <input type="radio" name="simTem"/> 템플릿명
                </label>
                <ul className="temImg">
                <li><img src={require("../../../../../img/slickimg1.png")} alt="템플릿1"/></li>
                <li><img src={require("../../../../../img/slickimg2.png")} alt="템플릿2"/></li>
                <li><img src={require("../../../../../img/slickimg3.png")} alt="템플릿3"/></li>
                </ul>
                <p>템플릿 설명</p>
            </li>
            <li>
                <label>
                <input type="radio" name="simTem"/> 템플릿명
                </label>
                <ul className="temImg">
                <li><img src={require("../../../../../img/slickimg1.png")} alt="템플릿1"/></li>
                <li><img src={require("../../../../../img/slickimg2.png")} alt="템플릿2"/></li>
                <li><img src={require("../../../../../img/slickimg3.png")} alt="템플릿3"/></li>
                </ul>
                <p>템플릿 설명</p>
            </li>
            <li>
                <label>
                <input type="radio" name="simTem"/> 템플릿명
                </label>
                <ul className="temImg">
                <li><img src={require("../../../../../img/slickimg1.png")} alt="템플릿1"/></li>
                <li><img src={require("../../../../../img/slickimg2.png")} alt="템플릿2"/></li>
                <li><img src={require("../../../../../img/slickimg3.png")} alt="템플릿3"/></li>
                </ul>
                <p>템플릿 설명</p>
            </li>
            <li>
                <label>
                <input type="radio" name="simTem"/> 템플릿명
                </label>
                <ul className="temImg">
                <li><img src={require("../../../../../img/slickimg1.png")} alt="템플릿1"/></li>
                <li><img src={require("../../../../../img/slickimg2.png")} alt="템플릿2"/></li>
                <li><img src={require("../../../../../img/slickimg3.png")} alt="템플릿3"/></li>
                </ul>
                <p>템플릿 설명</p>
            </li>
            </ul>
        </td>
        </tr>
        </table>
    </>
  )
}

export default ApUpdateT2