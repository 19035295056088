import { RESPONSE_RESULT_SUCCESS } from './../../../../../common/const';
import { useContext, useEffect, useState } from "react"
import { ItemReadResponse, ItemReadState } from "./ItemRead.model"
import { DEFINE_ITEM_READ, MBAAS_APP_CODE } from "../../../../../common/const"
import { useLocation } from "react-router-dom"
import { useAxios } from "../../../../../common/hooks"
import { fn_Debug } from "../../../../../common/module"
import { AlertContext } from '../../../../../common/context';

export const useItemReadHook = () => {
    const location = useLocation();
    const alertContext = useContext(AlertContext);
    const axios = useAxios();
    const [statusValue, setStatusValue] = useState<ItemReadState>({
        itemReadRequest: {
            appCode: sessionStorage.getItem(MBAAS_APP_CODE) ?? "",
            itemCode: location.state.itemCode,
        }
    });

    useEffect(() => {
        getItemRead();
    }, [])

    const getItemRead = async() => {
        const response = await axios.getData<ItemReadResponse>(
            DEFINE_ITEM_READ, 
            {params: {appCode: statusValue.itemReadRequest.appCode}}, true, [statusValue.itemReadRequest.itemCode]
        );

        fn_Debug("ITEM_READ_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setStatusValue({
                ...statusValue,
                itemReadResponse: response.data
            });
        } else {
            alertContext.openAlert(response.message, "error");
        }
    }

    return {
        statusValue,
    }
}