import { useContext, useState } from "react"
import { SimAppCreateState } from "./SimAppCreate.model"
import { useTemplateHook } from "../../Template.hook";
import { useAxios } from "../../../../../common/hooks";
import { AlertContext } from "../../../../../common/context";
import { DEFINE_SIMAPP_CREATE, RESPONSE_RESULT_SUCCESS } from "../../../../../common/const";
import { fn_Debug } from "../../../../../common/module";
import { useNavigate } from "react-router-dom";

export const useSimAppCreateHook = () => {
    const templateHook = useTemplateHook();
    const axios = useAxios();
    const alertContext = useContext(AlertContext);
    const navigator = useNavigate();
    const [statusValue, setStatusValue] = useState<SimAppCreateState>({
        simAppCreateRequest: {
            name: "",
            content: "",
            categoryList: [],
            fileId: "",
            randomType: "BASIC",
            status: "Y",
        }
    });

    const onChange = (e:any) => {
        setStatusValue({
            ...statusValue,
            simAppCreateRequest: {
                ...statusValue.simAppCreateRequest,
                [e.target.name] : e.target.value
            }
        })
    }

    const onChangeCate = (e: any) => {
        if(e.target.checked) {
            setStatusValue({
                ...statusValue,
                simAppCreateRequest: {
                    ...statusValue.simAppCreateRequest,
                    categoryList: [
                        ...statusValue.simAppCreateRequest.categoryList, JSON.parse(e.target.value)
                    ]
                }
            })
        }
        else {
            setStatusValue({
                ...statusValue,
                simAppCreateRequest: {
                    ...statusValue.simAppCreateRequest,
                    categoryList: 
                        statusValue.simAppCreateRequest.categoryList.filter(val => val.value !== JSON.parse(e.target.value).value)
                }
            })
        }
    }

    const onChangeContent = (data:string) => {
        setStatusValue({
            ...statusValue,
            simAppCreateRequest: {
                ...statusValue.simAppCreateRequest,
                content: data,
            }
        })
    }

    const createSimApp = async() => {
        if(!validCreateSimApp()) return;

        const response = await axios.postData(DEFINE_SIMAPP_CREATE, statusValue.simAppCreateRequest);

        fn_Debug("CREATE_SIMAPP_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            alertContext.openAlert("유사앱이 생성되었습니다.", "success");
            navigator(-1);
        } else {
            alertContext.openAlert(response.message, "error");
        }
    }

    const onChangeFileId = (fileId: string) => {
        setStatusValue({
            ...statusValue,
            simAppCreateRequest: {
                ...statusValue.simAppCreateRequest,
                fileId: fileId,
            }
        })
    }

    const validCreateSimApp = ():boolean => {
        if(!statusValue.simAppCreateRequest.name) {
            alertContext.openAlert("유사앱 이름은 필수입니다.", "warning");
            return false;
        }
        if(statusValue.simAppCreateRequest.categoryList.length === 0) {
            alertContext.openAlert("유사앱 앱형식은 필수입니다.", "warning");
            return false;
        }
        if(!statusValue.simAppCreateRequest.content) {
            alertContext.openAlert("유사앱 설명은 필수입니다.", "warning");
            return false;
        }
        if(!statusValue.simAppCreateRequest.fileId) {
            alertContext.openAlert("유사앱 예제이미지는 필수입니다.", "warning");
            return false;
        }

        return true;
    }

    return {
        statusValue,
        onChange,
        templateHook,
        onChangeCate,
        onChangeContent,
        createSimApp,
        onChangeFileId
    }
}