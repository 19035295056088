/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react"
import { ReserveListResponse, ReserveListState } from "./ReserveList.model"
import { useAxios } from "../../../../../common/hooks";
import { AlertContext } from "../../../../../common/context";
import { useNavigate } from "react-router-dom";
import { DEFINE_RESERVE_DELETE, DEFINE_RESERVE_LIST, MBAAS_APP_CODE, RESPONSE_RESULT_SUCCESS } from "../../../../../common/const";
import { fn_Debug } from "../../../../../common/module";
import { ITEMS_PER_PAGE } from "../../../../../common/config";

export const useReserveListHook = () => {
    const axios = useAxios();
    const alertContext = useContext(AlertContext);
    const navigator = useNavigate();

    const [statusValue, setStatusValue] = useState<ReserveListState>({
        reserveListRequest: {
            pageable: {
                page: 0,
                size: ITEMS_PER_PAGE,
            }
        },
        pagination: {
            itemOffset: 0,
        }
    });

    const [deleteIdxes, setDeleteIdxes] = useState<string[]>([]);

    useEffect(() => {
        getReserveList();
    }, [])

    const setItemOffset = (newOffset: number) => {
        setStatusValue({
            ...statusValue,
            reserveListRequest: {
                ...statusValue.reserveListRequest,
                pageable: {
                    ...statusValue.reserveListRequest.pageable,
                    page: newOffset / ITEMS_PER_PAGE,
                    size: ITEMS_PER_PAGE,
                }
            },
            pagination: {
                itemOffset: newOffset
            }
        })
    }

    const getReserveList = async() => {
        const response = await axios.getData<ReserveListResponse>(DEFINE_RESERVE_LIST, 
            {params: {...statusValue.reserveListRequest.pageable, appCode: sessionStorage.getItem(MBAAS_APP_CODE)}}
            );

        fn_Debug("RESPONSE_GET_RESERVE_LIST");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setStatusValue({
                ...statusValue,
                reserveListResponse: response.data,
            })
        }
    }

    const onCheck = (e: any) => {
        e.stopPropagation();
        if(e.target.checked) {
            setDeleteIdxes([...deleteIdxes, e.target.value]);
        }
        else {
            setDeleteIdxes(deleteIdxes.filter(dr => dr !== e.target.value));
        }
    }

    const onCheckAll = (e:any) => {
        let codeList = [];
        const mainList = statusValue.reserveListResponse?.mainList!;

        if(e.target.checked) {
            setDeleteIdxes([]);
            for(let idx in mainList) {
                codeList.push(mainList[idx].code);
            }
            setDeleteIdxes([...codeList]);
        } else {
            setDeleteIdxes([]);
        }
        
    }
    
    const validOnClickDelete = ():boolean => {
        if(deleteIdxes.length === 0) {
            alertContext.openAlert("삭제할 강의를 선택해주세요.", "warning");
            return false;
        }

        return true;
    }

    const onClickDelete = async() => {
        if(!validOnClickDelete()) return;
        const response = await axios.deleteData(DEFINE_RESERVE_DELETE, {data: {oneDayCodeList: deleteIdxes, appCode: sessionStorage.getItem(MBAAS_APP_CODE)}});

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            alertContext.openAlert("선택한 강의가 삭제되었습니다.", "success");
            getReserveList();
        } else {
            alertContext.openAlert(response.message, "error");
        }
    }

    const getStatus = (status: string): string => {
        if(status === "PREPARE") {
            return "수업준비중";
        } else if(status === "ON_CLASS") {
            return "수업개강";
        } else {
            return "수업종료";
        }
    }

    const getStatusClassName = (status: string): string => {
        if(status === "PREPARE") {
            return "use";
        } else if(status === "ON_CLASS") {
            return "use";
        } else {
            return "use";
        }
    }

    return {
        statusValue,
        setItemOffset,
        onCheck,
        onCheckAll,
        deleteIdxes,
        onClickDelete,
        getStatus,
        getStatusClassName
    }
}