import React from 'react'
import { useNavigate } from 'react-router-dom'
import { M_ADMIN_READ_PATH } from '../../../../../../common/path';
import { useMAdminListHook } from './MAdminList.hook';
import Paging from '../../../../../common/Paging';
import { getMemberStatus, getMemberStatusStr } from '../../../../../../common/module';
import NoActionAlert from '../../../../../alert/NoActionAlert';

const MAdminList = () => {
  const navigator = useNavigate();
  const mAdminListHook = useMAdminListHook();

  return (
    <>
        <ul className="tapContents">
            <li>
                <div className="search">
                    <select name="type" onChange={mAdminListHook.onChange}>
                        <option value="membername" selected>이름</option>
                        <option value="memberemail">아이디</option>
                        <option value="membername">회사명</option>
                        <option value="membername">앱 이름</option>
                    </select>
                    <input type="text" name='keyword' onChange={mAdminListHook.onChange} placeholder="검색어를 입력해주세요."/>
                    <button onClick={mAdminListHook.getAdminList}><img src={require("../../../../../../img/icon_search_white.png")} alt="검색"/></button>
                </div>
                <div className="filter">
                    <select name="status" onChange={mAdminListHook.onChange}>
                        <option value="" selected>전체</option>
                        <option value="GREEN">활동</option>
                        <option value="BLUE">휴면</option>
                        <option value="RED">탈퇴</option>
                        <option value="YELLOW">사용중지</option>
                        <option value="BLACK">강퇴</option>
                    </select>
                    <select name="sort" onChange={mAdminListHook.onChange}>
                        <option value="" selected>가입일순</option>
                        <option value="membername">이름순</option>
                    </select>
                </div>
                {
                    mAdminListHook.statusValue.mAdminListResponse?.memberInfoList?.length !== 0 ? 
                    <div>
                        <table className="table table--block">
                            <thead>
                                <tr>
                                    <th style={{width:"10%"}}>No</th>
                                    <th>이름</th>
                                    <th>아이디(이메일)</th>
                                    <th>부서</th>
                                    <th>연락처</th>
                                    <th>권한등급</th>
                                    <th>가입일</th>
                                    <th>상태</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    mAdminListHook.statusValue.mAdminListResponse?.memberInfoList?.map((admin, idx) => (
                                        <tr style={{cursor:"pointer"}} 
                                            onClick={() => {
                                                navigator(M_ADMIN_READ_PATH, {state: {memberCode: admin.memberCode}})
                                            }}
                                        >
                                            <td data-name="No.">{idx}</td>
                                            <td data-name="이름">{admin.memberName}</td>
                                            <td data-name="아이디">{admin.memberEmail}</td>
                                            <td data-name="부서">총괄</td>
                                            <td data-name="연락처">010-0000-0000</td>
                                            <td data-name="권한등급">슈퍼관리자</td>
                                            <td data-name="가입일">YYYY-MM-DD</td>
                                            <td data-name="상태"><span className={getMemberStatus(admin.status)}>{getMemberStatusStr(admin.status)}</span></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div> : <NoActionAlert alertText='조회된 회원이 없습니다.' open={true} serverity='warning' />
                }
                <div className="page">
                <Paging
                    itemOffset={mAdminListHook.statusValue.pagination.itemOffset}
                    setItemOffset={mAdminListHook.setItemOffset}
                    itemsLength={mAdminListHook.statusValue.totalCount}
                />
                </div>
            </li>
        </ul>
    </>
  )
}

export default MAdminList