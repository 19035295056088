/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { M_MEMBER_READ_PATH } from '../../../../../../common/path';
import { useMMemberListHook } from './MMemList.hook';
import Paging from '../../../../../common/Paging';
import { getMemberStatus, getMemberStatusStr } from '../../../../../../common/module';
import NoActionAlert from '../../../../../alert/NoActionAlert';

const MMemberList = () => {
  const naviagtor = useNavigate();
  const memListHook = useMMemberListHook();
  
  return (
    <>
      <ul className="tapContents">
        <li>
            <div className="search">
                <select name="type" onChange={memListHook.onChange}>
                    <option value="">전체</option>
                    <option value="membername" selected>이름</option>
                    <option value="memberemail">아이디</option>
                    <option value="membername">회사명</option>
                    <option value="membername">앱 이름</option>
                </select>
                <input type="text" name='keyword' onChange={memListHook.onChange} placeholder="검색어를 입력해주세요."/>
                <button onClick={memListHook.getMemberList}><img src={require("../../../../../../img/icon_search_white.png")} alt="검색"/></button>
            </div>
            <div className="filter">
                <select name="status" onChange={memListHook.onChange}>
                    <option value="" selected>전체</option>
                    <option value="GREEN">활동</option>
                    <option value="BLUE">휴면</option>
                    <option value="RED">탈퇴</option>
                    <option value="YELLOW">사용중지</option>
                    <option value="BLACK">강퇴</option>
                </select>
                <select name="sort" onChange={memListHook.onChange}>
                    <option value="" selected>가입일순</option>
                    <option value="membername">이름순</option>
                </select>
            </div>
            {
                memListHook.statusValue.mMemListResponse?.length !== 0 ?
                <div>
                    <table className="table table--block">
                        <thead>
                            <tr>
                                <th style={{width: "10%"}}>No</th>
                                <th>이름</th>
                                <th>아이디</th>
                                {/* <th className="tableLong">생성앱</th> */}
                                {/* <th>회사명</th> */}
                                {/* <th>앱회원수</th> */}
                                <th>가입일</th>
                                <th>상태</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            memListHook.statusValue.mMemListResponse?.map((member, index) => (
                                <tr key={member.memberCode}  onClick={() => {naviagtor(M_MEMBER_READ_PATH, {state: {memberCode: member.memberCode}})}}>
                                    <td data-name="No.">{index}</td>
                                    <td data-name="이름">{member.memberName}</td>
                                    <td data-name="아이디">{member.memberEmail}</td>
                                    {/* <td>
                                        {
                                            member.appResponseList.map((app, appIndex) => (
                                                <>
                                                    <span className="tdLink">{app.appName} (app.appCode)</span><br/>
                                                </>
                                            ))
                                        }
                                    </td> */}
                                    {/* <td>회사명</td> */}
                                    {/* <td>
                                        <span className="tdLink">50</span><br/>
                                        <span className="tdLink">60</span><br/>
                                    </td> */}
                                    <td data-name="가입일">{member.createDate}</td>
                                    {/* act : 활동,  sleep: 휴면, wthdr: 탈퇴, stop: 사용중지, fire: 강퇴 */}
                                    <td data-name="상태"><span className={getMemberStatus(member.status)}>{getMemberStatusStr(member.status)}</span></td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div> : <NoActionAlert alertText='조회된 회원이 없습니다.' open={true} serverity='warning'/>
            }
            <div className="page">
                <Paging
                    itemOffset={memListHook.statusValue.pagination.itemOffset}
                    setItemOffset={memListHook.setItemOffset}
                    itemsLength={memListHook.statusValue.totalCount}
                />
            </div>
        </li>
      </ul>  
    </>
  )
}

export default MMemberList