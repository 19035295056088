import { useState } from "react"

export const useUpdateTabHook = () => {
    const [currIdx, setCurrIdx] = useState<number>(1);

    const onClick = (idx: number) => {
        setCurrIdx(idx);
    }

    return {currIdx, onClick};
}