import { Link } from 'react-router-dom'
import { FIND_PW_PATH } from '../../../common/path';
import { LOGIN_EMAIL_NAME, LOGIN_PASSWORD_NAME } from './Login.const';
import { useLoginHook } from './Login.hooks';
import DefaultPopup from '../../alert/DefaultPopup';
import LoginErrorPopup from '../../popup/LoginErrorPopup';
import { fn_BlockSubmit } from '../../../common/module';

const Login = () => {
  const loginHook = useLoginHook()

  return (
    <>
        <div className="wrap" style={{background:"#fff"}}>
            <div className="loginWrap flex alignC">
                <div className="login_left">
                    <h1><a href="https://www.mbaas.shop/"><img src={require("../../../img/mbaas_shop_bi_white.png")} alt="엠바스 로고"/></a></h1>
                    <a className="link" href="https://www.mbaas.shop/" target='_blank' rel="noreferrer">mBaas 홈페이지로 이동<br/><span>mbaas.shop</span></a>
                </div>
                <div className="login_right">
                    <h2>mBaaS.shop 관리자 로그인</h2>
                    <div className="loginTxt">
                        <p>mBaaS.Shop 관리자 전용 페이지 입니다.<br/>
                        계정이 없을 시 담당자에게 문의주세요.<br/>
                        <span>ID/PW가 유출되지 않도록 각별히 주의해주시기 바라며,<br/> mBaaS가 인가하지 않은 유출이나 로그인이 발생한 경우<br/> 법적인 책임이 발생할 수 있습니다.</span>
                        </p>
                    </div>
                    <form onSubmit={fn_BlockSubmit}>
                        <div className="loginInWrap">
                            <input 
                                className="loginId" 
                                type="text" 
                                name={LOGIN_EMAIL_NAME} 
                                value={loginHook.statusValue.loginRequest.memberEmail} 
                                onChange={loginHook.onChange}
                                placeholder="이메일 입력"/><br/>
                            <input 
                                className="loginPw" 
                                type="password" 
                                name={LOGIN_PASSWORD_NAME} 
                                value={loginHook.statusValue.loginRequest.memberPassword}  
                                onChange={loginHook.onChange}
                                placeholder="비밀번호 입력"/>
                        </div>
                        <div className="loginSave flex spaceB">
                            <div>
                                <input type="checkbox" onClick={loginHook.saveId} id="checkId" name="checkId" defaultChecked={loginHook.statusValue.isSaveId}/>
                                <label htmlFor="checkId"> 아이디 저장</label>
                            </div>
                            <div><Link to={FIND_PW_PATH}>비밀번호 재설정</Link></div>
                        </div>
                        <div className="warning">!비밀번호가 일치하지 않습니다.</div>
                        <div className="loginBtn">
                            <button onClick={loginHook.login}>로그인</button>
                        </div>
                    </form>
                    <ul>
                        <li>1. ID/PW를 분실하셨거나 유출된 경우 즉시 담당자에게 신속하게 신고해주시기 바랍니다.</li>
                        <li>2. 작업을 마치신 후 반드시 로그아웃 하시기 바랍니다.</li>
                        <li>3. 보안상 5분간 사용이 없으면 자동으로 로그아웃됩니다.</li>
                    </ul>
                    <div className="copy">Copyright © 2023 ㈜mBaaS. All Rights Reserved</div>
                </div>
            </div>
        </div>
        {
            loginHook.isOpen ? 
            <DefaultPopup>
                <LoginErrorPopup onClick={loginHook.onClick} errorMessage={loginHook.statusValue.loginResponse.message}/>
            </DefaultPopup> : ""
        }
    </>
  )
}

export default Login