import { useState } from "react"
import { useLocation } from "react-router-dom";
import { ReserveModel } from "../Reserve.model";

export const useReserveReadHook = () => {
    const location = useLocation();
    const [statusValue, setStatusValue] = useState<ReserveModel>(location.state.data);

    return {
        statusValue,
    }
}