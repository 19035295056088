import React from 'react'
import { useNavigate } from 'react-router-dom'
import { M_ADMIN_READ_PATH } from '../../../../../../common/path';

const ApAdminList = () => {
  const navigator = useNavigate();

  return (
    <>
      <ul className="tapContents">
        <li>
            <div className="search">
                <select>
                    <option value="all">전체</option>
                    <option value="name" selected>이름</option>
                    <option value="id">아이디</option>
                    <option value="company">회사명</option>
                    <option value="appname">앱 이름</option>
                </select>
                <input type="text" placeholder="검색어를 입력해주세요."/>
                <button><img src={require("../../../../../../img/icon_search_white.png")} alt="검색"/></button>
            </div>
            <div className="filter">
                <select>
                    <option value="all">전체</option>
                    <option value="act" selected>활동</option>
                    <option value="sleep">휴면</option>
                    <option value="wthdr">탈퇴</option>
                    <option value="stop">사용중지</option>
                    <option value="fire">강퇴</option>
                </select>
                <select>
                    <option value="join" selected>가입일순</option>
                    <option value="name">이름순</option>
                </select>
            </div>
            <div>
                <table className="table table--block">
                    <thead>
                        <tr>
                            <th style={{width: "10%"}}>No</th>
                            <th>이름</th>
                            <th>아이디</th>
                            <th className="tableLong">관리앱</th>
                            <th>회사명</th>
                            <th>앱회원수</th>
                            <th>엠바스회원아이디</th>
                            <th>가입일</th>
                            <th>상태</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td data-name="No">100</td>
                            <td data-name="이름">직원1</td>
                            <td data-name="아이디" className="tdLink" onClick={() => {navigator(M_ADMIN_READ_PATH)}}>user245</td>
                            <td data-name="관리앱">
                                <span className="tdLink">앱명1 v1.0 (앱코드1)</span> <br/> 
                                <span className="tdLink">앱명2 v2.0 (앱코드2)</span> <br/>
                                <span className="tdLink">앱명3 v1.0 (앱코드3)</span>
                            </td>
                            <td data-name="회사명">엠바스</td>
                            <td data-name="앱회원수">
                                <span className="tdLink">50</span> <br/>
                                <span className="tdLink">80</span> <br/>
                                <span className="tdLink">100</span> <br/>
                            </td>
                            <td data-name="엠바스회원아이디">user12</td>
                            <td data-name="가입일">2023-07-23</td>
                            <td data-name="상태"><span className="act">활동</span></td>
                        </tr>
                        <tr>
                            <td data-name="No">99</td>
                            <td data-name="이름">직원2</td>
                            <td data-name="아이디" className="tdLink">user245</td>
                            <td data-name="관리앱">
                                <span className="tdLink">앱명1 v1.0 (앱코드1)</span> <br/> 
                                <span className="tdLink">앱명2 v2.0 (앱코드2)</span> <br/>
                                <span className="tdLink">앱명3 v1.0 (앱코드3)</span>
                            </td>
                            <td data-name="회사명">엠바스</td>
                            <td data-name="앱회원수">
                                <span className="tdLink">50</span> <br/>
                                <span className="tdLink">80</span> <br/>
                                <span className="tdLink">100</span> <br/>
                            </td>
                            <td data-name="엠바스회원아이디">user12</td>
                            <td data-name="가입일">2023-07-23</td>
                            <td data-name="상태"><span className="sleep">휴면</span></td>
                        </tr>
                        <tr>
                            <td data-name="No">98</td>
                            <td data-name="이름">직원3</td>
                            <td data-name="아이디" className="tdLink">user245</td>
                            <td data-name="관리앱">
                                <span className="tdLink">앱명1 v1.0 (앱코드1)</span>
                            </td>
                            <td data-name="회사명">엠바스</td>
                            <td data-name="앱회원수"><span className="tdLink">50</span></td>
                            <td data-name="엠바스회원아이디">user12</td>
                            <td data-name="가입일">2023-07-23</td>
                            <td data-name="상태"><span className="wthdr">탈퇴</span></td>
                        </tr>
                        <tr style={{cursor:"pointer"}}>
                            <td data-name="No">97</td>
                            <td data-name="이름">직원4</td>
                            <td data-name="아이디" className="tdLink">user245</td>
                            <td data-name="관리앱" className="tdLink">앱명1 v1.0 (앱코드1)</td>
                            <td data-name="회사명">엠바스</td>
                            <td data-name="앱회원수"><span className="tdLink">50</span></td>
                            <td data-name="엠바스회원아이디">user12</td>
                            <td data-name="가입일">2023-07-23</td>
                            <td data-name="상태"><span className="stop">사용중지</span></td>
                        </tr>
                        <tr style={{cursor:"pointer"}}>
                            <td data-name="No">96</td>
                            <td data-name="이름">직원5</td>
                            <td data-name="아이디" className="tdLink">user245</td>
                            <td data-name="관리앱" className="tdLink">앱명1 v1.0 (앱코드1)</td>
                            <td data-name="회사명">엠바스</td>
                            <td data-name="앱회원수"><span className="tdLink">50</span></td>
                            <td data-name="엠바스회원아이디">user12</td>
                            <td data-name="가입일">2023-07-23</td>
                            <td data-name="상태"><span className="fire">강퇴</span></td>
                        </tr>
                        <tr style={{cursor:"pointer"}}>
                            <td data-name="No">95</td>
                            <td data-name="이름">직원6</td>
                            <td data-name="아이디" className="tdLink">user245</td>
                            <td data-name="관리앱" className="tdLink">앱명1 v1.0 (앱코드1)</td>
                            <td data-name="회사명">엠바스</td>
                            <td data-name="앱회원수"><span className="tdLink">50</span></td>
                            <td data-name="엠바스회원아이디">user12</td>
                            <td data-name="가입일">2023-07-23</td>
                            <td data-name="상태"><span className="act">활동</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="page">
                <ul className="pagination modal">
                    <li><a href="#" className="arrow_left"></a></li>
                    <li><a href="#" className="num active">1</a></li>
                    <li><a href="#" className="num">2</a></li>
                    <li><a href="#" className="num">3</a></li>
                    <li><a href="#" className="num">4</a></li>
                    <li><a href="#" className="num">5</a></li>
                    <li><a href="#" className="arrow_right"></a></li>
                </ul>
            </div>
        </li>
    </ul>
    </>
  )
}

export default ApAdminList