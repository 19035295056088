/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react"
import { UserAskUpdateAnState } from "./UserAskUpdateAn.model";
import { useUserAskReadHook } from "../../read/UserAskRead.hook";
import { fn_Debug } from "../../../../../common/module";
import { DEFINE_USERASK_APP_UPDATE_ANSWER, DEFINE_USERASK_UPDATE_ANSWER, MBAAS_APP_CODE, RESPONSE_RESULT_FAIL, RESPONSE_RESULT_SUCCESS } from "../../../../../common/const";
import { useAxios } from "../../../../../common/hooks";
import { AlertContext } from "../../../../../common/context";


export const useUserAskUpdateAn = (callback?: () => void) => {
    const alertContext = useContext(AlertContext);
    const userAskReadHook = useUserAskReadHook();

    const [statusValue, setStatusValue] = useState<UserAskUpdateAnState>({
        isUpdate: false,
    });
    const axios = useAxios();

    useEffect(() => {
        fn_Debug("USERASK_UPDATE_STATE");
        fn_Debug(statusValue);
    }, [statusValue])

    useEffect(() => {
        setStatusValue({
            ...statusValue,
            userAskUpdateAnRequest: {
                ...statusValue.userAskUpdateAnRequest,
                appCode: sessionStorage.getItem(MBAAS_APP_CODE) ?? undefined,
                replyCode: userAskReadHook.statusValue.userAskReadResponse?.reply?.replyCode,
                title: userAskReadHook.statusValue.userAskReadResponse?.reply?.title,
                content: userAskReadHook.statusValue.userAskReadResponse?.reply?.content,
                fileId: userAskReadHook.statusValue.userAskReadResponse?.reply?.fileId,
                // files: userAskReadHook.statusValue.userAskReadResponse?.reply?.files,
                fileUrl: userAskReadHook.statusValue.userAskReadResponse?.reply?.fileUrl,
            }
        })
    }, [userAskReadHook.statusValue])

    const onChangeUpdate = () => {
        setStatusValue({
            ...statusValue,
            isUpdate: !statusValue.isUpdate
        })
    }

    const onChange = (e:any) => {
        setStatusValue({
            ...statusValue,
            userAskUpdateAnRequest: {
                ...statusValue.userAskUpdateAnRequest,
                [e.target.name] : e.target.value
            }
        })
    }

    const onChangeContent = (data:string) => {
        setStatusValue({
            ...statusValue,
            userAskUpdateAnRequest: {
                ...statusValue.userAskUpdateAnRequest,
                content: data,
            }
        })
    }

    const onChangeFileId = (fileId: string) => {
        setStatusValue({
            ...statusValue,
            userAskUpdateAnRequest: {
                ...statusValue.userAskUpdateAnRequest,
                fileId: fileId,
            }
        })
    }

    const validUpdateReply = ():boolean => {
        if(!statusValue.userAskUpdateAnRequest?.title) {
            alertContext.openAlert("제목은 필수입니다.", "warning");
            return false;
        }
        if(!statusValue.userAskUpdateAnRequest?.content) {
            alertContext.openAlert("내용은 필수입니다.", "warning");
            return false;
        }
        if(!statusValue.userAskUpdateAnRequest?.replyCode) {
            alertContext.openAlert("잘못된 접근입니다.", "warning");
            return false;
        }

        return true;
    }

    const updateReply = async() => {
        if(!validUpdateReply()) return;

        const response = await axios.putData(
            sessionStorage.getItem(MBAAS_APP_CODE) ? DEFINE_USERASK_APP_UPDATE_ANSWER : DEFINE_USERASK_UPDATE_ANSWER
            , statusValue.userAskUpdateAnRequest
        );

        fn_Debug("UPDATE_REPLY_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setStatusValue({
                ...statusValue,
                isUpdate: false,
            })
            if(callback) callback();
            userAskReadHook.getUserAskRead();
            alertContext.openAlert("문의 사항답변이 수정되었습니다.", "success");
        }

        if(response.result === RESPONSE_RESULT_FAIL) {
            alertContext.openAlert(response.message, "error");
        }

    }

    return {
        statusValue,
        onChangeUpdate,
        onChange,
        onChangeContent,
        onChangeFileId,
        updateReply,
    }
}