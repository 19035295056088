import React from 'react'
import { useNavigate } from 'react-router-dom'
import { AP_LIST_PATH, AP_UPDATET_PATH } from '../../../../../common/path';
import MovePopup from '../../../../alert/MovePopup';
import WarnDeleteAppPopup from '../../../../popup/WarnDeleteAppPopup';
import DenyDeleteAppPopup from '../../../../popup/DenyDeleteAppPopup';
import { usePopupHook } from '../../../../../common/hooks';

const ApRead = () => {
  const navigator = useNavigate();
  const warnHook = usePopupHook(false);
  const denyHook = usePopupHook(false);

  return (
    <>
        <h2>앱 관리 상세</h2>

        <div className="boxContents">
        <table className="table">
            <thead>
            <tr>
                <th>이름</th>
                <th>앱생성자</th>
                <th>앱관리자</th>
                <th>회사명</th>
                <th>회원수</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>김소연</td>
                <td>user123</td>
                <td>user234<br/>user236</td>
                <td>엠바스</td>
                <td>1,000,000</td>
            </tr>
            </tbody>
        </table>
        <br />
        <br />
        <ul className="tapMenu">
            <li className="on">앱명 v3.0(앱코드3)</li>
            <li>앱명 v2.0(앱코드2)</li>
            <li>앱명 v1.0(앱코드1)</li>
        </ul>
        <table className="tableCol">
            <tr>
            <th>앱용도</th>
            <td>앱용도명</td>
            </tr>
            <tr>
            <th>템플릿</th>
            <td>value&nbsp;&nbsp;&nbsp;&nbsp;<button>미리보기</button></td>
            </tr>
            <tr>
            <th>추가기능 (n/n)</th>
            <td>
                <ul className="addFunc">
                <li className="on">회원가입</li>
                <li className="on">주소록 접근</li>
                <li className="on">내 번호 보내기</li>
                <li>길찾기</li>
                <li>외부 기기연동</li>
                <li>채팅</li>
                <li>실시간 위치 추적(GPS)</li>
                </ul>
            </td>
            </tr>
            <tr>
            <th>기획서</th>
            <td><button>기획서 다운로드</button>&nbsp;&nbsp;<button>기획서 바로관리</button></td>
            </tr>
            <tr>
            <th>생성일</th>
            <td>2023-07-26</td>
            </tr>
            <tr>
            <th>상태</th>
            <td>value&nbsp;&nbsp;<button>앱 다운로드 URL 핸드폰 전송</button></td>
            </tr>
        </table>
        <div className="btnBoxB">
            <div>
            <button className="off" onClick={() => {navigator(AP_LIST_PATH)}}>목록</button>
            <button className="off">상담요청</button>
            </div>
            <div>
            <button className="off" onClick={warnHook.onClick}>삭제</button>
            <button className="on" onClick={() => {navigator(AP_UPDATET_PATH)}}>수정</button>
            </div>
        </div>
        </div>

        {
            warnHook.isOpen?
            <MovePopup>
                <WarnDeleteAppPopup onClickCancle={() => {warnHook.onClick()}} onClickConfirm={() => {denyHook.onClick(); warnHook.onClick()}}/>
            </MovePopup> : ""
        }
        {
            denyHook.isOpen ?
            <MovePopup>
                <DenyDeleteAppPopup onClickCancle={() => {}} onClickConfirm={() => {denyHook.onClick()}}/>
            </MovePopup> : ""
        }
        
    </>
  )
}

export default ApRead