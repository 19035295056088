export interface ChPwProps {
  onConfirm: () => void,
  onCancle: () => void,
  onChange: (e:any) => void,
}

const ChangePwPopup:React.FC<ChPwProps> = (props: ChPwProps) => {
  return (
    <>
      <div className="popBg">
          <p>비밀번호를 재설정 해주세요.</p>
          <input type="password" name="newPassword" onChange={props.onChange} placeholder="새 비밀번호 입력"/>
          <div><span>*영문 + 숫자 + 특수문자 8자 이상</span></div>
          <input type="password" name="reMemberPassword" onChange={props.onChange} placeholder="새 비밀번호 재입력"/>
          <div className="btnBox">
              <button className="off" onClick={props.onCancle}>닫기</button>
              <button className="on" onClick={props.onConfirm}>비밀번호 재설정</button>
          </div>
      </div>
    </>
  )
}

export default ChangePwPopup