import React from 'react'
import { useNavigate } from 'react-router-dom'
import { RESERVE_LIST_PATH } from '../../../../../common/path';
import Editor from '../../../../common/Editor';
import FileUpload from '../../../../common/FileUpload';
import { useReserveCreateHook } from './ReserveCreate.hook';

const ReserveCreate = () => {
  const navigator = useNavigate();
  const reserveCreateHook = useReserveCreateHook();
  
  return (
    <>
        <h2>클래스(강좌) 생성</h2>

        <div className="boxContents">
        <table className="tableCol">
        <tr>
                <th>이름</th>
                <td>
                    <input type="text" name='name' placeholder="이름을 입력하세요." 
                        onChange={reserveCreateHook.onChange}
                        value={reserveCreateHook.statusValue.reserveCreateRequest.name}/>
                </td>
            </tr>
            <tr>
                <th>가격</th>
                <td>
                    <input type="number" name='price' placeholder="가격을 입력하세요."
                    onChange={reserveCreateHook.onChange}
                        value={reserveCreateHook.statusValue.reserveCreateRequest.price}/>
                </td>
            </tr>
            <tr>
                <th>클래스 강사명</th>
                <td>
                    <input type="text" placeholder='강사 이름을 입력하세요.' name='tutor' 
                    onChange={reserveCreateHook.onChange}
                        value={reserveCreateHook.statusValue.reserveCreateRequest.tutor}/>
                </td>
            </tr>
            <tr>
                <th>클래스 최대수강생</th>
                <td>
                    <input type="number" placeholder='최대수강생을 입력하세요.' name='maxStudent'
                        onChange={reserveCreateHook.onChange}
                        value={reserveCreateHook.statusValue.reserveCreateRequest.maxStudent}/>
                </td>
            </tr>
            <tr>
                <th>강의 기간</th>
                <td>
                    <input name='start' onChange={reserveCreateHook.onChange} type='date'/> 
                    &nbsp; ~ &nbsp;<input name='end' onChange={reserveCreateHook.onChange} type='date'/>
                    <span> &nbsp;기간을 설정 후 시간을 추가주세요</span>
                </td>
            </tr>
            <tr>
                <th>시간<br/></th>
                <td className='reserveTime'>
                    <div>
                        <input type="date" name='classDate'
                             onChange={reserveCreateHook.onChangeOnedayClass}/> &nbsp;
                        <input type="time" step="300" name='classStart'
                            onChange={reserveCreateHook.onChangeOnedayClass}/>&nbsp;
                        ~&nbsp; <input type="time" step="300" name='classEnd'
                            onChange={reserveCreateHook.onChangeOnedayClass}/> 
                        <button onClick={reserveCreateHook.addClass}>+</button>
                      {/*  <span> &nbsp;시간은 기간을 초과할 수 없습니다.</span> */}
                    </div>
                    {
                        reserveCreateHook.statusValue.reserveCreateRequest.oneDayClassList.map(cls => (
                            <div key={cls.key}>
                                <input type="date" value={cls.classDate} onClick={(e) => {e.preventDefault()}}/> &nbsp;
                                <input type="time" value={cls.classStart} disabled/>&nbsp;
                                ~&nbsp; <input type="time" value={cls.classEnd} disabled/> 
                                <button onClick={() => {reserveCreateHook.removeClass(cls.key)}}>-</button>
                            </div>
                        ))
                    }
                </td>
            </tr>
            <tr>
                <th>설명</th>
                <td>
                    <div>
                        <Editor
                            onChange={reserveCreateHook.onChangeContent}/>
                    </div>
                </td>
            </tr>
            <tr>
                <th>이미지<br/>(가로 360px * 세로 270px)</th>
                <td>
                     <FileUpload
                        afterPost={reserveCreateHook.onChangeFileId}
                        isSingle={true}
                        />
                </td>
            </tr>
            {/* <tr>
            <th>사용 여부</th>
            <td>
                <label>
                <input type="radio" name="use" checked/> 사용
                </label>
                <label>
                <input type="radio" name="use"/> 미사용
                </label>
            </td>
            </tr> */}
        </table>
        <div className='explanationWrap'>
            <div className='tip'>{/* <img src={require("../../../../../img/icon_info.png")} alt="" />&nbsp; */}예약설정 하는 법</div>
            <div className='explanatioCon'>
                <ul>
                    <li>1. 강의 관련 정보를 입력해 줍니다.</li>
                    <li>2. 강의 기간을 설정해줍니다.</li>
                    <li>3. 강의 시간을 입력후 플러스 버튼을 눌러 추가해줍니다. 추가된 시간은 하단에 나열됩니다.</li>
                    <li>&nbsp;&nbsp;&nbsp; - 시간은 강의 기간 이전이나 초과할 수 없습니다. </li>
                    <li>&nbsp;&nbsp;&nbsp; - 필요없는 시간은 마이너스 버튼을 눌러 삭제시켜 줍니다.</li>
                </ul>
            </div>
        </div>
        </div>

        <div className="btnBoxB">
            <button className="off" onClick={() => {navigator(RESERVE_LIST_PATH)}}>목록</button>
            <div>
            <button className="off" onClick={() => {navigator(RESERVE_LIST_PATH)}}>취소</button>
            <button className="on" onClick={reserveCreateHook.createReserve}>생성</button>
            </div>
        </div>

    </>
  )
 }

 export default ReserveCreate