import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import axios from 'axios';
import { EDITOR_FILE_UPLOAD_URL } from '../../common/config';
import { fn_Debug } from '../../common/module';
import { EditorFileUploadResponse } from '../../common/model/file.model';
import { useEffect } from 'react';

interface Props {
    placeholder?: string,
    onChange?: (data: string) => void,
    initialData?: string,
}

const Editor:React.FC<Props> = (props?: Props) => {
    const customUploadAdapter = (loader: any) => {
        return {
            upload(){
                return new Promise ((resolve, reject) => {
                    const data = new FormData();
                     loader.file.then( (file: any) => {
                        data.append("upload", file);

                        axios.post<EditorFileUploadResponse>(EDITOR_FILE_UPLOAD_URL, data)
                            .then((res) => {
                                fn_Debug("EDITOR_FILE_UPLOAD_RESPONES");
                                fn_Debug(res);
                                resolve({
                                    default: `${res.data.url}`
                                });
                            })
                            .catch((err)=>reject(err));
                    })
                })
            }
        }
    }

    function uploadPlugin (editor: any){
        editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
            return customUploadAdapter(loader);
        }
    }

    useEffect(() => {
        fn_Debug(props?.initialData);
    }, [props?.initialData])

  return (
    <CKEditor
        editor={ ClassicEditor }
        config={{
            placeholder: props?.placeholder,
            extraPlugins: [uploadPlugin],
            initialData: props?.initialData,
        }}
        onReady={ editor => {
            // You can store the "editor" and use when it is needed.
            // console.log( 'Editor is ready to use!', editor );
        }}
        onChange={ ( event, editor ) => {
            const data = editor.getData();
            props?.onChange!(data);

            // console.log( { event, editor, data } );
        } }
        onBlur={ ( event, editor ) => {
            // console.log( 'Blur.', editor );
        } }
        onFocus={ ( event, editor ) => {
            // console.log( 'Focus.', editor );
        } }
    />
  )
}

export default Editor