import React from 'react'
import { PopupProps } from '../../common/model/popup.model'

const DenyDeleteAppPopup = (props: PopupProps) => {
  return (
    <div className="popWrap">
        <div className="popBg">
            <p className="center bold red" style={{fontSize: "24px"}}>경고</p>
            <br/>
            <p className="center bold red">“이름”의 “(앱코드)”는 현재 사용 중인 앱입니다. <br/>
                사용 중인 앱은 삭제하실 수 없습니다. <br/>
                다른 버전의 앱을 “사용중”으로 변경하시고 “(앱코드)”을 <br/>삭제하시기 바랍니다.</p>
            <div className="btnBox">
                <button className="on" onClick={() => {props.onClickConfirm()}}>확인</button>
            </div>
        </div>
    </div>
  )
}

export default DenyDeleteAppPopup