/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { CheckAuthCodeResponse, FindPwStatus, SendAuthCodeResponse, UpdatePasswordResponse } from "./FindPw.model";
import { fn_Cookies, fn_Debug, fn_Empty } from "../../../common/module";
import { useAxios, useInterval, usePopupHook } from "../../../common/hooks";
import { DEFINE_CHECK_AUTH_CODE, DEFINE_SEND_AUTH_CODE_EMAIL, DEFINE_UPDATE_PASSWORD, EMAIL_REGEX, PASSWORD_REGEX, RESPONSE_RESULT_SUCCESS } from "../../../common/const";
import { EMAIL_EXPIRE_TIME, SEND_EMAIL_COOKIE_MAXAGE } from "../../../common/config";
import { LOGIN_PATH } from "../../../common/path";
import { useNavigate } from "react-router-dom";
import { SEND_CNT_EXPIRED } from "./FindPw.const";

export const useFindPwHook = () => {
    const navigator = useNavigate();

    const [statusValue, setStatusValue] = useState<FindPwStatus>({
        isAuth : false, // 이메일 인증 여부
        isSend: false, // 이메일 전송 여부
        sendCnt: 0, // 이메일 발송 횟수
        isAuthCheckFail: false,
        authTime: EMAIL_EXPIRE_TIME,
        authTimeFormat: `${Math.floor(EMAIL_EXPIRE_TIME / 60)}
            : ${Math.floor(EMAIL_EXPIRE_TIME % 60) < 10 ?
                "0" + Math.floor(EMAIL_EXPIRE_TIME % 60) 
                : Math.floor(EMAIL_EXPIRE_TIME % 60)}`,
        authTimeInterval: 1000,
        isAuthTimeout: false,
        isSuccessChPw : false, // 비밀번호 변경 성공 여부
        isPopupOpen: false,
        isChPwPopupHookOpen: false,
        isSuccessChPwHookOpen: false,
        popupMessage : "",
        findPwRequest: {
            memberEmail: "",
        },
        checkAuthCodeRequest: {
            memberEmail: "",
            code: "",
        },
        checkAuthCodeResponse: {
            result : "",
            data: {},
            message: "",
            errorCode: "",
        },
        sendAuthCodeResponse: {
            result : "",
            data: {},
            message: "",
            errorCode: "",
        },
        updatePasswordRequest: {
            newPassword: "",
            reMemberPassword: "",
            code: "",
            email: "",
        }
    });

    const intervalHook = useInterval(() => {
        setStatusValue({
            ...statusValue,
            authTime: statusValue.authTime - 1,
            authTimeFormat: 
                `${Math.floor(statusValue.authTime / 60)}
                    :${Math.floor(statusValue.authTime % 60) < 10 
                        ? "0" + Math.floor(statusValue.authTime % 60) 
                        : Math.floor(statusValue.authTime % 60)}`,
        })
    }, statusValue.authTimeInterval);
    const axios = useAxios();

    // const [cookies, setCookie] = useCookies([SEND_CNT_EXPIRED]);

    const alertSendEmailPopupHook = usePopupHook(false);
    const chPwPopupHook = usePopupHook(false);
    const successChPwPopup = usePopupHook(false);

    useEffect(() => {
        fn_Debug("FIND_PW_STATUS:");
        fn_Debug(statusValue);
    }, [statusValue]);

    useEffect(() => {
        setStatusValue({
            ...statusValue,
            isPopupOpen: alertSendEmailPopupHook.isOpen,
            isChPwPopupHookOpen: chPwPopupHook.isOpen,
            isSuccessChPwHookOpen: successChPwPopup.isOpen,
        });
    }, [
        alertSendEmailPopupHook.isOpen,
        chPwPopupHook.isOpen,
        successChPwPopup.isOpen,
    ])

    useEffect(() => {
        if(statusValue.authTime === 0) {
            intervalHook.stop();
            setStatusValue({
                ...statusValue,
                isAuthTimeout: true,
                checkAuthCodeRequest: {
                    ...statusValue.checkAuthCodeRequest,
                    code: "",
                }
            })
        }
    }, [statusValue.authTime])

    const onChangeFindPwRequest = (event:any) => {
        setStatusValue({
            ...statusValue,
            findPwRequest: {
                ...statusValue.findPwRequest,
                [event.target.name] : event.target.value
            },
            checkAuthCodeRequest: {
                ...statusValue.checkAuthCodeRequest,
                [event.target.name] : event.target.value
            }
        })
    }

    const onChangeCheckAuthRequest = (event: any) => {
        setStatusValue({
            ...statusValue,
            checkAuthCodeRequest: {
                ...statusValue.checkAuthCodeRequest,
                [event.target.name] : event.target.value
            }
        })
    }

    /**
     * 인증메일 전송
     */
    const validSendAuthCode = (): boolean => {
        if(fn_Empty(statusValue.findPwRequest.memberEmail)) {
            setStatusValue({
                ...statusValue,
                popupMessage: "이메일을 입력해주세요",
            });
            alertSendEmailPopupHook.onClick();
            return false; 
        }
        if(!EMAIL_REGEX.test(statusValue.findPwRequest.memberEmail)) {
            setStatusValue({
                ...statusValue,
                popupMessage: "아이디는 이메일 형식입니다.",
            });
            alertSendEmailPopupHook.onClick();
            return false; 
        }
        if(fn_Cookies.get(SEND_CNT_EXPIRED)) {
            setStatusValue({
                ...statusValue,
                popupMessage: "이메일 전송횟수를 초과했습니다. 잠시후에 시도해주세요.",
            });

            alertSendEmailPopupHook.onClick();
            return false; 
        }
        if(statusValue.sendCnt > 3) {
            setStatusValue({
                ...statusValue,
                popupMessage: "이메일 전송횟수를 초과했습니다. 잠시후에 시도해주세요.",
            });

            if(!fn_Cookies.get(SEND_CNT_EXPIRED)) {
                fn_Cookies.set(SEND_CNT_EXPIRED, "expired", {maxAge: SEND_EMAIL_COOKIE_MAXAGE})
            }

            alertSendEmailPopupHook.onClick();
            return false; 
        }

        return true;
    }

    const sendAuthCode = async(): Promise<void> => {
        if(!validSendAuthCode()) return;

        setStatusValue({
            ...statusValue,
            authTime: EMAIL_EXPIRE_TIME,
            authTimeFormat: `${Math.floor(EMAIL_EXPIRE_TIME / 60)}
            :${Math.floor(EMAIL_EXPIRE_TIME % 60) < 10 ?
                "0" + Math.floor(EMAIL_EXPIRE_TIME % 60) 
                : Math.floor(EMAIL_EXPIRE_TIME % 60)}`,
        });

        const response = await axios.postData<SendAuthCodeResponse>(DEFINE_SEND_AUTH_CODE_EMAIL, statusValue.findPwRequest);
        fn_Debug("SEND_AUTH_EMAIL_RESULT");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            alertSendEmailPopupHook.onClick();
            setStatusValue(currstate => ({
                ...currstate,
                isSend: true,
                sendCnt: currstate.sendCnt + 1,
                popupMessage: response.message,
                isAuthCheckFail: false,
                isAuthTimeout: false,
            }))
            
            intervalHook.run();
        }
    }

    const sendAuthCodeSuccess = () => {
        alertSendEmailPopupHook.onClick();
    }

    /**
     * 인증번호 검증
     */
    const validCheckAuth = ():boolean => {
        if(!statusValue.checkAuthCodeRequest.code) {
            setStatusValue({
                ...statusValue,
                popupMessage: "인증코드를 입력해주세요",
            });
            alertSendEmailPopupHook.onClick();
            return false;
        } 
        if(!statusValue.isSend) {
            setStatusValue({
                ...statusValue,
                popupMessage: "인증메일을 먼저 발송해주세요.",
            });
            alertSendEmailPopupHook.onClick();
            return false;
        } 
        return true;
    }

    const checkAuthCode = async() => {
        //test code
        if(!validCheckAuth()) return;

        const response = await axios.postData<CheckAuthCodeResponse>(DEFINE_CHECK_AUTH_CODE, statusValue.checkAuthCodeRequest);
        fn_Debug("CHECK_AUTH_CODE_RESULT");
        fn_Debug(response);
        setStatusValue({
            ...statusValue,
            checkAuthCodeResponse: response,
        })

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setStatusValue({
                ...statusValue,
                isAuthCheckFail: false,
                updatePasswordRequest: {
                    email: statusValue.checkAuthCodeRequest.memberEmail,
                    newPassword: "",
                    reMemberPassword: "",
                    code: statusValue.checkAuthCodeRequest.code,
                }
            })
            intervalHook.stop();
            chPwPopupHook.onClick();
        }
        else {
            setStatusValue({
                ...statusValue,
                isAuthCheckFail: true
            })
        }
    }

    /**
     * 재전송
     */

    const reSendAuthCode = () => {
        intervalHook.stop();
        sendAuthCode();
    }

    /**
     * 팝업 관련
     */

    const onClickChPwPopup = ():void => {
        chPwPopupHook.onClick();
    }

    const onChangeUpdateUserPassword = (e: any): void => {
        setStatusValue({
            ...statusValue,
            updatePasswordRequest: {
                ...statusValue.updatePasswordRequest,
                [e.target.name]: e.target.value
            }
        })
    }

    const validUpdatePassword = ():boolean => {
        if(!statusValue.updatePasswordRequest.newPassword) {
            setStatusValue({
                ...statusValue,
                popupMessage: "변경할 비밀번호를 입력해주세요.",
            });
            alertSendEmailPopupHook.onClick();
            return false;
        }
        if(!statusValue.updatePasswordRequest.reMemberPassword) {
            setStatusValue({
                ...statusValue,
                popupMessage: "비밀번호 확인란을 입력해주세요.",
            });
            alertSendEmailPopupHook.onClick();
            return false;
        }
        if(statusValue.updatePasswordRequest.newPassword !== statusValue.updatePasswordRequest.reMemberPassword) {
            setStatusValue({
                ...statusValue,
                popupMessage: "비밀번호가 일치하지 않습니다.",
            });
            alertSendEmailPopupHook.onClick();
            return false;
        }
        if(
            !PASSWORD_REGEX.test(statusValue.updatePasswordRequest.newPassword) ||
            !PASSWORD_REGEX.test(statusValue.updatePasswordRequest.reMemberPassword)
        ) {
            setStatusValue({
                ...statusValue,
                popupMessage: "비밀번호 형식이 일치하지 않습니다.",
            });
            alertSendEmailPopupHook.onClick();
            return false;
        }

        return true;
    }

    const updateUserPassword = async():Promise<void> => {
        if(!validUpdatePassword()) return;
        const response = await axios.postData<UpdatePasswordResponse>(DEFINE_UPDATE_PASSWORD, statusValue.updatePasswordRequest);
        fn_Debug("UPDATE_PASSWORD_RESPONSE");
        fn_Debug(response); 
        if(response.result === RESPONSE_RESULT_SUCCESS) {
            chPwPopupHook.onClick();
            setStatusValue({
                ...statusValue,
                popupMessage: response.message,
            });
            successChPwPopup.onClick();
        }
    }

    const successUpdateUserPassword = () => {
        navigator(LOGIN_PATH, {replace: true});
    }

    return {
        statusValue,
        onChangeFindPwRequest,
        sendAuthCode,
        sendAuthCodeSuccess,
        onChangeCheckAuthRequest,
        checkAuthCode,
        reSendAuthCode,
        onClickChPwPopup,
        updateUserPassword,
        onChangeUpdateUserPassword,
        successUpdateUserPassword
    }
}