/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { MAdminListResponse, MAdminListState } from "./MAdminList.model";
import { BEARER_STRING, DEFINE_GET_M_ADMIN_LIST, MBAAS_ACCESS_TOKEN, RESPONSE_RESULT_SUCCESS } from "../../../../../../common/const";
import { ITEMS_PER_PAGE } from "../../../../../../common/config";
import { fn_Debug, fn_Decrypt } from "../../../../../../common/module";
import { useAxios } from "../../../../../../common/hooks";

export const useMAdminListHook = () => {
    const [statusValue, setStatusValue] = useState<MAdminListState>({
        mAdminListRequest: {
            role: "admin",
            pageable: {
                page: 0,
                size: ITEMS_PER_PAGE,
                type: "membername"
            }
        },
        pagination: {
            itemOffset: 0,
        },
        totalCount: 0,
    });
    const axios = useAxios();

    useEffect(() => {
        getAdminList();
    }, [
        statusValue.mAdminListRequest.pageable.sort,
        statusValue.mAdminListRequest.pageable.status
        ]
    );

    const getAdminList = async () => {
        const response = 
            await axios.getData<MAdminListResponse>(
                DEFINE_GET_M_ADMIN_LIST,
                {
                    headers: {
                        Authorization: 
                            `${BEARER_STRING}${fn_Decrypt(sessionStorage.getItem(MBAAS_ACCESS_TOKEN) ?? "")}`
                    },
                    params: statusValue.mAdminListRequest.pageable
                },
                true,
                [statusValue.mAdminListRequest.role],
            );
        fn_Debug("GET_M_ADMIN_LIST_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setStatusValue({
                ...statusValue,
                mAdminListResponse: response.data,
                totalCount: response.data.totalCount!,
            })
        }
    }

    const setItemOffset = (newOffset:number) => {
        setStatusValue({
            ...statusValue,
            mAdminListRequest: {
                ...statusValue.mAdminListRequest,
                pageable: {
                    ...statusValue.mAdminListRequest.pageable,
                    page: newOffset / 10,
                    size: 10,
                }
            },
            pagination: {
                itemOffset: newOffset
            }
        })
    }

    const onChange = (e: any) => {
        setStatusValue({
            ...statusValue,
            mAdminListRequest: {
                ...statusValue.mAdminListRequest,
                pageable: {
                    ...statusValue.mAdminListRequest.pageable,
                    [e.target.name] : e.target.value,
                }    
            },
        })
    }
    return {
        statusValue,
        setItemOffset,
        onChange,
        getAdminList,
    }
}