import { useContext, useEffect, useState } from "react"
import { ApMemListResponse, ApMemListState } from "./ApMemList.model";
import { DEFINE_GET_AP_MEM_LIST, MBAAS_APP_CODE, RESPONSE_RESULT_SUCCESS } from "../../../../../../common/const";
import { AlertContext } from "../../../../../../common/context";
import { useAxios } from "../../../../../../common/hooks";
import { fn_Debug } from "../../../../../../common/module";

export const useApMemListHook = () => {
    const alertContext = useContext(AlertContext);
    const axios = useAxios();
    const [statusValue, setStatusValue] = useState<ApMemListState>({
        apMemListReqeust: {
            appCode: sessionStorage.getItem(MBAAS_APP_CODE) ?? "",
            pageable: {
                page: 0,
                size: 1,
            },
        },
        pagination: {
            itemOffset: 0,
        },
        totalCount: 0,
    });

    useEffect(() => {
        if(!statusValue.apMemListReqeust.appCode) {
            alertContext.openAlert("앱코드가 없습니다.", "error");
        }
        else {
            getApMemberList();
        }
    }, []);

    const getApMemberList = async() => {
        const response = await axios.getData<ApMemListResponse>(DEFINE_GET_AP_MEM_LIST, {
            params: statusValue.apMemListReqeust.pageable,
        }, true, [statusValue.apMemListReqeust.appCode]);
        fn_Debug("AP_MEM_LIST_RESPONSE");
        fn_Debug(response);
        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setStatusValue({
                ...statusValue,
                apMemListResponse: response.data,
                totalCount: response.data.totalCount,
            })
        }
        
    }

    const setItemOffset = (newOffset:number) => {
        setStatusValue({
            ...statusValue,
            apMemListReqeust: {
                ...statusValue.apMemListReqeust,
                pageable: {
                    ...statusValue.apMemListReqeust.pageable,
                    page: newOffset / 10,
                    size: 10,
                }
            },
            pagination: {
                itemOffset: newOffset
            }
        })
    }

    return {
        statusValue,
        setItemOffset,
    }
}