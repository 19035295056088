import { useContext, useEffect, useState } from "react"
import { ItemListResponse, ItemListState } from "./ItemList.model";
import { DEFINE_ITEM_DELETE, DEFINE_ITEM_LIST, MBAAS_APP_CODE, RESPONSE_RESULT_SUCCESS } from "../../../../../common/const";
import { useAxios } from "../../../../../common/hooks";
import { ITEMS_PER_PAGE } from "../../../../../common/config";
import { fn_Debug } from "../../../../../common/module";
import { AlertContext } from "../../../../../common/context";

export const useItemListHook = () => {
    const axios = useAxios();
    const alertContext = useContext(AlertContext);
    const [statusValue, setStatusValue] = useState<ItemListState>({
        itemListRequest: {
            appCode: sessionStorage.getItem(MBAAS_APP_CODE) ?? "",
            pageable: {
                page: 0,
                size: 10,
            }
        },
        pagination: {
            itemOffset: 0,
        },
        itemListDeleteRequest: {
            appCode: sessionStorage.getItem(MBAAS_APP_CODE) ?? "",
            itemCodeList: [],
        }
    });
    

    useEffect(() => {
        getItemList();
    }, []);

    const getItemList = async() => {
        const response = 
            await axios.getData<ItemListResponse>(DEFINE_ITEM_LIST
                , {params: {
                    ...statusValue.itemListRequest.pageable
                    , appCode: statusValue.itemListRequest.appCode
                }});

        fn_Debug("GET_ITEM_LIST_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setStatusValue({
                ...statusValue,
                itemListResponse: response.data,
            });
        } else {
            alertContext.openAlert(response.message, "error");
        }
    }

    const setItemOffset = (newOffset: number) => {
        setStatusValue({
            ...statusValue,
            itemListRequest: {
                ...statusValue.itemListRequest,
                pageable: {
                    ...statusValue.itemListRequest.pageable,
                    page: newOffset / ITEMS_PER_PAGE,
                    size: ITEMS_PER_PAGE,
                }
            },
            pagination: {
                itemOffset: newOffset
            }
        })
    }

    const onClickCheckbox = (e: any) => {
        if(e.target.checked) {
            setStatusValue({
                ...statusValue,
                itemListDeleteRequest: {
                    ...statusValue.itemListDeleteRequest,
                    itemCodeList: [...statusValue.itemListDeleteRequest.itemCodeList, e.target.value]
                }
            });
        } else {
            setStatusValue({
                ...statusValue,
                itemListDeleteRequest: {
                    ...statusValue.itemListDeleteRequest,
                    itemCodeList: statusValue.itemListDeleteRequest.itemCodeList.filter(item => item !== e.target.value)
                }
            });
        }
    }

    const onClickCheckAll = (e: any) => {
        const arr: string[] = [];

        statusValue.itemListResponse?.mainList.forEach(data => {
            arr.push(data.code);
        })

        if(e.target.checked) {
            setStatusValue({
                ...statusValue,
                itemListDeleteRequest: {
                    ...statusValue.itemListDeleteRequest,
                    itemCodeList: arr
                }
            })
        } else {
            setStatusValue({
                ...statusValue,
                itemListDeleteRequest: {
                    ...statusValue.itemListDeleteRequest,
                    itemCodeList: []
                }
            })
        }
    }

    const validDeleteItem = () => {
        if(statusValue.itemListDeleteRequest.itemCodeList.length === 0) {
            alertContext.openAlert("삭제할 아이템을 선택해주세요", "warning");
            return false;
        }

        return true;
    }

    const onClickDelete = async() => {
        if(!validDeleteItem()) return;
        const response = await axios.deleteData(DEFINE_ITEM_DELETE, {data: {...statusValue.itemListDeleteRequest}});

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            alertContext.openAlert("아이템이 삭제되었습니다.", "success");
            getItemList();
        } else {
            alertContext.openAlert(response.message, "error");
        }
    }

    return {
        statusValue,
        setItemOffset,
        onClickCheckbox,
        onClickCheckAll,
        onClickDelete
    }
}