/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom"
import { MMeberReadState, MMemberReadResponse } from "./MMemberRead.model";
import { BEARER_STRING, DEFINE_COMPANY_READ, DEFINE_GET_M_MEM_READ, DEFINE_UPDATE_ACTIVE, DEFINE_UPDATE_BAN, DEFINE_UPDATE_KICK, MBAAS_ACCESS_TOKEN, RESPONSE_RESULT_SUCCESS } from "../../../../../../common/const";
import { fn_Debug, fn_Decrypt } from "../../../../../../common/module";
import { useAxios } from "../../../../../../common/hooks";
import { AlertContext } from "../../../../../../common/context";
import { CompInfoReadResponse } from "../../../../company/read/CompInfoRead.model";

export const useMMeberReadHook = () => {
    const location = useLocation();
    const alertContext = useContext(AlertContext);

    const [statusValue, setStatusValue] = useState<MMeberReadState>({
        mMemberReqeust: {
            memberCode: location.state["memberCode"],
        },
        requestActive: {
            memberCode: location.state["memberCode"],
        },
        requestBan: {
            memberCode: location.state["memberCode"],
            banContent: "",
            banEndDate: "",
            banStartDate: "",
        },
        requestKick: {
            kickContent: "",
            memberCode: location.state["memberCode"],
            status: "BLACK"
        },
        updateType: "",
        isOpen: false,
    });

    const [companyResponse, setCompanyResponse] = useState<CompInfoReadResponse>();
    const axios = useAxios();

    useEffect(() => {
        getMemberRead();
    }, [])

    const getMemberRead = async() => {
        const response = await axios.getData<MMemberReadResponse>(DEFINE_GET_M_MEM_READ, {headers: {Authorization: `${BEARER_STRING}${fn_Decrypt(sessionStorage.getItem(MBAAS_ACCESS_TOKEN) ?? "")}`}}, true, [statusValue.mMemberReqeust.memberCode]);
        fn_Debug("GET_M_MEM_READ_RESPONSE");
        fn_Debug(response);

        setStatusValue({
            ...statusValue,
            mMemberResponse: response.data,
            updateType: response.data.status
        })
    }

    const onChangeUpdateType = (e: any) => {
        setStatusValue({
            ...statusValue,
            updateType: e.target.value,
        })
    }

    const updateStatus = async() => {
        switch (statusValue.updateType) {
            case "GREEN":
                const response1 = await axios.postData(DEFINE_UPDATE_ACTIVE, statusValue.requestActive);
                if(response1.result === RESPONSE_RESULT_SUCCESS) {
                    alertContext.openAlert(response1.message, "success");
                } else {
                    alertContext.openAlert(response1.message, "error");
                }
                break;

            case "RED":
                const response2 = await axios.postData(DEFINE_UPDATE_KICK, statusValue.requestKick);
                if(response2.result === RESPONSE_RESULT_SUCCESS) {
                    alertContext.openAlert(response2.message, "success");
                } else {
                    alertContext.openAlert(response2.message, "error");
                }
                break;

            case "YELLOW":
                const response3 = await axios.postData(DEFINE_UPDATE_BAN, statusValue.requestBan);
                if(response3.result === RESPONSE_RESULT_SUCCESS) {
                    alertContext.openAlert(response3.message, "success");
                } else {
                    alertContext.openAlert(response3.message, "error");
                }
                break;
        
            default:
                break;
        }
    }

    const getCompanyInfo = async(companyCode: string):Promise<boolean> => {
        const response = await axios.getData<CompInfoReadResponse>(DEFINE_COMPANY_READ, {}, true, [companyCode]);

        if(!companyCode) {
            alertContext.openAlert("등록된 회사가 없습니다.", "warning");
            return false;
        }

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setCompanyResponse(response.data);
        } else {
            alertContext.openAlert(response.message, "error");
            return false;
        }

        return true;
    }

    const openPopup = async(companyCode: string) => {
        const result = await getCompanyInfo(companyCode);
        if(!companyCode || !result) return;
        setStatusValue({
            ...statusValue,
            isOpen: true
        })
    }
    
    const closePopup = () => {
        setStatusValue({
            ...statusValue,
            isOpen: false
        })
    }

    return {
        statusValue,
        onChangeUpdateType,
        updateStatus,
        openPopup,
        closePopup,
        companyResponse
    }
}