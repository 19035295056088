/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react"
import { ItemUpdateState } from "./ItemUpdate.model"
import { useItemReadHook } from "../read/ItemRead.hook";
import { DEFINE_ITEM_UPDATE, MBAAS_APP_CODE, RESPONSE_RESULT_SUCCESS } from "../../../../../common/const";
import { useLocation, useNavigate } from "react-router-dom";
import { useAxios } from "../../../../../common/hooks";
import { AlertContext } from "../../../../../common/context";

export const useItemUpdateHook = () => {
    const itemReadHook = useItemReadHook();
    const axios = useAxios();
    const location = useLocation();
    const navigator = useNavigate();
    const alertContext = useContext(AlertContext);
    const [statusValue, setStatusValue] = useState<ItemUpdateState>({
        itemUpdateRequest: {
            appCode : sessionStorage.getItem(MBAAS_APP_CODE) ?? "",
            content: "",
            fileId: "",
            itemCode: location.state.itemCode,
            name: "",
            price: 0,
            status: "",
        }
    });

    useEffect(() => {
        if(itemReadHook.statusValue.itemReadResponse) {
            setStatusValue({
                ...statusValue,
                itemUpdateRequest: {
                    ...statusValue.itemUpdateRequest,
                    content: itemReadHook.statusValue.itemReadResponse?.content!,
                    fileId: itemReadHook.statusValue.itemReadResponse?.fileId!,
                    name: itemReadHook.statusValue.itemReadResponse?.name!,
                    price: itemReadHook.statusValue.itemReadResponse?.price!,
                    status: itemReadHook.statusValue.itemReadResponse?.status!,
                }
            })
        }
    }, [itemReadHook.statusValue.itemReadResponse]);

    const onChange = (e: any) => {
        setStatusValue({
            ...statusValue,
            itemUpdateRequest: {
                ...statusValue.itemUpdateRequest,
                [e.target.name] : e.target.value
            }
        })
    }

    const onChangeContent = (data: string) => {
        setStatusValue({
            ...statusValue,
            itemUpdateRequest: {
                ...statusValue.itemUpdateRequest,
                content: data,
            }
        })
    }
    
    const validUpdateItem = ():boolean => {
        if(!statusValue.itemUpdateRequest.name) {
            alertContext.openAlert("아이템 이름을 입력해주세요.", "warning");
            return false;
        }
        if(!statusValue.itemUpdateRequest.price) {
            alertContext.openAlert("아이템 가격을 입력해주세요.", "warning");
            return false;
        }
        if(!statusValue.itemUpdateRequest.content) {
            alertContext.openAlert("아이템 설명을 입력해주세요.", "warning");
            return false;
        }
        if(!statusValue.itemUpdateRequest.fileId) {
            alertContext.openAlert("아이템 사진을 등록해주세요.", "warning");
            return false;
        }
        
        return true;
    }

    const updateItem = async() => {
        if(!validUpdateItem()) return;
        const response = await axios.putData(DEFINE_ITEM_UPDATE, statusValue.itemUpdateRequest);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            alertContext.openAlert("아이템이 수정되었습니다.", "success");
            navigator(-1);
        } else {
            alertContext.openAlert(response.message, "error");
        }
    }

    const onChangeFileId = (fileId: string) => {
        setStatusValue({
            ...statusValue,
            itemUpdateRequest: {
                ...statusValue.itemUpdateRequest,
                fileId: fileId
            }
        })
    }

    return {
        statusValue,
        onChange,
        onChangeContent,
        updateItem,
        onChangeFileId,
    }
}