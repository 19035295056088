import { useState, useEffect } from 'react'
import { AlertProvider, LoadingProvider } from '../../../common/context';
import { Margin, usePDF } from 'react-to-pdf';
import CompanyInfoForPdf from '../planning/companyInfo/read/CompanyInfoForPdf';
import AppInfoForPdf from '../planning/appInfo/read/AppInfoForPdf';
import SimAppInfoForPdf from '../planning/simAppInfo/SimAppInfoForPdf';

const AllPlanningLayout = () => {
  const [ScrollY, setScrollY] = useState(0);
  const [BtnStatus, setBtnStatus] = useState(false); // 버튼 상태
  const { toPDF, targetRef } = usePDF({
    filename: "usepdf-example.pdf",
    page: { margin: Margin.SMALL }
  });
  const [isCompanyOver, setIsCompanyOver] = useState<boolean>(false);
  const [isAppInfoOver, setIsAppInfoOver] = useState<boolean>(false);
  const [isSimAppOver, setIsSimAppOver] = useState<boolean>(false);


  const handleFollow = () => {
    setScrollY(window.pageYOffset);
    if(ScrollY > 100) {
      // 100 이상이면 버튼이 보이게
      setBtnStatus(true);
    } else {
      // 100 이하면 버튼이 사라지게
      setBtnStatus(false);
    }
  }

  const handleTop = () => {  // 클릭하면 스크롤이 위로 올라가는 함수
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    setScrollY(0);  // ScrollY 의 값을 초기화
    setBtnStatus(false); // BtnStatus의 값을 false로 바꿈 => 버튼 숨김
  }

  useEffect(() => {
    const watch = () => {
      window.addEventListener('scroll', handleFollow)
    }
    watch();
    return () => {
      window.removeEventListener('scroll', handleFollow)
    }
  })

  const onclickPdfDown = () => {
    toPDF();
  }

  return (
    <div className="PlanningWrap">
        <div className='header'>
        <h1 className="logo">
            <img src={require("../../../img/Ai.app_logo.png")} alt="로고 이미지" />
        </h1>
        <div className="address">
            <h2>Office address.</h2>
            <p>부산광역시 동구 중앙대로 314, 3층 4호</p>
            <p>mbaas.mkt@gmail.com</p>
        </div>
        </div>
        <LoadingProvider>
            <AlertProvider>
                <div className='PlannginWrap'>
                    <div className='main'>
                        <div className="mainTit">
                          <h2>AiApp 온라인 기획서</h2>
                          {
                            isCompanyOver &&
                            isAppInfoOver &&
                            isSimAppOver ?
                              <div className='pdfBtn' onClick={onclickPdfDown}>
                                <button>PDF로 다운로드</button>
                              </div> : "데이터 로딩중..."
                          }
                          
                        </div>
                        <div ref={targetRef}>
                          <CompanyInfoForPdf setOver={setIsCompanyOver}/>
                          <AppInfoForPdf setOver={setIsAppInfoOver}/>
                          <SimAppInfoForPdf setOver={setIsSimAppOver}/>
                        </div>
                    </div>
                </div>
            </AlertProvider>
        </LoadingProvider>
        <button 
        className={BtnStatus ? "topBtn active" : "topBtn"} // 버튼 노출 여부
        onClick={handleTop}  // 버튼 클릭시 함수 호출
        >TOP</button>
    </div>
  )
}

export default AllPlanningLayout