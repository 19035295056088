import { useContext, useEffect, useState } from "react"
import { SimAppUpdateState } from "./SimAppUpdate.model"
import { useLocation, useNavigate } from "react-router-dom"
import { AppUseCate } from "../../tem/list/TemList.model";
import { useTemplateHook } from "../../Template.hook";
import { useAxios } from "../../../../../common/hooks";
import { DEFINE_SIMAPP_UPDATE, RESPONSE_RESULT_SUCCESS } from "../../../../../common/const";
import { fn_Debug } from "../../../../../common/module";
import { AlertContext } from "../../../../../common/context";

export const useSimAppUpdateHook = () => {
    const location = useLocation();
    const templateHook = useTemplateHook();
    const axios = useAxios();
    const alertContext = useContext(AlertContext);
    const navigator = useNavigate();
    const [statusValue, setStatusValue] = useState<SimAppUpdateState>({
        simAppUpdateRequest: location.state["simApp"],
    })

    const onChange = (e:any) => {
        setStatusValue({
            ...statusValue,
            simAppUpdateRequest: {
                ...statusValue.simAppUpdateRequest,
                [e.target.name] : e.target.value
            }
        })
    }

    const onChangeContent = (data:string) => {
        setStatusValue({
            ...statusValue,
            simAppUpdateRequest: {
                ...statusValue.simAppUpdateRequest,
                content: data,
            }
        })
    }

    const onChangeCate = (e: any) => {
        if(e.target.checked) {
            setStatusValue({
                ...statusValue,
                simAppUpdateRequest: {
                    ...statusValue.simAppUpdateRequest,
                    categoryList: [
                        ...statusValue.simAppUpdateRequest.categoryList, JSON.parse(e.target.value)
                    ]
                }
            })
        }
        else {
            setStatusValue({
                ...statusValue,
                simAppUpdateRequest: {
                    ...statusValue.simAppUpdateRequest,
                    categoryList: 
                        statusValue.simAppUpdateRequest.categoryList.filter(val => val.value !== JSON.parse(e.target.value).value)
                }
            })
        }
    }

    const updateSimApp = async() => {
        if(!validUpdateSimApp()) return;
        const response = await axios.putData(DEFINE_SIMAPP_UPDATE, statusValue.simAppUpdateRequest);

        fn_Debug("UPDATE_SIMAPP_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            alertContext.openAlert("유사앱이 수정되었습니다.", "success");
            navigator(-1);
        }
    }

    const validUpdateSimApp = ():boolean => {
        if(!statusValue.simAppUpdateRequest.name) {
            alertContext.openAlert("유사앱 이름은 필수 값입니다.", "warning");
            return false;
        }
        if(statusValue.simAppUpdateRequest.categoryList.length === 0) {
            alertContext.openAlert("앱유형은 필수 값입니다.", "warning");
            return false;
        }
        if(!statusValue.simAppUpdateRequest.content) {
            alertContext.openAlert("유사앱 설명은 필수 값입니다.", "warning");
            return false;
        }
        if(!statusValue.simAppUpdateRequest.fileId) {
            alertContext.openAlert("유사앱 예제이미지는 필수 값입니다.", "warning");
            return false;
        }

        return true;
    }

    const onChangeFileId = (fileId: string) => {
        setStatusValue({
            ...statusValue,
            simAppUpdateRequest: {
                ...statusValue.simAppUpdateRequest,
                fileId: fileId,
            }
        })
    }

    const appUseDefaultChecked = (data: AppUseCate) => {
        const arr = location.state["simApp"]["categoryList"];
        for(let i = 0; i < arr.length; i++) {
            if(arr[i].value === data.value) {
                return true;
            }
        }
        return false;
    }

    return {
        statusValue,
        templateHook,
        onChange,
        onChangeContent,
        onChangeCate,
        updateSimApp,
        appUseDefaultChecked,
        onChangeFileId,
    }
}