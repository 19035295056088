import React from 'react'
import { usePlanningBridgeHook } from './PlanningBridge.hook'

const PlanningBridge = () => {
  const planningBridgeHook = usePlanningBridgeHook();
  return (
    <div>redirecting...</div>
  )
}

export default PlanningBridge