/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useFileHook } from '../../common/hooks'
import { FileResponse } from '../../common/model/file.model';
import NoActionAlert from '../alert/NoActionAlert';
import { IMG_ACCEPT, RESPONSE_RESULT_FAIL } from '../../common/const';
import { fn_Debug } from '../../common/module';

interface Props {
    fileResponse?: FileResponse[],
    fleGrpId?: string,
    afterPost: (fleGrpId: string) => void,
    isSingle?: boolean,
    maxFile?: number,
    accept? :string,
    idx?: number,
    required?: boolean,
}

const FileUpload: React.FC<Props> = ({fileResponse, fleGrpId, afterPost, isSingle = false, maxFile = 5, accept=IMG_ACCEPT, idx = 0, required=false}) => {
  const fileHook = useFileHook();

  useEffect(() => {
    fileHook.setFileState({
        ...fileHook.fileState,
        fileResponse: fileResponse,
        fleGrpId: fleGrpId,
    })
    
  }, [fileResponse])

  useEffect(() => {
    getFiles();
  }, [fleGrpId])

  const getFiles = async() => {
    // console.log(fileResponse)
    if(fleGrpId && !fileResponse) {
        const response = await fileHook.getFileByGrpId(fleGrpId!);

        fileHook.setFileState({
            ...fileHook.fileState,
            fileResponse: response,
            fleGrpId: fleGrpId,
        })
    }
  }

  const onClickPost = async(e:any) => {
    fn_Debug("ON_CLICK_POST");
    if(isSingle && (fileHook.fileState.fileResponse?.length === 1 || fileResponse?.length === 1)) {
        fileHook.setAlertProps({
            ...fileHook.alertProps,
            alertText: "파일은 하나만 등록가능합니다.",
            open: true,
            serverity: "error"
        })

        return;
    }

    const fleGrpId = await fileHook.uploadFile(e);

    if(fleGrpId !== RESPONSE_RESULT_FAIL) {
        fileHook.setFileState({
            ...fileHook.fileState,
            fleGrpId: fleGrpId,
            uploadFiles: [],
            deleteIdxes: [],
        });

        if(afterPost) {
            afterPost(fleGrpId);
        }

        const result = await fileHook.getFileByGrpId(fleGrpId);
        fn_Debug("GET_FILES_RESPONSE");
        fn_Debug(result);
        
        fileHook.setFileState({
            ...fileHook.fileState,
            fleGrpId: fleGrpId,
            fileResponse: result,
            uploadFiles: [],
            deleteIdxes: [],
        })
    }
  }

  const onClickModify = async(e:any) => {
    // if(isSingle && (fileHook.fileState.fileResponse?.length === 1 || fileResponse?.length === 1)) {
    //     fileHook.setAlertProps({
    //         ...fileHook.alertProps,
    //         alertText: "파일은 하나만 등록가능합니다.",
    //         open: true,
    //         serverity: "error"
    //     })

    //     return;
    // }
    
    // if(!isSingle && (fileHook.fileState.fileResponse?.length === maxFile || fileResponse?.length === maxFile)) {
    if((fileHook.fileState.fileResponse?.length === maxFile || fileResponse?.length === maxFile)) {
        fileHook.setAlertProps({
            ...fileHook.alertProps,
            alertText: `최대 ${maxFile}개까지 등록가능합니다.`,
            open: true,
            serverity: "error"
        })

        return;
    }

    let result = null;

    if(isSingle) {
        result = await fileHook.modifySingleFile(e);

        const fleGrpId = result;

        if(fleGrpId !== RESPONSE_RESULT_FAIL) {
            fileHook.setFileState({
                ...fileHook.fileState,
                fleGrpId: fleGrpId,
                uploadFiles: [],
                deleteIdxes: [],
            });
    
            if(afterPost) {
                afterPost(fleGrpId);
            }
    
            const result = await fileHook.getFileByGrpId(fleGrpId);
            fn_Debug("GET_FILES_RESPONSE");
            fn_Debug(result);
            
            fileHook.setFileState({
                ...fileHook.fileState,
                fleGrpId: fleGrpId,
                fileResponse: result,
                uploadFiles: [],
                deleteIdxes: [],
            })
        }
    } else {
        result = await fileHook.modifyFile(e);
    }

    if(result !== RESPONSE_RESULT_FAIL) {
        
    }
  }

  return (
    <>
        {
            fileHook.fileState.fileResponse ? fileHook.fileState.fileResponse.map((file) => (
                <div key={file.fleIdx}>{file.fleOrgNm} 
                    {
                        required && fileHook.fileState.fileResponse?.length === 1 
                        // false
                        ? "" : 
                        <span 
                            onClick={
                                (e) => {
                                    fileHook.deleteFileResponse(
                                        e
                                        , file.fleIdx
                                        , () => {
                                            afterPost("");
                                        }
                                    )
                                }
                                } style={{color:'#999',fontSize: '12px', paddingLeft:'5px'}}>
                            삭제
                        </span>
                    }
                </div>
            )) : <></>
        }

        <label className='input-file-button' htmlFor={`fileUpload${idx}`}>파일 찾기</label>
        <input type="file" id={`fileUpload${idx}`} accept={accept} onChange={fileHook.fileState.fleGrpId ? onClickModify : onClickPost}/><br/>
        <NoActionAlert
            alertText={fileHook.alertProps.alertText}
            open={fileHook.alertProps.open}
            serverity={fileHook.alertProps.serverity}
            // setOpen={setIsUploadFinish}
        /> 
    </>
  )
}

export default FileUpload