import { useEffect, useState } from "react"
import { TemReadResponse, TemReadState } from "./TemRead.model"
import { useLocation } from "react-router-dom";
import { useAxios } from "../../../../../common/hooks";
import { DEFINE_TEMPLATE_READ, RESPONSE_RESULT_SUCCESS } from "../../../../../common/const";
import { fn_Debug } from "../../../../../common/module";

export const useTemReadHook = () => {
    const location = useLocation();
    const axios = useAxios();
    const [statusValue, setStatusValue] = useState<TemReadState>({
        templateCode: location.state["templateCode"],
    });

    useEffect(() => {
        getTemRead();
    }, [])

    const getTemRead = async () => {
        const response = await axios.getData<TemReadResponse>(DEFINE_TEMPLATE_READ,{},true,[statusValue.templateCode]);

        fn_Debug("GET_TEMPLATEREAD_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setStatusValue({
                ...statusValue,
                temReadResponse: response.data
            })
        }
    }

    const convertAppUseName = () => {
        let newArr:string[] = [];
        statusValue.temReadResponse?.categoryList.forEach(cate => {
            newArr.push(cate.name);
        })

        return newArr.join(",");
    }

    return {
        statusValue,
        convertAppUseName
    }
}