import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ADV_CREATE_PATH, ADV_READ_PATH } from '../../../../../common/path';

const AdvList = () => {
  const navigator = useNavigate();

  return (
    <>
      <h2>광고 관리</h2>
      <div className="flex flexE" onClick={() => {navigator(ADV_CREATE_PATH)}}><button className="on ">광고 등록</button></div>
      <div className="flex spaceB alignC" style={{marginTop: "20px"}}>
        <div className="search">
          <select>
            <option value="all">전체</option>
            <option value="name" selected>광고명</option>
            <option value="explanation">설명</option>
          </select>
          <input type="text" placeholder="검색어를 입력해주세요."/>
          <button><img src={require("../../../../../img/icon_search_white.png")} alt="검색" /></button>
        </div>
        <div className="filter">
          <select>
            <option value="all">전체</option>
            <option value="use" selected>사용</option>
            <option value="notUse">미사용</option>
          </select>
          <select>
            <option value="member">전체</option>
            <option value="member" selected>포탈메인</option>
            <option value="name">포탈서브</option>
            <option value="date">앱메인</option>
            <option value="date">앱서브</option>
          </select>
        </div>
      </div>

      <div className="boxContents">
        <table className="table table--block">
          <thead>
            <tr>
              <th className="tableShort">No</th>
              <th>노출 영역</th>
              <th className="tableLong">이름</th>
              <th>사이즈</th>
              <th>샘플이미지</th>
              <th>생성일</th>
              <th>상태</th>
            </tr>
          </thead>
          <tbody>
            <tr onClick={() => {navigator(ADV_READ_PATH)}}>
              <td data-name="No.">100</td>
              <td data-name="노출 영역">포탈 메인</td>
              <td data-name="이름">포탈 메인 01</td>
              <td data-name="사이즈">300 * 300</td>
              <td data-name="샘플이미지"><button>미리보기</button></td>
              <td data-name="생성일">YYYY-MM-DD</td>
              <td data-name="상태"><span className="use">사용</span></td>
            </tr>
            <tr>
              <td data-name="No.">99</td>
              <td data-name="노출 영역">포탈 서브</td>
              <td data-name="이름">포탈 하단 02</td>
              <td data-name="사이즈">300 * 50</td>
              <td data-name="샘플이미지"><button>미리보기</button></td>
              <td data-name="생성일">YYYY-MM-DD</td>
              <td data-name="상태"><span className="notuse">미사용</span></td>
            </tr><tr>
              <td data-name="No.">98</td>
              <td data-name="노출 영역">앱 메인</td>
              <td data-name="이름">앱 하단 01</td>
              <td data-name="사이즈">300 * 50</td>
              <td data-name="샘플이미지"><button>미리보기</button></td>
              <td data-name="생성일">YYYY-MM-DD</td>
              <td data-name="상태"><span className="use">사용</span></td>
            </tr><tr>
              <td data-name="No.">97</td>
              <td data-name="노출 영역">앱 서브</td>
              <td data-name="이름">앱 메인 팝업 01</td>
              <td data-name="사이즈">300 * 300</td>
              <td data-name="샘플이미지"><button>미리보기</button></td>
              <td data-name="생성일">YYYY-MM-DD</td>
              <td data-name="상태"><span className="notuse">미사용</span></td>
            </tr><tr>
              <td data-name="No.">96</td>
              <td data-name="노출 영역">포탈 메인</td>
              <td data-name="이름">포탈 메인 01</td>
              <td data-name="사이즈">300 * 300</td>
              <td data-name="샘플이미지"><button>미리보기</button></td>
              <td data-name="생성일">YYYY-MM-DD</td>
              <td data-name="상태"><span className="use">사용</span></td>
            </tr><tr>
              <td data-name="No.">95</td>
              <td data-name="노출 영역">포탈 메인</td>
              <td data-name="이름">포탈 메인 01</td>
              <td data-name="사이즈">300 * 300</td>
              <td data-name="샘플이미지"><button>미리보기</button></td>
              <td data-name="생성일">YYYY-MM-DD</td>
              <td data-name="상태"><span className="use">사용</span></td>
            </tr>
          </tbody>
        </table>
        <div className="page">
          <ul className="pagination modal">
            <li className="arrow_left">처음</li>
            <li className="num active">1</li>
            <li className="num">2</li>
            <li className="num">3</li>
            <li className="num">4</li>
            <li className="num">5</li>
            <li className="arrow_right">다음</li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default AdvList