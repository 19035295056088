import React from 'react';

const Dashboard = () => {
  return (
    <div className="wrap">
       <h1>Dashboard</h1>
    </div>
  )
}

export default Dashboard