import { useSpring, animated } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { LayoutPopupProps } from '../../common/model/popup.model';

export const MovePopup = (props?: LayoutPopupProps) => {
    const pos = useSpring({x:0, y:0});
    const bindPos = useDrag((params)=>{
      pos.x.set(params.offset[0]);
      pos.y.set(params.offset[1]);
    });

    // useEffect(() => {
    //   console.log(pos.x);
    // }, [])
  
    return (
      <>
        <div className="popWrap" onClick={props?.onClickBackground}>
          <animated.div {...bindPos()} style={{
                x: pos.x,
                y: pos.y
            }}>
            {props?.children}
          </animated.div>
        </div>
      </>
    )
}

export default MovePopup