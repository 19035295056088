import { useState } from "react"

export const useDropDownHook = (initailValue: boolean) => {
    const [isOpen, setIsOpen] = useState<boolean>(initailValue);

    const onClick = (event:any) => {
        setIsOpen(!isOpen);
    }

    return {isOpen, onClick};
}

export const useTabHook = (initailValue?: number) => {
    const [selectedIndex, setSelectedIndex] = useState<number>(initailValue ?? 0);

    const onChange = (idx: number) => {
        setSelectedIndex(idx);
    }

    return {
        selectedIndex,
        onChange
    }
}