import { useContext, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { MyPageUpdateState } from "./MyPageUpdate.model";
import { useAxios } from "../../../../common/hooks";
import { DEFINE_MYPAGE_UPDATE, DEFINE_UPDATE_MYPAGE_PASSWORD, DEFINE_UPDATE_PASSWORD, PASSWORD_REGEX, RESPONSE_RESULT_SUCCESS } from "../../../../common/const";
import { fn_Debug } from "../../../../common/module";
import { AlertContext } from "../../../../common/context";

export const useMyPageUpdateHook = () => {
    const navigator = useNavigate();
    const location = useLocation();
    const axios = useAxios();
    const alertContext = useContext(AlertContext);
    const [statusValue, setStatusValue] = useState<MyPageUpdateState>({
        myPageUpdateRequest: location.state["me"],
        isUpdateOpen: false,
        updatePwRequest: {
            memberPassword: "",
            reMemberPassword: "",
        }
    })

    const onChange = (e: any) => {
        setStatusValue({
            ...statusValue,
            myPageUpdateRequest: {
                ...statusValue.myPageUpdateRequest,
                [e.target.name] : e.target.value
            }
        })
    }

    const updateMyPage = async() => {
        const response = await axios.putData(DEFINE_MYPAGE_UPDATE, statusValue.myPageUpdateRequest);

        fn_Debug("UPDATE_MYPAGE_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            navigator(-1);
            alertContext.openAlert("회원정보가 수정되었습니다.", "success");
        }
    }

    const onChangeUpdatePw = (e:any) => {
        setStatusValue({
            ...statusValue,
            updatePwRequest: {
                ...statusValue.updatePwRequest,
                [e.target.name] : e.target.value
            }
        })
    }

    const openUpdatePwPopup = () => {
        setStatusValue({
            ...statusValue,
            isUpdateOpen: true,
        })
    }

    const closeUpdatePwPopup = () => {
        setStatusValue({
            ...statusValue,
            isUpdateOpen: false,
        })
    }

    const validUpdatePw = ():boolean => {
        if(!statusValue.updatePwRequest.memberPassword) {
            alertContext.openAlert("변경할 비밀번호를 입력해주세요.", "warning");
            return false;
        }
        if(!statusValue.updatePwRequest.reMemberPassword) {
            alertContext.openAlert("변경할 비밀번호를 확인해주세요.", "warning");
            return false;
        }
        if(!PASSWORD_REGEX.test(statusValue.updatePwRequest.memberPassword)) {
            alertContext.openAlert("비밀번호 형식을 확인해주세요.", "warning");
            return false;
        }
        if(statusValue.updatePwRequest.memberPassword !== statusValue.updatePwRequest.reMemberPassword) {
            alertContext.openAlert("변경할 비밀번호가 일치하지 않습니다..", "warning");
            return false;
        }

        return true;
    }

    const updatePw = async() => {
        if(!validUpdatePw()) return;

        const response = await axios.postData(DEFINE_UPDATE_MYPAGE_PASSWORD, statusValue.updatePwRequest);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            alertContext.openAlert(response.message, "success");
            setStatusValue({
                ...statusValue,
                updatePwRequest: {
                    memberPassword: "",
                    reMemberPassword: "",
                }
            })
            closeUpdatePwPopup();
        } else {
            alertContext.openAlert(response.message, "error");
        }
    }

    return {
        statusValue,
        onChange,
        updateMyPage,
        openUpdatePwPopup,
        closeUpdatePwPopup,
        onChangeUpdatePw,
        updatePw
    }
}