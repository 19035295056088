import { createContext, useEffect, useState } from "react";
import { BasicAlertModel } from "../components/alert/BasicAlert.model";
import Loading from "../components/common/Loading";
import BasicAlert, { ServerityType } from "../components/alert/BasicAlert";
import CodeLoading from "../components/common/CodeLoading";

export const AlertContext = createContext({
    openAlert: (alertText:string, serverity:ServerityType) => {},
    closeAlert: () => {},
});

interface AlertProps {
  children: JSX.Element | JSX.Element[];
}

export const AlertProvider:React.FC<AlertProps> = ({children}) => {
  const [alertProps, setAlertProps] = useState<BasicAlertModel>({
    alertText: "",
    open: false,
    serverity: "success",
  });

  const openAlert = (alertText:string, serverity:ServerityType) => {
    setAlertProps({
      ...alertProps,
      alertText: alertText,
      open: true,
      serverity: serverity,
    })
  }

  const closeAlert = () => {
    setAlertProps({
      ...alertProps,
      open: false,
    })
  }

  return (
    <AlertContext.Provider value={{
      openAlert: openAlert,
      closeAlert: closeAlert
    }}>
      {children}
      <BasicAlert
        {...alertProps}
        setOpen={closeAlert}
      />
    </AlertContext.Provider>
  )
}

export const LoadingContext = createContext({
  loading: false,
  startLoading: () => {},
  endLoading: () => {},
  codeLoading: false,
  startCodeLoading: () => {},
  endCodeLoading: () => {}
});

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const LoadingProvider:React.FC<Props> = ({children}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [codeLoading, setCodeLoading] = useState<boolean>(false);

  const startLoading = () => {
    setLoading(true);
  }

  const endLoading = () => {
    setLoading(false);
  }

  const startCodeLoading = () => {
    setCodeLoading(true);
  }

  const endCodeLoading = () => {
    setCodeLoading(false);
  }

  return (
    <LoadingContext.Provider value={{
      loading: loading,
      startLoading: startLoading,
      endLoading: endLoading,
      codeLoading: codeLoading,
      startCodeLoading: startCodeLoading,
      endCodeLoading: endCodeLoading,
    }}>
      {children}
      <Loading display={loading}/>
      <CodeLoading display={codeLoading}/>
    </LoadingContext.Provider>
  )
}

// export const GlobalVarContext = createContext({
//   appCode: "",
//   setAppCode: (value: string) => {},
//   memberData: {
//     businessType: "",
//     createAt: "",
//     memberCode: "",
//     memberEmail: "",
//     memberMarketingAgree: "",
//     memberName: "",
//     memberPhone: "",
//     memberProfileAgree: "",
//     memberServiceAgree: "",
//     role: "",
//     status: "",
//   },
//   setMemberData: (value: MemberResponse) => {}
// })

// interface GlobalProps {
//   children: JSX.Element | JSX.Element[];
// }

// export const GlobalVarProvider:React.FC<GlobalProps> = ({children}) => {
//   const [appCode, setAppCode] = useState<string>("")
//   const [memberData, setMemberData] = useState<MemberResponse>({
//     businessType: "",
//     createAt: "",
//     memberCode: "",
//     memberEmail: "",
//     memberMarketingAgree: "",
//     memberName: "",
//     memberPhone: "",
//     memberProfileAgree: "",
//     memberServiceAgree: "",
//     role: "",
//     status: ""
//   })

//   return (
//     <GlobalVarContext.Provider value={{
//       appCode: appCode,
//       setAppCode: setAppCode,
//       memberData: memberData,
//       setMemberData: setMemberData,
//     }}>
//       {children}
//     </GlobalVarContext.Provider>
//   )
// }