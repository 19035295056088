/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { BoardUpdateState } from "./BoardUpdate.model";
import { useBoardReadHook } from "../read/BoardRead.hook";
import { DEFINE_UPDATE_APP_NOTICE, DEFINE_UPDATE_NOTICE, MBAAS_APP_CODE, RESPONSE_RESULT_SUCCESS } from "../../../../../common/const";
import { fn_Debug } from "../../../../../common/module";
import { M_BOARD_READ_PATH } from "../../../../../common/path";
import { useNavigate } from "react-router-dom";
import { useAxios } from "../../../../../common/hooks";
import { AlertContext } from "../../../../../common/context";

export const useBoardUpdateHook = () => {
    const navigator = useNavigate();
    const boardReadHook = useBoardReadHook();
    const alertContext = useContext(AlertContext);
    const [statusValue, setStatusValue] = useState<BoardUpdateState>({
        boardUpdateRequest: {
            appCode: sessionStorage.getItem(MBAAS_APP_CODE) ?? undefined,
            noticeCode: "",
            noticeTitle: "",
            noticeContent: "",
            noticeStartDate: "",
            fileId: "",
        },
    });
    const axios = useAxios();

    useEffect(() => {
        setStatusValue({
            ...statusValue,
            boardUpdateRequest: {
                ...statusValue.boardUpdateRequest,
                noticeCode: boardReadHook.statusValue.boardReadRequest.noticeCode,
                noticeTitle: boardReadHook.statusValue.boardReadResponse?.title!,
                noticeContent: boardReadHook.statusValue.boardReadResponse?.content!,
                noticeStartDate: boardReadHook.statusValue.boardReadResponse?.startDate!,
                fileId: boardReadHook.statusValue.boardReadResponse?.fileId,
            },
            fileResponse: boardReadHook.statusValue.boardReadResponse?.files,
        })
    }, [boardReadHook.statusValue])

    const onChange = (e: any) => {
        setStatusValue({
            ...statusValue,
            boardUpdateRequest: {
                ...statusValue.boardUpdateRequest,
                [e.target.name] : e.target.value
            }
        })
    }

    const onChangeContents = (data: string) => {
        setStatusValue({
            ...statusValue,
            boardUpdateRequest: {
                ...statusValue.boardUpdateRequest,
                noticeContent: data,
            }
        })
    }

    const validUpdateNotice = ():boolean => {
        fn_Debug(statusValue.boardUpdateRequest);
        if(!statusValue.boardUpdateRequest.noticeTitle) {
            alertContext.openAlert("제목은 필수값 입니다.", "warning");
            return false;
        }

        if(!statusValue.boardUpdateRequest.noticeStartDate) {
            alertContext.openAlert("게시일은 필수값 입니다.", "warning");
            return false;
        }

        if(!statusValue.boardUpdateRequest.noticeContent) {
            alertContext.openAlert("내용은 필수값 입니다.", "warning");
            return false;
        }

        return true;
    }

    const updateNotice = async() => {
        if(!validUpdateNotice()) return;
        const response = await axios.putData(
            sessionStorage.getItem(MBAAS_APP_CODE) ? DEFINE_UPDATE_APP_NOTICE : DEFINE_UPDATE_NOTICE
            , statusValue.boardUpdateRequest
        );
        fn_Debug("UPDATE_NOTICE_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            navigator(M_BOARD_READ_PATH, {
                state: {
                    noticeCode: statusValue.boardUpdateRequest.noticeCode
                    , updateAlert: true
                    , flag: "success",
                }
            });
        } else {
            alertContext.openAlert(response.message, "error");
        }
    }

    const updateNoticeFileId = async(fileId: string) => {
        setStatusValue({
            ...statusValue,
            boardUpdateRequest: {
                ...statusValue.boardUpdateRequest,
                fileId: fileId
            }
        })
    }

    const getOriginalContents = () => {
        return boardReadHook.statusValue.boardReadResponse?.content;
    }

    return {
        statusValue,
        onChange,
        onChangeContents,
        updateNotice,
        getOriginalContents,
        updateNoticeFileId,
    }
}