import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ITEM_LIST_PATH } from '../../../../../common/path';
import Editor from '../../../../common/Editor';
import FileUpload from '../../../../common/FileUpload';
import { useItemCreateHook } from './ItemCreate.hook';


const ItemCreate = () => {
  const navigator = useNavigate();
  const itemCreateHook = useItemCreateHook();

  return (
    <>
        <h2>아이템 관리 생성</h2>

        <div className="boxContents">
        <table className="tableCol">
            <tr>
            <th>이름</th>
            <td><input type="text" name='name' onChange={itemCreateHook.onChange} placeholder="이름을 입력하세요."/></td>
            </tr>
            <tr>
            <th>가격</th>
            <td><input type="number" name='price' onChange={itemCreateHook.onChange} placeholder="가격을 입력하세요."/>
                {/* <select>
                <option>KRW</option>
                <option>USD</option>
                <option>JPY</option>
                <option>EUR</option>
                <option>CNY</option>
                </select> */}
            </td>
            </tr>
            <tr>
            <th>설명</th>
            <td>
                <div>
                    <Editor onChange={itemCreateHook.onChangeContent}/>
                </div>
            </td>
            </tr>
            <tr>
            <th>이미지<br/>(가로 000px * 세로 000px)</th>
            <td>
                <FileUpload afterPost={itemCreateHook.onChangeFileId} maxFile={5}/>
            </td>
            </tr>
            {/* <tr>
            <th>사용 여부</th>
            <td>
                <label>
                <input type="radio" name="use" checked/> 사용
                </label>
                <label>
                <input type="radio" name="use"/> 미사용
                </label>
            </td>
            </tr> */}
        </table>
        <div className="btnBoxB">
            <button className="off" onClick={() => {navigator(ITEM_LIST_PATH)}}>목록</button>
            <div>
            <button className="off" onClick={() => {navigator(ITEM_LIST_PATH)}}>취소</button>
            <button className="on" onClick={itemCreateHook.createItem}>생성</button>
            </div>
        </div>
        </div>
    </>
  )
}

export default ItemCreate