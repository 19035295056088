import { useEffect, useState } from "react";
import { DEFINE_TEMCATEGORY_LIST, RESPONSE_RESULT_SUCCESS } from "../../../common/const";
import { useAxios } from "../../../common/hooks"
import { fn_Debug } from "../../../common/module";
import { TemplateState } from "./Template.model";
import { AppUseCate } from "./tem/list/TemList.model";

export const useTemplateHook = () => {
    const axios = useAxios();
    const [statusValue, setStatusValue] = useState<TemplateState>();

    useEffect(() => {
        getCategoryList();
    }, [])

    const getCategoryList = async() => {
        const response = await axios.getData<AppUseCate[]>(DEFINE_TEMCATEGORY_LIST);

        fn_Debug("GET_TEMCATEGORY_LIST_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setStatusValue({
                ...statusValue,
                temCategoryResponse: {
                    categoryList: response.data
                }
            })
        }
    }

    const convertCateListToStrList = (originalArr: AppUseCate[]):string[] =>{
        let newArr = [];
        for(let i = 0; i < originalArr?.length; i++) {
            newArr.push(originalArr[i].value);
        }
        return newArr;
    }

    return {
        statusValue,
        convertCateListToStrList,
    }
}