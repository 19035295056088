import React from 'react'
import ApUpdateT1 from './ApUpdateT1'
import ApUpdateT2 from './ApUpdateT2'
import ApUpdateT3 from './ApUpdateT3'
import { useUpdateTabHook } from './ApUpdate.hook'
import { useNavigate } from 'react-router-dom'
import { AP_LIST_PATH, AP_READ_PATH } from '../../../../../common/path'

const ApUpdate = () => {
  const navigator = useNavigate();
  const tabHook = useUpdateTabHook();
  
  return (
    <>
        <h2>앱 관리 수정</h2>

        <div className="boxContents">
        <table className="table table--block">
            <thead>
            <tr>
                <th style={{width: "10%"}}>이름</th>
                <th style={{width: "10%"}}>앱용도</th>
                <th style={{width: "10%"}}>앱생성자</th>
                <th>앱관리자</th>
                <th>회사정보</th>
                <th>대표자명</th>
                <th>대표이메일</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>value</td>
                <td>value</td>
                <td>LoginID</td>
                <td>
                <ul>
                    <li>
                    <label>
                        <input type="checkbox" checked/> mMemID(Name)
                    </label>
                    </li>
                    <li>
                    <label>
                        <input type="checkbox"/> mMemID(Name)
                    </label>
                    </li>
                    <li>
                    <label>
                        <input type="checkbox"/> mMemID(Name)
                    </label>
                    </li>
                </ul>
                </td>
                <td>
                <ul>
                    <li>
                    <label>
                        <input type="text" placeholder="회사명"/>
                    </label>
                    </li>
                    <li>
                    <label>
                        <input type="text" placeholder="사업자등록번호"/>
                    </label>
                    </li>
                    <li>
                    <label>
                        <input type="text" placeholder="통신판매업번호"/>
                    </label>
                    </li>
                </ul>
                </td>
                <td><input type="text"/></td>
                <td><input type="text"/></td>
            </tr>
            </tbody>
        </table>
        <br />
        <br />
        <ul className="tapMenu">
            <li onClick={() => {tabHook.onClick(1)}} className={tabHook.currIdx === 1 ? "on" : ""}>회사 정보</li>
            <li onClick={() => {tabHook.onClick(2)}} className={tabHook.currIdx === 2 ? "on" : ""}>템플릿 설정</li>
            <li onClick={() => {tabHook.onClick(3)}} className={tabHook.currIdx === 3 ? "on" : ""}>추가기능 설정</li>
        </ul>
        {tabHook.currIdx === 1 ? <ApUpdateT1/> : ""}
        {tabHook.currIdx === 2 ? <ApUpdateT2/> : ""}
        {tabHook.currIdx === 3 ? <ApUpdateT3/> : ""}
        <div className="btnBoxB">
            <div>
            <button className="off" onClick={() => {navigator(AP_LIST_PATH)}}>목록</button>
            <button className="off">중간저장</button>
            <button className="off">상담요청</button>
            </div>
            <div>
            <button className="off" onClick={() => {navigator(AP_READ_PATH)}}>취소</button>
            <button className="on" onClick={() => {navigator(AP_READ_PATH)}}>저장</button>
            </div>
        </div>
        </div>
    </>
  )
}

export default ApUpdate