import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useBoardCreateHook } from './BoardCreate.hook';
import Editor from '../../../../common/Editor';
import FileUpload from '../../../../common/FileUpload';
import { MAX_TITLE_LENGTH } from '../../../../../common/config';

const BoardCreate = () => {
  const navigator = useNavigate();
  const boardCreateHook = useBoardCreateHook();

  return (
    <>
      <h2>공지사항 등록</h2>
      <div className="boxContents">
        <table className="tableCol">
          <tr>
            <th>제목*(최대 100글자까지 작성가능합니다.)</th>
            <td>
              <label>
                <input type="text" name='noticeTitle' onChange={boardCreateHook.onChange} placeholder="제목을 입력해주세요." max={MAX_TITLE_LENGTH}/>
              </label>
            </td>
          </tr>
          {/* <tr>
            <th>앱선택*</th>
            <td>
              <select>
                <option>앱 선택</option>
                <option>앱이름1</option>
                <option>앱이름2</option>
                <option>앱이름3</option>
              </select>
            </td>
          </tr> */}
          <tr>
            <th>등록 게시일*</th>
            <td>
              <input type="date" name='noticeStartDate' onChange={boardCreateHook.onChange}/>&nbsp;&nbsp;<span className="warningRow">!등록개시일을 입력해주세요.</span>
            </td>
          </tr>
          <tr>
            <th>내용*</th>
            <td>
              <div>
                <Editor
                  onChange={boardCreateHook.onChangeContent}
                />
              </div>
            </td>
          </tr>
          <tr>
            <th>첨부파일</th>
            <td>
              <FileUpload
                afterPost={boardCreateHook.setFileId}
              />
            </td>
          </tr>
        </table>
        <div className="btnBox">
          <button className="off" onClick={() => {navigator(-1)}}>취소</button>
          <button className="on" onClick={boardCreateHook.createBoard}>등록</button>
        </div>
      </div>
    </>
  )
}

export default BoardCreate
