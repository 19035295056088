import React from 'react'
import { useNavigate } from 'react-router-dom'
import { M_BOARD_CREATE_PATH, M_BOARD_READ_PATH } from '../../../../../common/path';
import { useBoardListHook } from './BoardList.hook';
import Paging from '../../../../common/Paging';
import NoActionAlert from '../../../../alert/NoActionAlert';
import DefaultPopup from '../../../../alert/DefaultPopup';
import DeleteNoticePopup from '../../../../popup/DeleteNoticePopup';

const BoardList = () => {
  const navigator = useNavigate();
  const boardListHook = useBoardListHook();

  return (
    <>
      <h2>공지사항</h2>
      <div className="flex flexE" onClick={() => {navigator(M_BOARD_CREATE_PATH)}} style={{marginBottom:"10px"}}><button className="on ">공지사항 등록</button></div>

      {
      boardListHook.statusValue.totalCount !== 0 ?
        <>
          <div className="flex spaceB alignC" style={{marginTop: "20px"}}>
            <div className="search">
              <select>
                <option value="all">전체</option> 
                <option value="title" selected>제목</option>
                <option value="registrant">등록자</option>
                <option value="editor">편집자</option>
              </select>
              <input type="text" placeholder="검색어를 입력해주세요."/>
              <button><img src={require("../../../../../img/icon_search_white.png")} alt="검색" /></button>
            </div>
            <div className="filter">
              <select>
                <option value="all" selected>제목순</option>
                <option value="all">등록일순</option>
                <option value="bo">등록 게시일순</option>
              </select>
            </div>
          </div>
          <div className="boxContents">
            <button onClick={() => {boardListHook.setDeletePopup(true);}}>삭제</button>
            <table className="table table--block">
              <thead>
                <tr>
                  <th className="tableShort"><input type="checkbox" onClick={boardListHook.onCheckAll}/></th>
                  <th className="tableShort">No</th>
                  <th className="tableLong noticeLong">제목</th>
                  <th>등록일</th>
                </tr>
              </thead>
              <tbody>
                {
                  boardListHook.statusValue.boardListResponse.noticeList.map((board, index) => (
                    <tr onClick={() => {navigator(M_BOARD_READ_PATH, {state: {noticeCode: board.noticeCode}})}} key={board.noticeCode}>
                      <td onClick={(e) => {e.stopPropagation();}} className="tableShort">
                        <input 
                          name={board.noticeCode} 
                          onClick={boardListHook.onCheck} 
                          type="checkbox"
                          checked={boardListHook.deleteRequest.includes(board.noticeCode)}
                        />
                      </td>
                      <td data-name="No">{index}</td>
                      <td data-name="제목" className='noticeLong'>{board.noticeTitle}</td>
                      <td data-name="등록일">{board.noticeStartDate}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            <div className="page">
              <Paging
                itemOffset={boardListHook.statusValue.pagination.itemOffset}
                setItemOffset={boardListHook.setItemOffset}
                itemsLength={boardListHook.statusValue.totalCount}
              />
            </div>
          </div>
        </> : 
        <NoActionAlert
              alertText='등록된 공지사항이 없습니다.'
              open={true}
              serverity='warning'
        />
      }

     {
      boardListHook.deletePopup ?
      <DefaultPopup
        zIndex={500}
      >
        <DeleteNoticePopup
          onCancle={() => {boardListHook.setDeletePopup(false)}}
          onConfirm={boardListHook.deleteNotice}
        />
      </DefaultPopup> : ""
     }
    </>
  )
}

export default BoardList