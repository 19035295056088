import React from 'react'
import { useNavigate } from 'react-router-dom'
import { APP_USE_CREATE_PATH, APP_USE_READ_PATH } from '../../../../../common/path';

const AppUseList = () => {
  const navigator = useNavigate();
  return (
    <>
        <h2>앱용도 관리</h2>
        <div className="flex flexE" onClick={() => {navigator(APP_USE_CREATE_PATH)}}><button className="on">앱용도 등록</button></div>
        <div className="flex spaceB alignC" style={{marginTop: "20px"}}>
            <div className="search">
                <select>
                    <option value="all">전체</option>          
                    <option value="name" selected>이름</option>
                    <option value="explanation">설명</option>
                </select>
                <input type="text" placeholder="검색어를 입력해주세요."/>
                <button><img src={require("../../../../../img/icon_search_white.png")} alt="검색"/></button>
            </div>
            <div className="filter">
                <select>
                    <option value="all">전체</option>
                    <option value="use" selected>사용</option>
                    <option value="notUse">미사용</option>
                </select>
                <select>
                    <option value="date">생성일순</option>
                    <option value="amount"  selected>사용앱 수</option>
                    <option value="name">이름순</option>
                </select>
            </div>
        </div>
        
        <div className="boxContents">
            <table className="table table--block">
                <thead>
                    <th className="tableShort">No</th>
                    <th>이름</th>
                    <th>생성일</th>
                    <th>사용앱 수</th>
                    <th>상태</th>
                </thead>
                <tbody>
                    <tr onClick={() => {navigator(APP_USE_READ_PATH)}}>
                        <td data-name="No.">100</td>
                        <td data-name="이름">Take-out 전문점 앱</td>
                        <td data-name="생성일">YYYY-MM-DD</td>
                        <td data-name="사용앱 수">50</td>
                        <td data-name="상태"><span className="use">사용</span></td>
                    </tr>
                    <tr>
                        <td data-name="No.">99</td>
                        <td data-name="이름">원데이 클래스 앱</td>
                        <td data-name="생성일">YYYY-MM-DD</td>
                        <td data-name="사용앱 수">50</td>
                        <td data-name="상태"><span className="use">사용</span></td>
                    </tr>
                    <tr>
                        <td data-name="No.">98</td>
                        <td data-name="이름">사진공유</td>
                        <td data-name="생성일">YYYY-MM-DD</td>
                        <td data-name="사용앱 수">50</td>
                        <td data-name="상태"><span className="use">사용</span></td>
                    </tr>
                    <tr>
                        <td data-name="No.">97</td>
                        <td data-name="이름">협회앱</td>
                        <td data-name="생성일">YYYY-MM-DD</td>
                        <td data-name="사용앱 수">50</td>
                        <td data-name="상태"><span className="use">사용</span></td>
                    </tr>
                </tbody>
            </table>
            <div className="page">
                <ul className="pagination modal">
                    <li className="arrow_left">처음</li>
                    <li className="num active">1</li>
                    <li className="num">2</li>
                    <li className="num">3</li>
                    <li className="num">4</li>
                    <li className="num">5</li>
                    <li className="arrow_right">다음</li>
                </ul>
            </div>
        </div>
    </>
  )
}

export default AppUseList