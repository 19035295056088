import { ADMIN, APP, CREATE, LIST, MBAAS, MEMBER, UPDATE } from "./const";

export const LOGIN_PATH: string = "/login";

export const ROOT_PATH: string = "/";

export const COM_MEMBER_PATH: string = "/manage";
export const M_MEMBER_PATH: string = `${COM_MEMBER_PATH}/${MBAAS}/${MEMBER}`; // /member/mbaas/member
export const M_MEMBER_UPDATE_PATH: string = `${M_MEMBER_PATH}/${UPDATE}/insertid`;
export const M_MEMBER_READ_PATH: string = `${M_MEMBER_PATH}/read`;
export const M_ADMIN_PATH: string = `${COM_MEMBER_PATH}/${MBAAS}/${ADMIN}`; // /member/mbaas/admin
export const M_ADMIN_READ_PATH: string = `${M_ADMIN_PATH}/insertid`

export const APP_MEMBER_PATH: string = `${COM_MEMBER_PATH}/${APP}/${MEMBER}`; // /member/app/member
export const APP_ADMIN_PATH: string = `${COM_MEMBER_PATH}/${APP}/${ADMIN}`; // /member/app/admin
export const APP_ADMIN_READ_PATH: string = `${APP_ADMIN_PATH}/insertid`
export const APP_MEMBER_READ_PATH: string = `${APP_MEMBER_PATH}/insertid`;

export const FIND_PW_PATH: string = "/findpw";

export const COM_TEMPLATE_PATH: string = "/template";
export const COM_FUNC_PATH: string = "func";
export const ADD_FUNC_CREATE_PATH: string = `${COM_TEMPLATE_PATH}/${COM_FUNC_PATH}/${CREATE}`;
export const ADD_FUNC_LIST_PATH: string = `${COM_TEMPLATE_PATH}/${COM_FUNC_PATH}/${LIST}`;
export const ADD_FUNC_READ_PATH: string = `${COM_TEMPLATE_PATH}/${COM_FUNC_PATH}/insertid`;
export const ADD_FUNC_UPDATE_PATH: string = `${COM_TEMPLATE_PATH}/${COM_FUNC_PATH}/${UPDATE}/insertid`;

export const COM_APP_USE_PATH: string = `appUse`;
export const APP_USE_LIST_PATH:string = `${COM_TEMPLATE_PATH}/${COM_APP_USE_PATH}/${LIST}`;
export const APP_USE_CREATE_PATH: string = `${COM_TEMPLATE_PATH}/${COM_APP_USE_PATH}/${CREATE}`;
export const APP_USE_READ_PATH: string = `${COM_TEMPLATE_PATH}/${COM_APP_USE_PATH}/insertid`
export const APP_USE_UPDATE_PATH: string = `${COM_TEMPLATE_PATH}/${COM_APP_USE_PATH}/${UPDATE}/insertid`

export const COM_SIM_APP_PATH: string = `simApp`;
export const SIM_APP_LIST_PATH: string = `${COM_TEMPLATE_PATH}/${COM_SIM_APP_PATH}/${LIST}`;
export const SIM_APP_CREATE_PATH: string = `${COM_TEMPLATE_PATH}/${COM_SIM_APP_PATH}/${CREATE}`;
export const SIM_APP_READ_PATH: string = `${COM_TEMPLATE_PATH}/${COM_SIM_APP_PATH}/insertId`;
export const SIM_APP_UPDATE_PATH: string = `${COM_TEMPLATE_PATH}/${COM_SIM_APP_PATH}/${UPDATE}/insertId`;

export const COM_TEM_PATH: string = `tem`;
export const TEM_LIST_PATH: string = `${COM_TEMPLATE_PATH}/${COM_TEM_PATH}/${LIST}`;
export const TEM_CREATE_PATH: string = `${COM_TEMPLATE_PATH}/${COM_TEM_PATH}/${CREATE}`;
export const TEM_READ_PATH: string = `${COM_TEMPLATE_PATH}/${COM_TEM_PATH}/insertid`;
export const TEM_UPDATE_PATH: string = `${COM_TEMPLATE_PATH}/${COM_TEM_PATH}/${UPDATE}/insertid`;

export const COM_BOARD_PATH: string = "/board";
export const APP_BOARD_UPDATE_PATH: string = `${COM_BOARD_PATH}/${APP}/${UPDATE}`;
export const M_BOARD_LIST_PATH: string = `${COM_BOARD_PATH}/${MBAAS}/${LIST}`;
export const M_BOARD_CREATE_PATH: string = `${COM_BOARD_PATH}/${MBAAS}/${CREATE}`;
export const M_BOARD_READ_PATH: string = `${COM_BOARD_PATH}/${MBAAS}/view`;
export const M_BOARD_UPDATE_PATH: string = `${COM_BOARD_PATH}/${MBAAS}/${UPDATE}`;


export const COM_ADV_PATH: string = "adv";
export const ADV_LIST_PATH: string = `${COM_TEMPLATE_PATH}/${COM_ADV_PATH}/${LIST}`
export const ADV_CREATE_PATH: string = `${COM_TEMPLATE_PATH}/${COM_ADV_PATH}/${CREATE}`
export const ADV_READ_PATH: string = `${COM_TEMPLATE_PATH}/${COM_ADV_PATH}/insertid`
export const ADV_UPDATE_PATH: string = `${COM_TEMPLATE_PATH}/${COM_ADV_PATH}/${UPDATE}/insertid`

export const COM_AP_PATH: string = "ap";
export const AP_LIST_PATH: string = `${COM_TEMPLATE_PATH}/${COM_AP_PATH}/${LIST}`
export const AP_CREATE_PATH: string = `${COM_TEMPLATE_PATH}/${COM_AP_PATH}/${CREATE}`
export const AP_READ_PATH: string = `${COM_TEMPLATE_PATH}/${COM_AP_PATH}/insertid`
export const AP_UPDATET_PATH: string = `${COM_TEMPLATE_PATH}/${COM_AP_PATH}/${UPDATE}/insertid`

export const COM_ITEM_PATH: string = "item";
export const ITEM_LIST_PATH: string = `${COM_TEMPLATE_PATH}/${COM_ITEM_PATH}/${LIST}`;
export const ITEM_READ_PATH: string = `${COM_TEMPLATE_PATH}/${COM_ITEM_PATH}/insertid`;
export const ITEM_CREATE_PATH: string = `${COM_TEMPLATE_PATH}/${COM_ITEM_PATH}/${CREATE}`;
export const ITEM_UPDATE_PATH: string = `${COM_TEMPLATE_PATH}/${COM_ITEM_PATH}/${UPDATE}/insertid`;

export const COM_RESERVE_PATH: string = "reserve";
export const RESERVE_LIST_PATH: string = `${COM_TEMPLATE_PATH}/${COM_RESERVE_PATH}/${LIST}`;
export const RESERVE_CREATE_PATH: string = `${COM_TEMPLATE_PATH}/${COM_RESERVE_PATH}/${CREATE}`;
export const RESERVE_READ_PATH: string = `${COM_TEMPLATE_PATH}/${COM_RESERVE_PATH}/read`;
export const RESERVE_UPDATE_PATH: string = `${COM_TEMPLATE_PATH}/${COM_RESERVE_PATH}/${UPDATE}`;


export const COM_CONSULT_PATH: string = `consult`;
export const CONSULT_LIST_PATH: string = `${COM_TEMPLATE_PATH}/${COM_CONSULT_PATH}/${LIST}`;
export const CONSULT_CREATE_PATH: string = `${COM_TEMPLATE_PATH}/${COM_CONSULT_PATH}/${CREATE}`;
export const CONSULT_READ_PATH: string = `${COM_TEMPLATE_PATH}/${COM_CONSULT_PATH}/insertid`;
export const CONSULT_UPDATE_PATH: string = `${COM_TEMPLATE_PATH}/${COM_CONSULT_PATH}/${UPDATE}/insertid`;

export const COM_APHELP_PATH: string = `appHelp`;
export const APHELP_CREATE_PATH: string = `${COM_TEMPLATE_PATH}/${COM_APHELP_PATH}/${CREATE}`;
export const APHELP_READ_PATH: string = `${COM_TEMPLATE_PATH}/${COM_APHELP_PATH}/read`;
export const APHELP_LIST_PATH: string = `${COM_TEMPLATE_PATH}/${COM_APHELP_PATH}`;
export const APHELP_UPDATE_PATH: string = `${COM_TEMPLATE_PATH}/${COM_APHELP_PATH}/update`;

export const COM_SETTING_PATH: string = `/setting`;
export const SETTING_SYSTEM_PATH: string = `${COM_SETTING_PATH}/system`
export const SETTING_IMG_PATH: string = `${COM_SETTING_PATH}/img`;

export const COM_USERASK_PATH: string = `/userAsk`;
export const USERASK_LIST_PATH: string = `${COM_USERASK_PATH}/${LIST}`;
export const USERASK_CREATE_PATH: string = `${COM_USERASK_PATH}/${CREATE}`;
export const USERASK_UPDATE_PATH: string = `${COM_USERASK_PATH}/${UPDATE}/insertid`;
export const USERASK_READ_PATH: string = `${COM_USERASK_PATH}/insertid`;

export const COM_USERASK_ANSWER_PATH: string = `${COM_USERASK_PATH}/answer`;
export const USERASK_ANSWER_READ_PATH: string = `${COM_USERASK_ANSWER_PATH}/insertid`;

export const COM_MYPAGE_PATH: string = `/mypage`;
export const MYPAGE_READ_PATH: string = `${COM_MYPAGE_PATH}/insertid`;
export const MYPAGE_UPDATE_PATH: string = `${COM_MYPAGE_PATH}/${UPDATE}/insertid`;

export const COM_COMPANY_PATH: string = `/company`;
export const COMPANY_CREATE_PATH: string = `${COM_COMPANY_PATH}/${CREATE}`;
export const COMPANY_READ_PATH: string = `${COM_COMPANY_PATH}/read`;

export const BRIDGE_PATH: string = `/bridge`;

export const PLANNING_PATH: string = `/planning`;
export const PLANNING_BRIDGE_PATH: string = `${PLANNING_PATH}/bridge`;
export const PLANNING_COMPANYINFO_PATH: string = `${PLANNING_PATH}/companyinfo`;
export const PLANNING_COMPANYINFO_UPDATE_PATH: string = `${PLANNING_PATH}/companyinfo/update`;

export const PLANNING_SIMAPPINFO_PATH: string = `${PLANNING_PATH}/simappinfo`;

export const PLANNING_APPINFO_PATH: string = `${PLANNING_PATH}/appinfo`;
export const PLANNING_APPINFO_UPDATE_PATH: string = `${PLANNING_PATH}/appinfo/update`;

