import React from 'react'
import { useNavigate } from 'react-router-dom'
import { M_BOARD_LIST_PATH, M_BOARD_UPDATE_PATH } from '../../../../../common/path';
import { useBoardReadHook } from './BoardRead.hook';
import parse from 'html-react-parser';
import BasicAlert from '../../../../alert/BasicAlert';
import DefaultPopup from '../../../../alert/DefaultPopup';
import DeleteNoticePopup from '../../../../popup/DeleteNoticePopup';
import { FILE_HOST } from '../../../../../common/config';

const BoardRead = () => {
  const navigator = useNavigate();
  const boardReadHook = useBoardReadHook();

  return (
    <>
      <h2>공지사항 상세</h2>
      <BasicAlert
        alertText={boardReadHook.statusValue.updateAlertParam.alertText}
        open={boardReadHook.statusValue.updateAlertParam.open}
        serverity={boardReadHook.statusValue.updateAlertParam.serverity}
        setOpen={boardReadHook.changeUpdateAlert}
      />
      <div className="boxContents">
        <table className="tableCol">
          <tr>
            <th>제목*</th>
            <td>{boardReadHook.statusValue.boardReadResponse?.title}</td>
          </tr>
          <tr>
            <th>앱선택</th>
            <td>앱이름</td>
          </tr>
          <tr>
            <th>등록일</th>
            <td>{boardReadHook.statusValue.boardReadResponse?.startDate}</td>
          </tr>
          <tr>
            <th>첨부파일</th>
            <td>
              {
                boardReadHook.statusValue.boardReadResponse?.files ? boardReadHook.statusValue.boardReadResponse?.files.map(file => (
                  <p>
                    <a href={`${FILE_HOST}${file.fleUrl}`} download={true}>{file.fleOrgNm}</a>
                  </p>
                )) : <p>첨부된 파일이 없습니다.</p>
              }
            </td>
          </tr>
          <tr>
            <th>내용*</th>
            <td>
              {parse(boardReadHook.statusValue.boardReadResponse?.content ?? "")}
            </td>
          </tr>
        </table>
        <table className="tableCol" style={{marginTop: "100px"}}>
          <tr>
            <th>다음글</th>
            {
              boardReadHook.statusValue.boardReadResponse?.nextContent ?
                <td
                onClick={() => {boardReadHook.onClickPreviewBoard(boardReadHook.statusValue.boardReadResponse?.nextContent?.noticeCode)}}
                >
                    {boardReadHook.statusValue.boardReadResponse?.nextContent.noticeTitle}
                  </td> 
                : <td style={{color: "#999"}}>다음글이 없습니다.</td>
            }
          </tr>
          <tr>
            <th>이전글</th>
            {
              boardReadHook.statusValue.boardReadResponse?.prevContent ?
                <td
                  onClick={() => {boardReadHook.onClickPreviewBoard(boardReadHook.statusValue.boardReadResponse?.prevContent?.noticeCode)}}
                >
                  {boardReadHook.statusValue.boardReadResponse?.prevContent.noticeTitle}
                </td> 
                : <td style={{color: "#999"}}>이전글이 없습니다.</td>
            }
          </tr>
        </table>
        <div className="btnBox">
          <button className="off" onClick={() => {navigator(M_BOARD_LIST_PATH)}}>목록으로</button>
          <button className="off" onClick={() => {boardReadHook.setDeletePopup(true)}}>삭제</button>
          <button className="on" onClick={() => {navigator(M_BOARD_UPDATE_PATH, {state: {noticeCode: boardReadHook.statusValue.boardReadRequest.noticeCode}})}}>수정</button>
        </div>
        {
          boardReadHook.deletePopup ?
            <DefaultPopup
              zIndex={500}
            >
              <DeleteNoticePopup
                onCancle={() => {boardReadHook.setDeletePopup(false)}}
                onConfirm={boardReadHook.deleteNotice}
              />
            </DefaultPopup> : ""
        }
      </div>
    </>
  )
}

export default BoardRead