import React from 'react'
// import FileUpload from '../../../../common/FileUpload';

const HelpApCreate = () => {
  return (
    <>
        <h2>앱관리 생성</h2>

      <div className="boxContents">
        {/* <ul className="tapMenu">
          <li>앱1</li>
          <li className="on">앱2</li>
          <li>앱3</li>
        </ul> */}
        <table className="tableCol">
          <tr>
            <th>앱이름</th>
            <td>
              <input type="text"/>
            </td>
          </tr>
          <tr>
            <th>회사명</th>
            <td>
              <select name="" id="">
                <option value="">회사이름1</option>
                <option value="">회사이름2</option>
                <option value="">회사이름3</option>
              </select>
            </td>
          </tr>
          <tr>
            <th>앱유형</th>
            <td>
              <select>
                <option>원데이클래스</option>
                <option>사진공유</option>
                <option>테이크아웃</option>
                <option>협회</option>
              </select>
            </td>
          </tr>
          <tr>
            <th>앱아이콘</th>
            {/* <td><FileUpload></FileUpload><span>* 이미지 사이즈 120x120</span></td> */}
          </tr>
          <tr>
            <th>템플릿</th>
            <td>
              <ul className="temFlex">
                <li>
                  <label>
                    <input type="radio" name="temSelct"/> 템플릿명
                  </label>
                  <ul className="temImg">
                    <li><img src={require("../../../../../img/slickimg1.png")} alt="템플릿1"/></li>
                    <li><img src={require("../../../../../img/slickimg2.png")} alt="템플릿2"/></li>
                    <li><img src={require("../../../../../img/slickimg3.png")} alt="템플릿3"/></li>
                  </ul>
                  <p>템플릿 설명</p>
                </li>
                <li>
                  <label>
                    <input type="radio" name="temSelct"/> 템플릿명
                  </label>
                  <ul className="temImg">
                    <li><img src={require("../../../../../img/slickimg1.png")} alt="템플릿1"/></li>
                    <li><img src={require("../../../../../img/slickimg2.png")} alt="템플릿2"/></li>
                    <li><img src={require("../../../../../img/slickimg2.png")} alt="템플릿3"/></li>
                  </ul>
                  <p>템플릿 설명</p>
                </li>
                <li>
                  <label>
                    <input type="radio" name="temSelct"/> 템플릿명
                  </label>
                  <ul className="temImg">
                    <li><img src={require("../../../../../img/slickimg1.png")} alt="템플릿1"/></li>
                    <li><img src={require("../../../../../img/slickimg2.png")} alt="템플릿2"/></li>
                    <li><img src={require("../../../../../img/slickimg2.png")} alt="템플릿3"/></li>
                  </ul>
                  <p>템플릿 설명</p>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <th>유사앱 추천</th>
            <td>
              <ul className="temFlex">
                <li>
                  <p>유사앱명</p>
                  <ul className="temImg">
                    <li><img src={require("../../../../../img/etcImg.png")} alt="템플릿1"/></li>
                    <li><img src={require("../../../../../img/etcImg.png")} alt="템플릿2"/></li>
                    <li><img src={require("../../../../../img/etcImg.png")} alt="템플릿3"/></li>
                  </ul>
                  <p>유사앱 설명</p>
                </li>
                <li>
                  <p>유사앱명</p>
                  <ul className="temImg">
                    <li><img src={require("../../../../../img/etcImg.png")} alt="템플릿1"/></li>
                    <li><img src={require("../../../../../img/etcImg.png")} alt="템플릿2"/></li>
                    <li><img src={require("../../../../../img/etcImg.png")} alt="템플릿3"/></li>
                  </ul>
                  <p>유사앱 설명</p>
                </li>
                <li>
                  <p>유사앱명</p>
                  <ul className="temImg">
                    <li><img src={require("../../../../../img/etcImg.png")} alt="템플릿1"/></li>
                    <li><img src={require("../../../../../img/etcImg.png")} alt="템플릿2"/></li>
                    <li><img src={require("../../../../../img/etcImg.png")} alt="템플릿3"/></li>
                  </ul>
                  <p>유사앱 설명</p>
                </li>
                <li>
                  <p>유사앱명</p>
                  <ul className="temImg">
                    <li><img src={require("../../../../../img/etcImg.png")} alt="템플릿1"/></li>
                    <li><img src={require("../../../../../img/etcImg.png")} alt="템플릿2"/></li>
                    <li><img src={require("../../../../../img/etcImg.png")} alt="템플릿3"/></li>
                  </ul>
                  <p>유사앱 설명</p>
                </li>
                <li>
                  <p>유사앱명</p>
                  <ul className="temImg">
                    <li><img src={require("../../../../../img/etcImg.png")} alt="템플릿1"/></li>
                    <li><img src={require("../../../../../img/etcImg.png")} alt="템플릿2"/></li>
                    <li><img src={require("../../../../../img/etcImg.png")} alt="템플릿3"/></li>
                  </ul>
                  <p>유사앱 설명</p>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <th>추가기능</th>
            <td>
              <ul className="temFlex">
                <li>
                  <p>추가기능명</p>
                  <ul className="addFuncImg">
                    <li><img src={require("../../../../../img/etcImg.png")} alt="템플릿1"/></li>
                    <li><img src={require("../../../../../img/etcImg.png")} alt="템플릿2"/></li>
                    <li><img src={require("../../../../../img/etcImg.png")} alt="템플릿3"/></li>
                  </ul>
                  <p>추가기능 설명</p>
                </li>
                <li>
                  <p>추가기능명</p>
                  <ul className="addFuncImg">
                    <li><img src={require("../../../../../img/etcImg.png")} alt="템플릿1"/></li>
                    <li><img src={require("../../../../../img/etcImg.png")} alt="템플릿2"/></li>
                    <li><img src={require("../../../../../img/etcImg.png")} alt="템플릿3"/></li>
                  </ul>
                  <p>추가기능 설명</p>
                </li>
                <li>
                  <p>추가기능명</p>
                  <ul className="addFuncImg">
                    <li><img src={require("../../../../../img/etcImg.png")} alt="템플릿1"/></li>
                    <li><img src={require("../../../../../img/etcImg.png")} alt="템플릿2"/></li>
                    <li><img src={require("../../../../../img/etcImg.png")} alt="템플릿3"/></li>
                  </ul>
                  <p>추가기능 설명</p>
                </li>
                <li>
                  <p>추가기능명</p>
                  <ul className="addFuncImg">
                    <li><img src={require("../../../../../img/etcImg.png")} alt="템플릿1"/></li>
                    <li><img src={require("../../../../../img/etcImg.png")} alt="템플릿2"/></li>
                    <li><img src={require("../../../../../img/etcImg.png")} alt="템플릿3"/></li>
                  </ul>
                  <p>추가기능 설명</p>
                </li>
                <li>
                  <p>추가기능명</p>
                  <ul className="addFuncImg">
                    <li><img src={require("../../../../../img/etcImg.png")} alt="템플릿1"/></li>
                    <li><img src={require("../../../../../img/etcImg.png")} alt="템플릿2"/></li>
                    <li><img src={require("../../../../../img/etcImg.png")} alt="템플릿3"/></li>
                  </ul>
                  <p>추가기능 설명</p>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <th>기획서</th>
            <td>
              <button>파일 등록</button>
              <button>기획서 바로관리</button>
            </td>
          </tr>
        </table>
        <div className="btnBoxB">
          <div>
            <button className="off">중간저장</button>
          </div>
          <div>
            <button className="off">취소</button>
            <button className="on">생성</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default HelpApCreate