import { useEffect, useState } from "react"
import { MyPageReadResponse, MyPageReadState } from "./MyPageRead.model"
import { DEFINE_GET_MY_INFO, RESPONSE_RESULT_SUCCESS } from "../../../../common/const"
import { useAxios } from "../../../../common/hooks"
import { fn_Debug } from "../../../../common/module"

export const useMyPageReadHook = () => {
    const axios = useAxios();
    const [statusValue, setStatusValue] = useState<MyPageReadState>({

    })

    useEffect(() => {
        getMyInfo();
    }, [])

    const getMyInfo = async() => {
        const response = await axios.getData<MyPageReadResponse>(DEFINE_GET_MY_INFO);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setStatusValue({
                ...statusValue,
                myPageReadResponse: response.data
            });
        }
        fn_Debug("GET_MY_INFO_RESPONSE");
        fn_Debug(response);
    }

    return {
        statusValue,
    }
}