import React from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { APP_MEMBER_PATH, M_ADMIN_PATH, M_MEMBER_PATH } from '../../../common/path'
import { useLayoutHook } from '../../common/Layout.hook';
import { ROLE_ADMIN, ROLE_USER } from '../../../common/const';

const MemberLayout = () => {
  const location = useLocation();
  const layoutHook = useLayoutHook();

  return (
    <>
      <h2>회원관리</h2>
      <ul className="tapMenu">
          {
            layoutHook.statusValue.memberData?.role === ROLE_ADMIN ? <li className={location.pathname === M_MEMBER_PATH ? "on" : ""}><Link to={M_MEMBER_PATH}>엠바스 회원</Link></li> : ""
          }
          {
            // layoutHook.statusValue.memberData?.role === ROLE_USER ? <li className={location.pathname === APP_ADMIN_PATH ? "on" : ""}><Link to={APP_ADMIN_PATH}>앱관리자회원</Link></li> : ""
          }
          {
            layoutHook.statusValue.memberData?.role === ROLE_USER ? <li className={location.pathname === APP_MEMBER_PATH ? "on" : ""}><Link to={APP_MEMBER_PATH}>앱회원</Link></li> : ""
          }
          {
            layoutHook.statusValue.memberData?.role === ROLE_ADMIN ? <li className={location.pathname === M_ADMIN_PATH ? "on" : ""}><Link to={M_ADMIN_PATH}>엠바스 관리자</Link></li> : ""
          }
      </ul>
      <div className="tapContentsBg">
          <Outlet/>
      </div>
    </>
  )
}

export default MemberLayout