import { CookieSetOptions } from './../../node_modules/universal-cookie/cjs/types.d';
import { NavigateFunction } from "react-router-dom";
import { LOG_LEVEL, SECRET_KEY } from "./config"
import { LOGIN_PATH } from "./path";
import { Cookies } from "react-cookie";
import { MEM_STATUS, StatusClassType } from '../components/bo/memList/m/member/list/MMemList.model';

export const fn_Debug = (message: any): void => {
    if(LOG_LEVEL === "debug") {
        console.log(message);
    }
}

export const fn_Encrypt = (plainText: string): string => {
    const CryptoJS = require("crypto-js");
    plainText = fn_ReverseText(plainText);
    const result = CryptoJS.AES.encrypt(plainText, SECRET_KEY).toString();
    return result;
}

export const fn_Decrypt = (encryptedText?: string): string => {
    const CryptoJS = require("crypto-js");
    const bytes  = CryptoJS.AES.decrypt(encryptedText, SECRET_KEY);
    let plainText = bytes.toString(CryptoJS.enc.Utf8);
    plainText = fn_ReverseText(plainText);
    return plainText;
}

export const fn_ReverseText = (text: string): string => {
    return text.split("").reverse().join("");
}

export const fn_Empty = (val: any): boolean => {
    return val === undefined || val === null || val === "" || val.length === 0;
}

export const fn_Loginout = (navigator: NavigateFunction): void => {
    sessionStorage.clear();
    navigator(LOGIN_PATH);
}

export const fn_BlockSubmit = (e: any) => {
    e.preventDefault();
    return false;
}

export const fn_Cookies  = {
    "get": (name: string) => {
        const cookies = new Cookies();
        return cookies.get(name)
    },
    "getAll": () => {
        const cookies = new Cookies();
        return cookies.getAll();
    },
    "set": (name: string, value: string, option?: CookieSetOptions) => {
        const cookies = new Cookies();
        cookies.set(name, value, option);
    }
}

export const getMemberStatus = (memberStatus: MEM_STATUS): StatusClassType => {
    switch(memberStatus) {
        case "GREEN":
            return "act";

        case "BLACK":
            return "wthdr";

        case "BLUE":
            return "sleep";

        case "RED":
            return "fire";

        case "YELLOW":
            return "stop";
    }
}

export const getMemberStatusStr = (memberStatus: MEM_STATUS): string => {
    switch(memberStatus) {
        case "GREEN":
            return "활동";

        case "BLACK":
            return "강퇴";

        case "BLUE":
            return "휴면";

        case "RED":
            return "탈퇴";

        case "YELLOW":
            return "사용중지";
    }
}

export const joinArrToString = (arr?: string[], separator?: string) => {
    if(!arr) {
        return "";
    }

    return arr.join(separator);
}
