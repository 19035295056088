import React from 'react'
import { useNavigate } from 'react-router-dom'
import { CONSULT_READ_PATH } from '../../../../common/path';

const ConsultList = () => {
  const navigator = useNavigate();

  return (
    <>
      <h2>요청상담 관리</h2>
        <div className="flex spaceB alignC" style={{marginTop: "20px"}}>
          <div className="search">
            <select>
              <option value="all">전체</option>
              <option value="ID" selected>아이디</option>
              <option value="name">이름</option>
              <option value="phone">연락처</option>
              <option value="email">이메일</option>
            </select>
            <input type="text" placeholder="검색어를 입력해주세요."/>
            <button><img src={require("../../../../img/icon_search_white.png")} alt="검색" /></button>
          </div>
          <div className="filter">
            <select>
              <option value="all" selected>접수채널</option>
              <option value="all">전체</option>
              <option value="bo">bo</option>
              <option value="fo">fo</option>
            </select>
            <select>
              <option value="all" selected>상담분류</option>
              <option value="all">전체</option>
              <option value="Consult1">앱생성</option>
              <option value="Consult2">회사설명</option>
              <option value="Consult3">템플릿</option>
              <option value="Consult4">메인페이지</option>
              <option value="Consult5">서브페이지</option>
              <option value="Consult6">기본기능</option>
              <option value="Consult7">추가기능</option>
              <option value="Consult8">앱고유기능</option>
              <option value="Consult9">마케팅상담</option>
              <option value="Consult10">광고상담</option>
              <option value="Consult11">제휴상담</option>
              <option value="Consult12">일반상담</option>
            </select>
            <select>
              <option value="all">전체</option>
              <option value="unanswer" selected>미답변</option>
              <option value="answer">답변완료</option>
              <option value="complete">처리완료</option>
            </select>
          </div>
        </div>

        <div className="boxContents">
          <table className="table table--block">
            <thead>
              <tr>
                <th className="tableShort">No</th>
                <th>접수채널</th>
                <th>상담분류</th>
                <th>아이디</th>
                <th>연락처</th>
                <th>아이디</th>
                <th>날짜</th>
                <th>상태</th>
              </tr>
            </thead>
            <tbody>
              <tr onClick={() => {navigator(CONSULT_READ_PATH)}}>
                <td data-name="No.">100</td>
                <td data-name="접수채널">Fo</td>
                <td data-name="상담분류">앱생성</td>
                <td data-name="아이디">user123</td>
                <td data-name="연락처">010-0000-0000</td>
                <td data-name="아이디">aaa@aaa.com</td>
                <td data-name="날짜">YYYY-MM-DD</td>
                <td data-name="상태"><span className="use">답변완료</span></td>
              </tr>
              <tr>
                <td data-name="No.">99</td>
                <td data-name="접수채널">Fo</td>
                <td data-name="상담분류">앱용도 선택</td>
                <td data-name="아이디">user123</td>
                <td data-name="연락처">010-0000-0000</td>
                <td data-name="아이디">aaa@aaa.com</td>
                <td data-name="날짜">YYYY-MM-DD</td>
                <td data-name="상태"><span className="notuse">미답변</span></td>
              </tr>
              <tr>
                <td data-name="No.">98</td>
                <td data-name="접수채널">Fo</td>
                <td data-name="상담분류">앱생성</td>
                <td data-name="아이디">user123</td>
                <td data-name="연락처">010-0000-0000</td>
                <td data-name="아이디">aaa@aaa.com</td>
                <td data-name="날짜">YYYY-MM-DD</td>
                <td data-name="상태"><span className="sleep">처리완료</span></td>
              </tr>
              <tr>
                <td data-name="No.">97</td>
                <td data-name="접수채널">Fo</td>
                <td data-name="상담분류">회사정보</td>
                <td data-name="아이디">user123</td>
                <td data-name="연락처">010-0000-0000</td>
                <td data-name="아이디">aaa@aaa.com</td>
                <td data-name="날짜">YYYY-MM-DD</td>
                <td data-name="상태"><span className="use">답변완료</span></td>
              </tr>
              <tr>
                <td data-name="No.">96</td>
                <td data-name="접수채널">Fo</td>
                <td data-name="상담분류">템플릿 설정</td>
                <td data-name="아이디">user123</td>
                <td data-name="연락처">010-0000-0000</td>
                <td data-name="아이디">aaa@aaa.com</td>
                <td data-name="날짜">YYYY-MM-DD</td>
                <td data-name="상태"><span className="notuse">미답변</span></td>
              </tr>
              <tr>
                <td data-name="No.">95</td>
                <td data-name="접수채널">Fo</td>
                <td data-name="상담분류">추가기능 설정</td>
                <td data-name="아이디">user123</td>
                <td data-name="연락처">010-0000-0000</td>
                <td data-name="아이디">aaa@aaa.com</td>
                <td data-name="날짜">YYYY-MM-DD</td>
                <td data-name="상태"><span className="notuse">미답변</span></td>
              </tr>
              <tr>
                <td data-name="No.">94</td>
                <td data-name="접수채널">Fo</td>
                <td data-name="상담분류">마케팅상담</td>
                <td data-name="아이디">user123</td>
                <td data-name="연락처">010-0000-0000</td>
                <td data-name="아이디">aaa@aaa.com</td>
                <td data-name="날짜">YYYY-MM-DD</td>
                <td data-name="상태"><span className="use">답변완료</span></td>
              </tr>
              <tr>
                <td data-name="No.">93</td>
                <td data-name="접수채널">Fo</td>
                <td data-name="상담분류">광고상담</td>
                <td data-name="아이디">user123</td>
                <td data-name="연락처">010-0000-0000</td>
                <td data-name="아이디">aaa@aaa.com</td>
                <td data-name="날짜">YYYY-MM-DD</td>
                <td data-name="상태"><span className="sleep">처리완료</span></td>
              </tr>
              <tr>
                <td data-name="No.">92</td>
                <td data-name="접수채널">Fo</td>
                <td data-name="상담분류">제휴상담</td>
                <td data-name="아이디">user123</td>
                <td data-name="연락처">010-0000-0000</td>
                <td data-name="아이디">aaa@aaa.com</td>
                <td data-name="날짜">YYYY-MM-DD</td>
                <td data-name="상태"><span className="notuse">미답변</span></td>
              </tr>
              <tr>
                <td data-name="No.">91</td>
                <td data-name="접수채널">Fo</td>
                <td data-name="상담분류">일반상담</td>
                <td data-name="아이디">user123</td>
                <td data-name="연락처">010-0000-0000</td>
                <td data-name="아이디">aaa@aaa.com</td>
                <td data-name="날짜">YYYY-MM-DD</td>
                <td data-name="상태"><span className="use">답변완료</span></td>
              </tr>
              <tr>
                <td data-name="No.">90</td>
                <td data-name="접수채널">Bo</td>
                <td data-name="상담분류">앱정보</td>
                <td data-name="아이디">user123</td>
                <td data-name="연락처">010-0000-0000</td>
                <td data-name="아이디">aaa@aaa.com</td>
                <td data-name="날짜">YYYY-MM-DD</td>
                <td data-name="상태"><span className="use">답변완료</span></td>
              </tr>
              <tr>
                <td data-name="No.">89</td>
                <td data-name="접수채널">Bo</td>
                <td data-name="상담분류">회사정보</td>
                <td data-name="아이디">user123</td>
                <td data-name="연락처">010-0000-0000</td>
                <td data-name="아이디">aaa@aaa.com</td>
                <td data-name="날짜">YYYY-MM-DD</td>
                <td data-name="상태"><span className="notuse">미답변</span></td>
              </tr>
              <tr>
                <td data-name="No.">88</td>
                <td data-name="접수채널">Bo</td>
                <td data-name="상담분류">템플릿 설정</td>
                <td data-name="아이디">user123</td>
                <td data-name="연락처">010-0000-0000</td>
                <td data-name="아이디">aaa@aaa.com</td>
                <td data-name="날짜">YYYY-MM-DD</td>
                <td data-name="상태"><span className="sleep">처리완료</span></td>
              </tr>
              <tr>
                <td data-name="No.">87</td>
                <td data-name="접수채널">Bo</td>
                <td data-name="상담분류">추가기능 설정</td>
                <td data-name="아이디">user123</td>
                <td data-name="연락처">010-0000-0000</td>
                <td data-name="아이디">aaa@aaa.com</td>
                <td data-name="날짜">YYYY-MM-DD</td>
                <td data-name="상태"><span className="use">답변완료</span></td>
              </tr>
              <tr>
                <td data-name="No.">86</td>
                <td data-name="접수채널">Bo</td>
                <td data-name="상담분류">1:1상담</td>
                <td data-name="아이디">user123</td>
                <td data-name="연락처">010-0000-0000</td>
                <td data-name="아이디">aaa@aaa.com</td>
                <td data-name="날짜">YYYY-MM-DD</td>
                <td data-name="상태"><span className="sleep">처리완료</span></td>
              </tr>
            </tbody>
          </table>
          <div className="page">
            <ul className="pagination modal">
              <li className="arrow_left">처음</li>
              <li className="num active">1</li>
              <li className="num">2</li>
              <li className="num">3</li>
              <li className="num">4</li>
              <li className="num">5</li>
              <li className="arrow_right">다음</li>
            </ul>
          </div>
        </div>
    </>
  )
}

export default ConsultList