/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Collapse } from '@mui/material'
import { useRef } from 'react';

export type ServerityType = 'success' | 'error' | 'warning' | 'info';

export interface Props {
    open: boolean,
    alertText: string,
    serverity: ServerityType,
    autoFocus?: boolean,
}

const NoActionAlert:React.FC<Props> = (props: Props) => {
  const alertRef = useRef<HTMLDivElement>(null);

  return (
    <Collapse in={props.open} timeout={2}>
        <Alert ref={alertRef} severity={props.serverity}>{props.alertText}</Alert>
    </Collapse>
  )
  // return (
  //   <Box sx={{ width: 500 }}>
  //     <Snackbar
  //       anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
  //       open={props.open}
  //       key={"bottomcenter"}
  //       autoHideDuration={6000}
  //       onClose={props.onClose}
  //     >
  //       <Alert severity={props.serverity}>{props.alertText}</Alert>
  //     </Snackbar>
  //   </Box>
  // );
}

export default NoActionAlert