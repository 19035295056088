import React from 'react'
import { useNavigate } from 'react-router-dom'
import { TEM_LIST_PATH } from '../../../../../common/path';
import Editor from '../../../../common/Editor';
import { useTemUpdateHook } from './TemUpdate.hook';
import FileUpload from '../../../../common/FileUpload';
import { EXCEL_ACCEPT } from '../../../../../common/const';

const TemUpdate = () => {
  const navigator = useNavigate();
  const temUpdateHook = useTemUpdateHook();

  return (
    <>
        <h2>템플릿 관리 수정</h2>

        <div className="boxContents">
        <table className="tableCol">
            <tr>
            <th>이름</th>
            <td>
                <input 
                    type="text" 
                    name='name'
                    value={temUpdateHook.statusValue.temUpdateRequest.name} 
                    onChange={temUpdateHook.onChange}
                    placeholder="이름을 입력하세요."/>
            </td>
            </tr>
            <tr>
            <th>앱용도</th>
            <td>
                {
                    temUpdateHook.tempalteHook.statusValue?.temCategoryResponse?.categoryList ? temUpdateHook.tempalteHook.statusValue?.temCategoryResponse.categoryList.map(cate => (
                        <label>
                            <input 
                                type="checkbox" 
                                name="categoryList" 
                                value={JSON.stringify(cate)}
                                onClick={temUpdateHook.onChangeCate}
                                defaultChecked={temUpdateHook.appUseDefaultChecked(cate)}
                                />
                            <span>{cate.name}</span>
                        </label>
                    )) : ""
                }
            </td>
            </tr>
            <tr>
            <th>git url</th>
            <td>
                <input 
                    type="text" 
                    name='gitPath'
                    value={temUpdateHook.statusValue.temUpdateRequest.gitPath} 
                    onChange={temUpdateHook.onChange}
                    placeholder="git url을 입력하세요."/>
            </td>
            </tr>
            <tr>
            <th>관련 기획서 표준 양식</th>
            <td>
                <FileUpload
                    isSingle={true}
                    accept={EXCEL_ACCEPT}
                    idx={0}
                    afterPost={temUpdateHook.onChangeFileId}
                />
            </td>
            </tr>
            <tr>
            <th>관련 기획서 표준 URL</th>
            <td><input type="text" placeholder="URL 주소를 입력해주세요"/></td>
            </tr>
            <tr>
                <th>메인색상</th>
                <td>
                    <select name="color" 
                        value={temUpdateHook.statusValue.temUpdateRequest.color}
                        onChange={temUpdateHook.onChange}>
                        <option value="">색상을 선택해주세요.</option>
                        <option value={"DEFAULT"}>DEFAULT</option>
                        <option value={"MONO"}>MONO</option>
                        <option value={"ORANGE"}>ORANGE</option>
                        <option value={"GREEN"}>GREEN</option>
                        <option value={"YELLOW_GREEN"}>YELLOW_GREEN</option>
                        <option value={"PURPLE"}>PURPLE</option>
                    </select>
                </td>
            </tr>
            <tr>
            <th>설명</th>
            <td>
                <div>
                    <Editor 
                    placeholder="소개문구를 입력하세요"
                    initialData={temUpdateHook.statusValue.temUpdateRequest.content}
                    onChange={temUpdateHook.onChangeContent}/>
                    
                </div>
            </td>
            </tr>
            <tr>
            <th>예제 이미지<br/>(가로 360px * 세로640px)</th>
            <td>
                <FileUpload
                    afterPost={temUpdateHook.onChangeFileId}
                    maxFile={5}
                    fleGrpId={temUpdateHook.statusValue.originalData.fileId}
                    idx={1}
                    required={true}
                />
            </td>
            </tr>
            <tr>
            <th>추천 유형</th>
            <td>
                <label>
                <input 
                    type="radio" 
                    name="randomType" 
                    value="BASIC" 
                    onClick={temUpdateHook.onChange}
                    defaultChecked={temUpdateHook.statusValue.temUpdateRequest.randomType === "BASIC"}
                    />
                <span>기본 추천</span>
                </label>
                <label>
                <input 
                    type="radio" 
                    name="randomType" 
                    value="RANDOM"
                    onClick={temUpdateHook.onChange}
                    defaultChecked={temUpdateHook.statusValue.temUpdateRequest.randomType === "RANDOM"}/>
                <span>랜덤 추천</span>
                </label>
            </td>
            </tr>
            <tr>
            <th>사용 여부</th>
            <td>
                <label>
                <input type="radio" name="status" value="Y"
                    onClick={temUpdateHook.onChange}
                    defaultChecked={temUpdateHook.statusValue.temUpdateRequest.status === "Y"}
                />
                <span>사용</span>
                </label>
                <label>
                <input type="radio" name="status" value="N"
                    onClick={temUpdateHook.onChange}
                    defaultChecked={temUpdateHook.statusValue.temUpdateRequest.status === "N"}/>
                <span>미사용</span>
                </label>
            </td>
            </tr>
        </table>
        <div className="btnBoxB">
            <div>
            <button className="off" onClick={() => {navigator(TEM_LIST_PATH)}}>목록</button>
            {/* <button className="off" onClick={temUpdateHook.rollback}>저장값 복원</button> */}
            </div>
            <button className="on" onClick={temUpdateHook.updateTemplate}>저장</button>
        </div>
        </div>
    </>
  )
}

export default TemUpdate