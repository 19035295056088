/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react"
import { AddFuncUpdateState } from "./AddFuncUpdate.model"
import { useLocation, useNavigate } from "react-router-dom"
import { useTemplateHook } from "../../Template.hook";
import { AppUseCate } from "../../tem/list/TemList.model";
import { useAxios } from "../../../../../common/hooks";
import { AlertContext } from "../../../../../common/context";
import { DEFINE_ADDFUNC_UPDATE, RESPONSE_RESULT_SUCCESS } from "../../../../../common/const";
import { fn_Debug } from "../../../../../common/module";

export const useAddFuncUpdateHook = () => {
    const location = useLocation();
    const templateHook = useTemplateHook();
    const axios = useAxios();
    const alertContext = useContext(AlertContext);
    const navigator = useNavigate();
    const [statusValue, setStatusValue] = useState<AddFuncUpdateState>({
        addFuncUpdateReqeust: location.state["addFunc"],
        oriCategoryList: [],
    });

    useEffect(() => {
        setStatusValue({
            ...statusValue,
            oriCategoryList: templateHook.statusValue?.temCategoryResponse.categoryList!
        })
    }, [templateHook.statusValue?.temCategoryResponse.categoryList])

    const onChange = (e: any) => {
        setStatusValue({
            ...statusValue,
            addFuncUpdateReqeust: {
                ...statusValue.addFuncUpdateReqeust,
                [e.target.name] : e.target.value,
            }
        })
    }

    const onChangeContent = (data: string) => {
        setStatusValue({
            ...statusValue,
            addFuncUpdateReqeust: {
                ...statusValue.addFuncUpdateReqeust,
                content : data,
            }
        })
    }

    const onChangeAi = (data: string) => {
        setStatusValue({
            ...statusValue,
            addFuncUpdateReqeust: {
                ...statusValue.addFuncUpdateReqeust,
                ai : data,
            }
        })
    }

    const onChangePrompt = (data: string) => {
        setStatusValue({
            ...statusValue,
            addFuncUpdateReqeust: {
                ...statusValue.addFuncUpdateReqeust,
                prompt : data,
            }
        })
    }

    const onChangeCate = (e: any) => {
        if(e.target.checked) {
            setStatusValue({
                ...statusValue,
                addFuncUpdateReqeust: {
                    ...statusValue.addFuncUpdateReqeust,
                    categoryList: [
                        ...statusValue.addFuncUpdateReqeust.categoryList, JSON.parse(e.target.value)
                    ]
                }
            })
        }
        else {
            setStatusValue({
                ...statusValue,
                addFuncUpdateReqeust: {
                    ...statusValue.addFuncUpdateReqeust,
                    categoryList: 
                        statusValue.addFuncUpdateReqeust.categoryList.filter(val => val.value !== JSON.parse(e.target.value).value)
                }
            })
        }
    }

    const appUseDefaultChecked = (data: AppUseCate) => {
        const arr = location.state["addFunc"]["categoryList"];
        for(let i = 0; i < arr.length; i++) {
            if(arr[i].value === data.value) {
                return true;
            }
        }
        return false;
    }

    const onChangeFileId = (fileId: string) => {
        setStatusValue({
            ...statusValue,
            addFuncUpdateReqeust: {
                ...statusValue.addFuncUpdateReqeust,
                fileId: fileId,
            }
        })
    }

    const updateAddFunc = async() => {
        if(!validUpdateAddFunc()) return;
        const response = await axios.putData(DEFINE_ADDFUNC_UPDATE, statusValue.addFuncUpdateReqeust);

        fn_Debug("UPDATE_ADDFUNC_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            alertContext.openAlert("추가기능이 수정되었습니다.", "success");
            navigator(-1);
        }
    }

    const validUpdateAddFunc = ():boolean => {
        if(!statusValue.addFuncUpdateReqeust.name) {
            alertContext.openAlert("추가기능 이름은 필수입니다.", "warning");
            return false;
        }
        if(!statusValue.addFuncUpdateReqeust.content) {
            alertContext.openAlert("추가기능 설명은 필수입니다.", "warning");
            return false;
        }
        if(!statusValue.addFuncUpdateReqeust.categoryList) {
            alertContext.openAlert("추가기능 앱유형은 필수입니다.", "warning");
            return false;
        }
        if(!statusValue.addFuncUpdateReqeust.fileId) {
            alertContext.openAlert("추가기능 이미지는 필수입니다.", "warning");
            return false;
        }

        return true;
    }

    return {
        statusValue,
        onChange,
        onChangeAi,
        onChangeCate,
        onChangePrompt,
        onChangeContent,
        appUseDefaultChecked,
        updateAddFunc,
        onChangeFileId
    }
}