/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { MMemListResponse, MemListState } from "./MMemList.model";
import { BEARER_STRING, DEFINE_GET_M_MEM_LIST, MBAAS_ACCESS_TOKEN, RESPONSE_RESULT_SUCCESS } from "../../../../../../common/const";
import { AxiosRequestConfig } from "axios";
import { fn_Debug, fn_Decrypt } from "../../../../../../common/module";
import { ITEMS_PER_PAGE } from "../../../../../../common/config";
import { useAxios } from "../../../../../../common/hooks";

export const useMMemberListHook = () => {
    const [statusValue, setStatusValue] = useState<MemListState>({
        getMMemListRequest: {
            pageable: {
                page: 0,
                size: ITEMS_PER_PAGE,
                type: "membername",
            },
            role: "user"
        },
        pagination: {
            itemOffset: 0,
        },
        totalCount: 0,
    });
    const axios = useAxios();

    useEffect(() => {
        getMemberList();
    }, [
        statusValue.getMMemListRequest.pageable.page,
        statusValue.getMMemListRequest.pageable.sort,
        statusValue.getMMemListRequest.pageable.status,
    ])

    const getMemberList = async() => {
        const config: AxiosRequestConfig = {
            params: statusValue.getMMemListRequest.pageable,
            headers: {
                Authorization: `${BEARER_STRING}${fn_Decrypt(sessionStorage.getItem(MBAAS_ACCESS_TOKEN) ?? "")}`
            }
        };
        const response = await axios.getData<MMemListResponse>(DEFINE_GET_M_MEM_LIST, config, true, [statusValue.getMMemListRequest.role]);
        fn_Debug("GET_M_MEM_LIST_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setStatusValue({
                ...statusValue,
                mMemListResponse: response.data.memberInfoList,
                totalCount: response.data.totalCount!,
            })
        }
    }

    const setItemOffset = (newOffset:number) => {
        setStatusValue({
            ...statusValue,
            getMMemListRequest: {
                ...statusValue.getMMemListRequest,
                pageable: {
                    ...statusValue.getMMemListRequest.pageable,
                    page: newOffset / 10,
                    size: 10,
                }
            },
            pagination: {
                itemOffset: newOffset
            }
        })
    }

    const onChange = (e: any) => {
        setStatusValue({
            ...statusValue,
            getMMemListRequest: {
                ...statusValue.getMMemListRequest,
                pageable: {
                    ...statusValue.getMMemListRequest.pageable,
                    [e.target.name] : e.target.value,
                }    
            },
        })
    }

    return {
        statusValue,
        setItemOffset,
        onChange,
        getMemberList,
    }
}