import React from 'react'

const ApUpdateT3 = () => {
  return (
    <>
        <table className="tableCol">
            <tr>
            <th>추가기능 설정</th>
            <td>
                <ul className="addFuncCon">
                <li>
                    <label className="flex" style={{gap: "10px"}}>
                    <input type="checkbox"/>
                    <button className="addFuncBtn">예제 이미지 보기</button>
                    <dl>
                        <dt>추가기능 명</dt>
                        <dd>추가기능 설명</dd>
                    </dl>
                    </label>
                </li>
                <li>
                    <label className="flex" style={{gap: "10px"}}>
                    <input type="checkbox"/>
                    <button className="addFuncBtn">예제 이미지 보기</button>
                    <dl>
                        <dt>추가기능 명</dt>
                        <dd>추가기능 설명</dd>
                    </dl>
                    </label>
                </li>
                <li>
                    <label className="flex" style={{gap: "10px"}}>
                    <input type="checkbox"/>
                    <button className="addFuncBtn">예제 이미지 보기</button>
                    <dl>
                        <dt>추가기능 명</dt>
                        <dd>추가기능 설명</dd>
                    </dl>
                    </label>
                </li>
                </ul>
            </td>
            </tr>
        </table>
    </>
  )
}

export default ApUpdateT3