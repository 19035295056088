/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import { TemUpdateState } from "./TemUpdate.model";
import { fn_Debug } from "../../../../../common/module";
import { useTemplateHook } from "../../Template.hook";
import { useAxios } from "../../../../../common/hooks";
import { DEFINE_TEMPLATE_UPDATE, RESPONSE_RESULT_SUCCESS } from "../../../../../common/const";
import { AlertContext } from "../../../../../common/context";
import { AppUseCate } from "../list/TemList.model";

export const useTemUpdateHook = () => {
    const navigator = useNavigate();
    const alertContext = useContext(AlertContext);
    const location = useLocation();
    const [statusValue, setStatusValue] = useState<TemUpdateState>({
        temUpdateRequest: location.state["template"],
        originalData: location.state["template"],
    });
    const axios = useAxios();
    const tempalteHook = useTemplateHook();

    useEffect(() => {
        fn_Debug(statusValue);
    }, [statusValue]);

    useEffect(() => {
        setStatusValue({
            temUpdateRequest: {
                ...location.state["template"],
                color: location.state["template"].appColor.colorType
            },
            originalData: {
                ...location.state["template"],
                color: location.state["template"].appColor.colorType
            }
            
        })
    }, []);

    const onChange = (e:any) => {
        setStatusValue({
            ...statusValue,
            temUpdateRequest: {
                ...statusValue.temUpdateRequest,
                [e.target.name] : e.target.value,
            }
        })
    }

    const onChangeCate = (e: any) => {
        if(e.target.checked) {
            setStatusValue({
                ...statusValue,
                temUpdateRequest: {
                    ...statusValue.temUpdateRequest,
                    categoryList: [
                        ...statusValue.temUpdateRequest.categoryList, JSON.parse(e.target.value)
                    ]
                }
            })
        }
        else {
            setStatusValue({
                ...statusValue,
                temUpdateRequest: {
                    ...statusValue.temUpdateRequest,
                    categoryList: 
                        statusValue.temUpdateRequest.categoryList.filter(val => val.value !== JSON.parse(e.target.value).value)
                }
            })
        }
    }

    const onChangeContent = (data: string) => {
        setStatusValue({
            ...statusValue,
            temUpdateRequest: {
                ...statusValue.temUpdateRequest,
                content: data,
            }
        })
    }

    const onChangeFileId = (fileId: string) => {
        setStatusValue({
            ...statusValue,
            temUpdateRequest: {
                ...statusValue.temUpdateRequest,
                fileId: fileId,
            }
        })
    }

    const updateTemplate = async() => {
        if(!validCreateTemplate()) return;
        const response = await axios.putData(DEFINE_TEMPLATE_UPDATE, statusValue.temUpdateRequest);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            alertContext.openAlert("템플릿이 수정되었습니다.", "success");
            navigator(-1);
        }
    }

    const rollback = () => {

    }

    const validCreateTemplate = ():boolean => {
        if(!statusValue.temUpdateRequest.name) {
            alertContext.openAlert("템플릿의 이름은 필수값입니다.", "warning");
            return false;
        }
        if(statusValue.temUpdateRequest.categoryList.length === 0) {
            alertContext.openAlert("템플릿의 앱용도는 필수값입니다.", "warning");
            return false;
        }
        if(!statusValue.temUpdateRequest.gitPath) {
            alertContext.openAlert("git url은 필수값입니다.", "warning");
            return false;
        }
        if(!statusValue.temUpdateRequest.content) {
            alertContext.openAlert("템플릿의 설명은 필수값입니다.", "warning");
            return false;
        }
        if(!statusValue.temUpdateRequest.fileId) {
            alertContext.openAlert("템플릿의 이미지는 필수값입니다.", "warning");
            return false;
        }
        // if(!statusValue.temUpdateRequest.planFileId) {
        //     alertContext.openAlert("템플릿의 기획서는 필수값입니다.", "warning");
        //     return false;
        // }

        return true;
    }

    const appUseDefaultChecked = (data: AppUseCate) => {
        const arr = statusValue.originalData.categoryList;
        for(let i = 0; i < arr.length; i++) {
            if(arr[i].value === data.value) {
                return true;
            }
        }
        return false;
    }

    return {
        statusValue,
        tempalteHook,
        onChangeCate,
        onChange,
        onChangeContent,
        updateTemplate,
        rollback,
        appUseDefaultChecked,
        onChangeFileId,
    }
}