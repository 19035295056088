import React from 'react'
import Editor from '../../../../common/Editor'
import { useNavigate } from 'react-router-dom'
import { useAddFuncCreateHook } from './AddFuncCreate.hook'
import FileUpload from '../../../../common/FileUpload';
import { ADD_FUNC_LIST_PATH } from '../../../../../common/path'

const AddFuncCreate = () => {
  const addFuncCreateHook = useAddFuncCreateHook();
  const navigator = useNavigate();

  return (
    <>
        <h2>추가기능 관리 생성</h2>

        <div className="boxContents">
            <table className="tableCol">
            <tr>
                <th>앱용도</th>
                <td>
                    {
                        addFuncCreateHook.templateHook.statusValue?.temCategoryResponse.categoryList ? addFuncCreateHook.templateHook.statusValue?.temCategoryResponse.categoryList.map(cate => (
                            <label>
                                <input 
                                    type="checkbox" 
                                    name="categoryList" value={JSON.stringify(cate)}
                                    onClick={addFuncCreateHook.onChangeCate}
                                    />
                                <span>{cate.name}</span>
                            </label>
                        )) : ""
                    }
                </td>
            </tr>
            <tr>
                <th>이름</th>
                <td>
                    <input 
                        type="text" 
                        name='name' 
                        onChange={addFuncCreateHook.onChange}
                        placeholder="이름을 입력하세요."/>
                </td>
            </tr>
            <tr>
                <th>설명</th>
                <td>
                <div>
                    <Editor 
                        placeholder='소개문구를 입력하세요'
                        onChange={addFuncCreateHook.onChangeContent}
                        />
                </div>
                </td>
            </tr>
            <tr>
                <th>예제 이미지<br/>(가로 270px * 세로130px)</th>
                <td>
                    <FileUpload
                        afterPost={addFuncCreateHook.onChangeFileId}
                        maxFile={3}
                    />
                </td>
            </tr>
            <tr>
                <th>AI 생성 표준 프롬프트<br/><button>복사</button></th>
                <td>
                <div>
                    <Editor 
                        placeholder="해당 기능 소스를 AI로 자동 생성시킬 표준 프롬프트를 입력하세요"
                        onChange={addFuncCreateHook.onChangePrompt}
                        />
                </div>
                </td>
            </tr>
            <tr>
                <th>AI 생성 표준 소스<br/><button>복사</button></th>
                <td>
                <div>
                    <Editor 
                        placeholder="AI로 자동 생성된 표준 소스를 입력하세요"
                        onChange={addFuncCreateHook.onChangeAi}
                        />
                </div>
                </td>
            </tr>
            <tr>
                <th>사용 여부</th>
                <td>
                <label>
                    <input 
                        type="radio" 
                        name="status" 
                        value="Y" 
                        onClick={addFuncCreateHook.onChange}
                        defaultChecked/>
                    <span>사용</span>
                </label>
                <label>
                    <input 
                        type="radio" 
                        name="status" 
                        value="N"
                        onClick={addFuncCreateHook.onChange}/>
                    <span>미사용</span>
                </label>
                </td>
            </tr>
            </table>
            <div className="btnBoxB">
            <button className="off" onClick={() => {navigator(ADD_FUNC_LIST_PATH)}} >목록</button>
            <div>
                <button className="off" onClick={() => {navigator(ADD_FUNC_LIST_PATH)}}>취소</button>
                <button className="on" onClick={addFuncCreateHook.createAddFunc}>생성</button>
            </div>
            </div>
        </div>
    </>
  )
}

export default AddFuncCreate