import { useEffect, useState } from "react"
import { AddFuncReadResponse, AddFuncReadState } from "./AddFuncRead.model"
import { useLocation } from "react-router-dom"
import { useAxios } from "../../../../../common/hooks";
import { DEFINE_ADDFUNC_READ, RESPONSE_RESULT_SUCCESS } from "../../../../../common/const";
import { fn_Debug } from "../../../../../common/module";

export const useAddFuncReadHook = () => {
    const location = useLocation();
    const axios = useAxios();
    const [statusValue, setStatusValue] = useState<AddFuncReadState>({
        addFuncReadRequest: {
            capabilityCode: location.state["code"],
        }
    })

    useEffect(() => {
        getAddFuncRead();
    }, [])

    const getAddFuncRead = async() => {
        const response = await axios.getData<AddFuncReadResponse>(DEFINE_ADDFUNC_READ,{},true,[statusValue.addFuncReadRequest.capabilityCode]);

        fn_Debug("GET_ADDFUNCREAD_RESPONSE");
        fn_Debug(response);

        if(response.result === RESPONSE_RESULT_SUCCESS) {
            setStatusValue({
                ...statusValue,
                addFuncReadResponse: response.data
            })
        }
    }

    const convertAppUseName = () => {
        let newArr:string[] = [];
        statusValue.addFuncReadResponse?.categoryList.forEach(cate => {
            newArr.push(cate.name);
        })

        return newArr.join(",");
    }

    return {
        statusValue,
        convertAppUseName,
    }
}