import { useNavigate } from 'react-router-dom';
import Editor from '../../../../common/Editor';

const AppUseCreate = () => {
  const navigator = useNavigate();
  
  return (
    <>
        <h2>앱용도 관리 생성</h2>

        <div className="boxContents">
        <table className="tableCol">
            <tr>
            <th>이름</th>
            <td><input type="text" placeholder="이름을 입력하세요."/></td>
            </tr>
            <tr>
            <th>설명</th>
            <td>
                <div>
                    <Editor placeholder="소개문구를 입력하세요"/>
                </div>
            </td>
            </tr>
            <tr>
            <th>사용 여부</th>
            <td>
                <label>
                <input type="radio" name="contact" value="use" checked/>
                <span>사용</span>
                </label>
                <label>
                <input type="radio" name="contact" value="notUse"/>
                <span>미사용</span>
                </label>
            </td>
            </tr>
        </table>
        <div className="btnBoxB">
            <button className="off" onClick={() => {navigator(-1)}}>목록</button>
            <div>
            <button className="off" onClick={() => {navigator(-1)}}>취소</button>
            <button className="on" onClick={() => {navigator(-1)}}>생성</button>
            </div>
        </div>
        </div>
    </>
  )
}

export default AppUseCreate