import React, { useContext, useLayoutEffect, useRef, useState } from 'react'
import FileUpload from '../../../../common/FileUpload';
import { useHelpApHook } from './HelpApUpdate.hook';
import HTMLReactParser from 'html-react-parser';
import NoActionAlert from '../../../../alert/NoActionAlert';
import { useNavigate } from 'react-router-dom';
import { useChatGPT } from '../../../../../common/hooks';
import { AlertContext } from '../../../../../common/context';
import { fn_Debug } from '../../../../../common/module';
import { CopyBlock, a11yDark } from 'react-code-blocks';

const HelpApUpdate = () => {
  const navigator = useNavigate();
  const helpApUpdateHook = useHelpApHook();
  
  const chatGPTHook = useChatGPT();
  const alertContext = useContext(AlertContext);
  const [gptCode, setGptCode] = useState<string | null>(localStorage.getItem("GPT_GENERATED_CODE") ?? "");
  
  const getGptCode = async() => {
    const content = helpApUpdateHook.statusValue.helpApreadRequest.appAsk;
    if(!content) {
      alertContext.openAlert("의견을 입력해주세요.", "warning");
      return;
    }
    fn_Debug("SEND_GPT_CODE_REQUEST");
    const response = await chatGPTHook.sendContent(content!);
    fn_Debug(response);
    localStorage.setItem("GPT_GENERATED_CODE", response.choices[0].message.content);
    setGptCode(response.choices[0].message.content);
  }

  const textbox = useRef<HTMLTextAreaElement>(null);

  function adjustHeight() {
    textbox.current!.style.height = "inherit";
    textbox.current!.style.height = `${textbox.current!.scrollHeight}px`;
  }

  useLayoutEffect(adjustHeight, []);



  return (
    <>
      <h2>앱 수정</h2>

      <div className="boxContents">
        <table className="tableCol">
          <tr>
            <th>앱이름</th>
            <td>
              <input 
                type="text" 
                value={helpApUpdateHook.statusValue.helpApreadRequest?.appName}
                name="appName"
                onChange={helpApUpdateHook.onChange}/>
            </td>
          </tr>
          <tr>
            <th>회사명</th>
            <td>
              {
                helpApUpdateHook.companyResponse?.length === 0 ?
                <NoActionAlert alertText='등록된 회사가 없습니다.' open={true} serverity='warning'/> :
                <select name="companyCode" value={helpApUpdateHook.statusValue.helpApreadRequest.companyCode} onChange={helpApUpdateHook.onChange}>
                  <option value=""
                  selected={helpApUpdateHook.statusValue.helpApreadRequest.companyCode ? true : false}>없음</option>
                  {
                    helpApUpdateHook.companyResponse?.map((comp, idx) => (
                      <option key={comp.companyCode} value={comp.companyCode} 
                        selected={comp.companyCode === helpApUpdateHook.statusValue.helpApReadResponse?.companyCode}>{comp.name}</option>
                    ))
                  }
                </select>
              }
            </td>
          </tr>
          <tr>
            <th>앱아이콘</th>
            <td>
              <FileUpload 
                afterPost={helpApUpdateHook.onChangeFileId}
                fleGrpId={helpApUpdateHook.statusValue.helpApReadResponse?.appIcon}
                maxFile={2}
                required={true}/>
                <span>* 이미지 사이즈 120x120(이미지 수정시 새로운 이미지 등록 후 삭제해야합니다.)</span>
              </td>
          </tr>
          <tr>
            <th>템플릿</th>
            <td>
              <ul className="temFlex">
                    {
                      helpApUpdateHook.temResponse ?
                      helpApUpdateHook.temResponse.defaultList.map(tem => (
                          <li key={tem.code}>
                            <label>
                              <input type="radio" name="templateCode" onChange={helpApUpdateHook.onChange} value={tem.code}/><strong>{tem.name}</strong>
                              <div className='colorWrap'>
                                <p>메인색상</p>
                                <div className='colorCon' style={{backgroundColor: "#" + tem.appColor.appFullBtnFontColor}}></div>
                                <p>#{tem.appColor.appFullBtnFontColor}</p>
                              </div>
                              <ul className="temImg">
                              {
                                tem.fileUrl.map(url => (
                                  <li><img src={url} alt={url}/></li>
                                ))
                              }
                              </ul>
                              {HTMLReactParser(tem.content)}
                            </label>
                          </li>
                        
                      )) : ""
                    }
                    {
                      helpApUpdateHook.temResponse ?
                      helpApUpdateHook.temResponse.randomList.map(tem => (
                          <li key={tem.code}>
                            <label>
                              <input type="radio" name="templateCode" onChange={helpApUpdateHook.onChange} value={tem.code}
                                defaultChecked={tem.code === helpApUpdateHook.statusValue.helpApreadRequest.templateCode}/> {tem.name}
                              <div className='colorWrap'>
                                <p>메인색상</p>
                                <div className='colorCon' style={{backgroundColor: "#" + tem.appColor.appFullBtnFontColor}}></div>
                                <p>#{tem.appColor.appFullBtnFontColor}</p>
                              </div>
                              <ul className="temImg">
                              {
                                tem.fileUrl.map(url => (
                                  <li><img src={url} alt={url}/></li>
                                ))
                              }
                              </ul>
                              {HTMLReactParser(tem.content)}
                            </label>
                          </li>
                      )) : ""
                    }                  
              </ul>
            </td>
          </tr>
          <tr>
            <th>추가기능</th>
            <td>
              <ul className="temFlex">
                {
                  // helpApUpdateHook.statusValue.capabilityListResponse ?
                  helpApUpdateHook.capaResponse?.map(capa => (
                    <li key={capa.code}>
                      <label>
                        <input 
                          type='checkbox' 
                          value={`${capa.name}^${capa.code}`} 
                          defaultChecked={helpApUpdateHook.checkSelectedCapa(capa.code)}
                          onClick={helpApUpdateHook.onChangeCapabilities}
                          />
                        {capa.name}
                        <ul className="addFuncImg">
                          {
                            capa.fileUrl.map(url => (
                              <li><img src={url} alt={url}/></li>
                            ))
                          }
                        </ul>
                        <div>{HTMLReactParser(capa.content)}</div>
                      </label>
                    </li>
                  )) 
                }
              </ul>
            </td>
          </tr>
          <tr>
            <th>의견</th>
            <td>
              <textarea 
                ref={textbox}
                onChange={(e) => {
                  helpApUpdateHook.onChange(e);
                  adjustHeight();
                }} 
                name='appAsk' 
                defaultValue={helpApUpdateHook.statusValue.helpApreadRequest.appAsk ?? ""} 
                ></textarea>
              <button onClick={getGptCode}>생성</button>
            </td>
          </tr>
          {
            gptCode ? 
            <tr>
              <th>코드</th>
              <td className='codeBlockTd'>
                <CopyBlock
                  text={gptCode}
                  language='dart'
                  theme={a11yDark}
                  codeBlock={true}
                  onCopy={() => {
                    alertContext.openAlert("코드가 복사되었습니다.", "success");
                  }}
                  customStyle={{
                    height: '800px',
                    overflow: 'scroll',
                    padding: "0px",
                    backgroudColor: "none"
                  }}
                />
              </td>
            </tr> : ""
          }
        </table>
        <div style={{textAlign:"right", marginTop:"10px"}}>
          <p>앱 수정시 최대 5분가량 소요될 수 있습니다.</p>
        </div>
        <div className="btnBoxE">
          {/* <div>
            <button className="off">중간저장</button>
          </div> */}
          <div>
            <button className="off" onClick={() => {navigator(-1)}}>취소</button>
            <button className="on" onClick={helpApUpdateHook.updateApp}>수정</button>
          </div>
        </div>
      </div>
    </>
  )
}

export default HelpApUpdate