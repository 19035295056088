import React from "react";
import { useSimAppInfoHook } from "./SimAppInfo.hook";
import HTMLReactParser from "html-react-parser";

interface Props {
  setOver: (flag: boolean) => void,
}

const SimAppInfoForPdf:React.FC<Props> = (props: Props) => {
  const simAppInfoHook = useSimAppInfoHook(props.setOver);

  return (
    <div>
        {
          simAppInfoHook.simAppList ? 
          simAppInfoHook.simAppList.map(sim => (
            <table key={sim.code} className="simAppList">
              <tr>
                <th rowSpan={2} className="appIconPlan">
                  <img
                    src={`${sim.icon}?v=${window.crypto.getRandomValues(new Uint32Array(1))[0]}`}
                    alt={`${sim.icon}?v=${window.crypto.getRandomValues(new Uint32Array(1))[0]}`}
                  />
                </th>
                <td className="simappTit">{sim.name}</td>
              </tr>
              <tr>
                <td>
                  {
                    HTMLReactParser(sim.content)
                  }
                </td>
              </tr>
            </table>
          )) : ""
        }

        {/* <table className="simAppList">
          <tr>
            <th rowSpan={2} className="appIconPlan">
              <img
                src={require("../../../../img/takeout_Simapp02.png")}
                alt="유사앱 아이콘 이미지"
              />
            </th>
            <td className="simappTit">컴포즈</td>
          </tr>
          <tr>
            <td>
              ● 회원가입/본인인증 필수
              <br />
              ● 주요기능
              <br />
              - 매장조회/픽업오더/주변매장조회
              <br />- 리워드/즐겨찾기/이용후기
            </td>
          </tr>
        </table>

        <table className="simAppList">
          <tr>
            <th rowSpan={2} className="appIconPlan">
              <img
                src={require("../../../../img/takeout_Simapp03.png")}
                alt="유사앱 아이콘 이미지"
              />
            </th>
            <td className="simappTit">메가커피</td>
          </tr>
          <tr>
            <td>
              ● 회원가입/본인인증 필수
              <br />
              ● 주요기능
              <br />
              - 회원바코드/스템프/이벤트/쿠폰
              <br />- 주문하기/매장찾기
            </td>
          </tr>
        </table>

        <table className="simAppList">
          <tr>
            <th rowSpan={2} className="appIconPlan">
              <img
                src={require("../../../../img/takeout_Simapp04.png")}
                alt="유사앱 아이콘 이미지"
              />
            </th>
            <td className="simappTit">더벤티</td>
          </tr>
          <tr>
            <td>
              ● 회원가입/본인인증 필수
              <br />
              ● 주요기능
              <br />
              - 오더/푸쉬/활동뱃지
              <br />- 스팸트/쿠폰/선물하기
            </td>
          </tr>
        </table>

        <table className="simAppList">
          <tr>
            <th rowSpan={2} className="appIconPlan">
              <img
                src={require("../../../../img/takeout_Simapp05.png")}
                alt="유사앱 아이콘 이미지"
              />
            </th>
            <td className="simappTit">서브웨이</td>
          </tr>
          <tr>
            <td>
              ● 회원가입/본인인증 필수
              <br />
              ● 주요기능
              <br />
              - 픽업/배달/매장오더
              <br />- 충전하기/기프트사용
            </td>
          </tr>
        </table> */}
      </div>
  );
};

export default SimAppInfoForPdf;
